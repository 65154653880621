import { Link, useNavigate } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import styles from '../../styles/reports.module.css';
import generalStyles from '../../styles/general.module.css';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import Box from '../../components/UI/General/Box.jsx';
import Icon from '../../components/UI/General/Icon.jsx';
import Filters from '../../components/Admins/Reports/Filters.jsx';
import useGetReportsData from '../../components/Admins/Reports/helpers/useGetReportsData.jsx';
import { useStore } from '../../store/store.js';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote.jsx';

const DepartmentReport = () => {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const activeCompany = useStore((state) => state.activeCompany);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const { getReports, getExport } = useGetReportsData(getValues(), fetchTrigger);

  useEffect(() => {
    getReports();
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Department Name',
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <Link
            className={styles.nameLink}
            to={`/department-setup/${activeCompany.id}/edit/${row.id}`}
          >
            {row.name}
          </Link>
        ),
      },
      {
        name: 'Department ID',
        selector: (row) => row.id,
        wrap: true,
      },
      {
        name: 'Branch',
        selector: (row) => row.branch ?? '-',
      },
      {
        name: 'Users',
        selector: (row) => row.user ?? '-',
      },
      {
        name: 'Department Manager',
        selector: (row) => row.manager,
      },
    ],
    [],
  );
  return (
    <div>
      <Text className={styles.sectionTitle} type="h2" weight={600}>
        Department Reports
      </Text>
      <Box $radius={12} className={styles.container}>
        <form className={styles.filterForm}>
          <Filters
            control={control}
            errors={errors}
            trigger={trigger}
            watch={watch}
            clearErrors={clearErrors}
            setValue={setValue}
            filters={['users', 'depManager']}
            page="departments"
          />
          <div className={generalStyles.pageButtons}>
            <Button.Main
              onClick={() => setFetchTrigger((fetchTrigger) => !fetchTrigger)}
              $primary
              $style="pink"
              type="button"
            >
              Submit
            </Button.Main>
            <Button.Main
              onClick={() => navigate('/reports/overview')}
              $primary
              $style="gray"
              type="button"
            >
              Back to Overview
            </Button.Main>
          </div>
        </form>
      </Box>
      <br />
      <br />
      <Box $radius={12} className={styles.container}>
        <div>
          <div className={styles.exportBtnContainer}>
            <Button.Main
              $primary
              $style="pink"
              onClick={() => getExport('Department Reports')}
              $iconLeft
            >
              <Icon $width={20} $height={20} $icon="export" />
              <Text weight={500} type="subtitle">
                Export
              </Text>
            </Button.Main>
          </div>
          <div className={styles.reportsTable}>
            <DataTableBaseRemote
              columns={columns}
              separatePagination
              fetchData={getReports}
              selectableRows={false}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default DepartmentReport;
