import styled from 'styled-components';

const Fader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 9999;
  backdrop-filter: ${(props) => (props.$blur ? ' blur(2px)' : '')};
  @media all and (min-width: 1024px) {
    padding: 32px 0;
  }
`;

export default Fader;
