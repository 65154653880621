import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import SpmsService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import TabsSlider from "../../components/UI/General/TabsSlider";
import {nf} from "../../utils/nf";

const Payments = () => {
  const { tab } = useParams();
  const activeCompany = useStore((state) => state.activeCompany);

  const [filterText, setFilterText] = useState(undefined);

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: row => row?.title,
        cell: (row) => {
          const title = row.title.length > 20 ? `${row.title.slice(0, 20)}...` : row.title;
          let from;
          switch (tab) {
            case 'outstanding':
              from = 'po';
              break;
            case 'partially_paid':
              from = 'pp';
              break;
            case 'fully_paid':
              from = 'pf';
              break;
            case 'closed':
              from = 'pc';
              break;
            default:
              from = 'po';
          }
          return (
            <span data-content={"Title"} className={generalStyles.tableValue}>
             <Link to={'/purchase-orders/' + row.id} state={{from: from}}>
                <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>{title}</span>
              </Link>
            </span>
          );
        },
      },
      {
        name: 'Ref No',
        cell: row => <span data-content={"Ref No"} className={generalStyles.tableValue}><span>{row?.purchaseOrderNo}</span></span>,
        sortable: true,
        selector: row => row?.purchaseOrderNo
      },
      {
        name: 'Branch',
        cell: row => <span data-content={"Branch"} className={generalStyles.tableValue}><span>{row?.branchName}</span></span>,
        sortable: true,
        selector: row => row?.branchName
      },
      {
        name: 'Department',
        cell: row => <span data-content={"Department"} className={generalStyles.tableValue}><span>{row?.department?.name}</span></span>,
        sortable: true,
        selector: row => row?.department?.name
      },
      {
        name: 'Vendor',
        cell: row => <span data-content={"Vendor"} className={generalStyles.tableValue}><span>{row?.vendor?.legalName}</span></span>,
        sortable: true,
        selector: row => row?.vendor?.legalName
      },
      {
        name: 'Requester',
        cell: row => <span data-content={"Requester"} className={generalStyles.tableValue}><span>{row?.requester?.name}</span></span>,
        sortable: true,
        selector: row => row?.requester?.name
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return <span data-content={"Total Amount"} className={generalStyles.tableValue}><span>{cur + ' ' + nf.format(row?.total)}</span></span>;
        },
        sortable: true,
        selector: row => row?.total
      }
    ],
    [tab],
  );

  const getData = useCallback(
    (page, size) => {
      let tempTab;
      switch (tab) {
        case 'outstanding':
          tempTab = 'outstanding';
          break;
        case 'partially_paid':
          tempTab = 'partially_paid';
          break;
        case 'fully_paid':
          tempTab = 'paid';
          break;
        case 'closed':
          tempTab = 'closed';
          break;
        default:
          tempTab = 'outstanding';
      }
      const search = {
        paymentStatus: tempTab.toUpperCase(),
        purchaseOrderState: 'APPROVED',
        companyId: activeCompany?.id,
        title: filterText,
      };
      return SpmsService.getPurchaseOrders(size, page, search);
    },
    [tab, filterText, activeCompany?.id],
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#8A8B9D"
        placeholder="Search by title"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Text type="h2" weight={600}>
        Payments
      </Text>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.tabsSearch}>
          <TabsSlider>
            <NavLink
              to="/payments/overview/outstanding"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Outstanding
            </NavLink>
            <NavLink
              to="/payments/overview/partially_paid"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Partially Paid
            </NavLink>
            <NavLink
              to="/payments/overview/fully_paid"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Fully Paid
            </NavLink>
            <NavLink
              to="/payments/overview/closed"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Closed
            </NavLink>
          </TabsSlider>
          {filterCmp}
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            fetchData={getData}
          />
        </Suspense>
      </Box>
    </>
  );
};

export default Payments;
