import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import * as Button from '../../components/UI/Forms/Button';
import Box from '../../components/UI/General/Box';
import Textarea from '../../components/UI/Forms/Textarea';
import Text from '../../components/UI/Typography/Text';
import Toast from '../../components/UI/General/Toast';
import budgetServiceService from '../../services/budgetService.service';

import GlListView from './GlListView';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';

import { NumericFormat } from 'react-number-format';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import DataTableBase from '../../components/UI/General/DataTableBase';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useStore } from '../../store/store';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import { regExps } from '../../utils/regExps';
import Status from '../../components/UI/Typography/Status';
import spmsServiceService from '../../services/spmsService.service';
import Modal from '../../components/UI/Modal/Modal';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';

const BudgetsPendingApproval = () => {
  const navigate = useNavigate();
  const { companyId, budgetId } = useParams();
  const [budget, setBudget] = useState(null);
  const [tab, setTab] = useState('notes');
  const [monthsToRender, setMonthsToRender] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const user = useStore((state) => state.user);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const currentSubscription = useSubscriptionAllowed();

  const {
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const reason = watch('reason');

  const approvalColumns = useMemo(
    () => [
      {
        name: 'Approver Name',
        selector: (row) => row.approverName ?? 'System Approved',
      },
      {
        name: 'Date & Time',
        selector: (row) => row.dateApproved,
        format: (row) => moment(budget?.approval?.submissionDate).format('DD MMM, YYYY | hh:mm A'),
      },
      {
        name: 'Status',
        cell: (row) => (
          <Text weight={500} type={'body-2'}>
            <Status value={row?.status} pill />
          </Text>
        ),
      },
    ],
    [budget],
  );

  const columns = useMemo(
    () => [
      {
        name: 'GL Code',
        selector: (row) => row.glAccountName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'GL Code'} className={generalStyles.tableValue}>
            <span>{row.glAccountName}</span>
          </span>
        ),
      },
      {
        name: 'GL Description',
        selector: (row) => row.glAccountDescription,
        sortable: true,
        wrap: true,
        cell: (row) => {
          console.log(row)
          return (
            <span data-content={'GL Description'} className={generalStyles.tableValue}>
            <span>{row.glAccountDescription}</span>
          </span>
          )
        },
      },
      {
        name: 'Department',
        selector: (row) => row.departmentName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row.departmentName}</span>
          </span>
        ),
      },
      {
        name: 'Branch',
        selector: (row) => row.branchName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Branch'} className={generalStyles.tableValue}>
            <span>{row.branchName}</span>
          </span>
        ),
      },
      {
        name: 'GL Budget Value',
        cell: (row) => (
          <span data-content={'GL Budget Value'} className={generalStyles.tableValue}>
            <NumericFormat
              value={row?.glBudgetValue}
              displayType="text"
              thousandSeparator=","
              prefix={budget?.currency + ' '}
              decimalScale={2}
            />
          </span>
        ),
        sortable: true,
      },
    ],
    [budget],
  );

  const onSubmit = (isApproved) => async (data) => {
    if (currentSubscription?.plan?.name === 'Standard' && isApproved) {
      const metricsData = await spmsServiceService.getMetrics(companyId);
      const metrics = metricsData.data.data;
      if (
        (budget.budgetType === 'ANNUAL_BUDGET' &&
          metrics.approvedAnnualBudgets >= currentSubscription?.plan?.maxAnnualBudgets) ||
        (budget.budgetType === 'PROJECT_BUDGET' &&
          metrics.approvedProjectBudgets >= currentSubscription?.plan?.maxProjectBudgets)
      ) {
        setShowModal(true);
        return;
      }
    }
    const { reason } = data;
    if (!isApproved && (!reason || reason?.trim() === '')) {
      setError('reason', {
        type: 'manual',
        message: 'Please give a reason for rejecting the budget',
      });
      setTab('notes');
      return;
    }
    const requestBody = {
      reason: reason,
      status: isApproved ? 'APPROVED' : 'REJECTED',
    };
    const pac = budget.approval.approvers.filter(
      (item) => item.status === 'PENDING_APPROVAL' && item.userId !== user.id,
    ).length;
    console.log(pac);
    const to = isApproved && pac > 0 ? 'pending' : isApproved ? 'approved' : 'rejected';
    budgetServiceService
      .bugetApproval(requestBody, companyId, budgetId)
      .then((_response) => {
        setToast({
          opened: true,
          message: `Budget ${isApproved ? 'approved' : 'rejected'}`,
          type: 'success',
        });
        navigate('/budgets/' + to);
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    budgetServiceService.getBudgetById(budgetId, companyId).then((res) => setBudget(res.data.data));
  }, []);

  const updateMonthFields = () => {
    if (!budget) return;
    const months = moment.months();
    const startDate = new Date(budget.startDate);
    const endDate = new Date(budget.endDate);

    if (startDate && endDate) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endMonth = endDate.getMonth();
      const diffMonths = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

      const newMonthsToRender = [];
      for (let i = 0; i < diffMonths; i++) {
        const currentMonth = (startMonth + i) % 12;
        newMonthsToRender.push(months[currentMonth]);
      }

      setMonthsToRender(newMonthsToRender);
    } else {
      setMonthsToRender([]);
    }
  };

  const isButtonVisible = () => {
    if (!budget) return;
    const userApproval = budget.approval.approvers.find((approver) => approver.userId === user.id);
    return userApproval?.status === 'PENDING_APPROVAL';
  };

  return (
    <>
      <Text weight={600} type="h2" className="main-heading">
        {budget?.name}
      </Text>
      <Box $mobExtend $asHolder $noPadding>
        <div className={`${generalStyles.fieldsTwo} ${styles.budgetAdd}`}>
          <div className={styles.pendingDataContainer}>
            <div className={styles.savedBudgetData}>
              <div className={styles.savedField}>
                <Text weight={600}>Currency</Text>
                <Text type="subtitle">{budget?.currency}</Text>
              </div>
              <div className={styles.savedField}>
                <Text weight={600}>Start Date</Text>
                <Text type="subtitle">{moment(budget?.startDate).format('DD/MM/YYYY')}</Text>
              </div>
              <div className={styles.savedField}>
                <Text weight={600}>End Date</Text>
                <Text type="subtitle">{moment(budget?.endDate).format('DD/MM/YYYY')}</Text>
              </div>
              <div className={styles.savedField}>
                <Text weight={600}>Budget Amount</Text>
                <Text type="subtitle">
                  <NumericFormat
                    value={budget?.budgetValue}
                    displayType="text"
                    thousandSeparator=","
                    prefix={budget?.currency + ' '}
                    decimalScale={2}
                  />
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.glTable}>
          <GlListView
            budgetId={budgetId}
            companyId={companyId}
            glItems={budget?.glLineItems}
            monthsToRender={monthsToRender}
            updateMonthFields={updateMonthFields}
            columns={columns}
          />
          <form id={`cForm-budget`}>
            <TabsSlider selected={tab} className={generalStyles.tabs}>
              <span onClick={() => setTab('notes')}>Notes</span>
              <span onClick={() => setTab('history')}>History</span>
              <span onClick={() => setTab('approval ')}>Approval </span>
            </TabsSlider>
            {tab === 'notes' && (
              <div className={styles.notesContainer}>
                <div className="inp-container">
                  <Controller
                    name="reason"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 500,
                        message: 'Maximum 500 characters',
                      },
                      validate: {
                        // notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                        allowed: (v) =>
                          regExps.notes.test(v) || 'Only Alpha and Numerical characters',
                      },
                    }}
                    render={({ field }) => (
                      <Textarea
                        {...field}
                        $low
                        $counter
                        $counterMax={500}
                        placeholder="Enter notes"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                      />
                    )}
                  />
                  {errors.reason && (
                    <p className="error-message position-top-95">{errors.reason.message}</p>
                  )}
                </div>
                <NotesTab data={budget?.notes} />
              </div>
            )}
            {tab === 'history' && <HistoryTab data={budget.history} />}
            {tab === 'approval ' && (
              <DataTableBase
                data={budget.approval.approvers}
                columns={approvalColumns}
                selectableRows={false}
                pagination={false}
                separatePagination
              />
            )}
          </form>
        </div>
      </Box>
      <div className={generalStyles.pageButtons}>
        {isButtonVisible() && (
          <>
            <Button.Main
              type="button"
              $primary
              $style="pink"
              onClick={handleSubmit(onSubmit(true))}
            >
              Approve Budget
            </Button.Main>
            <Button.Main
              disabled={!isValid}
              type="button"
              $primary
              $style="gray"
              onClick={handleSubmit(onSubmit(false))}
            >
              Reject Budget
            </Button.Main>
          </>
        )}
        <Button.Main $style="pink" $primary onClick={() => navigate(-1)}>
          <span>Back to overview</span>
        </Button.Main>
      </div>
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>
            {` The Standard Subscription Plan only allows ${currentSubscription?.plan?.maxAnnualBudgets}
            annual and ${currentSubscription?.plan?.maxProjectBudgets} project budgets.`}
          </Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => navigate('/accounts-billing/subscription')}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default BudgetsPendingApproval;
