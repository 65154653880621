import React, { useState } from 'react';
import styles from '../../styles/vendor.module.css';
import Modal from '../../components/UI/Modal/Modal';
import Icon from '../../components/UI/General/Icon';
import Input from '../../components/UI/Forms/Input';
import Radio from '../../components/UI/Forms/Radio';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import spmsServiceService from '../../services/spmsService.service';
import DataTableBase from '../../components/UI/General/DataTableBase';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../store/store';

const SearchVendor = ({ setIsAddVendor }) => {
  const [selectedOption, setSelectedOption] = useState('Vendor ID');
  const [inputValue, setInputValue] = useState('');
  const activeCompany = useStore((state) => state.activeCompany);
  const [searchData, setData] = useState([]);
  const { tab } = useParams();
  const [hasSearched, setHasSeached] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleVendorSetup = () => {
    navigate('/vendors/' + activeCompany.id + '/create');
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
    setInputValue('');
  };

  const [search, setSearch] = useState(null);
  useEffect(() => {
    if (search !== null) handleSearchData(search);
  }, [search]);

  const handleSearchData = (value) => {
    setInputValue(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      if (!!value) {
        setIsLoading(true);
        const search = {
          registrationNo: selectedOption === 'Registration Number' ? value : '',
          legalName: selectedOption === 'Vendor Name' ? value : '',
          vendorId: selectedOption === 'Vendor ID' ? value : '',
        };
        spmsServiceService
          .searchVendors(search)
          .then((res) => {
            setData(res.data.data.content);
            setIsLoading(false);
            setHasSeached(true);
          })
          .catch((err) => {
            setIsLoading(false);
            setHasSeached(true);
          });
      } else {
        setData([]);
        setHasSeached(true);
      }
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  const handleVendorSetupByValue = (row) => {
    navigate(`/vendors/${activeCompany.id}/add/${row.id}`);
  };

  const columns = [
    {
      name: 'Registration number',
      selector: (row) => {
        return <a>{row.registrationNo}</a>;
      },
    },
    {
      name: 'Vendor ID',
      selector: (row) => (
        <Button.ActionLabeled>
          <Text
            className={styles.tableDataDecorations}
            weight={500}
            type="subtitle"
            onClick={() => handleVendorSetupByValue(row)}
          >
            {row.vendorKey}
          </Text>
        </Button.ActionLabeled>
      ),
    },
    {
      name: 'Legal Name',
      selector: (row) => row.legalName,
    },
    {
      name: 'City',
      selector: (row) => row.address?.city,
    },
    {
      name: 'Country',
      selector: (row) => row.address?.country,
    },
  ];

  const onClose = () => {
    setIsAddVendor(false);
    if (tab === 'create') navigate('/vendors/draft');
  };

  return (
    <Modal $show={true} $close={() => onClose()} $title="Search Vendor" $radius={12}>
      <div className={styles.mainDiv}>
        <div className={styles.radioMainDiv}>
          <div className={styles.radioChildDiv}>
            <div className={styles.textwrapper}>
              <Radio
                $size={20}
                value="Registration Number"
                checked={selectedOption === 'Registration Number'}
                onChange={() => handleRadioChange('Registration Number')}
                label="Registration Number"
                $tooltip="Search for an existing vendor via the registration number"
              />
            </div>
            <div className={styles.textwrapper}>
              <Radio
                $size={20}
                value="Vendor ID"
                checked={selectedOption === 'Vendor ID'}
                onChange={() => handleRadioChange('Vendor ID')}
                label="Vendor System ID"
                $tooltip="Search for an existing vendor via the vendor system ID"
              />
            </div>
            <div className={styles.textwrapper}>
              <Radio
                $size={20}
                value="Vendor Name"
                checked={selectedOption === 'Vendor Name'}
                onChange={() => handleRadioChange('Vendor Name')}
                label="Vendor Name"
                $tooltip="Search for an existing vendor via the vendor’s name"
              />
            </div>
          </div>
          <br />

          <div className={styles.inputField}>
            <Input
              type="text"
              $iconName="search"
              $iconColor="#8A8B9D"
              placeholder={`Enter ${selectedOption}`}
              value={inputValue}
              disabled={selectedOption === null}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {search !== null && (
            <div className={styles.dataTableBaseDiv}>
              {isLoading ? (
                <h2 className={styles.loader}>Loading...</h2>
              ) : (
                <DataTableBase
                  columns={columns}
                  data={searchData ? searchData : []}
                  loading={isLoading}
                  paginationPerPage={3}
                />
              )}
            </div>
          )}

          {selectedOption !== null && !isLoading && searchData?.length === 0 && hasSearched && (
            <div className={styles.createVendorButton}>
              <Button.Main $primary $style="pink" onClick={handleVendorSetup}>
                Create Vendor
              </Button.Main>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SearchVendor;
