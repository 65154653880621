import Box from '../../components/UI/General/Box';
import DataTableBase from '../../components/UI/General/DataTableBase';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/vendorProfile.module.css';
import { useEffect, useMemo, useState } from 'react';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';

const VendorClients = () => {
  const { externalId: vendorId } = useStore((state) => state.user);
  const [vendorClients, setVendorClients] = useState([]);

  useEffect(() => {
    if (!vendorId) return;
    spmsServiceService.getVendorCompaniesAttached(vendorId).then((res) => {
      const clients = res.data.data;
      const branches = clients
        .map((client) =>
          client.branches.map((branch) => ({ ...branch, companyName: client.company.name })),
        )
        .flat();
      setVendorClients(branches);
    });
  }, [vendorId]);

  const columns = useMemo(
    () => [
      {
        name: 'Company Name',
        sortable: true,
        wrap: true,
        selector: (row) => row.companyName,
      },
      {
        name: 'Branch Name',
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: 'Country',
        selector: (row) => row.address?.country,
        wrap: true,
      },
      {
        name: 'Phone Number',
        selector: (row) => row.phoneNumber,
        wrap: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        wrap: true,
      },
      {
        name: 'Total Order',
        selector: (row) => row.numberOfOrders,
        wrap: true,
      },
    ],
    [vendorClients],
  );

  return (
    <Box $radius={12} className={styles.wrapper}>
      <div className={styles.title}>
        <Text weight={600} type="h2" className="main-heading">
          Clients Overview
        </Text>
      </div>
      <DataTableBase
        data={vendorClients}
        columns={columns}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 15, 20, 30]}
      />
    </Box>
  );
};

export default VendorClients;
