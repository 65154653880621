import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import DataTableBase from '../../../UI/General/DataTableBase';
import {Link, useParams} from 'react-router-dom';
import spmsServiceService from '../../../../services/spmsService.service';
import { downloadExternal } from '../../../../utils/downloadExternal';
import generalStyles from "../../../../styles/general.module.css";
import Status from "../../../UI/Typography/Status";
import {nf} from "../../../../utils/nf";
import * as Button from "../../../UI/Forms/Button";
import Icon from "../../../UI/General/Icon";
import {useStore} from "../../../../store/store";

const PaymentsTab = (props) => {
  const { orderId } = useParams();
  const {refetch, shouldRefetch} = props;
  const user = useStore((state) => state.user);
  const [tableData, setTableData] = useState([]);
  const [updateData, setUpdateDate] = useState(false)

  const getDocument = (key, name) => {
    spmsServiceService.getAttachmentsByKey(key).then((res) => {
      const url = res.data.data;
      const fileType = name.split('.').at(-1);
      downloadExternal(url, fileType);
    });
  };

  const deleteItem = (itemId) => {
    props.setToast((item) => ({ ...item, opened: false }));
    spmsServiceService.deletePurchaseOrderPayment(orderId, itemId).then(() => {
      props.setToast({
        opened: true,
        message: 'Payment has been deleted successfully',
        type: 'success',
        cb: () => (setUpdateDate(s => !s),props.$trigger(state => state +1)),
      });
    }).catch(err => {
      props.setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    })
  }

  const columns = useMemo(
    () => [
      {
        name: 'Payment Reference',
        sortable: true,
        selector: (row) => row?.reference,
        cell: row => (
          <span data-tag="allowRowEvents" data-content={"Payment Reference"} className={generalStyles.tableValue}>
            <Link to={'/purchase-orders/' + orderId + '/payments/' + row.id} className={styles.linkHighlighted} state={{from: props?.prevLoc}}>
              {row?.reference ?? "-"}
            </Link>
          </span>
        )
      },
      {
        name: 'Linked Invoice',
        sortable: true,
        selector: (row) => row?.invoice?.id,
        cell: row => <span data-tag="allowRowEvents" data-content={"Linked Invoice"} className={generalStyles.tableValue}><span data-tag="allowRowEvents">{row?.invoice?.invoiceNumber ?? "-"}</span></span>,
      },
      {
        name: 'Action by',
        sortable: true,
        selector: (row) => row?.updatedBy?.name,
        cell: row => <span data-tag="allowRowEvents" data-content={"Action by"} className={generalStyles.tableValue}><span data-tag="allowRowEvents">{row?.updatedBy?.name ?? "-"}</span></span>,
      },
      {
        name: 'Status',
        sortable: true,
        selector: (row) => row?.paymentStatus,
        cell: row => <span data-tag="allowRowEvents" data-content={"Payment Reference"} className={generalStyles.tableValue}><Status data-tag="allowRowEvents" value={row?.paymentStatus}/></span>,
      },
      {
        name: 'Amount',
        sortable: true,
        selector: (row) => row?.amount,
        cell: row => (
          <>
            <span data-tag="allowRowEvents" data-content={"Amount"} className={generalStyles.tableValue}>
              <span data-tag="allowRowEvents">{row?.currency}&nbsp;</span>
              <span data-tag="allowRowEvents">{nf.format(row?.amount) ?? "-"}</span>
            </span>
            {(row?.createdBy?.userId === user.id && !props.viewOnly) ? (
              <div className={generalStyles.actionMenuHolder}>
                <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={() => deleteItem(row?.id)} type="button">
                  <Icon $width={20} $height={20} $icon="delete" $color="#F24638"/>
                </Button.Action>
              </div>
            ) : null}
          </>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (orderId !== null){
      spmsServiceService.getPurchaseOrderPayments(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          setTableData(r.data.data);
          shouldRefetch()
        }
      });
    }
  }, [orderId, refetch, props.status, updateData]);

  return (
    <DataTableBase
      data={tableData}
      columns={columns}
      pagination={false}
    />
  );
};

export default PaymentsTab;
