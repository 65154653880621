import React, { useEffect, useState } from 'react';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/systemRolesPermissions.module.css';
import * as Button from '../../components/UI/Forms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '../../components/UI/General/Box';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/UI/Forms/Input';
import Toast from '../../components/UI/General/Toast';
// import Users from '../../components/Admins/SystemRolesAndPermissions/Users';
import Permissions from '../../components/Admins/SystemRolesAndPermissions/Permissions';
import requestsService from '../../services/requestsService.service';
import { useStore } from '../../store/store';
// import AddUser from '../../components/Admins/SystemRolesAndPermissions/AddUser';

const CreateRole = () => {
  // const userCc = useStore((state) => state.user.customCompanies);
  const companyId = useStore((state) => state.company?.id);
  const company = useStore((state) => state.company);
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const [roleName, setRoleName] = useState();
  // const [usersData, setUsersData] = useState([]);
  // const [roleUsers, setRoleUsers] = useState();
  const [resources, setResources] = useState([]);
  const [editPermissions, setEditPermissions] = useState();
  // const [tab, setTab] = useState('permissions');
  // const [filterText, setFilterText] = useState('');
  // const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyId: '',
      roleName: '',
      roleDescription: '',
      permissions: [],
    },
    values,
  });

  const onSubmit = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const { permissionResponse, companyId: roleCompanyId, ...other } = data;
    let idOfCompany = companyId;
    if (roleCompanyId !== '' && roleCompanyId !== null) {
      idOfCompany = roleCompanyId;
    }
    if (company.parentId !== undefined && (roleCompanyId === '' || roleCompanyId === null)) {
      idOfCompany = company.parentId;
    }
    const newData = {
      ...other,
      companyId: idOfCompany,
      permissions: permissionResponse.map((item) => {
        if (typeof item.authorizationTypes === 'string') {
          item.authorizationTypes = [item.authorizationTypes];
        }
        return {
          resourceId: parseInt(item.resourceId),
          authorizationTypes: item.authorizationTypes !== false ? item.authorizationTypes : [],
        };
      }),
    };
    if (roleId !== undefined) {
      requestsService
        .editRoleByCompany(roleId, idOfCompany, newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Role updated successfully',
            type: 'success',
          });
          navigate('/user-roles-and-permissions/active');
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.toString,
            type: 'fail',
          });
        });
    } else {
      requestsService
        .createRole(newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Role created successfully',
            type: 'success',
          });
          navigate('/user-roles-and-permissions/active');
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.errors[0].errorMessage,
            type: 'fail',
          });
        });
    }
  };

  // useEffect(() => {
  //   const initData = async () => {
  //     const users = await Promise.all(
  //       userCc.map(async (item) => {
  //         return requestsService
  //           .getData(100, 0, { isEnabled: true, companyId: item.companyId })
  //           .then((r) => {
  //             return r.data.data.content.filter((el) => {
  //               const someData = el.customCompanies.map((some) => some.companyId);
  //               return someData.includes(item.companyId);
  //             });
  //           })
  //           .catch(() => null);
  //       }),
  //     );
  //     setUsersData(users[0]);
  //   };
  //   initData();
  // }, [userCc]);

  useEffect(() => {
    const getRoleData = async () => {
      await requestsService.getRoleById(roleId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const { permissionResponse, ...other } = r.data.data;
          setValues(other);
          setRoleName(r.data.data.roleName);
          setEditPermissions(permissionResponse);
        }
      });
    };
    if (roleId !== undefined) {
      getRoleData();
    }
  }, [roleId]);

  useEffect(() => {
    requestsService.getResources().then((r) => {
      if (r.data.message === 'Operation Successful') {
        if (roleId !== undefined) {
          if (editPermissions !== undefined) {
            const mergedArray = r.data.data.map((item1) => {
              const item2 = editPermissions.find((item) => item.resourceId === item1.resourceId);
              return { ...item1, authorizationTypes: item2?.authorizationTypes };
            });
            setResources(mergedArray);
          }
        } else {
          setResources(r.data.data);
        }
      }
    });
  }, [roleId, editPermissions]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box $radius={12} className={styles.formInner}>
          <div className={styles.rolesFields}>
            <div className="inp-container">
              <Controller
                name="companyId"
                control={control}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <Controller
                name="roleName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Role name is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Role Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Role Name"
                    $labelRequired
                    $tooltip="Name of the role"
                    disabled={values?.type === 'SYSTEM'}
                    {...field}
                    value={field.value === 'CLIENT_ADMIN' ? 'Site Admin' : field.value === 'END_USER' ? 'User' : field.value}
                  />
                )}
              />
              {errors.roleName && <p className="error-message">{errors.roleName.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="roleDescription"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Role description is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    $label="Description"
                    $labelRequired
                    $tooltip="More information about the role"
                    placeholder="Enter Description"
                    disabled={values?.type === 'SYSTEM'}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                )}
              />
              {errors.roleDescription && (
                <p className="error-message">{errors.roleDescription.message}</p>
              )}
            </div>
          </div>

          {/* <div className={styles.tabsWrapper}>
            <TabbedNav>
              <span onClick={() => handleTabClick('permissions')}>Permissions</span>
              <span onClick={() => handleTabClick('users')}>Users</span>
            </TabbedNav>
            {tab === 'users' && (
              <div className={generalStyles.pageButtons}>
                {filterCmp}
                <Button.ActionLabeled onClick={() => setShowModal(true)}>
                  <Button.Action $style="pink" $variant="circle" $width={20} $height={20}>
                    <Icon $width={20} $height={20} $icon="plus" $color="white" />
                  </Button.Action>
                  <Text weight={700} type="subtitle">
                    Add User
                  </Text>
                </Button.ActionLabeled>
              </div>
            )}
          </div> */}
          {/*{tab === 'permissions' ? (*/}
          {/*  <Permissions*/}
          {/*    register={register}*/}
          {/*    resources={resources.filter((resource) => resource.resourceName !== 'Configuration')}*/}
          {/*    roleName={roleName}*/}
          {/*    setValue={setValue}*/}
          {/*    getValues={getValues}*/}
          {/*    initValues={editPermissions ?? []}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <Users roleUsers={roleUsers} filterText={filterText} />*/}
          {/*)}*/}
          <Permissions
            register={register}
            resources={resources.filter((resource) => resource.resourceName !== 'Configuration')}
            roleName={roleName}
            setValue={setValue}
            getValues={getValues}
            initValues={editPermissions ?? []}
          />
        </Box>
        <div className={generalStyles.pageButtons}>
          {!['CLIENT_ADMIN', 'END_USER'].includes(roleName) && (
            <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid}>
              {roleId ? 'Update' : 'Save'}
            </Button.Main>
          )}
          <Button.Main $primary $style="gray" onClick={() => navigate(-1)} type="button">
            {['CLIENT_ADMIN', 'END_USER'].includes(roleName) ? 'Back to Roles' : 'Cancel'}
          </Button.Main>
        </div>
      </form>
      {/*{tab === 'users' && (*/}
      {/*  <AddUser usersData={usersData} showModal={showModal} setShowModal={setShowModal} />*/}
      {/*)}*/}
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default CreateRole;
