import * as Button from '../../../UI/Forms/Button.jsx';
import styles from '../../../../styles/approvalWorkflow.module.css';
import Icon from '../../../UI/General/Icon.jsx';
import Select from 'react-select';

const Pagination = ({
  itemsLength,
  currentPage,
  nextPage,
  prevPage,
  changePage,
  setItemsPerPage,
  itemsPerPage,
  endIndex,
  startIndex,
}) => {
  const pagesOptions = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 30, value: 30 },
  ];

  const pagesNumber = Math.ceil(itemsLength / itemsPerPage.value);
  const pages = new Array(pagesNumber).fill('_');

  const onSelectChange = (e) => {
    setItemsPerPage(e);
  };
  const perPageHandler = `${startIndex + 1}-${
    endIndex > itemsLength ? itemsLength : endIndex
  } of ${itemsLength}`;

  return (
    <div className={styles.paginationContainer}>
      <div className={styles.itemsPerPage}>
        <span className={styles.itemsQuantity}>{perPageHandler}</span>
        <Select
          className="react-select-container table-pagination"
          classNamePrefix="react-select"
          isSearchable={false}
          placeholder="Show Upto"
          options={pagesOptions}
          value={itemsPerPage}
          onChange={onSelectChange}
        />
      </div>
      <div className={styles.pagination}>
        <Button.Action
          $width={40}
          type="button"
          $height={40}
          $style={currentPage === 0 ? 'dark' : 'blue'}
          disabled={currentPage === 0}
          onClick={prevPage}
        >
          <Icon $icon="chevron-left" $width={24} $height={24} />
        </Button.Action>
        {pages.map((_, index) => (
          <Button.Action
            key={index}
            $width={40}
            $height={40}
            $style={currentPage === index ? 'pagination_active' : 'pagination'}
            type="button"
            onClick={() => changePage(index)}
          >
            {index + 1}
          </Button.Action>
        ))}
        <Button.Action
          $width={40}
          type="button"
          $height={40}
          $style={currentPage === pagesNumber - 1 ? 'dark' : 'blue'}
          disabled={currentPage === pagesNumber - 1}
          onClick={nextPage}
        >
          <Icon $icon="chevron-right" $width={24} $height={24} />
        </Button.Action>
      </div>
    </div>
  );
};

export default Pagination;
