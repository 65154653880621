import React, { useEffect, useState } from 'react';
import UserAccount from '../../components/Admins/Users/UserAccount';
import { useNavigate, useParams } from 'react-router-dom';
import Toast from '../../components/UI/General/Toast';
import requestsService from '../../services/requestsService.service';
import { useStore } from '../../store/store';
import { useForm, useWatch } from 'react-hook-form';
import spmsServiceService from '../../services/spmsService.service';
import generalStyles from '../../styles/general.module.css';
import style from '../../styles/users.module.css';
import * as Button from '../../components/UI/Forms/Button';

const Create = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const company = useStore((state) => state.company);
  const user = useStore((state) => state.user);
  const updateUser = useStore((state) => state.updateUser);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [values, setValues] = useState();
  const [linkedCompanies, setLinkedCompanies] = useState([]);
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '',
      roleName: [],
    },
    values,
  });
  const companiesChanged = useWatch({
    control,
    name: 'companiesChanged',
    defaultValue: false,
  });

  const submitUser = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    const { roles, roleName, ...other } = data;
    const newRoles = linkedCompanies.flatMap((lcompany) => {
      return lcompany.roles.map((role) => {
        return {
          company: { id: lcompany.companyId, name: lcompany.companyName },
          role: { id: role.value, name: role.label },
        };
      });
    });
    const newData = {
      ...other,
      customCompanies: linkedCompanies.map((company) => {
        const { department, branch } = company;
        console.log('company', company);
        return {
          ...company,
          department: department?.id,
          branchId: branch?.id,
          roles: company.roles.map((role) => role.label),
        };
      }),
      roles: newRoles,
    };
    if (userId) {
      try {
        const response = await requestsService.updateUserInfo(userId, newData);
        if (response.data.message === 'Operation Successful') {
          if (response.data.data.id === user.id) {
            updateUser(response.data.data);
          }
          setToast({
            opened: true,
            message: 'User updated',
            type: 'success',
            cb: () => navigate('/users/overview/active'),
          });
        }
      } catch (err) {
        setToast({
          opened: true,
          message: err.toString(),
          type: 'fail',
        });
      } finally {
        setIsButtonsBlocked(false);
      }
    } else {
      try {
        const response = await requestsService.registerUser(newData);
        if (response.data.message === 'Operation Successful') {
          setToast({
            opened: true,
            message: 'User created',
            type: 'success',
            cb: () => navigate('/users/overview/active'),
          });
        }
      } catch (error) {
        setToast({
          opened: true,
          message:
            error?.response?.data?.errors?.[0]?.errorMessage === undefined
              ? error?.response?.data?.message
              : error?.response?.data?.errors?.[0]?.errorMessage,
          type: 'fail',
        });
      } finally {
        setIsButtonsBlocked(false);
      }
    }
  };

  useEffect(() => {
    const fetchLimitTo = async () => {
      await spmsServiceService.getCompany(company.id).then((r) => {
        if (r.data.data?.subsidiaries && r.data.data?.subsidiaries.length > 0) {
          const tempSubs = r.data.data?.subsidiaries.filter((c) => c.active === true);
          const companies = [...tempSubs, r.data.data]
            .map((item) => ({
              label: item.name,
              value: item.id,
            }))
            .sort((a, b) => a.value - b.value);
          setCompanies(companies);
        } else {
          const company = [{ label: r.data.data.name, value: r.data.data.id }];
          setCompanies(company);
        }
      });
      await requestsService
        .getRolesByCompany(company.parentId !== undefined ? company.parentId : company.id)
        .then((r) => {
          if (r.data.message === "Operation Successful") {
            console.log(r.data)
            console.log(userId)
            console.log(company)
            const isUserMain = company?.createdBy?.userId === userId;
            console.log('company.createdBy.userId', company?.createdBy?.userId);
            console.log('user.id', user.id);
            console.log('isUserMain', isUserMain);
            const updRoles = r.data.data
              .filter((item) => item.isEnabled === true)
              .map((item) => ({
                label: item.roleName,
                value: item.roleId,
                isDisabled: item.roleName === 'CLIENT_ADMIN' && isUserMain,
              }));
            setRoles(updRoles);
          }
        });
    };
    if (company !== null && company.id !== undefined) {
      fetchLimitTo();
    }
  }, [company]);

  useEffect(() => {
    if (userId !== undefined && roles !== undefined) {
      requestsService.getUserDetailsById(userId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const { customCompanies, id, status, ...other } = r.data.data;
          setIsUserInvited(status === 'INVITED');
          let updCustomCompanies;
          if (
            customCompanies !== undefined &&
            Array.isArray(customCompanies) &&
            customCompanies.length > 0
          ) {
            const tempCompanies = companies.map((item) => item.value);
            const tempCustomCompanies = customCompanies.filter((el) =>
              tempCompanies.includes(el.companyId),
            );
            updCustomCompanies = tempCustomCompanies
              .map((item) => {
                const companyRoles = r.data.data.roles
                  .filter((role) => role.company.id === item.companyId)
                  .map((role) => role.role.id);
                const updRoles = roles.filter((role) => companyRoles?.includes(role.value));
                return {
                  ...item,
                  roles: updRoles,
                  companyName: companies.filter((el) => el.value === item.companyId)[0].label,
                  // department: item.departmentId,
                };
              })
              .sort((a, b) => a.companyId - b.companyId);
          } else {
            updCustomCompanies = [];
          }
          setValues({
            ...other,
            userId: id,
            companiesChanged: false,
          });
          setLinkedCompanies(updCustomCompanies);
        }
      });
    }
  }, [userId, roles, companies]);

  return (
    <>
      <form onSubmit={handleSubmit(submitUser)}>
        <UserAccount
          control={control}
          errors={errors}
          roles={roles}
          companies={companies}
          userId={userId}
          setLinkedCompanies={setLinkedCompanies}
          linkedCompanies={linkedCompanies}
          setValue={setValue}
          isUserInvited={isUserInvited}
        />
        <div className={generalStyles.pageButtons + ' ' + style.legalEntitySetupBtns}>
          <Button.Main
            $primary
            $style="pink"
            type="submit"
            disabled={
              !(
                (companiesChanged || isDirty) &&
                isValid &&
                !!linkedCompanies.length &&
                !isButtonsBlocked
              )
            }
          >
            {userId ? 'Edit' : 'Invite'} User
          </Button.Main>
          <Button.Main
            $primary
            $style={
              !((companiesChanged || isDirty) && isValid && !!linkedCompanies.length)
                ? 'pink'
                : 'gray'
            }
            onClick={() => navigate('/users/overview/active')}
            type="button"
          >
            Discard
          </Button.Main>
        </div>
      </form>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Create;
