import { useStore } from './store';
import requestsService from "../services/requestsService.service";

export const userSlice = (set, get) => ({
  user: null,
  updateUser: (data) => set({ user: data }),
  reset: () => {
    set({ user: null });
    useStore.setState({
      company: null,
      companyId: null,
      stepsDone: false,
      activeCompany: null,
      fullCompanies: [],
    });
  },
  fetchFreshUserData: () => {
    requestsService.getUserDetailsById(get().user.id).then((r) => {
      if (r.data.message === "Operation Successful"){
        set({ user: r.data.data })
      }
    })
  }
});
