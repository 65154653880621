import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import SignUp from "../components/Auth/SingUp";
import Verify from "../components/Auth/Verify";
import Box from "../components/UI/General/Box";
import Text from "../components/UI/Typography/Text";
import Icon from "../components/UI/General/Icon";
import style from "../styles/log_reg.module.css";

const Registration = () => {
  const [status, setStatus] = useState('sign-up');
  const [username, setUsername] = useState(null);

  return (
    <div className={style.auth}>
      <div className={style.authInner}>
        <Box $noPadding $radius={40} className={style.authContainer}>
          <div className={style.authImage}>
            <img src="assets/img/login_register/registration.svg" alt="registration"/>
            <div className={style.imageTexts + " " + style.imageTextsReg}>
              <Text type="h2" weight={600}>Start your 14-day free trial</Text>
              <Text type="subtitle" weight={500}>Try POMS today risk free</Text>
              <div className={style.imageText}>
                <Icon $icon="marked" $width={24} $height={24}/>
                <Text type="subtitle" weight={500}>Unlimited Users</Text>
              </div>
              <div className={style.imageText}>
                <Icon $icon="marked" $width={24} $height={24}/>
                <Text type="subtitle" weight={500}>Unlimited Requisitions</Text>
              </div>
              <div className={style.imageText}>
                <Icon $icon="marked" $width={24} $height={24}/>
                <Text type="subtitle" weight={500}>Unlimited Workflows</Text>
              </div>
            </div>
          </div>
          <div className={style.authContent}>
            <div className={style.authContentLogo}>
              <Link to={'/'}>
                <img src="assets/img/login_register/logo.svg" alt="Poms"/>
              </Link>
            </div>
            {status === 'sign-up' ? <SignUp setStatus={setStatus} setUsername={setUsername}/> : null}
            {status === 'verify' ? <Verify setStatus={setStatus} username={username}/> : null}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Registration;
