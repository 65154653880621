import styles from '../../../styles/approvalWorkflow.module.css';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import spmsServiceService from '../../../services/spmsService.service';
import getUsersAccess from '../ApprovalWorkflow/helpers/getUsersAccess';
import * as Button from '../../UI/Forms/Button.jsx';
import Icon from '../../UI/General/Icon';

const NotificationCondition = ({
  conditionId,
  setValue,
  control,
  watch,
  errors,
  companyBranches,
  companyId,
  deleteCondition,
  prevCondition,
  selectedSubjects,
  setIsAddConditionActive,
  module,
}) => {
  const [conditionOptions, setConditionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const subject = watch(`subject-${conditionId}`);
  const subjectCondition = watch(`subjectCondition-${conditionId}`);
  const conditionValue = watch(`conditionValue-${conditionId}`);
  const [page, setPage] = useState(0);
  const [allGlAmount, setAllGlAmount] = useState(null);
  const typeOptions = [
    { label: 'Branch', value: 'BRANCH' },
    { label: 'Department', value: 'DEPARTMENT' },
    { label: 'GL', value: 'GL' },
    { label: 'User', value: 'USER' },
    { label: 'Vendor', value: 'VENDOR' },
  ];
  const insOptions = [
    { label: 'IS', value: 'NOT_IN' },
    { label: 'IN', value: 'IN' },
  ];

  const permissions = {
    PURCHASE_ORDERS: 'Purchase_Order',
    REQUISITIONS: 'Requisition',
    BUDGETS: 'Budgets',
    VENDORS: 'Vendors',
    RECEIVING: 'Receiving',
    INVOICING: 'Invoicing',
    PAYMENTS: 'Payments',
  };

  useEffect(() => {
    setIsAddConditionActive(!!subject && !!subjectCondition && !!conditionValue);
  }, [subject, subjectCondition, conditionValue]);

  useEffect(() => {
    if (!prevCondition) return;
    setValue(
      `subject-${conditionId}`,
      typeOptions.find((option) => option.value === prevCondition.subject),
    );
    setValue(
      `subjectCondition-${conditionId}`,
      insOptions.find((option) => option.value === prevCondition.condition),
    );
  }, [prevCondition]);

  useEffect(() => {
    if (!prevCondition) return;
    const prevConditionOptions = conditionOptions.filter((option) =>
      prevCondition.values.includes(option.value),
    );
    setValue(`conditionValue-${conditionId}`, prevConditionOptions);
  }, [conditionOptions, prevCondition]);

  useEffect(() => {
    const geConditionOptions = async () => {
      setIsLoading(true);
      if (!subject) return;
      setConditionOptions([]);
      switch (subject.value) {
        case 'BRANCH':
          setConditionOptions(handleSelectData(companyBranches, 'name', 'id'));
          setIsLoading(false);
          return;
        case 'DEPARTMENT':
          spmsServiceService.getDepartments(companyId).then((res) => {
            setConditionOptions(handleSelectData(res.data.data, 'name', 'id'));
            setIsLoading(false);
          });
          return;
        case 'GL':
          spmsServiceService.getGlAccounts(companyId).then((res) => {
            setConditionOptions(handleSelectData(res.data.data.content, 'glCode', 'id'));
            setIsLoading(false);
          });
          return;
        case 'USER':
          getUsersAccess(companyId, 'VIEW_ALL', [permissions[module.value]]).then((requestors) => {
            setConditionOptions(
              requestors
                .filter((requester) => requester.status === 'ACTIVE')
                .map((requestor) => ({
                  label: `${requestor.firstName} ${requestor.lastName}`,
                  value: requestor.email,
                })),
            );
            setIsLoading(false);
          });
          return;
        case 'VENDOR':
          spmsServiceService
            .getVendors(companyId, { active: true, vendorStatus: 'APPROVED' })
            .then((res) => {
              setConditionOptions(
                res.data.data.content.map((vendor) => ({
                  label: vendor.vendor.legalName,
                  value: vendor.id,
                })),
              );
              setIsLoading(false);
            });
      }
    };
    geConditionOptions();
  }, [subject, module]);

  const fetchGlLines = useCallback(async () => {
    if (Math.round(allGlAmount / 10) >= page) {
      const result = await spmsServiceService.getFilteredGlAccounts(companyId, page, 10, {
        active: true,
        glCode: '',
      });
      const glData = result.data.data.content;
      const allGls = result.data.data.totalElements;
      if (!allGlAmount) setAllGlAmount(allGls);
      const glOptions = handleSelectData(glData, 'glCode', 'id');
      setConditionOptions((conditionOptions) =>
        page === 0 ? glOptions : [...conditionOptions, ...glOptions],
      );
    }
  }, [page]);

  useEffect(() => {
    if (subject?.value === 'GL') {
      fetchGlLines();
    }
  }, [fetchGlLines, subject]);

  const handleSelectData = (data, labelProp, valueProp) => {
    return data
      .filter((item) => !!item.active)
      .map((item) => ({
        label: item[labelProp],
        value: String(item[valueProp ?? labelProp]),
      }));
  };
  const isFieldRequired = !!subject || !!subjectCondition || !!conditionValue;

  return (
    <div className={styles.singleCondition}>
      <div className={styles.rowContainer}>
        <div className="inp-container">
          <Controller
            name={`subject-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: '100 !important',
                  }),
                }}
                onChange={(e) => {
                  field.onChange(e);
                  setValue(`subjectCondition-${conditionId}`, null);
                  setValue(`conditionValue-${conditionId}`, null);
                }}
                isDisabled={!module?.value}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select"
                isOptionDisabled={(option) =>
                  selectedSubjects.find((subject) => subject?.value === option.value)
                }
                options={typeOptions}
              />
            )}
          />
          {errors[`subject-${conditionId}`] && (
            <p className="error-message">{errors[`subject-${conditionId}`]?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`subjectCondition-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setValue(`conditionValue-${conditionId}`, null);
                }}
                isDisabled={!module?.value}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select"
                options={insOptions}
              />
            )}
          />
          {errors[`subjectCondition-${conditionId}`] && (
            <p className="error-message">{errors[`subjectCondition-${conditionId}`]?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`conditionValue-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
            }}
            render={({ field }) => (
              <Select
                isMulti={subjectCondition?.value === 'IN'}
                isDisabled={!subject?.value || !module?.value}
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                onMenuScrollToBottom={() => {
                  if (Math.floor(allGlAmount / 10) > page && subject?.value === 'GL') {
                    setPage((page) => page + 1);
                  }
                }}
                isSearchable={false}
                placeholder="Select"
                options={conditionOptions}
              />
            )}
          />

          {errors[`conditionValue-${conditionId}`] && (
            <p className="error-message">{errors[`conditionValue-${conditionId}`]?.message}</p>
          )}
          {!conditionOptions.length && subject?.value && !isLoading && (
            <p className="error-message">Please create a {subject.value}</p>
          )}
        </div>
      </div>
      <div className={styles.deleteButton}>
        <Button.Action
          $variant="circle"
          $style="lightGrayishCyan"
          // disabled={conditionIds.length <= 1}
          $width={40}
          $height={40}
          onClick={() => deleteCondition(conditionId)}
          type="button"
        >
          <Icon $width={20} $height={20} $icon="delete" $color="#F24638" />
        </Button.Action>
      </div>
    </div>
  );
};

export default NotificationCondition;
