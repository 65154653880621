import {axiosInstance, baseUrl, s2pmsBaseUrl} from './API';

class SpmsService {
  getCompanies(size, page) {
    return axiosInstance.get(s2pmsBaseUrl + '/company?page=' + page + '&size=' + size);
  }
  getCompany(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + id);
  }
  activateCompany(companyId) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/activate');
  }
  archiveCompany(companyId) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/archive');
  }
  getCompanyBranches(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + id + '/branches');
  }
  getCompanyContacts(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/get-contacts');
  }
  updateCompanyContacts(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/update-contacts', data);
  }
  activateBranch(companyId, branchId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/activate-branch/' + branchId,
    );
  }
  createDummyCompany(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/demo/create-demo-company', data);
  }

  archiveBranch(companyId, branchId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/archive-branch/' + branchId,
    );
  }

  getTaxList(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/tax');
  }
  createTax(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/tax/create', data);
  }
  updateTax(companyId, taxId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/tax/update/'+taxId, data);
  }
  getTax(companyId, id) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/tax/' + id);
  }
  archiveTax(companyId, id) {
    return axiosInstance.delete(s2pmsBaseUrl + '/company/' + companyId + '/tax/' + id + '/archive');
  }
  activateTax(companyId, id) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/tax/' + id + '/activate');
  }

  getHoliday(userId){
    return axiosInstance.get(baseUrl + '/users/out-of-office/'+userId)
  }
  createHoliday(userId, data){
    return axiosInstance.post(baseUrl + '/users/out-of-office/'+userId, data)
  }
  updateHoliday(userId, data){
    return axiosInstance.put(baseUrl + '/users/out-of-office/'+userId, data)
  }

  getRequisitions(size, page, searchBody) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/requisitions?size=' + size + '&page=' + page,
      searchBody,
    );
  }
  getRequisition(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/requisitions/' + id);
  }
  createRequisition(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/requisitions/create', data);
  }
  updateRequisition(id, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/requisitions/' + id + '/update', data);
  }
  discardRequisition(id) {
    return axiosInstance.delete(s2pmsBaseUrl + '/requisitions/' + id + '/delete');
  }
  recallRequisition(id, reason) {
    return axiosInstance.post(s2pmsBaseUrl + '/requisitions/' + id + '/recall?reason=' + reason);
  }
  submitForApprovalRequisition(id) {
    return axiosInstance.post(s2pmsBaseUrl + '/requisitions/' + id + '/submitForApproval');
  }
  approveRequisition(id, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/requisitions/' + id + '/approve', data);
  }

  getPurchaseOrders(size, page, searchBody) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders?size=' + size + '&page=' + page, searchBody);
  }
  getPurchaseOrder(id, companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + id, companyId && {
        headers: {
          'company-id': companyId,
        },
      }
    );
  }
  createPurchaseOrder(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/create', data);
  }
  updatePurchaseOrder(id, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/update/' + id, data);
  }
  deleteDocument(module, id, key) {
    return axiosInstance.delete(
      s2pmsBaseUrl + '/' + module + '/' + id + '/delete-document?document-key=' + key,
    );
  }
  submitForApprovalPurchaseOrder(id) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + id + '/submitForApproval');
  }
  approvePurchaseOrder(id, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + id + '/approve', data);
  }
  discardPurchaseOrder(id) {
    return axiosInstance.delete(s2pmsBaseUrl + '/purchase-orders/' + id + '/delete');
  }
  recallPurchaseOrder(id, reason) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + id + '/recall?reason=' + reason);
  }
  closePurchaseOrder(id) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + id + '/close');
  }

  getReceiveItemsPurchaseOrder(poId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + poId + '/received-items/');
  }
  getReceiveItemPurchaseOrder(poId, itemId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + poId + '/received-items/' + itemId);
  }
  createReceiveItemsPurchaseOrder(poId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + poId + '/receive-items', data);
  }
  updateReceiveItemPurchaseOrder(poId, itemId, data) {
    return axiosInstance.put(s2pmsBaseUrl + '/purchase-orders/' + poId + '/received-items/' + itemId, data);
  }
  deleteReceiveItemPurchaseOrder(poId, itemId) {
    return axiosInstance.delete(s2pmsBaseUrl + '/purchase-orders/' + poId + '/received-items/' + itemId);
  }

  getPurchaseOrderInvoices(poId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + poId + '/invoice');
  }
  getPurchaseOrderInvoice(poId, invoiceId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId);
  }
  createPurchaseOrderInvoice(poId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + poId + '/invoice', data);
  }
  updatePurchaseOrderInvoice(poId, invoiceId, data) {
    return axiosInstance.put(s2pmsBaseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId, data);
  }
  deletePurchaseOrderInvoice(poId, invoiceId) {
    return axiosInstance.delete(s2pmsBaseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId);
  }
  approvePurchaseOrderInvoice(poId, invoiceId, body) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId, body);
  }

  getPurchaseOrderPayments(poId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + poId + '/payments');
  }
  getPurchaseOrderPayment(poId, paymentId) {
    return axiosInstance.get(s2pmsBaseUrl + '/purchase-orders/' + poId + '/payments/' + paymentId);
  }
  createPurchaseOrderPayment(poId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/purchase-orders/' + poId + '/payments', data);
  }
  updatePurchaseOrderPayment(poId, paymentId, data) {
    return axiosInstance.put(s2pmsBaseUrl + '/purchase-orders/' + poId + '/payments/' + paymentId, data);
  }
  deletePurchaseOrderPayment(poId, paymentId) {
    return axiosInstance.delete(s2pmsBaseUrl + '/purchase-orders/' + poId + '/payments/' + paymentId);
  }

  addNoteToPO(poID, note){
    return axiosInstance.post(s2pmsBaseUrl+'/purchase-orders/'+poID+'/add-note?notes='+note)
  }
  verifyPurchaseOrderBudget(data){
    return axiosInstance.post(s2pmsBaseUrl+'/purchase-orders/verify-budget', data)
  }

  attachmentShow(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/attachments/show?id=' + id);
  }
  createCompany(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/create', data);
  }
  updateCompany(data) {
    return axiosInstance.patch(s2pmsBaseUrl + '/company/update', data);
  }
  getBranches(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/branches');
  }
  searchBranches(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        `/company/${companyId}/branches?direction=DESC&orderBy=createdAt&page=${page}&size=${size}`,
      data,
    );
  }
  createBranch(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/add-branch', data);
  }
  updateBranch(companyId, id, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/update-branch/' + id,
      data,
    );
  }
  getDepartments(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/departments');
  }
  searchDepartments(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        '/company/' +
        companyId +
        '/departments/search?direction=DESC&orderBy=createdAt&page=' +
        page +
        '&size=' +
        size +
        '',
      data,
    );
  }
  getDepartment(companyId, id) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/departments/' + id);
  }
  createDepartment(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/departments', data);
  }
  updateDepartment(companyId, id, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/departments/' + id, data);
  }
  deleteDepartment(companyId, data) {
    return axiosInstance.delete(
      s2pmsBaseUrl + '/company/' + companyId + '/departments?ids=' + data,
    );
  }
  activateDepartment(companyId, id) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/departments/activate?ids=' + id,
    );
  }
  getVendorsList(body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/vendor/get-vendors?direction=DESC&orderBy=id&page=0&size=30',
      body,
    );
  }
  getVendors(companyId, body, page = 0, size = 30) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        '/company/' +
        companyId +
        '/vendors/get-vendors?direction=DESC&orderBy=id&page=' +
        page +
        '&size=' +
        size +
        '',
      body,
    );
  }
  addCompanyVendor(vendorId, companyId, addCompanyVendorData) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/vendor/' + companyId + '/' + vendorId + '/add',
      addCompanyVendorData,
    );
  }
  searchVendors(body) {
    return axiosInstance.post(s2pmsBaseUrl + '/vendor/search', body);
  }
  createVendor(companyId, body) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/vendors', body);
  }
  editVendor(companyId, vendorId, body) {
    return axiosInstance.put(s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId, body);
  }
  approveVendor(companyId, vendorId, body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/approve',
      body,
    );
  }
  archiveVendor(companyId, vendorId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/archive',
    );
  }
  activateVendor(companyId, vendorId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/unarchive',
    );
  }
  submitVendorApproval(companyId, vendorId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/submit-for-approval',
    );
  }
  recallVendor(companyId, vendorId, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/recall',
      data,
    );
  }
  deleteVendorDocument(companyId, vendorId, key) {
    return axiosInstance.delete(
      s2pmsBaseUrl +
        '/company/' +
        companyId +
        '/vendors/' +
        vendorId +
        '/delete-document?document-key=' +
        key,
    );
  }

  getVendor(vendorId) {
    return axiosInstance.get(s2pmsBaseUrl + '/vendor/id/' + vendorId);
  }
  getCompanyVendorById(companyId, vendorId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId);
  }
  getVendorCompaniesAttached(vendorId) {
    return axiosInstance.get(s2pmsBaseUrl + '/vendor/companies/' + vendorId);
  }
  getVendorContactDetails(companyId, vendorId) {
    return axiosInstance.get(s2pmsBaseUrl + '/vendor/' + companyId + '/' + vendorId + '/contacts');
  }

  addVendorNotes(companyId, vendorId, body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/add-notes',
      body,
    );
  }
  addVendorContactDetails(companyId, vendorId, body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/vendor/' + companyId + '/' + vendorId + '/contacts',
      body,
    );
  }
  deleteSpecificVendorContact(companyId, vendorId, contactId) {
    return axiosInstance.delete(
      s2pmsBaseUrl + '/vendor/' + companyId + '/' + vendorId + '/contacts/' + contactId,
    );
  }
  editvendorContactDetails(contactId, body) {
    return axiosInstance.put(s2pmsBaseUrl + '/vendor/contact/' + contactId, body);
  }
  getVendorRating(vendorId) {
    return axiosInstance.get(s2pmsBaseUrl + '/vendor/rating/' + vendorId);
  }
  deleteVendor(companyId, vendorId) {
    return axiosInstance.delete(s2pmsBaseUrl + '/vendor/' + companyId + '/' + vendorId);
  }
  editVendorDetails(vendorId, body) {
    return axiosInstance.put(s2pmsBaseUrl + '/vendor/' + vendorId, body);
  }
  getGlAccounts(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/gl-accounts?companyId=' + companyId);
  }
  getFilteredGlAccounts(companyId, page, size, search) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        '/gl-accounts/filter?&page=' +
        page +
        '&size=' +
        size +
        '&companyId=' +
        companyId,
      search,
    );
  }
  getGlAccount(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/gl-accounts/' + id);
  }
  createGlAccount(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/gl-accounts', data);
  }
  updateGlAccount(id, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/gl-accounts/' + id, data);
  }
  deleteGlAccount(data) {
    return axiosInstance.delete(s2pmsBaseUrl + '/gl-accounts?ids=' + data);
  }
  uploadGlBulk(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/gl-accounts/' + companyId + '/bulk', data);
  }
  purchaseOrder(body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/purchase-orders?direction=DESC&orderBy=id&page=0&size=30',
      body,
    );
  }
  activateGlAccount(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/gl-accounts/activate?ids=' + data);
  }
  getApprovalMatrix(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix');
  }
  getApprovalModuleById(companyId, id, isActive) {
    return axiosInstance.get(
      s2pmsBaseUrl +
        '/company/' +
        companyId +
        '/approval-matrix/' +
        id +
        '?active-rules=' +
        isActive,
    );
  }
  editApprovalModuleById(companyId, id, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/' + id,
      data,
    );
  }
  editApprovalMatrix(companyId, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/edit',
      data,
    );
  }
  addApprovalMatrixRule(companyId, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/add-rule',
      data,
    );
  }
  getApprovalMatrixRule(companyId, ruleId) {
    return axiosInstance.get(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/rules/' + ruleId,
    );
  }

  archiveApprovalMatrixRule(companyId, matrixId, ruleId) {
    return axiosInstance.get(
      s2pmsBaseUrl +
        '/company/' +
        companyId +
        '/approval-matrix/' +
        matrixId +
        '/rules/' +
        ruleId +
        '/archive',
    );
  }
  activateApprovalMatrixRule(companyId, matrixId, ruleId) {
    return axiosInstance.get(
      s2pmsBaseUrl +
        '/company/' +
        companyId +
        '/approval-matrix/' +
        matrixId +
        '/rules/' +
        ruleId +
        '/unarchive',
    );
  }
  fetchReports(path, body, page = 0, size = 30) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        '/reports/' +
        path +
        '?direction=DESC&orderBy=id&page=' +
        page +
        '&size=' +
        size,
      body,
    );
  }
  getMetrics(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/metrics/dashboard/' + companyId);
  }
  getNotifications(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/notifications');
  }

  getNotification(companyId, id) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/notifications/' + id);
  }
  createNotification(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/notifications', data);
  }
  editNotification(companyId, id, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/notifications/' + id,
      data,
    );
  }
  activateNotification(companyId, id) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/notifications/' + id + '/unarchive',
    );
  }
  archiveNotification(companyId, id) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/notifications/' + id + '/archive',
    );
  }
  getAttachmentsByKey(key) {
    return axiosInstance.get(s2pmsBaseUrl + '/attachments?key=' + key);
  }

  subscriptionForCompany(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/company/' + companyId);
  }
  subscriptionPlanById(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/' + id);
  }
  getAllSubscriptionsPlans() {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans');
  }
  getSubscriptionInvoices(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/invoices/' + companyId);
  }
  downloadSubscriptionInvoice(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/invoices/download/' + id, {
      responseType: 'blob',
    });
  }
  changeCompanySubscription(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/subscription-plans/' + companyId + '/change', data);
  }
  getSystemAuditLog(size, page, companyId, data) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        '/audit/' +
        companyId +
        '?direction=DESC&orderBy=createdAt&page=' +
        page +
        '&size=' +
        size,
      data,
    );
  }

  getIntegrationBCCompanies(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/'+companyId+'/integrations/business-central/companies')
  }
  getAllIntegrations(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/'+companyId+'/integrations')
  }
  createIntegration(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/'+companyId+'/integrations', data)
  }
  getIntegration(companyId, integrationId){
    return axiosInstance.get(s2pmsBaseUrl + '/company/'+companyId+'/integrations/'+ integrationId)
  }
  editIntegration(companyId, integrationId, data){
    return axiosInstance.put(s2pmsBaseUrl + '/company/'+companyId+'/integrations/'+integrationId, data)
  }
  activateIntegration(companyId, integrationId) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/'+companyId+'/integrations/'+integrationId+'/activate')
  }
  deactivateIntegration(companyId, integrationId) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/'+companyId+'/integrations/'+integrationId+'/deactivate')
  }

}
export default new SpmsService();
