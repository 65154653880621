import React, {useState, useEffect, useCallback} from 'react';

import Select from 'react-select';
import Box from '../../components/UI/General/Box';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import formStyles from '../../styles/companySetup.module.css';
import generalStyles from '../../styles/general.module.css';
import vendorStyles from '../../styles/vendor.module.css';
import {Controller, useForm, useWatch} from 'react-hook-form';

import Textarea from '../../components/UI/Forms/Textarea';
import * as Button from '../../components/UI/Forms/Button';
import Documents from '../../components/UI/Forms/Documents';
import spmsServiceService from '../../services/spmsService.service';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion';
import { vendorExtendedSchema } from '../../utils/validation/vendorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../store/store';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import Text from '../../components/UI/Typography/Text';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import {vendorCategories} from "../../constants/vendorCategories";
import {currencies} from "../../constants/currencies";

const LegalInformationTab = ({ vendorSetupData, setToast, setMainTab, getVendorCompany }) => {
  const [formValues, setFormValues] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    getValues,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(vendorExtendedSchema),
    mode: 'onChange',
    values: formValues,
  });
  console.log('getValues', getValues());
  const { companyId, vendorId, mode } = useParams();
  const isEditingDisabled =
    vendorSetupData?.vendorStatus === 'APPROVED' ||
    mode === 'add' ||
    vendorSetupData?.companiesAssigned > 1 ||
    vendorSetupData?.previouslyApproved;
  const countries = useStore((state) => state.countries);
  const setSteps = useStore((state) => state.setSteps);
  const accessVendor = useAccessAllowed('Vendors');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  // const vendorCountry = useWatch({name: 'vendorCountry', control});
  const [tab, setTab] = useState('Notes');
  const navigate = useNavigate();

  const companyCountry = watch('vendorCountry');

  // const createCurrencyOptions = () => {
  //   const createOptions = (optionArr) => optionArr.map((opt) => ({ value: opt, label: opt }));
  //   const countryCurrency = countries?.find(c => c?.value === vendorCountry?.value)?.currency;
  //   return countryCurrency ? createOptions([...new Set([countryCurrency, ...currencies.map(c => c.value)])]) : currencies;
  // };

  useEffect(() => {
    if (!countries || !companyCountry) return undefined;
    const selectedCountryCurrency = countries.find(
      (country) => country.value === companyCountry.value,
    )?.currency;
    if (['create', 'add'].includes(mode)){
      setValue('defaultCurrency', { label: selectedCountryCurrency, value: selectedCountryCurrency });
    }
  }, [countries, companyCountry]);

  const createCurrencyOptions = useCallback(() => {
    if (!countries || !countries.length) return;
    const allCurrencies = countries.map((country) => country.currency).sort();
    const uniqueCurrencies = [...new Set([...allCurrencies])];
    return uniqueCurrencies.map((currency) => ({ label: currency, value: currency }));
  }, [countries]);

  const files = (files) =>
    files.map((file) => ({
      id: null,
      attachment: file,
      name: file.name,
      type: 'VENDOR_DOC',
    }));

  useEffect(() => {
    if (!vendorSetupData) return;
    const vendorPreloadData = {
      registrationNo: vendorSetupData.registrationNo,
      vendorType: { label: vendorSetupData.vendorType, value: vendorSetupData.vendorType },
      legalName: vendorSetupData.legalName,
      vendorAddressLine1: vendorSetupData.address.streetAddress ?? '',
      vendorAddressLine2: vendorSetupData.address.addressLine2,
      vendorCity: vendorSetupData.address.city,
      vendorZipCode: vendorSetupData.address.zipCode,
      vendorCountry: {
        label: vendorSetupData.address.country,
        value: vendorSetupData.address.country,
      },
      vendorState: { label: vendorSetupData.address.state, value: vendorSetupData.address.state },
      vendorCode: vendorSetupData.vendorCode,
      onceOffPOLimit: vendorSetupData.onceOffPOLimit,
      overallAnnualLimit: vendorSetupData.overallAnnualLimit,
      vendorEmail: vendorSetupData.email,
      vendorPhoneNumber: vendorSetupData.phoneNumber,
      defaultCurrency: vendorSetupData.currency
        ? {
            label: vendorSetupData.currency,
            value: vendorSetupData.currency,
          }
        : undefined,
    };
    setFormValues(vendorPreloadData);
  }, [vendorSetupData]);

  const submitVendor = (next, forApproval) => async (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let vendorFiles = []
    if (data.vendorAttachments !== undefined && data.vendorAttachments.length > 0){
      const files = data.vendorAttachments.map(async (item) => ({
        type: 'VENDOR_DOC',
        name: item.name.split(' ').join('-'),
        attachment: await readFile(item.attachment),
      }));
      vendorFiles = await Promise.all(files).then((result) => result);
    }
    if (mode === 'add') {
      const addCompanyVendorData = {
        onceOffPOLimit: Number(data.onceOffPOLimit),
        overallAnnualLimit: Number(data.overallAnnualLimit),
        vendorNotes: data.vendorNotes,
        vendorCode: data.vendorCode,
        currency: data.defaultCurrency.value,
        attachments: vendorFiles.map((file) => ({
          name: file.name.split(' ').join('-'),
          document: file.attachment,
        })),
        vendorStatus: 'DRAFT',
      };
      try {
        await spmsServiceService.addCompanyVendor(vendorId, companyId, addCompanyVendorData);
        setToast({
          opened: true,
          message: 'Vendor has been added',
          type: 'success',
        });
        if (forApproval) {
          await spmsServiceService.submitVendorApproval(companyId, vendorId);
          navigate('/vendors/pending-approval');
          return;
        }
        if (next) {
          setMainTab('Representatives');
          navigate(`/vendors/${companyId}/edit/${vendorId}`, { replace: true });
          getVendorCompany(vendorId);
        } else navigate('/vendors/draft');
      } catch (err) {
        setToast({
          opened: true,
          message: err.response.data.error ? err.response.data.error : 'Something Went Wrong',
          type: 'fail',
        });
      } finally {
        setIsButtonsBlocked(false);
      }
    } else {
      const bodyData = {
        vendor: {
          legalName: data.legalName,
          registrationNo: data.registrationNo,
          vendorNotes: data.vendorNotes,
          vendorType: data.vendorType.value,
          email: data.vendorEmail,
          currency: data.defaultCurrency.value,
          phoneNumber: data.vendorPhoneNumber,
          address: {
            addressLine2: data.vendorAddressLine2,
            streetAddress: data.vendorAddressLine1,
            city: data.vendorCity,
            state: data.vendorState.value,
            zipCode: data.vendorZipCode,
            country: data.vendorCountry.value,
          },
        },
        vendorCode: data.vendorCode,
        onceOffPOLimit: Number(data.onceOffPOLimit),
        overallAnnualLimit: Number(data.overallAnnualLimit),
        currency: data.defaultCurrency.value,
        userIds: [],
        departmentIds: [],
        branchIds: [],
        glAccountIds: [],
        notes: data.vendorNotes,
        attachments: vendorFiles.map((file) => ({
          name: file.name.split(' ').join('-'),
          document: file.attachment,
        })),
      };
      if (vendorId) {
        try {
          await spmsServiceService.editVendor(companyId, vendorId, bodyData);
          if (vendorSetupData.vendorStatus === 'REJECTED' && !forApproval) {
            setToast({
              opened: true,
              message: 'This vendor will now be available in draft',
              type: 'success',
              cb: () => navigate('/vendors/draft'),
            });
            return;
          }
          if (forApproval) {
            await spmsServiceService.submitVendorApproval(companyId, vendorId);
            setToast({
              opened: true,
              message: 'Vendor submited for approval',
              type: 'success',
              cb: () => navigate('/vendors/pending-approval'),
            });
            return;
          }
          if (next) {
            setMainTab('Representatives');
            getVendorCompany(vendorId);
          }
          setToast({
            opened: true,
            message: 'Vendor has been updated',
            type: 'success',
          });
        } catch (err) {
          setToast({
            opened: true,
            message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
            type: 'fail',
          });
        } finally {
          setIsButtonsBlocked(false);
        }
      } else {
        try {
          const res = await spmsServiceService.createVendor(companyId, bodyData);
          const newVendorId = res.data.data.vendor.id;
          if (forApproval) {
            await spmsServiceService.submitVendorApproval(companyId, newVendorId);
            setToast({
              opened: true,
              message: 'Vendor submitted for approval',
              type: 'success',
              cb: () => navigate('/vendors/pending-approval'),
            });
          }
          if (next) {
            setMainTab('Representatives');
            navigate(`/vendors/${companyId}/edit/${newVendorId}`, { replace: true });
            getVendorCompany(newVendorId);
            setToast({
              opened: true,
              message: 'Vendor has been created',
              type: 'success',
            });
            setSteps();
          } else {
            setToast({
              opened: true,
              message: 'Vendor has been created',
              type: 'success',
              cb: () => navigate('/vendors/draft'),
            });
            setSteps();
          }
        } catch (err) {
          console.log('err', err);
          setToast({
            opened: true,
            message: err.response?.data?.message
              ? err.response.data.message
              : 'Something Went Wrong',
            type: 'fail',
          });
        } finally {
          setIsButtonsBlocked(false);
        }
      }
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = function () {
        resolve(filereader.result);
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
  };

  const deleteDocument = (key) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .deleteVendorDocument(companyId, vendorId, key)
      .then(() => {
        setToast({
          opened: true,
          message: 'Document has been deleted',
          type: 'success',
          cb: () => setToast((item) => ({ ...item, opened: false })),
        });
        getVendorCompany(vendorId);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
          cb: () => setToast((item) => ({ ...item, opened: false })),
        });
      });
  };
  return (
    <>
      <Box $raduis={12} $mobExtend $asHolder $noOverflow>
        <form id="cForm" className={formStyles.form}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="legalName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Legal Name "
                    $label="Legal Name"
                    $labelRequired
                    $tooltip="Legal registered name of the vendor"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.legalName && <p className="error-message">{errors.legalName.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="registrationNo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    $labelRequired
                    placeholder="Enter Registration No "
                    $label="Registration No"
                    $tooltip="Registration number of the vendor"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.registrationNo && (
                <p className="error-message">{errors.registrationNo.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    $labelRequired
                    disabled={!accessVendor?.edit || vendorSetupData?.vendorStatus === 'APPROVED'}
                    placeholder="Enter Vendor Code "
                    $label="Vendor Code"
                    $tooltip="Vendor identifier"
                    {...field}
                  />
                )}
              />
              {errors.vendorCode && <p className="error-message">{errors.vendorCode.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Vendor Category" $isRequired $tooltipText="Vendor category type" />
              <Controller
                name="vendorType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    isDisabled={!accessVendor?.edit || isEditingDisabled}
                    formatGroupLabel={(data) => (
                      <Text type="body-1" weight={600}>
                        {data.label}
                      </Text>
                    )}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    styles={{
                      group: (base) => ({ ...base, padding: 0 }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: '100 !important',
                      }),
                      groupHeading: () => ({ padding: '4px 12px' }),
                    }}
                    placeholder="Select Category "
                    options={vendorCategories}
                  />
                )}
              />
              {errors.vendorType && (
                <p className="error-message">{errors.vendorType.value.message}</p>
              )}
            </div>

            <div className="inp-container">
              <Controller
                name="vendorAddressLine1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Suit/Building Name"
                    $label="Suite Number/Building Name/Office Park"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.vendorAddressLine1 && (
                <p className="error-message">{errors.vendorAddressLine1.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorAddressLine2"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Street Address "
                    $labelRequired
                    $label="Street Address"
                    $tooltip="Street name and number"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.vendorAddressLine2 && (
                <p className="error-message">{errors.vendorAddressLine2.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorCity"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter City "
                    $label="City"
                    $labelRequired
                    $tooltip="City this vendor is located in"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.vendorCity && <p className="error-message">{errors.vendorCity.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorZipCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Postal Code "
                    $label="Postal Code"
                    $labelRequired
                    $tooltip="Postal code for this vendor"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.vendorZipCode && (
                <p className="error-message">{errors.vendorZipCode.message}</p>
              )}
            </div>
            <CountryAndRegion
              countryFieldName="vendorCountry"
              regionFieldName="vendorState"
              control={control}
              isDisable={!accessVendor?.edit || isEditingDisabled}
              countries={countries}
              errors={errors}
              countryTooltip="Country this vendor is located in"
              regionTooltip="Region the vendor is located in"
            />
            <div className="inp-container">
              <Controller
                name="vendorEmail"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Business Email "
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Business Email"
                    $labelRequired
                    $tooltip="Primary business email address for this vendor"
                    id={field.name}
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.vendorEmail && <p className="error-message">{errors.vendorEmail?.message}</p>}
            </div>
            <div className="inp-container">
              <Label
                $title="Business Phone Number"
                $isRequired
                $tooltipText="Primary business phone number for this vendor"
              />
              <Controller
                name="vendorPhoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <PhoneInput
                      limitMaxLength={true}
                      {...field}
                      placeholder="Enter Phone Number "
                      international
                      disabled={!accessVendor?.edit || isEditingDisabled}
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    />
                  );
                }}
              />
              {errors.vendorPhoneNumber && (
                <p className="error-message">{errors.vendorPhoneNumber?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="onceOffPOLimit"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    disabled={!accessVendor?.edit}
                    $labelRequired
                    $tooltip="Maximum purchase order amount"
                    placeholder="Enter Once Off PO Limit "
                    $label="Once Off PO Limit"
                    {...field}
                  />
                )}
              />
              {errors.onceOffPOLimit && (
                <p className="error-message">{errors.onceOffPOLimit.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="overallAnnualLimit"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    $labelRequired
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    disabled={!accessVendor?.edit}
                    placeholder="Enter Overall Annual Limit "
                    $label="Overall Annual Limit"
                    $tooltip="Maximum annual purchase amount"
                    {...field}
                  />
                )}
              />
              {errors.overallAnnualLimit && (
                <p className="error-message">{errors.overallAnnualLimit.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label $title="Default Currency" $isRequired />
              <Controller
                name="defaultCurrency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    isDisabled={!accessVendor?.edit}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Default Currency "
                    options={createCurrencyOptions()}
                  />
                )}
              />
              {errors.defaultCurrency && (
                <p className="error-message">{errors.defaultCurrency.value.message}</p>
              )}
            </div>
          </div>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('Notes')}>Notes</span>
            <span onClick={() => setTab('Documents')}>Documents</span>
          </TabsSlider>
          {tab === 'Notes' && (
            <div className="inp-container">
              <Controller
                name="vendorNotes"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $label="Notes"
                    $small
                    disabled={!accessVendor?.edit}
                    $low
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $counter
                    $counterMax={500}
                    placeholder="Enter Notes about the vendor"
                  />
                )}
              />
              {errors.vendorNotes && (
                <p style={{ transform: 'translate(0, -20px)' }} className="error-message">
                  {errors.vendorNotes.message}
                </p>
              )}
              <NotesTab data={vendorSetupData?.vendorNotes} />
            </div>
          )}
          {tab === 'Documents' && (
            <>
              <div className={vendorStyles.docs}>
                <Label $title="Import Files"/>
                <Documents
                  control={control}
                  fieldName="vendorAttachments"
                  filesSchema={files}
                  error={errors?.vendorAttachments?.message}
                  isDisable={!accessVendor?.edit}
                />
                {vendorSetupData?.vendorAttachments && (
                  <QuotesTab
                    data={vendorSetupData?.vendorAttachments}
                    deleteDocument={deleteDocument}
                  />
                )}
              </div>
            </>
          )}
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        {vendorSetupData?.vendorStatus !== 'APPROVED' && mode !== 'add' && (
          <Button.Main
            onClick={handleSubmit(submitVendor(true, false))}
            $primary
            $style="pink"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            {mode === 'edit' ? 'Update' : 'Save'}
          </Button.Main>
        )}

        {(mode === 'create' || mode === 'add') && (
          <Button.Main
            onClick={handleSubmit(submitVendor(false, false))}
            $primary
            $style="pink"
            type="submit"
            disabled={!isDirty || !isValid || isButtonsBlocked}
          >
            Save as Draft
          </Button.Main>
        )}

        <Button.Main
          onClick={handleSubmit(submitVendor(false, true))}
          $primary
          $style={!isValid || !vendorId ? 'gray' : 'pink'}
          type="submit"
          disabled={
            !isValid || !vendorId || (!isDirty && vendorSetupData.vendorStatus === 'REJECTED')
          }
        >
          Submit for Approval
        </Button.Main>
        <Button.Main
          onClick={() => navigate('/vendors/draft')}
          $primary
          $style={!isDirty || !isValid ? 'pink' : 'gray'}
          type="button"
        >
          Discard
        </Button.Main>
      </div>
    </>
  );
};

export default LegalInformationTab;
