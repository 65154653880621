import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/users.module.css';
import requestsServiceService from '../../services/requestsService.service';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import Toast from '../../components/UI/General/Toast';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import AddButton from '../../components/shared/AddButton';
import Export from '../../components/shared/Export';
import Modal from '../../components/UI/Modal/Modal';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import spmsServiceService from '../../services/spmsService.service';

const Users = () => {
  const { tab } = useParams();
  const companyId = useStore((state) => state.company?.id);
  const company = useStore((state) => state.company);
  const user = useStore((state) => state.user);
  const [usersLength, setUsersLength] = useState(undefined);
  const [allCompaniesIds, setAllCompaniesIds] = useState([companyId]);
  const [showModal, setShowModal] = useState(false);
  const currentSubscription = useSubscriptionAllowed();

  const [filterText, setFilterText] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setAllCompaniesIds(
      [company.id, company.subsidiaries && company.subsidiaries.map((sub) => sub.id)].flat(),
    );
  }, [company]);

  const columns = useMemo(
    () => [
      {
        name: 'Full Name',
        selector: (row) => row.firstName + ' ' + row.lastName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <Link className={styles.link} to={'/users/edit/' + row.id}>
            <span data-content={'Full Name'} className={generalStyles.tableValue}>
              <span>{`${row.firstName} ${row.lastName}`}</span>
            </span>
          </Link>
        ),
      },
      {
        name: 'Email',
        wrap: true,
        sortable: true,
        selector: (row) => row.email,
        cell: (row) => (
          <span data-content={'Email'} className={generalStyles.tableValue}>
            <span>{row.email}</span>
          </span>
        ),
      },
      {
        name: 'Roles',
        cell: (row) => (
          <div data-content={'Roles'} className={generalStyles.tableValue}>
            <div>
              {Array.from(new Set([...row.roles.map((el) => el.role.name)])).map((role, index) => (
                <Text weight={500} key={index} type="body-2">
                  {role}
                </Text>
              ))}
            </div>
          </div>
        ),
      },
      {
        name: 'Company',
        cell: (row) => (
          <div data-content={'Company'} className={generalStyles.tableValue}>
            {row.customCompanies?.map((cmp) => cmp.companyName).join(', ')}
          </div>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {tab === 'active' && (
                    <Link to={'/users/edit/' + row.id}>
                      <MenuItem>Edit</MenuItem>
                    </Link>
                  )}
                  {tab === 'active' && row.id !== user.id ? (
                    <MenuItem onClick={() => resetPassword(row.email)}>Reset Password</MenuItem>
                  ) : null}
                  {tab === 'invited' && (
                    <MenuItem onClick={() => resendInvitation(row.email)}>
                      Resend Invitation
                    </MenuItem>
                  )}
                  {tab === 'invited' && (
                    <MenuItem onClick={() => deleteInvitedUser(row.id)}>Delete</MenuItem>
                  )}
                  {tab === 'active' && row.id !== user.id ? (
                    <MenuItem onClick={() => deactivateUser(row.email)}>Archive</MenuItem>
                  ) : null}
                  {tab === 'archive' && (
                    <MenuItem onClick={() => activateUser(row.email)}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, navigate],
  );

  const resetPassword = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .resetUserPassword({ email: email })
      .then((res) => {
        setToast({
          opened: true,
          message: 'Password resetted successfully',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.errors[0].errorMessage,
          type: 'fail',
        });
      });
  };
  const resendInvitation = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .resendInvitation(email)
      .then((res) => {
        setToast({
          opened: true,
          message: 'Invitation resent successfully',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data?.message,
          type: 'fail',
        });
      });
  };
  const deactivateUser = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .deactivateUser(email)
      .then((res) => {
        setToast({
          opened: true,
          message: 'User deactivated successfully',
          type: 'success',
        });
        setTrigger((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.errors[0].errorMessage,
          type: 'fail',
        });
      });
  };
  const activateUser = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .activateUser(email)
      .then((res) => {
        setToast({
          opened: true,
          message: 'User activated successfully',
          type: 'success',
        });
        setTrigger((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.toString(),
          type: 'fail',
        });
      });
  };
  const deleteInvitedUser = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .deleteInvitedUser(id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'User deleted successfully',
          type: 'success',
        });
        setTrigger((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.toString(),
          type: 'fail',
        });
      });
  };

  const getData = useCallback(
    async (page, size) => {
      if (!allCompaniesIds) return;
      let tempTab;
      switch (tab) {
        case 'active':
          tempTab = 'ACTIVE';
          break;
        case 'invited':
          tempTab = 'INVITED';
          break;
        case 'archive':
          tempTab = 'ARCHIVED';
          break;
        default:
          tempTab = 'ACTIVE';
      }
      return await requestsServiceService.getData(size, page, {
        status: tempTab,
        companyIds: allCompaniesIds,
      });
    },
    [tab, filterText, trigger, allCompaniesIds],
  );

  const getExport = useCallback(async () => {
    const response = await requestsServiceService.getData(
      undefined,
      undefined,
      {
        companyIds: allCompaniesIds,
        title: filterText,
      },
      true,
    );
    return response.data.data.content.map((item) => ({
      'Full name': item.firstName + ' ' + item.lastName,
      Email: item.email,
      Roles: Array.from(new Set([...item.roles.map((el) => el.role.name)])).join('; '),
      Company: item.customCompanies?.map((cmp) => cmp.companyName).join('; '),
      Status: item.status,
    }));
  }, [filterText, allCompaniesIds]);

  useEffect(() => {
    spmsServiceService.getMetrics(companyId).then((res) => {
      const metrics = res.data.data;
      setUsersLength(metrics.invitedUsers + metrics.activeUsers);
    });
  }, []);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#8A8B9D"
        placeholder="Search by Name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <Box $radius={12} $mobExtend $asHolder $noOverflow>
      <div className={generalStyles.tabSection}>
        <TabsSlider>
          <NavLink
            to="/users/overview/active"
            className={({ isActive }) => (isActive ? 'active' : '')}
            $islink="true"
          >
            Active
          </NavLink>
          <NavLink
            to="/users/overview/invited"
            className={({ isActive }) => (isActive ? 'active' : '')}
            $islink="true"
          >
            Invited
          </NavLink>
          <NavLink
            to="/users/overview/archive"
            className={({ isActive }) => (isActive ? 'active' : '')}
            $islink="true"
          >
            Archive
          </NavLink>
        </TabsSlider>
        <div className={generalStyles.addItemButton}>
          {usersLength !== undefined && currentSubscription && (
            <AddButton
              $click={() => {
                if (usersLength >= currentSubscription?.plan?.maxUsers) {
                  setShowModal(true);
                  return;
                }
                navigate('/users/add');
              }}
            />
          )}
          <Export name="Users" getDataFunction={getExport} />
        </div>
      </div>
      <div className={generalStyles.search}>{filterCmp}</div>
      <Suspense fallback={<h2>Loading...</h2>}>
        <DataTableBaseRemote
          columns={columns}
          selectableRows={false}
          paginationResetDefaultPage={false}
          fetchData={getData}
        />
      </Suspense>
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>
            The {currentSubscription?.plan?.name} Subscription Plan only allows{' '}
            {currentSubscription?.plan?.maxUsers} users.
          </Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => navigate('/accounts-billing/subscription')}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default Users;
