import { useEffect, useMemo, useState } from 'react';
import Dropdown from '../../UI/General/Dropdown';
import * as Button from '../../UI/Forms/Button.jsx';
import Icon from '../../UI/General/Icon.jsx';
import { Menu, MenuItem } from '../../UI/General/Menu';
import generalStyles from '../../../styles/general.module.css';
import styles from '../../../styles/notifications.module.css';
import Box from '../../UI/General/Box';
import TabbedNav from '../../UI/General/TabbedNav.jsx';
import Text from '../../UI/Typography/Text.jsx';
import { useNavigate } from 'react-router-dom';
import DataTableBase from '../../UI/General/DataTableBase';
import spmsServiceService from '../../../services/spmsService.service.js';
import Toast from '../../UI/General/Toast.jsx';
import AddButton from '../../shared/AddButton.jsx';

const CompanyNotification = ({ companyId }) => {
  const [tab, setTab] = useState('ACTIVE');
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [notificationData, setNotificationData] = useState([]);
  const navigate = useNavigate();
  const getNotifications = () => {
    spmsServiceService
      .getNotifications(companyId)
      .then((res) => setNotificationData(res.data.data));
  };
  useEffect(() => {
    getNotifications();
  }, [companyId]);

  const handleColumnValues = (value) => {
    return value
      .split('_')
      .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
      .join(' ');
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Name'} className={generalStyles.tableValue}>
            <span>{row.name}</span>
          </span>
        ),
      },
      {
        name: 'Notification Module',
        sortable: true,
        selector: (row) => row.module,
        cell: (row) => (
          <span data-content={'Notification Module'} className={generalStyles.tableValue}>
            <span>{handleColumnValues(row.module)}</span>
          </span>
        ),
      },
      {
        name: 'Notification Type',
        sortable: true,
        selector: (row) => row.triggerEvent,
        cell: (row) => (
          <span data-content={'Notification Type'} className={generalStyles.tableValue}>
            <span>{handleColumnValues(row.triggerEvent)}</span>
          </span>
        ),
      },
      {
        name: 'Users',
        cell: (row) => (
          <div data-content={'Notification Type'} className={generalStyles.tableValue}>
            {row.recipients.map((user) => (
              <span className={styles.users} key={user}>
                {user.name}
              </span>
            ))}
          </div>
        ),
      },

      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => {
          return (
            <div className={generalStyles.actionMenuHolder}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Action $style="white" $width={32} $height={32}>
                    <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                  </Button.Action>
                </Dropdown.Header>
                <Dropdown.Body>
                  {tab === 'ACTIVE' ? (
                    <Menu className={generalStyles.actionMenuList}>
                      <MenuItem
                        onClick={() => navigate(`/notification/${companyId}/edit/${row.id}`)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => archiveNotification(row.id)}>Archive</MenuItem>
                    </Menu>
                  ) : (
                    <Menu className={generalStyles.actionMenuList}>
                      <MenuItem onClick={() => activateNotification(row.id)}>Activate</MenuItem>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [tab],
  );

  const archiveNotification = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveNotification(companyId, id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Notification archived',
          type: 'success',
        });
        getNotifications();
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };
  const activateNotification = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateNotification(companyId, id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Notification activated',
          type: 'success',
        });
        getNotifications();
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };

  return (
    <Box $radius={12} $mobExtend $asHolder>
      <div className={generalStyles.tabSection}>
        <TabbedNav>
          <span onClick={() => setTab('ACTIVE')}>Active</span>
          <span onClick={() => setTab('ARCHIVED')}>Archive</span>
        </TabbedNav>
        <div className={generalStyles.addItemButton}>
          <AddButton $click={() => navigate(`/notification/${companyId}/add`)} />
        </div>
      </div>
      <DataTableBase
        data={notificationData.filter((notification) => notification.status === tab)}
        columns={columns}
        expandableRows
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 15, 20, 30]}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default CompanyNotification;
