import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import React from "react";

export const AdminErrorBoundary = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return error.data ? <div>{error.data}</div> : <div>This page doesn't exist!</div>;
    }
    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }
    if (error.status === 405) {
      return error.data ? <div>{error.data}</div> : <div>Not allowed</div>;
    }
    if (error.status === 500) {
      return <div>Internal server error</div>;
    }
    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }
  }else {
    if (error?.response?.status === 404) {
      return error.response.data.message ? <div>{error.response.data.message}</div> : <div>This page doesn't exist!</div>;
    }
    if (error?.response?.status === 500) {
      return error.response.data.message ? <div>{error.response.data.message}</div> : <div>Internal server error</div>;
    }
  }
}
