import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/companySetup.module.css';

const ExpandedVendor = ({ data }) => {
  return (
    <div className={styles.extendedComponent}>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>Branch</Text>
        {!data.branches.length
          ? 'All'
          : data.branches.map((branch) => <Text key={branch.id}>{branch.name}</Text>)}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>User</Text>
        {!data.users.length ? 'All' : data.users.map((user) => <Text key={user}>{user.name}</Text>)}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>Department</Text>
        {!data.departments.length
          ? 'All'
          : data.departments.map((department) => (
              <Text key={department.id}>{department.name}</Text>
            ))}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>General Ledger</Text>
        {!data.glAccounts.length
          ? 'All'
          : data.glAccounts.map((gl) => <Text key={gl.id}>GL-{gl.glCode}</Text>)}
      </div>
    </div>
  );
};

export default ExpandedVendor;
