import styled from 'styled-components';
const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  row-gap: 30px;
  padding: 22px 22px 38px 22px;
  width: 60%;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;
const Key = styled.div`
  font-weight: 600;
  max-width: 200px;
`;
const Value = styled.div`
  font-weight: 500;
`;

const BranchExpanded = ({ data }) => {
  return (
    <Column>
      <Row>
        <Key>Suite Number/Building Name/ Office Block:</Key>
        <Value>{data.address.streetAddress ?? '- '}</Value>
      </Row>
      <Row>
        <Key>Phone Number:</Key>
        <Value>{data.phoneNumber}</Value>
      </Row>
      <Row>
        <Key>Street Address:</Key>
        <Value>{data.address.addressLine2}</Value>
      </Row>
      <Row>
        <Key>Postal Code:</Key>
        <Value>{data.address.zipCode}</Value>
      </Row>
    </Column>
  );
};

export default BranchExpanded;
