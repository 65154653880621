import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../../styles/vendor.module.css';

import { Controller, useForm } from 'react-hook-form';
import Text from '../../components/UI/Typography/Text';
import Textarea from '../../components/UI/Forms/Textarea';
import * as Button from '../../components/UI/Forms/Button';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import { useEffect } from 'react';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import moment from 'moment';
import DataTableBase from '../../components/UI/General/DataTableBase';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useStore } from '../../store/store';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import { regExps } from '../../utils/regExps';
import Status from "../../components/UI/Typography/Status";

const PendingDashboard = () => {
  const [tab, setTab] = useState('Notes');
  const { vendorId, companyId } = useParams();
  const user = useStore((state) => state.user);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm({});

  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const [pendingVendorData, setPendingVendorData] = useState({
    vendor: { address: {} },
    branches: [],
    departments: [],
    glAccounts: [],
  });

  const approveRejectVendor = (isApproved) => async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      approved: isApproved,
    };
    if (!isApproved) {
      if (!data.requestNotes?.trim()) {
        setTab('Notes');
        setError('requestNotes', {
          type: 'manual',
          message: 'Please give a reason for rejecting the vendor',
        });
        return;
      }
      requestBody.reason = data.requestNotes;
    }
    // const pac = pendingVendorData.approval.approvers.filter(item => item.status === "PENDING_APPROVAL" && item.userId !== user.id).length
    // console.log(pac)
    // const to = isApproved && pac > 0 ? "pending-approval" : isApproved ? 'approved' : 'rejected'
    // console.log(to)
    spmsServiceService
      .approveVendor(companyId, vendorId, requestBody)
      // .then((_res) => navigate('/vendors/'+to))
      .then(() => navigate('/vendors/pending-approval'))
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message || 'Something Went Wrong',
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    spmsServiceService
      .getCompanyVendorById(companyId, vendorId)
      .then((res) => {
        setPendingVendorData(res.data.data);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
          type: 'fail',
        });
      });
  }, [vendorId]);

  const legalInfo = [
    { name: 'Legal Name', value: pendingVendorData.vendor.legalName },
    { name: 'Registration No', value: pendingVendorData.vendor.registrationNo },
    {
      name: 'Vendor Code',
      value: pendingVendorData.vendorCode != null ? pendingVendorData.vendorCode : '-',
    },
    { name: 'Vendor Type', value: pendingVendorData.vendor.vendorType },
    {
      name: 'Suite Number/Building Name/Office Park',
      value: pendingVendorData.vendor.address.streetAddress,
    },
    { name: 'Street Address', value: pendingVendorData.vendor.address.addressLine2 },
    { name: 'City', value: pendingVendorData.vendor.address.city },
    { name: 'Postal Code', value: pendingVendorData.vendor.address.zipCode },
    { name: 'Country', value: pendingVendorData.vendor.address.country },
    { name: 'Region', value: pendingVendorData.vendor.address.state },
    { name: 'Business Email', value: pendingVendorData.vendor.email },
    { name: 'Business Phone Number', value: pendingVendorData.vendor.phoneNumber },
    { name: 'Once Off PO Limit', value: pendingVendorData.onceOffPOLimit },
    { name: 'Overall Annual Limit', value: pendingVendorData.overallAnnualLimit },
    { name: 'Default Currency', value: pendingVendorData.currency },
  ];

  const limitations = [
    {
      name: "GL's",
      values:
        pendingVendorData.glAccounts.length > 0
          ? pendingVendorData.glAccounts.map((item) => item.glCode)
          : ['All'],
    },
    {
      name: 'Departments',
      values:
        pendingVendorData.departments.length > 0
          ? pendingVendorData.departments.map((item) => item.name)
          : ['All'],
    },
    {
      name: 'Branches',
      values:
        pendingVendorData.branches.length > 0
          ? pendingVendorData.branches.map((item) => item.name)
          : ['All'],
    },
    {
      name: 'Users',
      values:
        pendingVendorData.glAccounts.length > 0
          ? pendingVendorData.glAccounts.map((item) => item.glCode)
          : ['All'],
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Approver Name',
        cell: (row) => (
          <span data-content={'Approver Name'} className={generalStyles.tableValue}>
            <span>{row.approverName ?? 'System Approved'}</span>
          </span>
        ),
      },
      {
        name: 'Date & Time',
        cell: (_row) => (
          <span data-content={'Date & Time'} className={generalStyles.tableValue}>
            <span>
              {moment(pendingVendorData?.approval?.submissionDate).format('DD MMM, YYYY | hh:mm A')}
            </span>
          </span>
        ),
      },
      {
        name: 'Status',
        cell: (row) => (
          <span data-content={'Status'} className={generalStyles.tableValue}>
            <Status value={row?.status} wordCount={1} pill/>
          </span>
        ),
      },
    ],
    [pendingVendorData],
  );

  const isButtonVisible = () => {
    if (!pendingVendorData.approval) return;
    const userApproval = pendingVendorData.approval.approvers.find(
      (approver) => approver.userId === user.id,
    );
    return userApproval.status === 'PENDING_APPROVAL';
  };

  return (
    <>
      <div className={generalStyles.pageButtons}>
        <Text type="h2" weight={600}>
          {pendingVendorData.vendor.legalName}
        </Text>
      </div>
      <Box $mobExtend $asHolder $noPadding className={styles.pendingContainer}>
        <div className={styles.vendorTitle}>
          <Text type="h3">Legal Information</Text>
        </div>
        <div className={styles.legalPending}>
          <div className={generalStyles.fieldsThree}>
            {legalInfo.map((item) => (
              <div key={item.name} className={styles.legalPendingBlock}>
                <Text weight={500}>{item.name}</Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.vendorTitle}>
          <Text type="h3">Configuration</Text>
          <Text>Vendor is limited to the following</Text>
        </div>
        <div className={styles.pendingLimitations}>
          {limitations.map((limitation) => (
            <div key={limitation.name} className={styles.limitation}>
              <Text weight={600}>{limitation.name}</Text>
              <div className={styles.limitationValues}>
                {limitation.values.map((value) => (
                  <span key={value} className={styles.limitationValue}>
                    {value}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
        <form id="cForm" className={styles.form}>
          <TabsSlider selected={tab} className={generalStyles.tabs}>
            <span onClick={() => setTab('Notes')}>Notes</span>
            <span onClick={() => setTab('Documents')}>Documents</span>
            <span onClick={() => setTab('History')}>History</span>
            <span onClick={() => setTab('Approval ')}>Approval </span>
          </TabsSlider>
          {tab === 'Notes' && (
            <div>
              <div className="inp-container">
                <Controller
                  name="requestNotes"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 500,
                      message: 'Maximum 500 characters',
                    },
                    validate: {
                      // notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                      allowed: (v) =>
                        regExps.notes.test(v) || 'Only Alpha and Numerical characters',
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      $low
                      $counter
                      $counterMax={500}
                      placeholder="Enter notes"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  )}
                />
                {errors.requestNotes && (
                  <p className="error-message position-top-95">{errors.requestNotes.message}</p>
                )}
                <NotesTab data={pendingVendorData?.vendorNotes} />
              </div>
            </div>
          )}
          {tab === 'Documents' && <QuotesTab data={pendingVendorData.attachments} />}
          {tab === 'History' && <HistoryTab data={pendingVendorData.history} />}
          {tab === 'Approval ' && (
            <DataTableBase
              data={pendingVendorData.approval?.approvers}
              columns={columns}
              selectableRows={false}
              pagination={false}
              separatePagination
            />
          )}
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        {isButtonVisible() && (
          <>
            <Button.Main
              $primary
              $style="pink"
              form="cForm"
              type="submit"
              onClick={handleSubmit(approveRejectVendor(true))}
            >
              Approve
            </Button.Main>
            <Button.Main
              $primary
              $style="gray"
              type="submit"
              disabled={!isValid}
              onClick={handleSubmit(approveRejectVendor(false))}
            >
              Reject
            </Button.Main>
          </>
        )}
        <Button.Main $style="gray" $primary onClick={() => navigate(-1)}>
          <span>Back</span>
        </Button.Main>
      </div>
      {toast.opened && <Toast type={toast.type} message={toast.message} opened={toast.opened} />}
    </>
  );
};

export default PendingDashboard;
