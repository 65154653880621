import React from 'react';
import Label from '../../UI/Forms/Label';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';

const ModuleEvent = ({ moduleName, eventName, control, errors, setValue, modules, events }) => {
  const formModuleValue = useWatch({ name: moduleName, control });
  return (
    <>
      <div className="inp-container">
        <Label $title="Notification Module" $isRequired $tooltipText="Module this notification is linked to" />
        <Controller
          name={moduleName}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Module is required',
            },
          }}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              onChange={(e) => {
                field.onChange(e);
                setValue(eventName, null);
              }}
              classNamePrefix="react-select"
              isSearchable={false}
              placeholder="Select Module"
              options={modules}
            />
          )}
        />
        {errors?.[moduleName] && <p className="error-message">{errors?.[moduleName]?.message}</p>}
      </div>
      <div className="inp-container">
        <Label $title="Notification Event" $isRequired $tooltipText="Event that will trigger the notification" />
        <Controller
          name={eventName}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Event is required',
            },
          }}
          render={({ field }) => (
            <Select
              isDisabled={!formModuleValue?.value}
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isSearchable={false}
              placeholder="Select Event"
              options={events[formModuleValue?.value] ?? events.OTHER}
            />
          )}
        />
        {errors?.[eventName] && <p className="error-message">{errors?.[eventName]?.message}</p>}
      </div>
    </>
  );
};

export default ModuleEvent;
