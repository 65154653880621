import React, {Suspense, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from "react-hook-form";
import Text from "../../components/UI/Typography/Text";
import Input from "../../components/UI/Forms/Input";
import Label from "../../components/UI/Forms/Label";
import * as Button from "../../components/UI/Forms/Button";
import Box from "../../components/UI/General/Box";
import "react-datepicker/dist/react-datepicker.css";
import generalStyles from '../../styles/general.module.css'
import {useStore} from "../../store/store";
import Toast from "../../components/UI/General/Toast";
import styles from "../../styles/notificationTemplates.module.css";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.min.css';
import s2pmsEmail from "../../services/s2pmsEmail.service";
import ModuleEvent from "../../components/shared/Notifications/ModuleEvent";
import {readableTitleFromBackend} from "../../utils/readableTitleFromBackend";

const modules = [
  { label: 'Purchase Order', value: 'PURCHASE_ORDERS' },
  { label: 'Requisitions', value: 'REQUISITIONS' },
  { label: 'Budgets', value: 'BUDGETS' },
  { label: 'Vendors', value: 'VENDORS' },
  { label: 'Receiving', value: 'RECEIVING' },
  { label: 'Invoicing', value: 'INVOICING' },
  { label: 'Payments', value: 'PAYMENTS' },
  { label: 'Support', value: 'SUPPORT' },
];

const events = {
  PAYMENTS: [{ label: 'Payment Received', value: 'PAYMENT_RECEIVED' }],
  RECEIVING: [{ label: 'Received', value: 'RECEIVED' }],
  SUPPORT: [{ label: 'Contact Us', value: 'CONTACT_US' },{ label: 'Support Ticket Created', value: 'SUPPORT_TICKET_CREATED' }],
  OTHER: [
    { label: 'Submitted for Approval', value: 'SUBMITTED_FOR_APPROVAL' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
  ],
};

const NtAdd = () => {
  const {templateId} = useParams();
  const navigate = useNavigate();
  const {id} = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null)
  const [previewed, setPreviewed] = useState(false)
  const [code, setCode] = useState('');
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {handleSubmit, control, setValue, formState: {errors, isDirty, isValid}} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      subject: '',
      notificationModule: '',
      notificationEvent: '',
      html: '',
    },
    values
  });

  useEffect(() => {
    if (templateId !== undefined){
      s2pmsEmail.getTemplate(templateId).then(r => {
        if (r.data.message === "Operation Successful"){
          const rData = r.data.data
          const updData = {
            title: rData?.name,
            subject: rData?.subject,
            notificationModule: {label: readableTitleFromBackend(rData?.module), value: rData?.module},
            notificationEvent: {label: readableTitleFromBackend(rData?.event), value: rData?.event},
            html: rData.template
          }
          setValues(updData)
          setCode(rData.template)
        }
      })
    }
  },[templateId])

  const onSubmit = (data) => {
    console.log(data)
    setToast((item) => ({ ...item, opened: false }));
    const newData = {
      name: data.title,
      subject: data.subject,
      module: data.notificationModule.value,
      event: data.notificationEvent.value,
      template: code
    }
    console.log(newData)
    if (templateId !== undefined){
      s2pmsEmail.updateTemplate(templateId, newData).then(r => {
        console.log(r)
        setToast({
          opened: true,
          message: 'Notification Template updated successfully',
          type: 'success',
          cb: () => navigate('/notification-templates/overview/active'),
        });
      }).catch(reason => {
        console.log(reason)
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      })
    }else {
      s2pmsEmail.createTemplate(newData).then(r => {
        console.log(r)
        setToast({
          opened: true,
          message: 'Notification Template created successfully',
          type: 'success',
          cb: () => navigate('/notification-templates/overview/active'),
        });
      }).catch(reason => {
        console.log(reason)
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      })
    }
  };

  return (
    <>
      <Text weight={600} type="h2">{templateId !== undefined ? "Edit" : "Create"} Notification Template</Text>
      <Suspense fallback={<h2>Loading...</h2>}>
        <form id="templateForm">
          <Box $mobExtend $asHolder $noOverflow>
            <div className={generalStyles.fieldsFour}>
              <div className="inp-container">
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Title is required',
                    },
                  }}
                  render={({field}) => (
                    <Input
                      type="text"
                      placeholder="Title"
                      className={errors.hasOwnProperty(field.name) && "error"}
                      $label="Title"
                      $labelRequired
                      {...field}/>
                  )}
                />
                {errors.title && <p className="error-message">{errors.title?.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="subject"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Subject is required',
                    },
                  }}
                  render={({field}) => (
                    <Input
                      type="text"
                      placeholder="Subject"
                      className={errors.hasOwnProperty(field.name) && "error"}
                      $label="Subject"
                      $labelRequired
                      {...field}/>
                  )}
                />
                {errors.subject && <p className="error-message">{errors.subject?.message}</p>}
              </div>
              <ModuleEvent
                moduleName="notificationModule"
                eventName="notificationEvent"
                control={control}
                errors={errors}
                setValue={setValue}
                modules={modules}
                events={events}
              />
            </div>

            <div className={styles.htmlAreaWrapper}>
              <div className="inp-container">
                <Label $isRequired $title="HTML"/>
                <Controller
                  name="html"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'HTML is required',
                    },
                  }}
                  render={({field}) => (
                    <div className={styles.htmlArea}>
                      <Editor
                        value={field.value}
                        onValueChange={val => {
                          field.onChange(val)
                          setCode(val)
                        }}
                        highlight={code => highlight(code, languages.html, languages.html)}
                        style={{
                          minHeight: 360,
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12
                        }}
                        {...field}
                      />
                    </div>
                  )}
                />
                {errors.html && <p className="error-message">{errors.html?.message}</p>}
              </div>
            </div>
            {previewed && code !== '' ? (
              <div className={styles.preview}>
                <Label>Preview</Label>
                <iframe srcDoc={code} title="Preview" frameBorder="0" name="preview" sandbox="allow-same-origin"/>
              </div>
            ) : null}
          </Box>
        </form>
        <div className={generalStyles.pageButtons}>
          <Button.Main $primary $style="pink" type="button" disabled={!isValid} onClick={() =>setPreviewed(true)}>Preview</Button.Main>
          <Button.Main $primary $style="pink" type="button" disabled={(!isValid || !isDirty) || !previewed} onClick={handleSubmit((data) => onSubmit(data))}>Activate</Button.Main>
          <Button.Main $primary $style="gray" type="button" onClick={() => navigate('/notification-templates/overview/active')}>Discard</Button.Main>
        </div>
      </Suspense>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} /> : null}
    </>
  );
};

export default NtAdd;
