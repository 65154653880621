import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "../../../styles/slick.css"
import Icon from "./Icon";
import * as Button from "../Forms/Button";
import styled, {css} from "styled-components";

const StyledSlider = styled.div`
  flex: 1;
  max-width: 100%;
  border-bottom: 1px solid rgba(69, 186, 188, 0.5);
  & a {
    margin-bottom: -1px;
    padding: 10px 12px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    white-space: nowrap;
    &:hover,
    &.active {
      color: #45babc;
      border-color: #45babc;
    }
    &.active {
      font-weight: 700;
    }
    @media all and (min-width: 768px) {
      margin-bottom: -2px;
      padding: 0 14px 10px 14px;
      font-size: 15px;
    }
    @media all and (min-width: 1024px) {
      //padding: 0 24px 15px 24px;
      padding: 0 16px 12px 16px;
      font-size: 20px;
    }    
  }
  & .regular.slider{
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const TabbedItem = styled.div`
  display: flex;
  font-size: ${({ $small }) => ($small ? '16px' : '20px')};
  font-weight: 500;
  & span {
    display: block;
    margin-bottom: -1px;
    padding: ${({ $small }) => ($small ? '0 20px 12px 20px' : '10px 12px')};
    font-size: 16px;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    @media all and (min-width: 768px) {
      margin-bottom: -2px;
      //padding: 0 24px 15px 24px;
      padding: 0 16px 12px 16px;
      font-size: 20px;
    }
  }
  &:hover span,
  &.active span {
    color: #45babc;
    border-color: #45babc;
    cursor: pointer;
  }
  &.active span {
    font-weight: 700;
  }  
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 74px;
  display: flex;
  align-items: center;
  padding: 0 16px;  
  z-index: 2;
  ${({ $next }) => $next ? (
    css`right: -16px;justify-content: flex-end;background: linear-gradient(90deg, rgba(248, 248, 250, 0.83) 26.39%, #F8F8FA 94.76%);`
  ) : null}
  ${({ $prev }) => $prev ? (
    css`left: -16px;justify-content:flex-start;background: linear-gradient(270deg, rgba(248, 248, 250, 0.83) 26.39%, #F8F8FA 94.76%);`
  ) : null}
  &.slick-disabled{
    display: none;
  }
`

const NextArrow = (props) => (
  <Arrow $next className={props.className}>
    <Button.Action $style="blue" $variant="circle" $width={26} $height={26} onClick={props.onClick}>
      <Icon $width={26} $height={26} $icon="chevron-right" $color="white" />
    </Button.Action>
  </Arrow>
)
const PrevArrow = (props) => (
  <Arrow $prev className={props.className}>
    <Button.Action $style="blue" $variant="circle" $width={26} $height={26} onClick={props.onClick}>
      <Icon $width={26} $height={26} $icon="chevron-left" $color="white" />
    </Button.Action>
  </Arrow>
)

const settings = {
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick"
    },
    {
      breakpoint: 1367,
      settings: {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        infinite: false,
        centerMode: false,
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    }
  ]
};

const TabsSlider = (props) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    if (props?.selected) {
      setActive(props.children.findIndex(data => data.props?.children?.toString().toLowerCase() === props?.selected?.toString().toLowerCase()));
    }
  }, [props.selected]);

  let children;
  if (Array.isArray(props.children)){
    children = props.children
  }else {
    children = [];
    children.push(props.children)
  }

  return (
    <StyledSlider {...props}>
      <Slider {...settings}>
        {children.map((item, i) => {
          if (item && item.props?.$islink) {
            return item;
          } else {
            return (
              <TabbedItem
                key={i}
                $small={props.$small}
                className={active === i ? "active" : ""}
                onClick={() => item.props.$isActive === undefined || item.props?.$isActive === "true" ? setActive(i) : null}
              >
                {item}
              </TabbedItem>
            );
          }
        })}
      </Slider>
    </StyledSlider>
  );
}

export default TabsSlider;
