import React from 'react';

const Currencies = () => {
  return (
    <div>
      Currencies
    </div>
  );
};

export default Currencies;
