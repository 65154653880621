import React, {useEffect, useMemo, useState} from 'react';
import Dropdown from '../../components/UI/General/Dropdown';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import styles from '../../styles/companySetup.module.css';
import Box from '../../components/UI/General/Box';
import DataTableBase from '../../components/UI/General/DataTableBase';
import Toast from '../../components/UI/General/Toast';
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom';
import AddButton from '../../components/shared/AddButton';
import Text from "../../components/UI/Typography/Text";
import spmsServiceService from "../../services/spmsService.service";
import {useStore} from "../../store/store";
import TabsSlider from "../../components/UI/General/TabsSlider";

const Integrations = () => {
  const { tab } = useParams();
  const activeCompany = useStore((state) => state.activeCompany);
  const [integrations, setIntegrations] = useState([]);
  const [refetch, setRefetch] = useState(false)

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        name: 'companyId',
        sortable: true,
        selector: (row) => row.companyId,
        cell: (row) => (
          <Link className={styles.addLink} to={`/integrations/edit/${row.id}`}>
            <span data-content={'companyId'} className={generalStyles.tableValue}>
              <span>{row.companyId ?? '-'}</span>
            </span>
          </Link>
        ),
      },
      {
        name: 'environmentName',
        sortable: true,
        selector: (row) => row.environmentName,
        cell: (row) => (
          <span data-content={'environmentName'} className={generalStyles.tableValue}>
            <span>{row.environmentName ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'tenantId',
        cell: (row) => (
          <span data-content={'tenantId'} className={generalStyles.tableValue}>
            <span>{row.tenantId ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {tab === 'active' ? (
                    <>
                      <MenuItem onClick={() => deactivate(activeCompany.id,row.id)}>Archive</MenuItem>
                      <MenuItem onClick={() => navigate(`/integrations/edit/${row.id}`)}>Edit</MenuItem>
                    </>
                  ) : (
                    <MenuItem onClick={() => activate(activeCompany.id,row.id)}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, activeCompany.id],
  );

  useEffect(() => {
    if (activeCompany.id) {
      spmsServiceService.getAllIntegrations(activeCompany.id).then(r => {
        console.log(r)
        if (r.data.message === 'Operation Successful'){
          if (tab === "active"){
            setIntegrations(r.data?.data?.filter(item => item.active === true))
          }else {
            setIntegrations(r.data?.data?.filter(item => item.active === false))
          }
        }
      }).catch(err => {
        console.log(err)
        setToast({
          opened: true,
          message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
          type: 'fail',
        });
      })
    }
  },[tab, activeCompany.id, refetch])

  const activate = (companyId, integrationId) => {
    setToast((item) => ({...item, opened: false}));
    spmsServiceService.activateIntegration(companyId, integrationId).then(r => {
      console.log(r)
      setRefetch(s => !s)
      setToast({
        opened: true,
        message: 'Integration activated successfully',
        type: 'success',
      });
    }).catch(err => {
      console.log(err)
      setToast({
        opened: true,
        message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
        type: 'fail',
      });
    })
  }

  const deactivate = (companyId, integrationId) => {
    setToast((item) => ({...item, opened: false}));
    spmsServiceService.deactivateIntegration(companyId, integrationId).then(r => {
      console.log(r)
      setRefetch(s => !s)
      setToast({
        opened: true,
        message: 'Integration deactivated successfully',
        type: 'success',
      });
    }).catch(err => {
      console.log(err)
      setToast({
        opened: true,
        message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
        type: 'fail',
      });
    })
  }

  return (
    <>
      <Text type="h2" weight={600}>Integrations Overview</Text>
      <Box $mobExtend $asHolder $radius={12}>
        <div className={styles.departmentBox}>
          <div className={generalStyles.tabSection}>
            <TabsSlider>
              <NavLink
                to="/integrations/overview/active"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Active
              </NavLink>
              <NavLink
                to="/integrations/overview/archived"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Archived
              </NavLink>
            </TabsSlider>
            <div className={generalStyles.addItemButton}>
              <AddButton $click={() => navigate(`/integrations/add`)}/>
            </div>
          </div>
          <DataTableBase
            data={integrations}
            columns={columns}
          />
          {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} />: null}
        </div>
      </Box>
    </>
  );
};

export default Integrations;
