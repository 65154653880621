import React, { useCallback, useEffect, useState } from 'react';
import DataTableBase from './DataTableBase';

function DataTableBaseRemote(props) {
  const { fetchData } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(props.paginationPerPage ? props.paginationPerPage : 10);

  const fetch = useCallback(
    async (page) => {
      setLoading(true);

      const response = await fetchData(page, perPage);

      setData(response.data.data.content);
      setTotalRows(response.data.data.totalElements);
      setLoading(false);
    },
    [fetchData, perPage],
  );

  const handlePageChange = (page) => {
    fetch(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await fetchData(page - 1, newPerPage);

    setData(response.data.data.content);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetch(0); // fetch page 1 of data
  }, [fetch]);

  return (
    <DataTableBase
      selectableRows
      selectableRowsHighlight
      selectableRowsNoSelectAll
      data={data}
      progressPending={loading}
      pagination={totalRows > 10}
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      {...props}
    />
  );
}

export default DataTableBaseRemote;
