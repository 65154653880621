import { useEffect, useMemo, useState } from 'react';
import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/vendorProfile.module.css';
import generalStyles from '../../styles/general.module.css';
import DataTableBase from '../../components/UI/General/DataTableBase';
import { Link, NavLink, useParams } from 'react-router-dom';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import moment from 'moment';
import TabsSlider from '../../components/UI/General/TabsSlider';

const Orders = () => {
  const { tab } = useParams();
  const [vendorOrders, setVendorOrders] = useState([]);
  const { externalId: vendorId } = useStore((state) => state.user);

  useEffect(() => {
    spmsServiceService.getPurchaseOrders(100, 0, { vendorId }).then((r) => {
      if (r.data.message === 'Operation Successful') {
        setVendorOrders(r.data.data.content);
      }
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'PO Nr',
        selector: (row) => row.purchaseOrderNo,
        sortable: true,
        cell: (row) => (
          <span data-content={'Order Date'} className={generalStyles.tableValue}>
            <Link className={styles.link} to={`/vendor-orders/order/${row.id}`}>
              {row.purchaseOrderNo}
            </Link>
          </span>
        ),
      },
      {
        name: 'Title',
        selector: (row) => row.title,
        sortable: true,
        cell: (row) => (
          <span data-content={'Title'} className={generalStyles.tableValue}>
            {row.title}
          </span>
        ),
      },
      {
        name: 'Order Date',
        selector: (row) => row.dateApproved,
        omit: tab !== 'new',
        wrap: true,
        sortable: true,
        cell: (row) => (
          <span data-content={'Order Date'} className={generalStyles.tableValue}>
            {moment(row.dateApproved).format('MM/DD/YYYY')}
          </span>
        ),
      },
      {
        name: 'Delivery Date',
        selector: (row) => row.deliveryDate,
        sortable: true,
        omit: tab !== 'new' && tab !== 'delivered',
        cell: (row) => (
          <span data-content={'Delivery Date'} className={generalStyles.tableValue}>
            {row.deliveryDate}
          </span>
        ),
      },
      {
        name: 'Company',
        selector: (row) => row.companyName,
        wrap: true,
        sortable: true,
        cell: (row) => (
          <span data-content={'Company'} className={generalStyles.tableValue}>
            {row.companyName}
          </span>
        ),
      },
      {
        name: 'Branch',
        selector: (row) => row.branchName,
        sortable: true,
        cell: (row) => (
          <span data-content={'Branch'} className={generalStyles.tableValue}>
            {row.branchName}
          </span>
        ),
      },
      {
        name: 'Requester',
        selector: (row) => row.requester?.name,
        sortable: true,
        cell: (row) => (
          <span data-content={'Requester'} className={generalStyles.tableValue}>
            {row.requester?.name}
          </span>
        ),
      },
      {
        name: 'Amount',
        selector: (row) => row.total.toFixed(2),
        sortable: true,
        cell: (row) => (
          <span data-content={'Amount'} className={generalStyles.tableValue}>
            {`$ ${row.total.toFixed(2)}`}
          </span>
        ),
      },
    ],

    [tab],
  );

  const tabs = ['new', 'delivery', 'invoice', 'paid', 'completed'];

  return (
    <Box $radius={12} $mobExtend $asHolder>
      <div className={styles.title}>
        <Text weight={600} type="h2">
          Orders Overview
        </Text>
      </div>
      <div className={generalStyles.tabSection}>
        <TabsSlider selected={tab}>
          {tabs.map((tab) => (
            <NavLink to={`/vendor-orders/${tab}`}>
              {tab.slice(0, 1).toUpperCase() + tab.slice(1)}
            </NavLink>
          ))}
        </TabsSlider>
      </div>
      <DataTableBase
        data={vendorOrders.filter((order) => order?.status.toLowerCase() === tab) || []}
        columns={columns}
        paginationResetDefaultPage={false}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 15, 20, 25]}
      />
    </Box>
  );
};

export default Orders;
