import React from 'react';
import { Controller } from 'react-hook-form';
import Textarea from '../../../UI/Forms/Textarea';
import Documents from '../../../UI/Forms/Documents';
import styles from '../../../../styles/purchaseOrders.module.css';
import generalStyles from '../../../../styles/general.module.css';
import TabsSlider from "../../../UI/General/TabsSlider";
import {regExps} from "../../../../utils/regExps";
import QuotesTab from "../Tabs/QuotesTab";
import Label from "../../../UI/Forms/Label";
import NotesTab from "../Tabs/NotesTab";

const Docs = ({ errors, control, nameNotes, nameFiles, labelNotes, labelFiles, tab, setTab, clearErrors, requiredRef, attachments, notesList, showInteraction = true }) => {
  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  return (
    <>
      <TabsSlider selected={tab} className={generalStyles.tabs} $small>
        <span onClick={() => setTab('Notes')}>{labelNotes}</span>
        <span onClick={() => setTab('Documents')}>{labelFiles}</span>
      </TabsSlider>
      <>
        {tab === 'Notes' && (
          <>
            {showInteraction && (
              <div className="inp-container">
                <Controller
                  name={nameNotes}
                  control={control}
                  rules={{
                    pattern: {
                      value: regExps.notes,
                      message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      $low
                      $counter
                      $counterMax={500}
                      placeholder="Notes text goes here"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  )}
                />
                {errors[nameNotes] && <p className="error-message">{errors[nameNotes]?.message}</p>}
              </div>
            )}
            {!!notesList?.length && <NotesTab data={notesList}/>}
          </>
        )}
        {tab === 'Documents' && (
          <div className={styles.attachmentsBlockItem}>
            {!!attachments?.length ? <QuotesTab data={attachments}/> : null}
            {showInteraction && (
              <div>
                <Label $title="Upload Supporting Documents" $isRequired={requiredRef.current}/>
                <Documents
                  control={control}
                  fieldName={nameFiles}
                  filesSchema={files}
                  error={errors?.[nameFiles]?.message || errors?.[nameFiles]?.root?.message}
                  required={requiredRef.current}
                  clearErrors={clearErrors}
                />
              </div>
            )}
          </div>

        )}
      </>
    </>
  );
};

export default Docs;
