import Text from '../../components/UI/Typography/Text';
import { useState, useEffect, useMemo, useCallback } from 'react';
import SearchVendor from './SearchVendor';
import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import ActiveVendorOverview from './ActiveVendorOverview';
import PendingVendorOverview from './PendingVendorOverview';
import ArchiveVendorOverview from './ArchiveVendorOverview';
import spmsServiceService from '../../services/spmsService.service';
import DraftVendorOverview from './DraftVendorOverview';
import RejectedVendorOverview from './RejectedVendorOverview';
import Box from '../../components/UI/General/Box';
import { NavLink, useParams } from 'react-router-dom';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import TabsSlider from '../../components/UI/General/TabsSlider';
import AddButton from '../../components/shared/AddButton';
import Input from '../../components/UI/Forms/Input';
import Radio from '../../components/UI/Forms/Radio';

const CompanyVendorSetup = () => {
  const activeCompany = useStore((state) => state.activeCompany);
  const companyId = useStore((state) => state.company?.id);
  const [filterText, setFilterText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOption, setSearchOption] = useState('name');
  const { tab } = useParams();
  const [isAddVendor, setIsAddVendor] = useState(false);
  const accessVendor = useAccessAllowed('Vendors');
  const [refetchTrigger, setRefetchTrigger = { setRefetchTrigger }] = useState(false);
  const user = useStore((state) => state.user);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const fetchVendors = useCallback(
    async (page, size) => {
      const bodyData = {
        active: tab !== 'archived',
        vendorCode: searchOption === 'code' ? searchQuery : undefined,
        vendorName: searchOption === 'name' ? searchQuery : undefined,
      };
      if (tab !== 'archived') {
        bodyData.vendorStatus = tab === 'create' ? 'DRAFT' : tab.split('-').join('_').toUpperCase();
      }
      return await spmsServiceService.getVendors(activeCompany?.id, bodyData, page, size);
      // .then((res) => {
      //   const vendors = res.data.data.content;
      //   const userVendors = !accessVendor?.viewAll
      //     ? vendors.filter((vendor) => vendor.createdBy.userId === user.id)
      //     : vendors;
      // })
    },
    [searchQuery, tab, activeCompany?.id, refetchTrigger],
  );

  useEffect(() => {
    const filterData = setTimeout(() => {
      setSearchQuery(filterText);
    }, 500);
    return () => clearTimeout(filterData);
  }, [filterText, tab, activeCompany?.id]);

  useEffect(() => {
    if (tab === 'create') setIsAddVendor(true);
  }, [tab, activeCompany?.id, accessVendor]);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#8A8B9D"
        placeholder="Search Vendor"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Text type="h2" weight={600}>
        Vendor Overview
      </Text>
      <Box $radius={12} $mobExtend $asHolder>
        {!activeCompany ? (
          <h2 className={styles.loader}>Loading...</h2>
        ) : (
          <>
            <div className={generalStyles.tabSection}>
              <TabsSlider>
                <NavLink
                  to="/vendors/draft"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  $islink="true"
                >
                  Draft
                </NavLink>
                <NavLink
                  to="/vendors/pending-approval"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  $islink="true"
                >
                  Pending Approval
                </NavLink>
                <NavLink
                  to="/vendors/approved"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  $islink="true"
                >
                  Approved
                </NavLink>
                <NavLink
                  to="/vendors/rejected"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  $islink="true"
                >
                  Rejected
                </NavLink>
                <NavLink
                  to="/vendors/archived"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  $islink="true"
                >
                  Archived
                </NavLink>
              </TabsSlider>
              {accessVendor?.create && (
                <div className={generalStyles.addItemButton}>
                  <AddButton $click={() => setIsAddVendor(true)} />
                </div>
              )}
            </div>
            <div className={styles.search}>
              {filterCmp}
              <Radio
                $size={16}
                label="Search by Name"
                checked={searchOption === 'name'}
                value="name"
                $tooltip=""
                onChange={() => setSearchOption('name')}
              />
              <Radio
                checked={searchOption === 'code'}
                $size={16}
                label="Search by Code"
                value="code"
                $tooltip=""
                onChange={() => setSearchOption('code')}
              />
            </div>

            {tab === 'approved' && (
              <ActiveVendorOverview
                companyId={activeCompany?.id}
                fetchVendors={fetchVendors}
                setToast={setToast}
                setRefetchTrigger={setRefetchTrigger}
              />
            )}
            {tab === 'pending-approval' && (
              <PendingVendorOverview
                companyId={activeCompany?.id}
                fetchVendors={fetchVendors}
                setToast={setToast}
                setRefetchTrigger={setRefetchTrigger}
              />
            )}
            {tab === 'archived' && (
              <ArchiveVendorOverview
                fetchVendors={fetchVendors}
                companyId={activeCompany?.id}
                setToast={setToast}
                setRefetchTrigger={setRefetchTrigger}
              />
            )}
            {(tab === 'draft' || tab === 'create') && (
              <DraftVendorOverview
                companyId={activeCompany?.id}
                fetchVendors={fetchVendors}
                setToast={setToast}
                setRefetchTrigger={setRefetchTrigger}
              />
            )}
            {tab === 'rejected' && (
              <RejectedVendorOverview companyId={activeCompany?.id} fetchVendors={fetchVendors} />
            )}
          </>
        )}
      </Box>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
      {isAddVendor && <SearchVendor setIsAddVendor={setIsAddVendor} />}
    </>
  );
};

export default CompanyVendorSetup;
