import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text';
import { useState } from 'react';
import Textarea from '../../components/UI/Forms/Textarea';
import { Controller, useForm } from 'react-hook-form';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import spmsServiceService from '../../services/spmsService.service.js';
import { useNavigate } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button.jsx';
import TabsSlider from '../../components/UI/General/TabsSlider.jsx';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab.jsx';
import Box from '../../components/UI/General/Box.jsx';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab.jsx';
import { regExps } from '../../utils/regExps.js';

const ActiveLegalInfo = ({ activeVendorData, setToast, companyId, triggerRefetch }) => {
  const [tab, setTab] = useState('notes');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();

  const submitVendor = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    try {
      await spmsServiceService.addVendorNotes(companyId, activeVendorData.id, data);
      setToast({
        opened: true,
        message: 'Vendor has been updated',
        type: 'success',
      });
      reset();
      triggerRefetch((refetch) => !refetch);
    } catch (err) {
      setToast({
        opened: true,
        message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
        type: 'fail',
      });
    }
  };
  return (
    <>
      <Box $mobExtend $asHolder className={styles.approvedContainer}>
        <div>
          <div className={generalStyles.fieldsThree}>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Legal Name
              </Text>
              <Text>{activeVendorData?.legalName ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Registration No
              </Text>
              <Text>{activeVendorData?.registrationNo ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Vendor Code
              </Text>
              <Text>{activeVendorData?.vendorCode ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Vendor Type
              </Text>
              <Text>{activeVendorData?.vendorType ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Suite Number/Building Name/Office Park
              </Text>
              <Text>{activeVendorData?.address.streetAddress ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Street Address
              </Text>
              <Text>{activeVendorData?.address.addressLine2 ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                City
              </Text>
              <Text>{activeVendorData?.address.city ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Postal Code
              </Text>
              <Text>{activeVendorData?.address.zipCode ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Country
              </Text>
              <Text>{activeVendorData?.address.country ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Region
              </Text>
              <Text>{activeVendorData?.address.state ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Business Email
              </Text>
              <Text>{activeVendorData?.email ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Business Phone Number
              </Text>
              <Text>{activeVendorData?.phoneNumber ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Once Off PO Limit
              </Text>
              <Text>{activeVendorData?.onceOffPOLimit ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Overall Annual Limit
              </Text>
              <Text>{activeVendorData?.overallAnnualLimit ?? '-'}</Text>
            </div>
            <div className={styles.legalPendingBlock}>
              <Text className={styles.limitationValue} weight={600}>
                Default Currency
              </Text>
              <Text>{activeVendorData?.currency ?? '-'}</Text>
            </div>
          </div>
        </div>
        <TabsSlider className={generalStyles.tabs}>
          <span onClick={() => setTab('notes')}>Notes</span>
          <span onClick={() => setTab('documents')}>Documents</span>
          <span onClick={() => setTab('history')}>History</span>
          <span onClick={() => setTab('approval ')}>Approval </span>
        </TabsSlider>
        <div className={styles.approvedNotes}>
          {tab === 'notes' && (
            <div>
              <div className="inp-container">
                <Controller
                  name="notes"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 500,
                      message: 'Maximum 500 characters',
                    },
                    validate: {
                      // notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                      allowed: (v) =>
                        regExps.notes.test(v) || 'Only Alpha and Numerical characters',
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      $low
                      $counter
                      $counterMax={500}
                      placeholder="Enter notes"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  )}
                />
                {errors.notes && <p className="error-message">{errors.notes.message}</p>}
                <NotesTab data={activeVendorData?.vendorNotes} />
              </div>
            </div>
          )}
          {tab === 'documents' && <QuotesTab data={activeVendorData?.vendorAttachments} />}
          {tab === 'history' && <HistoryTab data={activeVendorData.history} />}
          {tab === 'approval ' && <ApprovalTab data={activeVendorData.approval?.approvers} />}
        </div>
      </Box>
      <div className={generalStyles.pageButtons}>
        <Button.Main
          $primary
          $style="pink"
          form="cForm"
          type="submit"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(submitVendor)}
        >
          Save
        </Button.Main>
        <Button.Main
          $primary
          $style="gray"
          type="submit"
          onClick={() => navigate('/vendors/approved')}
        >
          Discard
        </Button.Main>
      </div>
    </>
  );
};

export default ActiveLegalInfo;
