import React from 'react';

const Agents = () => {
  return (
    <div>
      Agents
    </div>
  );
};

export default Agents;
