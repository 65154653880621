import React from 'react';
import styles from "../../styles/companySetup.module.css";
import Accordion from "../../components/UI/General/Accordion";
import Text from "../../components/UI/Typography/Text";
import {useStore} from "../../store/store";
import PoBody from "../../components/Admins/Company/POBody";

const PoSetup = () => {
  const company = useStore((state) => state.company);
  const allCompanies = company?.subsidiaries ? [company, ...company.subsidiaries.filter((c) => c.active === true)] : [company];

  return (
    <div className={styles.accordionItems}>
      {allCompanies.length === 1 ? (
        <PoBody companyData={allCompanies[0]}/>
      ) : (
        <>
          {allCompanies && allCompanies.length > 0 ? (
            allCompanies.map((company) => (
              <Accordion key={company.id}>
                <div className={styles.branch}>
                  <Accordion.Header>
                    <div className={styles.branchHead}>
                      <Text weight={500} type="subtitle">
                        Company Name
                      </Text>
                      <div className={styles.branchName}>{company.name}</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <PoBody companyData={company}/>
                  </Accordion.Body>
                </div>
              </Accordion>
            ))
          ) : (
            <Text type="h3">User have no companies</Text>
          )}
        </>
      )}
    </div>
  );
};

export default PoSetup;
