import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button.jsx';
import styles from '../../styles/accountBillings.module.css';
import generalStyles from '../../styles/general.module.css';
import Icon from '../../components/UI/General/Icon.jsx';
import { useEffect, useMemo, useState } from 'react';
import DataTableBase from '../../components/UI/General/DataTableBase.jsx';
import moment from 'moment';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed.jsx';

const AccountOverview = () => {
  const currentSubscription = useSubscriptionAllowed();
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (!currentSubscription?.company) return;
    const { cycleType, userCount, currentPeriodStartedAt, currentPeriodEndsAt } =
      currentSubscription.company;
    const subscriptionData = {
      name: currentSubscription.plan.name,
      cycleType,
      userCount,
      currentPeriodStartedAt,
      currentPeriodEndsAt,
    };
    setSubscriptionData(subscriptionData);
  }, [currentSubscription]);

  const columns = useMemo(
    () => [
      {
        name: 'Subscription',
        selector: (row) => row.name,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Subscription'} className={generalStyles.tableValue}>
            <span>{row.name}</span>
          </span>
        ),
      },
      {
        name: 'Billing Cycle',
        selector: (row) => row.cycleType,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Billing Cycle'} className={generalStyles.tableValue}>
            <span>{row.cycleType}</span>
          </span>
        ),
      },
      {
        name: 'Billing Period',
        selector: (row) => row.glAccountName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Billing Period'} className={generalStyles.tableValue}>
            <span className={styles.twotableRows}>
              <span>{moment(row.currentPeriodStartedAt).format('DD MMMM YYYY')} -</span>
              <span>{moment(row.currentPeriodEndsAt).format('DD MMMM YYYY')}</span>
            </span>
          </span>
        ),
      },
      {
        name: 'No of Active Users',
        selector: (row) => row.userCount,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'No of Active Users'} className={generalStyles.tableValue}>
            <span>{row.userCount}</span>
          </span>
        ),
      },
      {
        name: 'Payment Method',
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Payment Method'} className={generalStyles.tableValue}>
            <Button.ActionLabeled onClick={() => {}}>
              <Icon $width={20} $height={20} $icon="payment-method" $color="#c85d9f" />
              <Text className={styles.wrap} weight={700} type="subtitle">
                Add Payment Method
              </Text>
            </Button.ActionLabeled>
          </span>
        ),
      },
    ],
    [],
  );
  return (
    <>
      <div className={styles.overviewTitle}>
        <Text type="h3">Your Billing Estimate</Text>
      </div>
      <div className={styles.overviewEstimate}>
        <Text type="h5" weight={400}>
          Your Trial Period ends on{' '}
          <Text weight={600}>
            {currentSubscription?.company?.trialEndsAt &&
              moment(currentSubscription?.company?.trialEndsAt).format('DD MMMM YYYY')}
          </Text>
        </Text>
        <Text type="h5" weight={400}>
          An estimate of{' '}
          <Text weight={600}>
            {currentSubscription?.company?.total} {currentSubscription?.company?.currency}
          </Text>{' '}
          will be due on
          <Text weight={600}>
            {' '}
            {currentSubscription?.company?.currentPeriodEndsAt &&
              moment(currentSubscription?.company?.currentPeriodEndsAt).format('DD MMMM YYYY')}
          </Text>
        </Text>
        <Text type="h5" weight={400}>
          Includes estimated sales tax or VAT, calculated based on your primary company address.
          Your recurring fees are subject to increase based on your usage.
        </Text>
      </div>

      <DataTableBase data={subscriptionData ? [subscriptionData] : []} columns={columns} />
    </>
  );
};

export default AccountOverview;
