import React, {useState, useEffect} from "react";
import styled from "styled-components";

const StyledTabbedNav = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(69, 186, 188, 0.5);
  margin-bottom: ${(props) => props.$marginB ?? "0px"};
  & a {
    margin-bottom: -2px;
    padding: 0 24px 15px 24px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    &:hover,
    &.active {
      color: #45babc;
      border-color: #45babc;
    }
    &.active {
      font-weight: 700;
    }
  }
`;

const TabbedItem = styled.div`
  display: flex;
  font-size: ${({ $small }) => ($small ? '16px' : '20px')};
  font-weight: 500;
  & > * {
    margin-bottom: -2px;
    border-bottom: 3px solid transparent;
    padding: ${({ $small }) => ($small ? '0 20px 12px 20px' : '0 24px 15px 24px')};
  }
  &:hover > *,
  &.active > * {
    color: #45babc;
    border-color: #45babc;
    cursor: pointer;
  }
  &.active {
    font-weight: 700;
  }
`;

const TabbedNav = (props) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    if (props?.selected) {
      setActive(props.children.findIndex((data) => data.props.children === props?.selected));
    }
  }, [props.selected]);
  return (
    <StyledTabbedNav {...props}>
      {props.children.map((item, i) => {
        if (item && item.props.$islink) {
          return item;
        } else {
          return (
            <TabbedItem
              key={i}
              $small={props.$small}
              className={active === i ? "active" : ""}
              onClick={() => item.props.$isActive === undefined || item.props.$isActive === "true" ? setActive(i) : null}
            >
              {item}
            </TabbedItem>
          );
        }
      })}
    </StyledTabbedNav>
  );
};

export default TabbedNav;
