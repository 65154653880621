import React, {useEffect, useMemo, useState} from 'react';
import Box from "../../UI/General/Box";
import {Controller, useForm} from "react-hook-form";
import Input from "../../UI/Forms/Input";
import Label from "../../UI/Forms/Label";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber} from "react-phone-number-input";
import * as Button from "../../UI/Forms/Button";
import {useNavigate} from "react-router-dom";
import File from "../../UI/Forms/File";
import {useStore} from "../../../store/store";
import requestsService from "../../../services/requestsService.service";
import Toast from "../../UI/General/Toast";
import generalStyles from "../../../styles/general.module.css";
import styles from "../../../styles/systemRolesPermissions.module.css";
import Text from "../../UI/Typography/Text";
import DataTableBase from "../../UI/General/DataTableBase";
import spmsServiceService from "../../../services/spmsService.service";

const ProfileAccount = () => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const {updateUser} = useStore((state) => state);
  const [files, setFiles] = useState(null)
  const [values, setValues] = useState();
  const [tableData, setTableData] = useState([]);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined
  });
  const {handleSubmit, control, formState: {errors, isValid, isDirty}} = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      position: "",
      email: "",
      phoneNumber: "",
      profileImage: ""
    },
    values
  });

  const columns = useMemo(() => [
    {
      name: 'Company Name',
      selector: (row) => row.companyName ?? '-',
      sortable: true
    },
    {
      name: 'LOA',
      selector: (row) => row.limitOfAuthority ?? '-',
      sortable: true
    },
    {
      name: 'Branch',
      selector: (row) => row.branchName ?? '-',
      sortable: true
    },
    {
      name: 'Department',
      selector: (row) => row.departmentName ?? '-',
      sortable: true
    },
    {
      name: 'Roles',
      cell: (row) => {
        const roles = user.roles.filter(el => el.company?.id === row.companyId).map(item => item.role.name)
        return(
          <div className={styles.roles}>
            {!!roles.length ? roles.map((item, index) => <span key={index}>{item}</span>) : '-'}
          </div>
        )
      },
    },
  ], [user]);

  const onSubmit = (data) => {
    setToast(item => ({...item, opened: false}))
    console.log(data)
    const {firstName, lastName, phoneNumber} = data;
    const newData = {
      firstName,
      lastName,
      phoneNumber,
      profileImage: files !== null ? files : ""
    }
    console.log(newData)

    requestsService.updateProfile(user.id, newData).then(r => {
      if (r.data.message === "Operation Successful"){
        updateUser(r.data.data)
        console.log("SUC")
        setToast({
          opened: true,
          message: "Your account has been updated",
          type: "success"
        });
      }
    }).catch(reason => {
      setToast({
        opened: true,
        message: reason.response.data.errors[0].errorMessage,
        type: "fail"
      })
    })
  }

  const readFile = (event) => {
    if (event.target.files[0] !== undefined){
      let filereader = new FileReader();
      filereader.readAsDataURL(event.target.files[0]);
      filereader.onload = function () {
        setFiles(filereader.result.split(';base64,')[1]);
      };
      filereader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }else {
      setFiles(null);
    }
  };

  useEffect(() => {
    const initData = async () => {
      const data = await Promise.all(user.customCompanies.map(async (item) => {
        const data = await Promise.all([
          spmsServiceService.getDepartment(item.companyId, item.departmentId).then(r => {
            return r.data.name
          }).catch(() => null),
          spmsServiceService.getBranches(item.companyId).then(r => {
            if (r.data.status === "success"){
              return r.data.data.filter(el => el.id === item.branchId)[0].name
            }
          }).catch(() => null),
        ])
        return {limitOfAuthority: item.limitOfAuthority, departmentName: data[0], companyId: item.companyId, companyName: item.companyName, branchName: data[1]}
      }));
      console.log(data)
      setTableData(data)
    }
    initData()
  }, [])

  useEffect(() => {
    if (user !== null){
      const {firstName, lastName, position, email, phoneNumber, profileImage} = user
      setValues({
        firstName: firstName !== null ? firstName : '',
        lastName: lastName !== null ? lastName : '',
        position: position !== null ? position : '',
        email: email !== null ? email : '',
        phoneNumber: phoneNumber !== null ? phoneNumber : '',
        profileImage: profileImage !== null ? profileImage : '',
      })
    }
  }, [user])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box $radius={12} className={generalStyles.box} $noOverflow>
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "First Name is required"
                },
                maxLength: {
                  value: 50,
                  message: "Maximum 50 characters"
                },
                pattern: {
                  value: /^[-'a-zA-Z\s]+$/,
                  message: "First name is not valid",
                }
              }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter First Name"
                  className={errors.hasOwnProperty(field.name) && "error"}
                  $label="First Name"
                  $labelRequired
                  {...field}/>
              )}
            />
            {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required"
                },
                maxLength: {
                  value: 50,
                  message: "Maximum 50 characters"
                },
                pattern: {
                  value: /^[-'A-Za-z\s]*$/,
                  message: "Last name is not valid",
                }
              }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter Last Name"
                  className={errors.hasOwnProperty(field.name) && "error"}
                  $label="Last Name"
                  $labelRequired
                  {...field}/>
              )}
            />
            {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="position"
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Required"
              //   },
              //   maxLength: {
              //     value: 30,
              //     message: "Maximum 30 characters"
              //   },
              //   validate: {
              //     allowed: v => /^[A-Za-z\s]*$/.test(v) || 'Alphabetic characters and space only',
              //   },
              // }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter Job Description"
                  className={errors.hasOwnProperty(field.name) && "error"}
                  $label="Job Description"
                  disabled={true}
                  {...field}/>
              )}
            />
            {errors.position && <p className="error-message">{errors.position.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required"
                },
                pattern: {
                  // value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  value: /^[\w]*@*[A-Za-z]*\.*[\w]{3,}(\.)*(@yopmail\.com|@gmail\.com|@yahoo\.com|@bing\.com)$/g,
                  message: "Email is not valid"
                }
              }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter Email Address"
                  className={errors.hasOwnProperty(field.name) && "error"}
                  $label="Email"
                  disabled={true}
                  {...field}/>
              )}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>
          <div className="inp-container">
            <Label $title="Phone Number" $isRequired/>
            <Controller
              name="phoneNumber"
              rules={{
                required: {
                  value: true,
                  message: "Phone number is required"
                },
                validate: {
                  isValid: (value) => (isValidPhoneNumber(value || "") && isPossiblePhoneNumber(value || "")) || "Enter a valid phone number"
                }
              }}
              control={control}
              render={({field}) => (
                <PhoneInput
                  {...field}
                  placeholder="Enter Phone Number"
                  limitMaxLength={true}
                  international={true}
                  className={errors.hasOwnProperty(field.name) ? "error" : ""}
                />
              )}
            />
            {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
          </div>
          <div className="inp-container">
            <Label $title="Profile Photo"/>
            <Controller
              name="profileImage"
              control={control}
              rules={{
                validate: {
                  isValid: (value) => {
                    if (value?.name){
                      return ["jpg", "jpeg", "png", "gif", "svg", "bmp",].includes(value.name.split(".").pop()) || "Wrong file type"
                    }
                  },
                },
              }}
              render={({ field }) => (
                <File
                  {...field}
                  value={field.value.filename}
                  $isInvalid={errors.hasOwnProperty(field.name)}
                  $picture={field.value !== "" && field.value instanceof Blob ? URL.createObjectURL(field.value) : field.value !== "" && !(field.value instanceof Blob) ? "data:image/jpg;base64,"+field.value : ""}
                  $small
                  onChange={(e) => {
                    field.onChange({target: {value: e.target.files[0], name: field.name}})
                    readFile(e)
                  }}
                  accept="image/png, image/jpeg"
                />
              )}
            />
            {errors.profileImage && <p className="error-message">{errors.profileImage.message}</p>}
          </div>
        </div>
        <Text weight={600} type={'h3'} style={{margin: '32px 0'}}>Companies I belong to:</Text>
        <DataTableBase
          data={tableData}
          columns={columns}
        />
      </Box>
      <div className={generalStyles.pageButtons + " " + generalStyles.btnsOutside}>
        <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid}>Save</Button.Main>
        <Button.Main $primary $style="gray" onClick={() => navigate("/dashboard")} type="button">Discard</Button.Main>
      </div>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} /> : null}
    </form>
  );
};

export default ProfileAccount;
