import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Button from '../../components/UI/Forms/Button';
import Textarea from '../../components/UI/Forms/Textarea';

import spmsServiceService from '../../services/spmsService.service';

import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';

import Label from '../../components/UI/Forms/Label';
import Text from '../../components/UI/Typography/Text';
import { regExps } from '../../utils/regExps';
import moment from 'moment';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import SelectGls from "../../components/shared/SelectGls";

const AddNewGl = ({
  companyId,
  setGlMode,
  setToast,
  monthsToRender,
  updateMonthFields,
  glMode,
  editableGl,
  handleRowClick,
  handleGlLine,
  currency,
  glItems,
}) => {
  let validationSchema = yup.object().shape({
    notes: yup
      .string()
      .nullable()
      .matches(regExps.notes, 'Only letters, numbers, and spaces are allowed'),
    glAccountId: yup.object().shape({
      label: yup.object().shape({
        code: yup.string().required('Required'),
        description: yup.string().required('Required'),
      }),
      value: yup.string().required('Required'),
    }),
    departmentId: yup.lazy((value) => {
      if (!value) return yup.string().required('Required');
      return yup.object().shape({
        value: yup.string().required('Required'),
      });
    }),
    branchId: yup.lazy((value) => {
      if (!value) return yup.string().required('Required');
      return yup.object().shape({
        value: yup.string().required('Required'),
      });
    }),
    glBudgetValue: yup.string().required('Required'),
    monthlyAllocations: yup.array().of(
      yup.object({
        amount: yup.string().required('This field is required'),
      }),
    ),
  });
  const allMonths = moment.months().map((month) => month.toLowerCase());

  const getMonthNameByIndex = (data) => {
    const [month, year] = data.split('-');
    const monthString = readableTitleFromBackend(allMonths[parseFloat(month) - 1]);
    return `${monthString} ${year}`;
  };
  const {
    handleSubmit,
    control,
    setValue,
    unregister,
    formState: { errors, isDirty, isValid },
    reset,
    trigger,
    clearErrors,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      notes: '',
      monthlyAllocations: [],
    },
  });

  const [glsOptions, setGlsOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [branchesOptions, setBranchesOptions] = useState([]);
  const [totalAmountErr, setTotalAmountErr] = useState(false);
  const glCode = watch('glAccountId');
  const [focus, setFocus] = useState(false);
  const [page, setPage] = useState(0);
  const [allGlAmount, setAllGlAmount] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);

  useEffect(() => {
    updateMonthFields();
  }, []);

  const fetchGlLines = useCallback(async () => {
    if (Math.round(allGlAmount / 10) >= page) {
      const result = await spmsServiceService.getFilteredGlAccounts(companyId, page, 10, {
        active: true,
        description: debouncedValue,
      });
      const glData = result.data.data.content;
      const allGls = result.data.data.totalElements;
      if (!allGlAmount) setAllGlAmount(allGls);
      setGlsOptions((glOptions) => (page === 0 ? glData : [...glOptions, ...glData]));
    }
  }, [page, debouncedValue]);

  useEffect(() => {
    if (!companyId) return;
    fetchGlLines();
  }, [fetchGlLines]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
      setPage(0);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    if (!editableGl || !monthsToRender || !glsOptions.length) return;
    const reqGL = glsOptions.find((gl) => gl?.id === editableGl.glAccountId);
    const editGLItem = {
      departmentId: {
        value: editableGl?.departmentId,
        label: editableGl?.departmentName,
      },
      glAccountId: {
        label: {
          code: 'GL-' + editableGl.glAccountName,
          description: reqGL.description,
        },
        value: editableGl.glAccountId,
      },
      branchId: editableGl.branchId
        ? {
            value: editableGl.branchId,
            label: editableGl.branchName,
          }
        : undefined,
      notes: editableGl?.notes && editableGl?.notes,
      glBudgetValue: editableGl.glBudgetValue,
    };
    reset({
      ...editGLItem,
    });
    setValue('monthlyAllocations', editableGl.allocations);
  }, [editableGl, monthsToRender, glsOptions]);

  const fixStringBudgetValue = (value) =>
    typeof value === 'string' ? parseFloat(value.replace(/,/g, ''), 10) : value === '' ? 0 : value;

  const onSubmitGLItems = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const { branchId, departmentId, glAccountId, glBudgetValue, notes, monthlyAllocations } = data;
    if (
      glMode.type === 'add' &&
      glItems.find(
        (glItem) =>
          glItem.glAccountId === glAccountId.value &&
          glItem.departmentId === departmentId.value &&
          glItem.branchId === branchId.value,
      )
    ) {
      setToast({
        opened: true,
        message: 'Unique combiantion of Gl Code, Department and Branch is required',
        type: 'fail',
        cb: () => setToast((item) => ({ ...item, opened: false })),
      });
      return;
    }

    const budgetValue = parseFloat(glBudgetValue.split(' ').at(-1));
    const requestBody = {
      glBudgetValue: budgetValue,
      glAccountDescription: glAccountId?.label?.description,
      glAccountId: glAccountId?.value,
      glAccountName: glAccountId?.label?.code,
      branchName: branchId?.label,
      departmentName: departmentId?.label,
      departmentId: departmentId?.value,
      branchId: branchId?.value,
      notes: notes,
      allocations: monthlyAllocations.map((month) => ({
        ...month,
        amount: fixStringBudgetValue(month.amount),
      })),
    };
    if (editableGl) requestBody.id = editableGl.id;
    handleGlLine(requestBody);
    reset();
  };

  useEffect(() => {
    if (!glCode || !glsOptions.length || !glsOptions) return;
    const reqGlOption = glsOptions.find((option) => option.id === glCode.value);
    if (!reqGlOption) return;
    const { departments, branches } = reqGlOption;
    const handleOptions = (data) => {
      return data.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
    };
    if (!departments.length) {
      spmsServiceService.getDepartments(companyId).then((response) => {
        setDepartmentOptions(handleOptions(response.data.data.filter(el => el.active)));
      });
    } else {
      setDepartmentOptions(handleOptions(departments));
    }
    if (!branches.length) {
      spmsServiceService.getBranches(companyId).then((response) => {
        setBranchesOptions(handleOptions(response.data.data.filter(el => el.active)));
      });
    } else {
      setBranchesOptions(handleOptions(branches));
    }
  }, [glCode, glsOptions]);

  const handleDataFetching = () => {
    setValue('departmentId', null);
    setValue('branchId', null);
    trigger(['departmentId', 'branchId']);
  };

  const handleGlBudgetValueChange = (floatValue) => {
    setValue('glBudgetValue', floatValue);
    const monthsAmount = monthsToRender?.length;
    const dividedValue = (floatValue / monthsAmount).toFixed(2);
    trigger('glBudgetValue');
    clearErrors(monthsToRender);
    unregister('monthlyAllocations');
    const updatedMonths = monthsToRender.map((month) => ({ month, amount: dividedValue }));
    if (dividedValue * monthsToRender.length !== floatValue) {
      const lastMonth = updatedMonths[updatedMonths.length - 1];
      const difference = (floatValue - dividedValue * monthsToRender.length).toFixed(2);
      const resultAmount = +dividedValue + +difference;
      updatedMonths[updatedMonths.length - 1] = { ...lastMonth, amount: resultAmount };
    }
    setValue('monthlyAllocations', updatedMonths);
  };

  const onMonthBudgetChange = (field, month, amount) => {
    const amountNumber = amount.split(' ').at(-1);
    field.onChange({ month, amount: amountNumber });
    setTotalAmountErr(false);
    const monthlyAllocations = getValues('monthlyAllocations');
    totalAmountErr && clearErrors(monthsToRender);
    const updatedBudget = monthlyAllocations.reduce((acc, curr) => {
      const currNumber = curr?.amount ? fixStringBudgetValue(curr.amount) : 0;
      return acc + currNumber;
    }, 0);
    setValue('glBudgetValue', updatedBudget);
    trigger('monthlyAllocations');
  };
  const handleUsedGl = () => {
    const options = glsOptions.map((gl) => ({
      label: {
        code: gl.glCode,
        description: gl.description,
      },
      value: gl.id,
    }));
    return options;
  };
  const searchByFirstCharacter = (option, inputValue) =>
    option.data.label.description.toLowerCase().includes(inputValue.toLowerCase());

  return (
    <form
      className={styles.glForm}
      style={glMode.type === 'add' ? { border: '1px solid #d9d9d9', marginBottom: 30 } : {}}
      onSubmit={handleSubmit(onSubmitGLItems)}
    >
      <div className={generalStyles.fieldsFour}>
        <div className="inp-container">
          <Label $title="GL Code" $isRequired $tooltipText="GL linked to the budget" />
          <Controller
            name="glAccountId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                classNamePrefix="react-select"
                placeholder="Select GL Code"
                options={handleUsedGl()}
                noOptionsMessage={() => 'No Options'}
                isSearchable
                onMenuScrollToBottom={() => {
                  if (Math.floor(allGlAmount / 10) > page) {
                    setPage((page) => page + 1);
                  }
                }}
                control={control}
                onInputChange={(value) => {
                  setFilterText(value);
                }}
                filterOption={searchByFirstCharacter}
                getOptionLabel={(option) => (
                  <div>
                    <div>
                      <Text weight={500} type="body-1">
                        {option.label.code}
                      </Text>
                    </div>
                    <div style={{ marginTop: 4 }}>{option.label.description}</div>
                  </div>
                )}
                className={errors.hasOwnProperty(field.name) && 'error'}
                onChange={(e) => {
                  field.onChange(e);
                  handleDataFetching();
                }}
              />
            )}
          />
          <p className="error-message">{errors?.glAccountId?.label?.message}</p>
        </div>
        <div className="inp-container">
          <Label $title="Department" $isRequired $tooltipText="Department linked to the budget" />
          <Controller
            name="departmentId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={errors.hasOwnProperty(field.name) && 'error'}
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Department"
                options={departmentOptions || []}
              />
            )}
          />
          <p className="error-message">
            {errors?.departmentId?.message ?? errors?.departmentId?.value?.message}
          </p>
        </div>
        <div className="inp-container">
          <Label $title="Branch" $isRequired $tooltipText="Branch linked to the budget" />
          <Controller
            name="branchId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={true}
                placeholder="Select Branch"
                hideSelectedOptions={false}
                options={branchesOptions || []}
              />
            )}
          />
          <p className="error-message">
            {errors?.branchId?.message ?? errors?.branchId?.value?.message}
          </p>
        </div>
        <div className="inp-container">
          <Label $title="GL Budget Value" $isRequired $tooltipText="Value of the GL Line Budget" />
          <Controller
            name="glBudgetValue"
            control={control}
            render={({ field: { ref, ...other } }) => (
              <NumericFormat
                {...other}
                prefix={currency + ' '}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                decimalScale={2}
                placeholder="Enter GL Budget Value"
                style={errors.hasOwnProperty(other.name) ? { borderColor: '#cd3e27' } : {}}
                className={styles.numberInput}
                onValueChange={({ floatValue }) => {
                  if (focus) {
                    handleGlBudgetValueChange(floatValue);
                  }
                }}
              />
            )}
          />
          <p className="error-message">{errors?.glBudgetValue?.message}</p>
        </div>
      </div>
      <div>
        <div className={generalStyles.fieldsSix}>
          {monthsToRender?.map((month, index) => {
            return (
              <div className="inp-container" key={month}>
                <Label $title={getMonthNameByIndex(month)} $isRequired />
                <Controller
                  name={`monthlyAllocations[${index}]`}
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <NumericFormat
                      {...other}
                      value={
                        getValues('monthlyAllocations')
                          ? getValues('monthlyAllocations')[index]?.amount
                          : ''
                      }
                      allowLeadingZeros
                      thousandSeparator=","
                      placeholder="2500.00"
                      prefix={currency + ' '}
                      decimalScale={2}
                      style={
                        errors?.monthlyAllocations && errors.monthlyAllocations[index]
                          ? { borderColor: '#cd3e27' }
                          : {}
                      }
                      className={styles.numberInput}
                      onChange={(e) => {
                        onMonthBudgetChange(other, month, e.target.value);
                      }}
                    />
                  )}
                />
                {errors?.monthlyAllocations && (
                  <p className="error-message">
                    {errors.monthlyAllocations[index]?.amount?.message}
                  </p>
                )}
              </div>
            );
          })}
        </div>
        {totalAmountErr && (
          <p className={`error-message ${styles.totalAmountError}`}>
            The total amount does not match the budget value
          </p>
        )}
      </div>

      <div className="inp-container">
        <Controller
          name="notes"
          control={control}
          rules={{
            maxLength: {
              value: 500,
              message: 'Maximum 500 characters',
            },
            validate: {
              allowed: (v) => regExps.notes.test(v) || 'Only Alpha and Numerical characters',
            },
          }}
          render={({ field }) => {
            return (
              <Textarea
                placeholder="Enter notes"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Notes"
                $counter
                $low
                $counterMax={500}
                {...field}
              />
            );
          }}
        />
        <p style={{ transform: 'translate(0, -20px)' }} className="error-message">
          {errors?.notes?.message}
        </p>
      </div>
      <div className={generalStyles.pageButtons}>
        <Button.Main disabled={!isDirty || !isValid} $primary $style="pink" type="submit">
          Save
        </Button.Main>
        <Button.Main
          $primary
          $style="gray"
          type="button"
          onClick={() => {
            glMode?.type !== 'edit' && setGlMode({ type: null, active: false });
            handleRowClick && handleRowClick();
            reset();
          }}
        >
          Discard
        </Button.Main>
      </div>
    </form>
  );
};

export default AddNewGl;
