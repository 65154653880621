import React from 'react';
import styles from '../../../../styles/purchaseOrders.module.css'
import Text from "../../../UI/Typography/Text";
import moment from "moment";

const HistoryTab = ({data}) => {
  return (
    <div className={styles.history}>
      {data.sort((a,b) => Date.parse(b.date) - Date.parse(a.date)).map((item, index) => (
        <div className={styles.historyItem} key={index}>
          <div className={styles.historyInfo}>
            <Text type={"subtitle"} weight={600} style={{textTransform: "capitalize"}}>{item?.name}</Text>
            <Text type={"body-1"}>{item?.action}</Text>
            <Text type={"body-1"} weight={500} className={styles.historyTime}>{moment(item?.date).format('[On] MMMM D, YYYY [at] hh:mm A')}</Text>
          </div>
          {item?.result && <Text type={"body-1"} className={styles.historyResult}>None → Ranked lower</Text>}
        </div>
      ))}
    </div>
  );
};

export default HistoryTab;
