import React, {useEffect, useMemo, useState} from 'react';
import Box from "../../UI/General/Box";
import generalStyles from "../../../styles/general.module.css";
import * as Button from "../../UI/Forms/Button";
import Toast from "../../UI/General/Toast";
import {Controller, useForm, useWatch} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../../store/store";
import Switch from "../../UI/Forms/Switch";
import DatePicker from "react-datepicker";
import Input from "../../UI/Forms/Input";
import DataTableBase from "../../UI/General/DataTableBase";
import spmsServiceService from "../../../services/spmsService.service";
import SelectUsers from "../../shared/SelectUsers";

const ProfileOffice = () => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const company = useStore((state) => state.company);
  const fetchFreshUserData = useStore((state) => state.fetchFreshUserData);
  const [values, setValues] = useState(null);
  const [tableData, setTableData] = useState([])
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    register,
    control,
    trigger,
    formState: {errors, isDirty, isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      isOutOfOffice: false,
      dateFrom: '',
      dateTo: '',
      delegateSettings: []
    },
    values,
  });

  const outOfOfficeWatcher = useWatch({name: 'isOutOfOffice', control});

  const columns = useMemo(() => [
    {
      name: 'Company Name',
      selector: (row, index) => {
        console.log(row)
        return (
          <>
            <input
              type="hidden"
              {...register(`delegateSettings.${index}.companyId`)}
              value={row?.id}
            />
            {row?.name}
          </>
        )
      }
    },
    {
      name: 'Delegate authority',
      selector: (row, index) => (
        <Controller
          name={`delegateSettings.${index}.delegateAuthorityId`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <SelectUsers
              {...rest}
              selectRef={ref}
              className={errors.hasOwnProperty(rest.name) ? 'react-select-container error' : 'react-select-container'}
              isDisabled={!outOfOfficeWatcher}
              isClearable={true}
              companyId={row?.id}
              roleName={["APPROVER"]}
            />
          )}
        />
      )
    }
  ], [outOfOfficeWatcher, values]);

  const onSubmit = async (data) => {
    setToast((item) => ({...item, opened: false}));
    console.log(data)
    const newData = {
      ...data,
      delegateSettings: data.delegateSettings.filter(el => el.delegateAuthorityId !== undefined && el?.delegateAuthorityId !== null).map(item => ({companyId: item.companyId, delegateAuthorityId: item.delegateAuthorityId.userId}))
    }
    console.log(newData)
    if (values !== null){
      await spmsServiceService.updateHoliday(user?.id, newData).then(r => {
        console.log(r)
        if (r.data.message === "Operation Successful"){
          const {dateFrom, dateTo, delegateSettings, ...rest} = r.data.data
          setValues({
            ...rest,
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
            delegateSettings: delegateSettings.map(el => ({...el, delegateAuthorityId:{label: el.delegateAuthorityName, userId: el.delegateAuthorityId}}))
          })
          setToast({
            opened: true,
            message: 'Holiday updated successfully',
            type: 'success',
          });
        }
      }).catch(err => {
        console.log(err)
        setToast({
          opened: true,
          message: err.response?.data?.message,
          type: 'fail',
        });
      })
    }else {
      await spmsServiceService.createHoliday(user?.id, newData).then(r => {
        if (r.data.message === "Operation Successful"){
          const {dateFrom, dateTo, delegateSettings, ...rest} = r.data.data
          setValues({
            ...rest,
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
            delegateSettings: delegateSettings.map(el => ({...el, delegateAuthorityId:{label: el.delegateAuthorityName, userId: el.delegateAuthorityId}}))
          })
          setToast({
            opened: true,
            message: 'Holiday created successfully',
            type: 'success',
          });
        }
      }).catch(err => {
        console.log(err)
        setToast({
          opened: true,
          message: err.response?.data?.message,
          type: 'fail',
        });
      })
    }
  }

  useEffect(() => {
    const userCompanies = user?.customCompanies.map(el => ({name: el.companyName, id: el.companyId}))
    setTableData(userCompanies)
    console.log(userCompanies)
    const userCompaniesIds = user?.customCompanies.map(el => el.companyId)
    console.log(userCompaniesIds)
    // if (company !== null){
    //   spmsServiceService.getCompany(company.id).then((r) => {
    //     if (r.data.data?.subsidiaries && r.data.data?.subsidiaries.length > 0) {
    //       const tempSubs = r.data.data?.subsidiaries.filter(c => c.active === true);
    //       const companies = [...tempSubs, r.data.data].map(item => ({name: item.name, id: item.id}))
    //       setTableData(companies);
    //     } else {
    //       const company = [{ name: r.data.data.name, id: r.data.data.id }];
    //       setTableData(company);
    //     }
    //   });
    // }
  },[company, user])

  useEffect(() => {
    if (user.id !== undefined && values === null){
      spmsServiceService.getHoliday(user.id).then(r => {
        if (r.data.message === "Operation Successful"){
          console.log(r.data.data)
          const {dateFrom, dateTo, delegateSettings, ...rest} = r.data.data
          setValues({
            ...rest,
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
            delegateSettings: delegateSettings.map(el => ({...el, delegateAuthorityId:{label: el.delegateAuthorityName, userId: el.delegateAuthorityId}}))
          })
        }
      }).catch(err => {
        console.log(err)
        setToast({
          opened: true,
          message: err.response?.data?.message,
          type: 'fail',
        });
      })
    }
  },[user, values])

  useEffect(() => {
    fetchFreshUserData()
  },[])

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow className={generalStyles.gapFormButtons}>
        <div className="inp-container">
          <Controller
            name="isOutOfOffice"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Out of office is required',
              },
            }}
            render={({field}) => (
              <Switch
                {...field}
                label="Out of office"
                $tooltip="Toggle to enable/disable out of office"
                $size={20}
                $reverse
              />
            )}
          />
          {errors.isOutOfOffice && <p className="error-message">{errors.isOutOfOffice?.message}</p>}
        </div>
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Controller
              name="dateFrom"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Date from is required',
                },
                maxLength: {
                  value: 10,
                  message: 'Maximum 10 characters',
                },
              }}
              render={({field}) => (
                <DatePicker
                  {...field}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="Select Date"
                  selected={field.value}
                  wrapperClassName="custom-datepicker"
                  disabled={!outOfOfficeWatcher}
                  customInput={
                    <Input
                      {...field}
                      $label="Date from"
                      $labelRequired
                      $tooltip="Date from"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  }
                  onChange={(e) => {
                    field.onChange(e);
                    trigger(field.name);
                  }}
                />
              )}
            />
            {errors.dateFrom && <p className="error-message">{errors.dateFrom?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="dateTo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Date to is required',
                },
                maxLength: {
                  value: 10,
                  message: 'Maximum 10 characters',
                },
              }}
              render={({field}) => (
                <DatePicker
                  {...field}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(new Date().getTime() + 86400000)}
                  placeholderText="Select Date"
                  selected={field.value}
                  wrapperClassName="custom-datepicker"
                  disabled={!outOfOfficeWatcher}
                  customInput={
                    <Input
                      {...field}
                      $label="Date to"
                      $labelRequired
                      $tooltip="Date to"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  }
                  onChange={(e) => {
                    field.onChange(e);
                    trigger(field.name);
                  }}
                />
              )}
            />
            {errors.dateTo && <p className="error-message">{errors.dateTo?.message}</p>}
          </div>
        </div>
        <DataTableBase
          data={tableData}
          columns={columns}
        />
      </Box>
      <div className={generalStyles.pageButtons}>
        <Button.Main
          $primary
          $style="pink"
          type="submit"
          form="poForm"
          onClick={handleSubmit((data) => onSubmit(data))}
          disabled={!isDirty || !isValid}
        >
          Save
        </Button.Main>
        <Button.Main
          $primary
          $style="gray"
          type="button"
          onClick={() => navigate("/dashboard")}
        >
          Discard
        </Button.Main>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type}/>
      ) : null}
    </>
  );
};

export default ProfileOffice;
