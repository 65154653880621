import styles from '../../../styles/accountBillings.module.css';
import Text from '../../UI/Typography/Text';

const PaymentModalRadio = ({ name, description, img }) => {
  return (
    <div className={styles.paymentRadio}>
      <img src={img} alt="debit-card" />
      <div className={styles.paymentRadioInfo}>
        <Text weight={600}>{name}</Text>
        <Text weight={500}>{description}</Text>
      </div>
    </div>
  );
};

export default PaymentModalRadio;
