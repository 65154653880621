import { useEffect, useState } from 'react';
import requestsService from '../services/requestsService.service';
import { useShallow } from 'zustand/react/shallow';
import { useStore } from '../store/store';

export const useAccessAllowed = (name, idOfCompany, person) => {
  const user = useStore((state) => state.user);
  const activeCompany = useStore(useShallow((state) => state.activeCompany));
  const [rbac, setRbac] = useState(undefined);
  const [permissions, setPermissions] = useState(undefined);

  useEffect(() => {
    if (rbac !== undefined) {
      if (Array.isArray(name)){
        let output = []
        let finalO = {}
        rbac.flatMap(el => el).filter((item) => name.includes(item.moduleName)).forEach(item => {
          let existing = output.filter(function(v, i) {
            return v.moduleName === item.moduleName;
          });
          if (existing.length) {
            let existingIndex = output.indexOf(existing[0]);
            output[existingIndex].permissions = output[existingIndex].permissions.concat(item.permissions);
          } else {
            if (typeof item.permissions === 'string'){
              item.permissions = [item.permissions];
            }
            output.push(item);
          }
        });
        output.forEach(el => {
          finalO = {...finalO, [el.moduleName.toLowerCase()]: {
              viewAll: el.permissions.includes('VIEW_ALL'),
              viewOwn: el.permissions.includes('VIEW_OWN'),
              create: el.permissions.includes('CREATE'),
              edit: el.permissions.includes('EDIT'),
              delete: el.permissions.includes('DELETE'),
              approve: el.permissions.includes('APPROVE'),
            }}
        })
        setPermissions(finalO)
      }else {
        const currentPermissions = rbac.flatMap(el => el).filter((item) => item?.moduleName === name).flatMap((item) => item?.permissions);
        setPermissions({
          viewAll: currentPermissions.includes('VIEW_ALL'),
          viewOwn: currentPermissions.includes('VIEW_OWN'),
          create: currentPermissions.includes('CREATE'),
          edit: currentPermissions.includes('EDIT'),
          delete: currentPermissions.includes('DELETE'),
          approve: currentPermissions.includes('APPROVE'),
        });
      }
    }
  }, [rbac]);

  useEffect(() => {
    const userObj = person !== undefined ? person : user;
    const companyId = idOfCompany !== undefined ? idOfCompany : activeCompany?.id

    const rbac = async () => {
      const roles = userObj.roles.filter(r => r.company?.id === companyId).map(r => r.role.id)
      if (!!roles.length) {
        const data = await Promise.all(
          roles.map(async (item) => {
            const data = await Promise.all([
              await requestsService.getRoleById(item).then((r) => {
                if (r.data.message === 'Operation Successful') {
                  if (r.data.data.isEnabled !== false){
                    return r.data.data.permissionResponse.map((item) => ({
                      moduleName: item.resourceUri,
                      permissions: item.authorizationTypes,
                    }));
                  }else {
                    console.log("Role disabled")
                  }
                }
              }).catch(() => null),
            ]);
            return data[0];
          }),
        );
        setRbac(data);
      } else {
        setRbac([]);
      }
    };
    if (companyId){
      rbac();
    }
  }, [user, activeCompany?.id, idOfCompany]);

  return permissions;
};
