import React from 'react';
import * as Button from "../UI/Forms/Button";
import Icon from "../UI/General/Icon";
import Text from "../UI/Typography/Text";

const AddButton = (props) => {
  return (
    <Button.ActionLabeled onClick={props.$click}>
      <Button.Action $style="pink" $variant="circle" $width={20} $height={20}>
        <Icon $width={20} $height={20} $icon="add" $color="white" />
      </Button.Action>
      <Text weight={700} type="subtitle">Add</Text>
    </Button.ActionLabeled>
  );
};

export default AddButton;
