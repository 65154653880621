import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { regExps } from '../regExps';

const vendorContactsDetailsSchema = yup.object().shape({
  contactType: yup.object().shape({
    value: yup.string().required('Type is required'),
  }),
  contactTypeOther: yup.string().when('contactType', {
    is: (fieldValue) => fieldValue && fieldValue.value === 'Other',
    then: () =>
      yup
        .string()
        .required('Name of Type name is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Invalid characters')
        .test(
          'no-empty-spaces',
          'Name of Type cannot be just empty spaces',
          (value) => value.trim() !== '',
        )
        .max(25, '25 characters max'),
  }),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Invalid characters')
    .test(
      'no-empty-spaces',
      'First name cannot be just empty spaces',
      (value) => value.trim() !== '',
    )
    .max(50, '50 characters max'),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Invalid characters')
    .test(
      'no-empty-spaces',
      'Last name cannot be just empty spaces',
      (value) => value.trim() !== '',
    )
    .max(50, '50 characters max'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', (value) => {
      return isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '');
    }),
  email: yup
    .string()
    .required('Email is required')
    .matches(regExps.email, 'Invalid email address')
    .max(50, '50 characters max'),
  jobDescription: yup
    .string()
    .required('Role is required')
    .matches(/^[a-zA-Z\s]+$/, 'Invalid characters')
    .test('no-empty-spaces', 'Role cannot be just empty spaces', (value) => value.trim() !== '')
    .max(30, '30 characters max'),
});

export default vendorContactsDetailsSchema;
