import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

import { userSlice } from './userSlice';
import { countriesSlice } from './countriesSlice';
import { companySlice } from './companySlice';
import { setupSlice } from './setupSlice';

export const useStore = create(
  devtools(
    persist(
      (...a) => ({
        ...userSlice(...a),
        ...countriesSlice(...a),
        ...companySlice(...a),
        ...setupSlice(...a),
      }),
      {
        name: 'poms-store', // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used if need use 'sessionStorage'
        partialize: (state) => ({
          user: state.user,
          company: state.company,
          activeCompany: state.activeCompany,
          fullCompanies: state.fullCompanies,
          stepsDone: state.stepsDone,
        }),
        onRehydrateStorage: (state) => {
          console.log('hydration starts');
          console.log(state);
          // optional
          return (state, error) => {
            if (error) {
              console.log('an error happened during hydration', error);
            } else {
              console.log('hydration finished');
              console.log(state);
            }
          };
        },
      },
    ),
  ),
);
