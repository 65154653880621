import React, { Suspense, useMemo, useState } from 'react';
import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Icon from '../../components/UI/General/Icon';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import spmsServiceService from '../../services/spmsService.service';
import { Link } from 'react-router-dom';
import Modal from '../../components/UI/Modal/Modal';
import Textarea from '../../components/UI/Forms/Textarea';
import { Controller, useForm } from 'react-hook-form';
import ExpandedVendor from './ExpandedVendor';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';

const PendingVendorOverview = ({ setRefetchTrigger, companyId, fetchVendors, setToast }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [vendorToRecall, setVendorToRecall] = useState(null);
  const accessVendor = useAccessAllowed('Vendors');

  const recallVendor = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      reason: data.requestNotes,
    };
    spmsServiceService
      .recallVendor(companyId, vendorToRecall.id, requestBody)
      .then((_res) => {
        closeModal();
        setRefetchTrigger((refetchTrigger) => !refetchTrigger);
        setToast({
          opened: true,
          message: 'Recalled',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message || 'Something Went Wrong',
          type: 'fail',
        });
      });
  };

  const closeModal = () => {
    reset();
    setVendorToRecall(null);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Vendor Name',
        selector: (row) => row.vendor.legalName,
        sortable: true,
        cell: (row) => (
          <span data-content={'Vendor Name'} className={generalStyles.tableValue}>
            {accessVendor?.viewOwn ? (
              <Link
                className={styles.tableLink}
                weight={500}
                type="subtitle"
                to={'/vendors/' + companyId + '/viewPending/' + row.vendor.id}
              >
                <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>
                  {row.vendor.legalName}
                </span>
              </Link>
            ) : (
              <span>{row.vendor.legalName}</span>
            )}
          </span>
        ),
      },
      {
        name: 'Vendor Code',
        sortable: true,
        selector: (row) => row.vendorCode,
        cell: (row) => (
          <span data-content={'Vendor Code'} className={generalStyles.tableValue}>
            <span>{row.vendorCode}</span>
          </span>
        ),
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{!!row.departments.length ? 'Limited' : 'All'}</span>
          </span>
        ),
      },
      {
        name: 'Branch',
        cell: (row) => (
          <span data-content={'Branch'} className={generalStyles.tableValue}>
            <span>{!!row.branches.length ? 'Limited' : 'All'}</span>
          </span>
        ),
      },
      {
        name: 'GL',
        cell: (row) => (
          <span data-content={'GL'} className={generalStyles.tableValue}>
            <span>{!!row.glAccounts.length ? 'Limited' : 'All'}</span>
          </span>
        ),
      },
      {
        name: 'User',
        cell: (row) => (
          <span data-content={'User'} className={generalStyles.tableValue}>
            <span>{!!row.users.length ? 'Limited' : 'All'}</span>
          </span>
        ),
      },
      {
        name: 'Pending Approvers',
        selector: (row) => row.row.approval?.approvers,
        cell: (row) => (
          <span data-content={'Pending Approvers'} className={generalStyles.tableValue}>
            <span>
              {row.approval?.approvers
                ?.filter((approver) => approver.status === 'PENDING_APPROVAL')
                .map((approver) => approver.approverName)
                .join(', ')}
            </span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        omit: !accessVendor?.create,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={styles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={styles.actionMenuList}>
                  <MenuItem
                    onClick={() =>
                      setVendorToRecall({ id: row.vendor.id, name: row.vendor.legalName })
                    }
                  >
                    Recall
                  </MenuItem>
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [accessVendor],
  );

  return (
    <>
      <Suspense fallback={<h2 className={styles.loader}>Loading...</h2>}>
        <DataTableBaseRemote
          columns={columns}
          fetchData={fetchVendors}
          selectableRows={false}
          paginationResetDefaultPage={false}
          expandableRows
          expandableRowsComponent={ExpandedVendor}
          expandOnRowClicked
        />
      </Suspense>
      <Modal $show={vendorToRecall?.id} $radius={4}>
        <div className={generalStyles.mainDiv}>
          <div className={generalStyles.textIconDiv}>
            <Text type="h4" className={generalStyles.textField}>
              Recall Vendor {vendorToRecall?.name}
            </Text>
            <Icon
              onClick={closeModal}
              $width={12}
              $height={24}
              $icon="close"
              $size={18}
              className={generalStyles.iconStyle}
            />
          </div>
        </div>
        <div className={generalStyles.modalContainer}>
          <div className="inp-container">
            <Controller
              name="requestNotes"
              control={control}
              rules={{
                maxLength: { value: 499, message: 'Maximum 500 characters' },
                required: {
                  value: true,
                  message: 'Recall reason is required',
                },
                validate: {
                  allowed: (v) =>
                    (/^[A-Za-z0-9\s]*$/.test(v) && v.trim() !== '') ||
                    'Only Alpha and Numerical characters',
                },
              }}
              render={({ field }) => (
                <Textarea
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  {...field}
                  $counter
                  $counterMax={500}
                  placeholder="Enter the reason for recall"
                />
              )}
            />
            {errors.requestNotes && (
              <p style={{ transform: 'translate(0, -15px)' }} className="error-message">
                {errors.requestNotes.message}
              </p>
            )}
          </div>
          <div className={generalStyles.modalButtons}>
            <Button.Main
              onClick={handleSubmit(recallVendor)}
              $primary
              $style="pink"
              type="submit"
              form="cForm"
            >
              Continue
            </Button.Main>
            <Button.Main onClick={closeModal} $primary $style="gray" type="submit" form="cForm">
              Close
            </Button.Main>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PendingVendorOverview;
