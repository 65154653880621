import { Controller } from 'react-hook-form';
import Label from '../../UI/Forms/Label';
import DatePicker from 'react-datepicker';
import Input from '../../UI/Forms/Input';
import styles from '../../../styles/reports.module.css';
import { useEffect, useRef } from 'react';

const DateFromTo = ({ control, errors, trigger, watch, setValue, clearErrors }) => {
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (startDate < endDate) clearErrors(['startDate', 'endDate']);
  }, [startDate, endDate]);

  const handleDateChange = (name, date) => {
    setValue(name, date);
    if (startDate < endDate) clearErrors(['startDate', 'endDate']);
    trigger(name);
  };
  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);
  const openStartDatePicker = () => {
    if (startPickerRef.current) {
      startPickerRef.current.setOpen(true);
    }
  };

  const openEndDatePicker = () => {
    if (endPickerRef.current) {
      endPickerRef.current.setOpen(true);
    }
  };
  return (
    <>
      <div className="inp-container">
        <Label $title="Date From" $tooltipText="" />
        <Controller
          name="startDate"
          control={control}
          rules={{
            validate: {
              isValid: (value) => {
                if (!startDate || !endDate) return true;
                else return value < endDate || "Date From can't be greater than Date To";
              },
            },
          }}
          render={({ field }) => {
            return (
              <DatePicker
                {...field}
                dateFormat="dd/MM/yyyy"
                placeholderText="Enter Start Date"
                selected={field.value}
                wrapperClassName="custom-datepicker"
                autoComplete="off"
                customInput={
                  <Input
                    {...field}
                    $iconName="calendar"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $iconclick={openStartDatePicker}
                  />
                }
                onChange={(date) => {
                  handleDateChange('startDate', date);
                }}
                ref={startPickerRef}
              />
            );
          }}
        />
        <p className={`error-message ${styles.dateError}`}>{errors?.startDate?.message}</p>
      </div>
      <div className="inp-container">
        <Label $title="Date To" $tooltipText="" />
        <Controller
          name="endDate"
          control={control}
          rules={{
            validate: {
              isValid: (value) => {
                if (!startDate || !endDate) return true;
                else return value > startDate || 'Date To must be greater than Date To';
              },
            },
          }}
          render={({ field }) => {
            return (
              <DatePicker
                {...field}
                dateFormat="dd/MM/yyyy"
                placeholderText="Enter End Date"
                selected={field.value}
                wrapperClassName="custom-datepicker"
                autoComplete="off"
                customInput={
                  <Input
                    {...field}
                    $iconName="calendar"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $iconclick={openEndDatePicker}
                  />
                }
                onChange={(date) => {
                  handleDateChange('endDate', date);
                }}
                ref={endPickerRef}
              />
            );
          }}
        />
        <p className={`error-message ${styles.dateError}`}>{errors?.endDate?.message}</p>
      </div>
    </>
  );
};

export default DateFromTo;
