import React, { useState, useEffect } from 'react';
import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Icon from '../../components/UI/General/Icon';
import { useForm } from 'react-hook-form';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import spmsServiceService from '../../services/spmsService.service';
import DataTableBase from '../../components/UI/General/DataTableBase';
import vendorContactsDetailsSchema from '../../utils/validation/vendorContactsDetailsSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../components/UI/General/Box';
import AddNewContact from './AddNewContact';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';

const Representatives = ({ setToast, setTab, isActive }) => {
  const [data, setData] = useState([]);
  const { companyId, vendorId } = useParams();
  const navigate = useNavigate();
  const accessVendor = useAccessAllowed('Vendors');

  const {
    control,
    handleSubmit,
    reset,
    unregister,
    watch,
    formState: { errors, isDirty },
    setValue,
  } = useForm({ resolver: yupResolver(vendorContactsDetailsSchema), mode: 'onChange' });

  const [isVendorContactDetails, setIsVendorContactDetails] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(null);
  const contactType = watch('contactType');

  const getVendorContacts = () => {
    spmsServiceService.getVendorContactDetails(companyId, vendorId).then((res) => {
      setData(res.data.data);
    });
  };
  useEffect(() => {
    getVendorContacts();
  }, [vendorId]);

  const submitVendorDetails = async (formData) => {
    setToast((item) => ({ ...item, opened: false }));
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      jobDescription,
      contactType,
      contactTypeOther,
    } = formData;

    const contactDetails = {
      name: `${firstName} ${lastName}`,
      contactType: contactType.value === 'Other' ? contactTypeOther : contactType.value,
      email,
      phoneNumber,
      jobDescription,
    };
    if (contactToEdit) {
      await spmsServiceService
        .editvendorContactDetails(contactToEdit, contactDetails)
        .then((_res) => {
          setToast({
            opened: true,
            message: 'Contact has been edited',
            type: 'success',
          });
          reset();
          setContactToEdit(null);
          getVendorContacts();
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.error ? err.response.data.error : 'Something Went Wrong',
            type: 'fail',
          });
        });
    } else {
      const newContactDetails = {
        vendorContactRequestList: [contactDetails],
      };

      await spmsServiceService
        .addVendorContactDetails(companyId, vendorId, newContactDetails)
        .then((_res) => {
          setToast({
            opened: true,
            message: 'Contact has been created',
            type: 'success',
          });
          getVendorContacts();
          setIsVendorContactDetails(false);
          reset();
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.error ? err.response.data.error : 'Something Went Wrong',
            type: 'fail',
          });
        });
    }
  };

  const deleteVendorContact = (row) => {
    spmsServiceService
      .deleteSpecificVendorContact(companyId, vendorId, row.vendorContactId)
      .then((res) => {
        setData([]);
        spmsServiceService.getVendorContactDetails(companyId, vendorId).then((res) => {
          setData(res.data.data);
        });
      });
  };

  const editVendor = (row) => {
    setContactToEdit(row.vendorContactId);
    let names = row.name.split(' ');
    const savedContactType = ['Sales', 'Billing'].includes(row.contactType)
      ? row.contactType
      : 'Other';
    setValue('contactType', { value: savedContactType, label: savedContactType });
    setValue('contactTypeOther', row.contactType);
    setValue('firstName', names[0]);
    setValue('lastName', names[1]);
    setValue('phoneNumber', row.phoneNumber);
    setValue('email', row.email);
    setValue('jobDescription', row.jobDescription);
    setValue('contactID', row.vendorContactId);
  };

  const handelContactCancel = () => {
    setIsVendorContactDetails(false);
    setContactToEdit(null);
    reset();
  };

  const addNewContact = () => {
    setIsVendorContactDetails(true);
    reset();
  };
  const columns = [
    {
      name: 'Full Name',
      selector: (row) => (
        <Button.ActionLabeled>
          <Text className={styles.tableDataDecorations} weight={500} type="subtitle">
            {row.name}
          </Text>
        </Button.ActionLabeled>
      ),
    },
    {
      name: 'Phone Number',
      selector: (row) => row.phoneNumber,
    },
    {
      name: 'Email Address',
      selector: (row) => row.email,
    },
    {
      name: 'Role/Position',
      selector: (row) => row.jobDescription,
    },
    {
      name: 'Type',
      selector: (row) => row.contactType,
    },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      omit: !accessVendor?.edit,
      cell: (row) => (
        <Dropdown collapsible className={styles.actionMenu}>
          <Dropdown.Header>
            <Button.Action $style="white" $width={32} $height={32}>
              <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
            </Button.Action>
          </Dropdown.Header>
          <Dropdown.Body>
            <Menu className={styles.actionMenuList}>
              <MenuItem id="phoneNumber" onClick={(e) => editVendor(row, e.target.vendorContactId)}>
                Edit
              </MenuItem>
              <MenuItem id="phoneNumber" onClick={(e) => deleteVendorContact(row)}>
                Delete
              </MenuItem>
            </Menu>
          </Dropdown.Body>
        </Dropdown>
      ),
    },
  ];

  const submitForApproval = async () => {
    await spmsServiceService.submitVendorApproval(companyId, vendorId);
    navigate('/vendors/pending-approval');
  };

  return (
    <>
      <Box $mobExtend $asHolder $radius={12}>
        <div className={styles.contactsTitle}>
          <Text weight={600} type="h4">
            Vendor Contact Details
          </Text>
          {accessVendor?.edit && (
            <Button.ActionLabeled onClick={addNewContact}>
              <Button.Action $style="pink" $variant="circle" $width={20} $height={20} type="button">
                <Icon $width={20} $height={20} $icon="add" $color="white" />
              </Button.Action>
              <Text weight={700} type="subtitle">
                Add New Contact
              </Text>
            </Button.ActionLabeled>
          )}
        </div>

        <div className={styles.container}>
          {isVendorContactDetails && !data.length ? null : (
            <DataTableBase
              separatePagination
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20, 50]}
              columns={columns}
              data={data}
              expandableRows
              expandableRowsComponent={AddNewContact}
              expandableRowExpanded={(row) => row.vendorContactId === contactToEdit}
              expandableRowsComponentProps={{
                errors,
                isDirty,
                control,
                submitVendorDetails,
                unregister,
                contactType,
                handleSubmit,
                handelContactCancel,
              }}
            />
          )}
          {isVendorContactDetails && (
            <AddNewContact
              errors={errors}
              control={control}
              submitVendorDetails={submitVendorDetails}
              unregister={unregister}
              contactType={contactType}
              handleSubmit={handleSubmit}
              handelContactCancel={handelContactCancel}
              isDirty={isDirty}
            />
          )}
        </div>
      </Box>
      {!isVendorContactDetails && !contactToEdit && !isActive && (
        <div className={generalStyles.pageButtons}>
          <Button.Main onClick={() => setTab('Configuration')} $primary $style="pink" type="submit">
            Save
          </Button.Main>
          <Button.Main
            onClick={() => navigate('/vendors/draft')}
            $primary
            $style="pink"
            type="submit"
          >
            Save as Draft
          </Button.Main>
          <Button.Main onClick={submitForApproval} $primary $style="gray" type="submit">
            Submit for Approval
          </Button.Main>
          <Button.Main
            onClick={() => navigate('/vendors/draft')}
            $primary
            $style="gray"
            type="button"
          >
            Discard
          </Button.Main>
        </div>
      )}
    </>
  );
};

export default Representatives;
