import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import styles from '../../styles/reports.module.css';
import generalStyles from '../../styles/general.module.css';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';
import Box from '../../components/UI/General/Box.jsx';
import Icon from '../../components/UI/General/Icon.jsx';
import Status from '../../components/UI/Typography/Status';
import Filters from '../../components/Admins/Reports/Filters.jsx';
import useGetReportsData from '../../components/Admins/Reports/helpers/useGetReportsData.jsx';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote.jsx';
import {nf} from "../../utils/nf";

const RequisitionPOReports = () => {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const { pathname } = useLocation();
  const isRequisitionPage = pathname.includes('requisitions');
  const navigate = useNavigate();
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const { getReports, getExport } = useGetReportsData(getValues(), fetchTrigger);

  const handleLink = (status, id) =>
    status === 'DRAFT' ? `/${pathname.split('/')[2]}/edit/${id}` : `/${pathname.split('/')[2]}/${id}`;

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        selector: (row) => row.title,
        sortable: true,
        cell: (row) => (
          <Link
            className={styles.nameLink}
            to={handleLink(
              isRequisitionPage ? row.requisitionState : row.purchaseOrderState,
              row.id,
            )}
          >
            {row.title}
          </Link>
        ),
      },
      {
        name: 'Ref No',
        selector: (row) => (isRequisitionPage ? row.requestNo : row.purchaseOrderNo),
        wrap: true,
      },
      {
        name: 'Company',
        selector: (row) => row.companyName ?? '-',
        sortable: true,
      },
      {
        name: 'Branch',
        selector: (row) => row.branchName ?? '-',
      },
      {
        name: 'Department',
        selector: (row) => row.departmentName ?? '-',
      },
      {
        name: 'Requester',
        selector: (row) => row.requester?.name ?? '-',
      },
      {
        name: 'Total Amount',
        selector: (row) => `${row?.currency} ${nf.format(row.totalAmount) ?? 0}`,
      },
      {
        name: 'Status',
        selector: (row) => isRequisitionPage ? row.requisitionState : row.purchaseOrderState,
        cell: (row) => {
          console.log(row)
          return <Status value={isRequisitionPage ? row.requisitionState : row.purchaseOrderState} pill wordCount={1}/>
        },
      },
    ],
    [],
  );

  return (
    <div>
      <Text className={styles.sectionTitle} type="h2" weight={600}>
        {isRequisitionPage ? 'Requisition' : 'Purchase Order'} Reports
      </Text>
      <Box $radius={12} className={styles.container}>
        <form className={styles.filterForm}>
          <Filters
            control={control}
            errors={errors}
            trigger={trigger}
            watch={watch}
            clearErrors={clearErrors}
            setValue={setValue}
            filters={['vendor', 'department', 'gl', 'users']}
            page={isRequisitionPage ? 'requisitions' : 'po'}
          />
          <div className={generalStyles.pageButtons}>
            <Button.Main
              onClick={() => setFetchTrigger((fetchTrigger) => !fetchTrigger)}
              $primary
              $style="pink"
              type="button"
            >
              Submit
            </Button.Main>
            {/*<Button.Main*/}
            {/*  onClick={() => navigate('/reports/overview')}*/}
            {/*  $primary*/}
            {/*  $style="gray"*/}
            {/*  type="button"*/}
            {/*>*/}
            {/*  Back to Overview*/}
            {/*</Button.Main>*/}
          </div>
        </form>
      </Box>
      <br />
      <br />
      <Box $radius={12} className={styles.container}>
        <div>
          <div className={styles.exportBtnContainer}>
            <Button.Main
              $primary
              $style="pink"
              onClick={() => getExport(`${isRequisitionPage ? 'Requistion' : 'PO'} Reports`)}
              $iconLeft
            >
              <Icon $width={20} $height={20} $icon="export" />
              <Text weight={500} type="subtitle">
                Export
              </Text>
            </Button.Main>
          </div>
          <div className={styles.reportsTable}>
            <DataTableBaseRemote
              columns={columns}
              separatePagination
              fetchData={getReports}
              selectableRows={false}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default RequisitionPOReports;
