import React from "react";
import styles from "../../../styles/vendor.module.css";

const StarRating = ({ rating }) => {
  const maxRating = rating || 0;

  return (
    <div>
      <div>
        {[...Array(maxRating)]?.map((_, index) => (
          <span className={styles.ratingSty} key={index}>★</span>
        ))}
      </div>
    </div>
  );
};

export default StarRating;