import { useMemo } from 'react';
import { useStore } from '../../../store/store';
import { useShallow } from 'zustand/react/shallow';
import Box from '../../UI/General/Box';
import Text from '../../UI/Typography/Text';
import styles from '../../../styles/dashboard.module.css';
import { Link } from 'react-router-dom';
import Icon from '../../UI/General/Icon';

const Widget = ({ small }) => {
  const steps = useStore(useShallow((state) => state.steps));
  const stepsDone = useStore(useShallow((state) => state.stepsDone));
  const companyStep = steps.find((step) => step.type === 'company');

  const stepsLeft = useMemo(() => steps.filter((item) => item.done === false).length, [steps]);
  const blockUsers = useMemo(
    () => steps.filter((item) => item.type === 'department')[0].done,
    [steps],
  );
  const percents = useMemo(
    () => parseInt((100 / steps.length) * steps.filter((item) => item.done === true).length),
    [steps],
  );
  const checkPreviousStep = (currentStep) => {
    if (currentStep.number === 1) return true;
    const previousStep = steps.find((step) => step.number === currentStep.number - 1);
    return previousStep.done;
  };

  return (
    <>
      {stepsDone === false && stepsLeft && stepsLeft > 0 ? (
        <Box $radius={12} className={`${styles.setup} ${small ? styles.setupSmall : ''}`}>
          <div className={styles.text}>
            <Text type="h3" weight={700}>
              Setup Completion {percents}%
            </Text>
            <Text type="body-2" weight={500}>
              Your are {stepsLeft} steps behind to add your information.
            </Text>
          </div>
          <div className={styles.steps}>
            {steps &&
              steps.map((item) => {
                const className =
                  item.done === true ? styles.step + ' ' + styles.stepDone : styles.step;
                return (
                  <div className={className} key={item.number}>
                    <Link className={styles.stepNumber} to={item.done ? item.link : '#'}>
                      {item.number}
                    </Link>
                    <Text type="body-2" weight={500}>
                      {item.text}
                    </Text>
                    {item.done === false && checkPreviousStep(item) ? (
                      <>
                        {(item.type === 'users' && blockUsers === false) ||
                        (!companyStep.done && item.type !== 'company') ? null : (
                          <Link to={item.link} className={styles.stepLink}>
                            <span>Add {item.type === 'users' && blockUsers === false}</span>
                            <Icon $icon="arrow-right" $width={20} $height={20} />
                          </Link>
                        )}
                      </>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </Box>
      ) : null}
    </>
  );
};

export default Widget;
