import React, {useState} from 'react';
import styles from "../../../../styles/purchaseOrders.module.css";
import stylesR from "../../../../styles/requisition.module.css";
import Icon from "../../../UI/General/Icon";
import * as Button from "../../../UI/Forms/Button";
import Text from "../../../UI/Typography/Text";
import Label from "../../../UI/Forms/Label";
import {nf} from "../../../../utils/nf";
import Tooltip from "../../../UI/General/Tooltip";

const GridItem = ({data, id, ...props}) => {
  const [showNote, setShowNote] = useState(false)

  const tooltipInfo = () => (
    <div className={stylesR.tooltipRow}>
      <div>
        {data?.budgetVerification?.remainingBudget && <Text weight={600} type="body-2">Available</Text>}
        <Text weight={600} type="body-2">Requested</Text>
        </div>
      <div>
        {data?.budgetVerification?.remainingBudget && <Text type="body-2"><Text weight={500}>{props?.budgetCur}</Text> {nf.format(data?.budgetVerification?.remainingBudget)}</Text>}
        <Text type="body-2"><Text weight={500}>{props?.budgetCur}</Text> {nf.format(data?.totalCost * props?.exchangeRate)}</Text>
      </div>
    </div>
  )

  const tooltipIcon = () => (
    <Button.Action $variant="circle" $style={data?.budgetVerification?.isWithinBudget === true ? "budgetOk" : data?.budgetVerification?.isWithinBudget === false ? "budgetHigh" : "budgetDefault"} $width={32} $height={32} type="button">
      <Icon $width={32} $height={32} $icon="budget"/>
    </Button.Action>
  )

  return (
    <div className={styles.item}>
      <div className={styles.itemInner}>
        <div>
          <Tooltip text={tooltipInfo()} icon={tooltipIcon()} $maxWidth={"max-content"} />
        </div>
        <div>
          <Label className={styles.label} $title="Description"/>
          <Text weight={500} type={"body-2"}>{data?.description ?? "-"}</Text>
        </div>
        <div>
          <Label className={styles.label} $title="Gl Code"/>
          <Text weight={500} type={"body-3"}>{data?.glAccount?.code ? data?.glAccount?.code : data?.glAccount?.glCode ?? "-"}</Text>
          <Text weight={500} type={"body-3"} title={data?.glAccount?.description} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{data?.glAccount?.description ? data?.glAccount?.description : data?.glAccount?.description ?? "-"}</Text>
        </div>
        <div>
          <Label className={styles.label} $title="Quantity"/>
          <Text weight={500} type={"body-2"}>{data?.quantity ?? "-"}</Text>
        </div>
        <div>
          <Label className={styles.label} $title="Unit price"/>
          <Text weight={500} type={"body-2"}>{nf.format(data?.unitPrice) ?? "-"}</Text>
        </div>
        <div>
          <Label className={styles.label} $title="Tax"/>
          <Text weight={500} type={"body-2"}>{data?.tax?.type ?? "-"}</Text>
        </div>
        <div>
          <Label className={styles.label} $title="Subtotal"/>
          <Text weight={500} type={"body-2"}>{nf.format(data?.subtotal) ?? "-"}</Text>
        </div>
        <div className={styles.itemActions}>
          <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={() => setShowNote(!showNote)} type="button">
            <Icon $width={20} $height={20} $icon="add-note" $color="#45BABC"/>
          </Button.Action>
        </div>
      </div>
      {showNote ? (
        <div className={styles.itemNote}>
          <Text weight={500} type={"body-1"} className={styles.itemNoteLabel}>Notes</Text>
          <Text weight={500} type={"body-2"}>{data?.itemNotes === "" ? "There is no notes to display" : data?.itemNotes}</Text>
        </div>
      ) : null}
    </div>
  );
};

export default GridItem;
