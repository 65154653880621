import tableStyles from '../../styles/home.module.css';

import SubscriptionColumn from '../../components/Admins/AccountBillings/SubscriptionColumn.jsx';
import React, { useEffect, useState } from 'react';
import spmsServiceService from '../../services/spmsService.service.js';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed.jsx';
import Toast from '../../components/UI/General/Toast.jsx';

const AccountSubscription = () => {
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const currentSubscription = useSubscriptionAllowed();

  useEffect(() => {
    spmsServiceService.getAllSubscriptionsPlans().then((res) => {
      const r = res.data.data.sort((a, b) => parseInt(a.planCode) - parseInt(b.planCode));
      setAllSubscriptions(r);
    });
  }, []);

  const getButtonText = (planName) => {
    if (!allSubscriptions.length || !currentSubscription?.plan) return 'Subscribe';
    const types = allSubscriptions.map((sub) => sub.name);
    if (types.indexOf(planName) === types.indexOf(currentSubscription.plan.name)) return null;
    else if (types.indexOf(planName) < types.indexOf(currentSubscription.plan.name))
      return 'Downgrade';
    else return 'Upgrade';
  };

  return (
    <div className={tableStyles.pricingTable}>
      {allSubscriptions?.map((sub) => (
        <SubscriptionColumn
          buttonText={getButtonText(sub.name)}
          isSelected={currentSubscription?.plan?.name === sub.name}
          key={sub.name}
          sub={sub}
          setToast={setToast}
        />
      ))}
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </div>
  );
};

export default AccountSubscription;
