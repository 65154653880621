import React from 'react';

const LegalRegions = () => {
  return (
    <div>
      Legal Regions
    </div>
  );
};

export default LegalRegions;
