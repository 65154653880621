import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import style from '../../styles/log_reg.module.css';
import Box from '../../components/UI/General/Box';
import Text from '../UI/Typography/Text';
import Input, { Password } from '../UI/Forms/Input';
import * as Button from '../UI/Forms/Button';
import Icon from '../UI/General/Icon';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Toast from '../UI/General/Toast';
import {regExps} from "../../utils/regExps";

const Login = ({ setUser, setStatus, setUserName }) => {
  const {handleSubmit, control, formState: { errors, isValid, isDirty }} = useForm({ mode: 'onChange' });
  const [confirmed, setConfirmed] = useState(undefined);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  async function logIn({ email, password }) {
    setIsButtonsBlocked(true)
    setToast((item) => ({...item, opened: false}));
    setUserName(email);
    try {
      const user = await Auth.signIn(email, password);
      console.log(user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setStatus('new-password');
      }
      if (user.challengeName === 'MFA_SETUP') {
        setStatus('topt');
      }
      setUser(user);
    } catch (error) {
      console.log('error signing in', error);
      console.log('message', error.message);
      if (error.message === 'User is not confirmed.') {
        console.log('need confirmation');
        setConfirmed(false)
      }
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: error.message !== undefined ? error.message : error,
        type: 'fail',
      });
    }
  }

  return (
    <div className={style.auth}>
      <div className={style.authInner}>
        <Box $radius={40} className={style.authContainer} $noPadding>
          <div className={style.authImage}>
            <img src="assets/img/login_register/login.svg" alt="login" />
            <div className={style.imageTexts + ' ' + style.imageTextsLogin}>
              <Text type="h2" weight={600}>
                Welcome Back
              </Text>
              <Text type="h3" weight={500}>
                Enter your credentials to access your account.
              </Text>
            </div>
          </div>
          <div className={style.authContent}>
            <div className={style.authContentLogo}>
              <Link to={'/'}>
                <img src="assets/img/login_register/logo.svg" alt="Poms"/>
              </Link>
            </div>
            <div className={style.authHeader}>
              <Text type="h2" weight={600}>
                Welcome to POMS
              </Text>
            </div>
            <form
              onSubmit={handleSubmit(logIn)}
              className={style.authForm + ' ' + style.authFormSmall}
            >
              <div className={style.inputs + ' ' + style.inputsColomn}>
                <div className="inp-container">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value: regExps.email,
                        message: 'Email is not valid',
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        type="email"
                        placeholder="Enter Email *"
                        $iconName={'mail'}
                        $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        {...field}
                      />
                    )}
                  />
                  {errors.email && <p className="error-message">{errors.email?.message}</p>}
                </div>
                <div className="inp-container">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <Password
                        placeholder="Password *"
                        $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        {...field}
                      />
                    )}
                  />
                  {errors.password?.type === 'required' && (
                    <p className="error-message">Password is required</p>
                  )}
                </div>
              </div>
              <div className={style.btnBefore + ' ' + style.btnBeforeLogin}>
                <span onClick={() => setStatus('forgot')}>Forgot your Password?</span>
                {confirmed === false ? <span onClick={() => setStatus('confirm')}>Confirm?</span> : null}
              </div>
              <div className={style.btn}>
                <Button.Main
                  $primary
                  $full
                  $style="pink"
                  $iconRight
                  type="submit"
                  disabled={!isValid|| !isDirty || isButtonsBlocked}
                >
                  <span>Log In</span>
                  <Icon $width={24} $height={24} $icon="arrow-right" />
                </Button.Main>
              </div>
            </form>
            <div className={style.btnAfter}>
              <Text type="body-1" weight={500}>
                Don’t have an account? <Link to="/register">Register here</Link>
              </Text>
            </div>
          </div>
        </Box>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb}/>
      ) : null}
    </div>
  );
};

export default Login;
