import React from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {useStore} from "../../store/store";
import budgetService from "../../services/budgetService.service";
import styles from "../../styles/requisition.module.css";
import * as Button from "../UI/Forms/Button";
import Icon from "../UI/General/Icon";
import Text from "../UI/Typography/Text";
import {useNavigate} from "react-router-dom";

const SelectBudgets = (props) => {
  const activeCompany = useStore((state) => state.activeCompany);
  const navigate = useNavigate();

  const fetchBudgets = async (search, loadedOptions, { page }) => {
    const result = await budgetService.searchBudgets(activeCompany?.id, page, 10, {
      approvalStatuses: ["APPROVED"],
      active: true,
      name: search
    }).catch(err => {
      console.log(err)
      return undefined
    })
    const finalData = result?.data?.data?.content?.map(item => (
      {
        label: item.name,
        value: item.id,
        currency: item.currency,
        budgetValue: item.budgetValue
      }
    ))
    const hasMore = finalData !== undefined && result.data.data.totalElements > 0 ? loadedOptions.length < result.data.data.totalElements : false;
    return {
      options: finalData || [],
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const NoOptionsMessage = () => (
    <div className={styles.noOptionRedirect}>
      <Button.ActionLabeled onClick={() => navigate(`/budgets/${activeCompany?.id}/create`)}>
        <Button.Action $style="pink" $variant="circle" $width={20} $height={20} type="button">
          <Icon $width={18} $height={18} $icon="plus" $color="white" />
        </Button.Action>
        <Text weight={700} type="subtitle">Add Budget</Text>
      </Button.ActionLabeled>
    </div>
  );

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchBudgets}
      classNamePrefix="react-select"
      isSearchable={true}
      placeholder="Select Budget"
      components={{NoOptionsMessage: NoOptionsMessage}}
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectBudgets;
