import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import CompanyContent from '../../components/Admins/ApprovalWorkflow/Overview/CompanyContent';
import Accordion from '../../components/UI/General/Accordion';
import Text from '../../components/UI/Typography/Text';
import companyStyles from '../../styles/companySetup.module.css';
import { useShallow } from 'zustand/react/shallow';

const ApprovalWorkflow = () => {
  const company = useStore((state) => state.company);
  const [selCompany, setSelCompany] = useState(null);
  const preOpenedItem = localStorage.getItem('preOpenedRule');
  const companiesData = company?.subsidiaries
    ? [company, ...company.subsidiaries].filter((company) => company.active)
    : [company];
  useEffect(() => {
    if (preOpenedItem) {
      setSelCompany(preOpenedItem);
    } else if (companiesData.length === 1) {
      setSelCompany(companiesData[0].id);
    }
  }, [preOpenedItem, companiesData]);

  return (
    <div className={companyStyles.accordionItems}>
      {companiesData.length === 1 ? (
        <CompanyContent selCompany={selCompany} companyId={companiesData[0].id} />
      ) : (
        <>
          {companiesData?.map((selectedCompany) => (
            <Accordion
              key={selectedCompany.id}
              onOpen={() => setSelCompany(selectedCompany.id)}
              preOpened={Number(preOpenedItem) === selectedCompany.id}
              observedItem={selectedCompany.id}
              selCompany={selCompany}
            >
              <div className={companyStyles.branch}>
                <Accordion.Header>
                  <div className={companyStyles.branchHead}>
                    <Text weight={500} type="subtitle">
                      Company Name
                    </Text>
                    <div className={companyStyles.branchName}>{selectedCompany.name}</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <CompanyContent
                    selCompany={selCompany}
                    key={selectedCompany.id}
                    companyId={selectedCompany.id}
                    companyName={selectedCompany.name}
                  />
                </Accordion.Body>
              </div>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
};

export default ApprovalWorkflow;
