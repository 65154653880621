import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import {regExps} from "../regExps";

const validFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'ico', 'svg', 'bmp', 'pdf'];

function isValidFileType(fileName) {
  if (!fileName) return true;
  return fileName && validFileExtensions.indexOf(fileName.split('.').pop()) > -1;
}

const companyBasicSchema = yup.object().shape({
  name: yup
    .string()
    .required('Legal Registered Name is required')
    .matches(/^[a-zA-Z0-9@&()'-\s]+$/, 'Invalid characters')
    .test(
      'no-empty-spaces',
      'Legal Registered Name cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .max(50, 'Legal Registered Name must be at most 50 characters'),
  country: yup.object().shape({
    value: yup.string().required('Country is required'),
  }),
  defaultCurrency: yup.object().shape({
    value: yup.string().required('Currency is required'),
  }),
  industry: yup.object().shape({
    value: yup.string().required('Industry is required'),
  }),
});
const companyExtendedSchema = companyBasicSchema.shape({
  tradingName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Trading name cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9@&()'-\s]+$/, 'Invalid characters')
    .max(50, 'Trading name must be at most 50 characters'),
  registrationNumber: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Registration number cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
    .max(20, 'Registration number cannot exceed 20 characters'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', (value) => {
      return isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '');
    }),
  email: yup
    .string()
    .required('Email is required')
    .matches(regExps.email, 'Invalid email address')
    .max(50, 'E-mail must be at most 50 characters'),
  vatNumber: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'VAT number cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
    .max(20, 'VAT Number must be at most 20 characters'),
  companyLogo: yup
    .mixed()
    .nullable(true)
    .test('is-valid-type', 'Not a valid image type', (value) =>
      isValidFileType(value && value.name.toLowerCase()),
    ),
  allowedVarianceAmount: yup
    .number()
    .transform((_, val) => (val !== "" ? Number(val) : 0))
    .typeError('Only numbers allowed')
    .test('allow-2-decimals', 'Only 2 decimals allowed', (value => {
      return /^[0-9]+([.][0-9]{1,2})?$/.test(value)
    }))
    .min(0, "Min value is 0"),
  allowedVariancePercentage: yup
    .number()
    .transform((_, val) => (val !== "" ? Number(val) : 0))
    .typeError('Only numbers allowed')
    .integer('Only integers allowed')
    .min(0, "Min value is 0")
    .test('length', 'Only 3 digits allowed', val => val?.toString().length < 4),
  poDisclaimer: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
});

export { companyBasicSchema, companyExtendedSchema };
