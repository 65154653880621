import React, {useEffect, useState} from 'react';
import Box from "../../UI/General/Box";
import generalStyles from "../../../styles/general.module.css";
import styles from "../../../styles/companySetup.module.css";
import Label from "../../UI/Forms/Label";
import {Controller, useForm, useWatch} from "react-hook-form";
import Input from "../../UI/Forms/Input";
import Text from "../../UI/Typography/Text";
import Radio from "../../UI/Forms/Radio";
import * as Button from "../../UI/Forms/Button";
import Toast from "../../UI/General/Toast";
import spmsServiceService from "../../../services/spmsService.service";
import {regExps} from "../../../utils/regExps";
import Textarea from "../../UI/Forms/Textarea";
import {useStore} from "../../../store/store";
import Checkbox from "../../UI/Forms/Checkbox";

const PoBody = ({companyData}) => {
  const company = useStore((state) => state.company);
  const updateCompany = useStore((state) => state.updateCompany);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      autoGeneratedNumberAffix: companyData?.purchaseOrderProperties?.autoGeneratedNumberAffix ?? 'SUFFIX',
      poNumberFormat: companyData?.purchaseOrderProperties?.poNumberFormat ?? 'PO',
      poDisclaimer: companyData?.purchaseOrderProperties?.poDisclaimer ?? '',
    },
  });

  const autoGeneratedNumberAffixWatch = useWatch({name: 'autoGeneratedNumberAffix', control});
  const poNumberFormatWatch = useWatch({name: 'poNumberFormat', control});

  const onSubmit = (data) => {
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }))
    const {logo, ...rest} = companyData
    const finalData = {
      ...rest,
      purchaseOrderProperties: {
        poNumberFormat: data.poNumberFormat,
        autoGeneratedNumberAffix: data.autoGeneratedNumberAffix,
        addCurrentDate: false,
        poDisclaimer: data.poDisclaimer,
      },
    };
    spmsServiceService.updateCompany(finalData).then((res) => {
      if (res.data.status === 'success') {
        if (companyData?.parent?.id) {
          const updSubs = company?.subsidiaries.map(obj => [finalData].find(o => o.id === obj.id) || obj);
          const updCompany = {
            ...company,
            subsidiaries: updSubs
          }
          updateCompany(updCompany)
        } else {
          updateCompany(finalData)
        }
        setToast({
          opened: true,
          message: 'PO setup updated successfully!',
          type: 'success',
        });
      }
    }).catch(err => {
      console.log(err)
      setIsButtonsBlocked(false)
    })
  }

  return (
    <>
      <form>
        <Box $radius={0} className={styles.poSetup}>
          <div className={generalStyles.fieldsThree}>
            <div className={styles.poFormat}>
              <Label $title="PO Number Format" />
              <div className={styles.poFormatSections}>
                <div className="inp-container">
                  <Controller
                    name="poNumberFormat"
                    control={control}
                    rules={{
                      required: {
                        message: "Can't save with empty value",
                        value: true
                      }
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="Enter PO number format"
                        className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                        id={field.name}
                        onChange={(e) => {
                          field.onChange(e);
                          setIsButtonsBlocked(false)
                        }}
                      />
                    )}
                  />
                  {errors.poNumberFormat && <p className="error-message">{errors.poNumberFormat?.message}</p>}
                </div>
                {poNumberFormatWatch && (
                  <div>
                    <Label $title="Sample PO Number Format" />
                    <Text type={'body-1'}>
                      {autoGeneratedNumberAffixWatch === 'SUFFIX' ? `${poNumberFormatWatch}123` : `123${poNumberFormatWatch}`}
                    </Text>
                  </div>
                )}
                <div>
                  <Label $title="Add Auto-Generated Number As" />
                  <Controller
                    name="autoGeneratedNumberAffix"
                    control={control}
                    render={({ field }) => (
                      <div className={styles.poFormatRadio}>
                        <Radio
                          defaultChecked={autoGeneratedNumberAffixWatch === 'SUFFIX'}
                          {...field}
                          $size={20}
                          label="Suffix"
                          value="SUFFIX"
                          onChange={(e) => {
                            field.onChange(e);
                            setIsButtonsBlocked(false)
                          }}
                        />
                        <Radio
                          defaultChecked={autoGeneratedNumberAffixWatch === 'PREFIX'}
                          {...field}
                          $size={20}
                          label="Prefix"
                          value="PREFIX"
                          onChange={(e) => {
                            field.onChange(e);
                            setIsButtonsBlocked(false)
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="inp-container" style={{gridColumn: "span 2"}}>
              <Controller
                name="poDisclaimer"
                control={control}
                rules={{
                  pattern: {
                    value: regExps.notes,
                    message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                  },
                }}
                render={({field}) => (
                  <Textarea
                    {...field}
                    $low
                    $counter
                    $counterMax={500}
                    $label="PO Disclaimer"
                    placeholder="PO Disclaimer text goes here"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    onChange={(e) => {
                      field.onChange(e);
                      setIsButtonsBlocked(false)
                    }}
                  />
                )}
              />
              {errors.poDisclaimer && <p className="error-message">{errors.poDisclaimer?.message}</p>}
            </div>
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              onClick={handleSubmit((data) => onSubmit(data))}
              disabled={!isDirty || !isValid || isButtonsBlocked}
            >
              Submit
            </Button.Main>
          </div>
        </Box>
      </form>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb}/>
      ) : null}
    </>
  );
};

export default PoBody;
