import React, { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import Text from '../UI/Typography/Text';
import styles from '../../styles/companySetup.module.css';
import { useStore } from '../../store/store';
import Widget from '../Admins/Dashboard/Widget';
import TabsSlider from '../UI/General/TabsSlider';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import {useAccessAllowed} from "../../hooks/useAccessAllowed";

const Setup = () => {
  const company = useStore((state) => state.company);
  const switchCompanyDropdown = useStore((state) => state.switchCompanyDropdown);
  const stepsDone = useStore((state) => state.stepsDone);
  const currentSubscription = useSubscriptionAllowed();
  const accessPO = useAccessAllowed('Purchase_Order')

  useEffect(() => {
    switchCompanyDropdown(false);
    return () => {
      switchCompanyDropdown(true);
    };
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ transform: 'translate(0, -40px)' }}>
        <Widget small />
      </div>
      {stepsDone && (
        <div>
          <Text type="h2" weight={600}>
            Configuration
          </Text>
          <TabsSlider className={styles.setupTabsContainer}>
            <NavLink
              to="/company-setup"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Company Profile
            </NavLink>
            {company &&
            !!company?.branches?.length &&
            company.holding &&
            !!currentSubscription?.plan?.maxSubsidiaries ? (
              <NavLink
                to={'/subsidiary-setup'}
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Subsidiaries
              </NavLink>
            ) : null}
            {company && !!company?.branches?.length ? (
              <NavLink
                to={'/department-setup'}
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Departments
              </NavLink>
            ) : null}
            {company && !!company?.branches?.length ? (
              <NavLink
                to="/gl-setup"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                General Ledger
              </NavLink>
            ) : null}
            {company && !!company?.branches?.length ? (
              <NavLink
                to="/user-roles-and-permissions/active"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                User Roles
              </NavLink>
            ) : null}
            {company && !!company?.branches?.length ? (
              <NavLink
                to="/users/overview/active"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Users
              </NavLink>
            ) : null}

            {company && !!company?.branches?.length ? (
              <NavLink
                to="/approval"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Approvals
              </NavLink>
            ) : null}
            <NavLink
              to="/notification"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Notifications
            </NavLink>
            {company && (accessPO?.viewAll || accessPO?.viewOwn) && currentSubscription?.plan?.purchaseOrdersEnabled ? (
              <NavLink
                to="/po-setup"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                PO Setup
              </NavLink>
            ) : null}
          </TabsSlider>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default Setup;
