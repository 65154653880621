import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Icon from "./Icon";
import Text from "../Typography/Text";

const StyledToast = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  gap: 16px;
  max-width: 300px;
  padding: 16px;
  -webkit-border-radius: 8px;-moz-border-radius: 8px;border-radius: 8px;
  background-color: #fff;
  z-index: 9999;
& svg{
  cursor: pointer;
}
&.success{
  background-color: green;
  color: #ffffff;
}
&.fail{
  background-color: red;
  color: #ffffff;
}
`

const Toast = ({type, message, opened, cb}) => {
  const [show, setShow] = useState(false)
  useEffect(()=> {
    setShow(opened)
    const timerId = setTimeout(()=>{
      setShow(false)
      if (typeof cb === "function"){
        cb()
      }
    }, 3000)
    return () => clearTimeout(timerId);
  }, [opened])
  return (
    <>
      {show === true ? (
        <StyledToast className={type}>
          <Text type={"body-3"} weight={500}>{message?.toString()}</Text>
          <Icon $icon="close" $width={16} $height={16} onClick={()=>setShow(false)}/>
        </StyledToast>
      ) : null}
    </>
  )
};

export default Toast;
