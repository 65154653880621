import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Button from '../Forms/Button';
import Icon from './Icon';

const AccordionContext = createContext(false);

const StyledAccordionHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  cursor: pointer;
  & button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &.active button svg {
    transform: rotate(180deg);
  }
  &.selected {
    background-color: rgba(69, 186, 188, 0.2);
  }
`;

const AccordionInner = styled.div`
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: ${({ $radius }) => ($radius ? $radius + 'px' : '12px')};
  overflow: ${({ $noOverflow }) => ($noOverflow ? 'hidden' : 'auto')};
`;

const AccordionHeader = ({ children, $selected }) => {
  const { isActive, setIsActive } = useContext(AccordionContext);
  return (
    <StyledAccordionHeader
      className={
        isActive && $selected
          ? 'active selected'
          : $selected
          ? 'selected'
          : isActive
          ? 'active'
          : ''
      }
      onClick={() => setIsActive((state) => !state)}
    >
      {children}
      <Button.Action $style="white" type="button">
        <Icon $width={20} $height={20} $icon="dropdown" />
      </Button.Action>
    </StyledAccordionHeader>
  );
};
const AccordionBody = ({ children }) => {
  const { isActive } = useContext(AccordionContext);
  return <div style={{ display: isActive ? 'block' : 'none' }}>{children}</div>;
};

const Accordion = ({ children, onOpen, preOpened, observedItem, selCompany }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (observedItem && selCompany && observedItem !== selCompany) {
      setIsActive(false);
    }
  }, [observedItem, selCompany]);

  useEffect(() => {
    if (onOpen !== undefined) {
      isActive && onOpen();
    }
  }, [isActive]);

  useEffect(() => {
    if (preOpened) {
      setIsActive(true);
    }
  }, []);

  return (
    <AccordionContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </AccordionContext.Provider>
  );
};

export default Object.assign(Accordion, {
  Header: AccordionHeader,
  Body: AccordionBody,
  Inner: AccordionInner,
});
