import React, { useState } from "react";
import LoginCmp from "../components/Auth/Login";
import NewPassword from "../components/Auth/NewPassword";
import Topt from "../components/Auth/Topt";
import ForgetPassword from "../components/Auth/ForgetPassword";
import ResetPassword from "../components/Auth/ResetPassword";
import ForgotResetPassword from "../components/Auth/ForgotResetPassword";
import CheckEmail from "../components/Auth/CheckEmail";
import ConfirmUser from "../components/Auth/ConfirmUser";

const Login = () => {
	const [status, setStatus] = useState("sign-in");
	const [user, setUser] = useState(null);
	const [username, setUserName] = useState(null);

	return (
		<>
			{status === "sign-in" ? <LoginCmp setStatus={setStatus} setUser={setUser} setUserName={setUserName} /> : null}
			{status === "new-password" ? <NewPassword setUser={setUser} user={user} />: null}
			{status === "topt" ? <Topt user={user} /> : null}
			{status === "forgot" ? <ForgetPassword setStatus={setStatus} setUserName={setUserName} />: null}
			{status === "confirm" ? <ConfirmUser username={username} setStatus={setStatus} />: null}
			{status === "check-email" && <CheckEmail setStatus={setStatus} setUserName={setUserName} />}
			{status === "reset-password" ? <ResetPassword setStatus={setStatus} username={username} />: null}
			{status === "forgot-reset-password" ? <ForgotResetPassword setStatus={setStatus} username={username} />: null}
		</>
	);
};

export default Login;
