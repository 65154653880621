import { axiosBaseInstance, axiosInstance, baseUrl } from './API';

const RequestsService = {
  checkUserExist(email, phone) {
    return axiosBaseInstance.post(baseUrl + '/users/validate-create-user-request', {
      email: email,
      phoneNumber: phone,
    });
  },
  confirmUser(username, code){
    return axiosBaseInstance.get(baseUrl + '/users/confirm-sign-up?user_name='+username+'&confirmation_code='+code+'')
  },

  getData(size, page, searchBody, getAll = false) {
    if ((size || page) && !getAll) {
      return axiosInstance.post(
        baseUrl + '/users/list-users?size=' + size + '&page=' + page,
        searchBody,
      );
    } else {
      return axiosInstance.post(baseUrl + '/users/list-users', searchBody);
    }
  },

  updateProfile(userId, data) {
    return axiosInstance.put(baseUrl + '/users/' + userId + '/update-profile', data);
  },

  updateUserInfo(userId, data) {
    return axiosInstance.post(baseUrl + '/users/' + userId + '/update', data);
  },

  createHolidayPlan(data) {
    return axiosInstance.post(baseUrl + '/users/holidays', data);
  },
  getHolidayPlan(userId) {
    return axiosInstance.get(baseUrl + '/users/' + userId + '/holidays');
  },

  activateUser(email) {
    return axiosInstance.post(baseUrl + '/users/enableUser/' + email);
  },

  deactivateUser(email) {
    return axiosInstance.post(baseUrl + '/users/disableUser/' + email);
  },
  deleteInvitedUser(id) {
    return axiosInstance.delete(baseUrl + '/users/' + id + '/delete');
  },
  resetUserPassword(body) {
    return axiosInstance.post(baseUrl + '/users/reset-password', body);
  },

  resendInvitation(email) {
    return axiosInstance.post(baseUrl + '/users/resend-user-invitation?email=' + email);
  },

  resendConfirmation(email) {
    return axiosBaseInstance.post(baseUrl + '/users/resend-confirmation?username=' + email);
  },

  listCountries() {
    return axiosInstance.get(baseUrl + '/utils/countries');
  },
  regionsList() {
    return axiosInstance.get(baseUrl + '/utils/region');
  },

  listCountryRegions(code) {
    return axiosInstance.get(baseUrl + '/utils/region/' + code);
  },

  registerUser(data) {
    return axiosInstance.post(baseUrl + '/users', data);
  },

  updateUserCompany(data) {
    return axiosInstance.post(baseUrl + '/users/company', data);
  },

  getUserDetailsById(id) {
    return axiosInstance.get(baseUrl + '/users/id/' + id);
  },

  getUserDetailsByEmail(email) {
    return axiosInstance.get(baseUrl + '/users/' + email);
  },

  getUserDetailsByPhoneNumber(phoneNumber) {
    return axiosInstance.get(baseUrl + '/users/phoneNumber/' + phoneNumber);
  },

  updateUserDetails(data) {
    return axiosInstance.post(baseUrl + '/users/edit', data);
  },

  getRolesByCompany(companyId) {
    return axiosInstance.get(baseUrl + '/roles/company/' + companyId);
  },
  getRoleUsers(roleName, page = 0, size = 10000) {
    return axiosInstance.get(
      baseUrl + '/roles/users/' + roleName + '?page=' + page + '&size=' + size + '',
    );
  },
  getRoleByName(roleName) {
    return axiosInstance.get(baseUrl + '/roles/name/' + roleName);
  },
  getRoleById(roleId) {
    return axiosInstance.get(baseUrl + '/roles/id/' + roleId);
  },
  createRole(data) {
    return axiosInstance.post(baseUrl + '/roles', data);
  },
  editRole(roleId, data) {
    return axiosInstance.put(baseUrl + '/roles/' + roleId, data);
  },
  editRoleByCompany(roleId, companyId, data) {
    return axiosInstance.put(baseUrl + '/roles/' + roleId + '/company/' + companyId, data);
  },
  activateRole(id) {
    return axiosInstance.post(baseUrl + '/roles/' + id);
  },
  archiveRole(id) {
    return axiosInstance.delete(baseUrl + '/roles/' + id);
  },

  getResources() {
    return axiosInstance.get(baseUrl + '/resources');
  },
};

export default RequestsService;
