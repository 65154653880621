import React, { useState, useMemo, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import Icon from '../../components/UI/General/Icon';
import DataTableBase from '../../components/UI/General/DataTableBase';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import { ProgressBarWrapper, ProgressBarFill } from '../../components/UI/General/ProgressBar';

import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';
import Text from '../../components/UI/Typography/Text';
import EditApprovedGl from './EditApprovedGl';
import { useNavigate, useParams } from 'react-router-dom';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import budgetServiceService from '../../services/budgetService.service';
import moment from 'moment';

const ExpandedComponent = ({ data }) => (
  <div className={styles.expandedGlContainer}>
    <Text weight={600}>Budget Used / Budget Allocated </Text>
    <div className={generalStyles.fieldsSix}>
      {data.allocations.sort((a,b) => moment([a.month.split('-')[1], a.month.split('-')[0]-1]).diff(moment([b.month.split('-')[1], b.month.split('-')[0]-1])))?.map((allocation) => (
        <div className={styles.expandedGlLine} key={allocation.month}>
          <Text weight={600}>{moment([allocation.month.split('-')[1], allocation.month.split('-')[0]-1]).format("MMMM YYYY")}:</Text>
          <Text weight={500}>
            {allocation.totalPOApprovedAmount ?? 0} / {allocation.amount}
          </Text>
        </div>
      ))}
    </div>
  </div>
);

const BudgetDetailsGlList = ({ budget }) => {
  const [editableGl, setEditableGl] = useState(null);
  const [requisitions, setRequisitions] = useState(false);
  const { companyId, budgetId } = useParams();

  const navigate = useNavigate();

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    spmsServiceService
      .getCompany(companyId)
      .then((res) => setRequisitions(res.data.data.requisitionsRequired));
  }, []);

  const calculateUsedPercantage = (allocations) => {
    if (!allocations) return 0;
    const spentAmount = allocations.reduce((acc, curr) => acc + curr.totalPOApprovedAmount, 0);
    const allocated = allocations.reduce((acc, curr) => acc + curr.amount, 0);
    return !spentAmount && !allocated ? 0 : ((spentAmount / allocated) * 100).toFixed(0);
  };

  const columns = useMemo(
    () => [
      {
        name: 'GL',
        sortable: true,
        selector: (row) => row?.glAccountName,
        cell: (row) => <span data-content={"GL"} className={generalStyles.tableValue}><span>{row?.glAccountName || '-'}</span></span>,
      },
      {
        name: 'GL Description',
        sortable: true,
        selector: (row) => row?.glAccountDescription,
        cell: (row) => <span data-content={"GL Description"} className={generalStyles.tableValue}><span>{row?.glAccountDescription || '-'}</span></span>,
      },
      {
        name: 'Department',
        wrap: true,
        selector: (row) => row?.departmentName,
        cell: (row) => <span data-content={"Department"} className={generalStyles.tableValue}><span>{row?.departmentName || '-'}</span></span>,
      },
      {
        name: 'Branch',
        selector: (row) => row?.branchName,
        cell: (row) => <span data-content={"Branch"} className={generalStyles.tableValue}><span>{row?.branchName || '-'}</span></span>,
      },
      {
        name: 'Total Allocated',
        cell: (row) => (
          <span data-content={"Total Allocated"} className={generalStyles.tableValue}>
            <NumericFormat
              value={row?.glBudgetValue}
              displayType="text"
              thousandSeparator=","
              prefix={budget.currency + ' '}
              decimalScale={2}
            />
          </span>
        ),
        sortable: true,
      },
      {
        name: 'Used',
        cell: (row) => {
          const percantage = calculateUsedPercantage(row.allocations);
          return (
            <span data-content={"Used"} className={generalStyles.tableValue}>
              <div>
                <div className={styles.budgetUsed}>{percantage} %</div>
                <ProgressBarWrapper width={75}>
                  <ProgressBarFill progress={percantage} />
                </ProgressBarWrapper>
              </div>
            </span>
          );
        },
        sortable: false,
      },
      // {
      //   name: 'Action',
      //   allowOverflow: true,
      //   button: true,
      //   cell: (row) => {
      //     console.log(row)
      //     return(
      //       <div className={generalStyles.actionMenuHolder}>
      //         <Dropdown collapsible className={generalStyles.actionMenu}>
      //           <Dropdown.Header>
      //             <Button.Action $style="white" $width={32} $height={32}>
      //               <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
      //             </Button.Action>
      //           </Dropdown.Header>
      //           <Dropdown.Body>
      //             <Menu className={generalStyles.actionMenuList}>
      //               <MenuItem onClick={() => setEditableGl(row)}>Edit</MenuItem>
      //             </Menu>
      //           </Dropdown.Body>
      //         </Dropdown>
      //       </div>
      //     )
      //   }
      // },
    ],
    [],
  );

  const editApprovedBudget = async (glData) => {
    setToast((item) => ({ ...item, opened: false }));
    const filteredGlItems = budget.glLineItems.filter((gl) => gl.id !== glData.id);
    const updatedGLItems = [...filteredGlItems, glData];
    const updatedBudget = {
      name: budget.name,
      currency: budget.currency,
      startDate: budget.startDate,
      endDate: budget.endDate,
      budgetType: budget.budgetType,
      budgetStatus: budget.budgetStatus,
      glLineItems: updatedGLItems,
    };
    budgetServiceService
      .editBudgetItem(updatedBudget, companyId, budgetId)
      .then((_response) => {
        navigate('/budgets/pending');
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
      });
  };

  return (
    <>
      <DataTableBase
        data={budget?.glLineItems}
        columns={columns}
        expandableRows
        expandOnRowClicked
        expandableRowExpanded={(row) => row.id === editableGl?.id}
        expandableRowsComponent={editableGl ? EditApprovedGl : ExpandedComponent}
        expandableRowsComponentProps={editableGl && { setEditableGl, setToast, editApprovedBudget }}
        onRowClicked={() => setEditableGl(null)}
      />
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} />: null}
    </>
  );
};

export default BudgetDetailsGlList;
