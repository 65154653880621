import React, {memo} from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import Text from "../../../UI/Typography/Text";

const Top = memo(({data}) => {
  return (
    <div className={styles.top}>
      <div className={styles.topCol}>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Requester</Text>
          <Text weight={500} type="body-1">{data?.requester?.name}</Text>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Request on behalf of</Text>
          <Text weight={500} type="body-1">{data?.requestOnBehalfOf?.name ?? "None"}</Text>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Date Required</Text>
          <Text weight={500} type="body-1">{data?.deliveryDate}</Text>
        </div>
      </div>
      <div className={styles.topCol}>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Budget</Text>
          <Text weight={500} type="body-1">{data?.budget?.name ?? "None"}</Text>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Branch</Text>
          <Text weight={500} type="body-1">{data?.branchName || data?.branch?.name}</Text>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Department</Text>
          <Text weight={500} type="body-1">{data?.department?.name}</Text>
        </div>
      </div>
      <div className={styles.topCol}>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Vendor</Text>
          <Text weight={500} type="body-1">{data?.vendor?.legalName}</Text>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Vendor Currency</Text>
          <Text weight={500} type="body-1">{data?.currency?.code}</Text>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-1">Rate Of Exchange</Text>
          <Text weight={500} type="body-1">{data?.currency?.rate}</Text>
        </div>
      </div>
    </div>
  );
});

export default Top;
