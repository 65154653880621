import styles from '../../styles/accountBillings.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import { useForm } from 'react-hook-form';
import { useStore } from '../../store/store.js';
import { Link } from 'react-router-dom';
import ContactConfig from '../../components/Admins/AccountBillings/ContactConfig.jsx';
import { useEffect, useState } from 'react';
import requestsService from '../../services/requestsService.service.js';
import spmsServiceService from '../../services/spmsService.service.js';

const AccountCompanyInfo = () => {
  const company = useStore((state) => state.company);
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [companyLogo, setCompanyLogo] = useState(null);

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
  });
  useEffect(() => {
    if (company.logo) {
      spmsServiceService.getAttachmentsByKey(company.logo.key).then((res) => {
        console.log(res);
        setCompanyLogo(res.data.data);
      });
    }
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const allUsers = await requestsService.getData(100, 0, {
          isEnabled: true,
          companyId: company.id,
        });
        setUsers(allUsers.data.data.content);
        const allUserOptions = allUsers.data.data.content.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }));
        setUserOptions(allUserOptions);
        const companyContacts = await spmsServiceService.getCompanyContacts(company.id);
        const contactsData = companyContacts.data.data;
        const { primaryContacts, technicalContacts, billingContacts } = contactsData;
        const setContacts = (contacts) => {
          return contacts.map((contact) =>
            allUserOptions.find((user) => user.value === contact.userId),
          );
        };
        setValue('primary', setContacts(primaryContacts));
        setValue('billing', setContacts(technicalContacts));
        setValue('technical', setContacts(billingContacts));
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  const updateContacts = (data) => {
    const { billing, primary, technical } = data;
    const formatContact = (contact) => {
      const reqUser = users.find((user) => user.id === contact.value);
      return {
        userId: reqUser.id,
        email: reqUser.email,
        name: `${reqUser.firstName} ${reqUser.lastName}`,
        phoneNumber: reqUser.phoneNumber,
        limitOfAuthority: null,
      };
    };
    const updatedContacts = {
      billingContacts: billing.map(formatContact),
      primaryContacts: primary.map(formatContact),
      technicalContacts: technical.map(formatContact),
    };
    spmsServiceService.updateCompanyContacts(company.id, updatedContacts).then((res) => {
      console.log(res.data.data);
    });
  };

  return (
    <>
      <div className={styles.companyGeneral}>
        <div className={styles.companyInfo}>
          <div className={styles.companyLogo}>
            <img src={companyLogo} alt="" />
          </div>
          <div className={styles.companyData}>
            <div className={styles.dataItem}>
              <Text type="h5">Company name</Text>
              <Text type="h5" weight={500}>
                {company.name}
              </Text>
            </div>
            <div className={styles.dataItem}>
              <Text type="h5">Primary Company Address</Text>
              <Text type="h5" weight={500}>
                {company.country}
              </Text>
            </div>
            <div className={styles.dataItem}>
              <Text type="h5">VAT Number</Text>
              <Text type="h5" weight={500}>
                {company.vatNumber ?? '--'}
              </Text>
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <Link to="/company-setup">
            <Button.Main $primary $style="pink">
              Edit
            </Button.Main>
          </Link>
        </div>
      </div>
      <div className={styles.contactInfo}>
        <div className={styles.contactTitle}>
          <Text type="h3" weight={600}>
            Contact Information
          </Text>
        </div>
        <div className={generalStyles.fieldsThree}>
          <ContactConfig
            name="primary"
            control={control}
            errors={errors}
            title={'Primary Account Contact'}
            description={'The user will receive all important account notifications.'}
            options={userOptions}
          />
          <ContactConfig
            name="billing"
            control={control}
            errors={errors}
            title={'Billing Contact'}
            description={
              'Send copies of invoices, receipts, orders and other renewal notifications to these users.'
            }
            options={userOptions}
          />
          <ContactConfig
            name="technical"
            control={control}
            errors={errors}
            title={'Technical Contact'}
            description={'User will receive all technical related queries.'}
            options={userOptions}
          />
        </div>
        <div className={generalStyles.pageButtons}>
          <Button.Main
            onClick={handleSubmit(updateContacts)}
            $primary
            $style="pink"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            Save
          </Button.Main>
        </div>
      </div>
    </>
  );
};

export default AccountCompanyInfo;
