import React, {memo} from 'react';
import styles from "../../../../styles/purchaseOrders.module.css";
import Text from "../../../UI/Typography/Text";
import {nf} from "../../../../utils/nf";
import Tooltip from "../../../UI/General/Tooltip";

const Summary = memo((props) => {
  return (
    <div className={styles.summary}>
      {(props.subtotal !== undefined && typeof props.subtotal === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600}>Subtotal:</Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.subtotal)}</Text>
        </div>
      )}
      {(props.taxTotal !== undefined  && typeof props.taxTotal === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600} className={styles.summaryTitle}>Tax Total:</Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.taxTotal)}</Text>
        </div>
      )}
      {(props.totalCost !== undefined && typeof props.totalCost === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600}>Total:</Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.totalCost)}</Text>
        </div>
      )}
      {(props.totalInvoicedAmount !== undefined && typeof props.totalInvoicedAmount === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600}>Invoice Total:</Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.totalInvoicedAmount)}</Text>
        </div>
      )}
      {(props.variance !== undefined && typeof props.variance === "number" && props?.variance > 0) && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600} className={styles.summaryTitle}>Variance:<Tooltip text="Difference between approved PO and approved Invoice amount"/></Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.variance)}</Text>
        </div>
      )}
      {(props.totalBudgetCost !== undefined && typeof props.totalBudgetCost === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600}>Budget estimate:</Text>
          <Text type="body-1" weight={500}>{props?.currencyBudget} {nf.format(props.totalBudgetCost)}</Text>
        </div>
      )}
      {(props.amountPaid !== undefined && typeof props.amountPaid === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600}>Amount Paid:</Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.amountPaid)}</Text>
        </div>
      )}
      {(props.outstandingAmount !== undefined && typeof props.outstandingAmount === "number") && (
        <div className={styles.summaryItem}>
          <Text type="h5" weight={600}>Outstanding Amount:</Text>
          <Text type="body-1" weight={500}>{props?.currency} {nf.format(props.outstandingAmount)}</Text>
        </div>
      )}
    </div>
  );
});

export default Summary;
