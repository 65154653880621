import { useState, useMemo, useEffect, Fragment } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { ProgressBarWrapper, ProgressBarFill } from '../../components/UI/General/ProgressBar';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import DataTableBase from '../../components/UI/General/DataTableBase';

import ActionModal from './ActionModal';
import budgetServiceService from '../../services/budgetService.service';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';
import moment from 'moment';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import Icon from '../../components/UI/General/Icon';
import Toast from '../../components/UI/General/Toast';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import AddButton from '../../components/shared/AddButton';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import spmsServiceService from '../../services/spmsService.service';
import Modal from '../../components/UI/Modal/Modal';

const Budgets = () => {
  const { id: companyId } = useStore((state) => state.activeCompany);
  const [isBusy, setIsBusy] = useState(false);
  const [allBudgets, setAllBudgets] = useState([]);
  const user = useStore((state) => state.user);
  const { tab } = useParams();
  const [handledBudget, setHandledBudget] = useState({
    type: 'Recall',
    budget: { id: null, name: null },
  });
  const [showModal, setShowModal] = useState(false);
  const currentSubscription = useSubscriptionAllowed();
  const navigate = useNavigate();
  const accessBudgets = useAccessAllowed('Budgets');
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const fetchData = async () => {
    setIsBusy(true);
    setAllBudgets([]);
    try {
      const response = await budgetServiceService.getAllBudgetData(companyId);
      const bgtData = response?.data?.data;
      if (bgtData) {
        let tabFilterData = [];
        switch (tab) {
          case 'approved':
            tabFilterData = bgtData.filter((item) => item.budgetStatus === 'ACTIVE' && item.active);
            break;
          case 'draft':
            tabFilterData = bgtData.filter((item) => item.budgetStatus === 'DRAFT' && item.active);
            break;
          case 'archived':
            tabFilterData = bgtData.filter((item) => item.budgetStatus === 'ARCHIVED');
            break;
          case 'rejected':
            tabFilterData = bgtData.filter((item) => item.budgetStatus === 'REJECTED');
            break;
          case 'pending':
            tabFilterData = bgtData.filter(
              (item) =>
                (item.budgetStatus === 'PENDING' || item.budgetStatus === 'PENDING_APPROVAL') &&
                item.active,
            );
            break;
          default:
        }
        const filteredBudgets = !accessBudgets?.viewAll
          ? tabFilterData.filter((data) => data.createdBy.userId === user.id)
          : tabFilterData;
        setAllBudgets(filteredBudgets);
      }
    } catch (error) {
      setIsBusy(false);
    } finally {
      setIsBusy(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [companyId, accessBudgets, tab]);

  const toggleArchiveActive = async (row, msg) => {
    setToast((item) => ({ ...item, opened: false }));
    if (msg === 'archive' && !accessBudgets.delete) {
      setToast({
        opened: true,
        message: 'No permission to archive',
        type: 'fail',
      });
      return;
    }
    if (currentSubscription?.plan?.name === 'Standard' && msg === 'activate') {
      const metricsData = await spmsServiceService.getMetrics(companyId);
      const metrics = metricsData.data.data;
      if (
        (row.budgetType === 'ANNUAL_BUDGET' &&
          metrics.approvedAnnualBudgets >= currentSubscription?.plan?.maxAnnualBudgets) ||
        (row.budgetType === 'PROJECT_BUDGET' &&
          metrics.approvedProjectBudgets >= currentSubscription?.plan?.maxProjectBudgets)
      ) {
        setShowModal(true);
        return;
      }
    }
    const selectedBudget = [row.id];
    const data = {};
    budgetServiceService
      .archiveBudgets(data, companyId, selectedBudget)
      .then((_response) => {
        setToast({
          opened: true,
          message: `The budget has been ${msg}d`,
          type: 'success',
        });
        fetchData();
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
      });
  };

  const createExportData = async (budgetId) => {
    const response = await budgetServiceService.getBudgetById(budgetId, companyId);
    const budgetData = response.data.data;
    const budgetObject = {
      'Budget Name': budgetData.name,
      Currency: budgetData.currency,
      Start: moment(budgetData?.startDate).format('DD/MM/YYYY'),
      End: moment(budgetData?.endDate).format('DD/MM/YYYY'),
      Type: readableTitleFromBackend(budgetData.budgetType),
      Status: readableTitleFromBackend(budgetData.budgetStatus),
    };
    let csvContent = Object.entries(budgetObject)
      .map(([key, value]) => `${key};${value}`)
      .join('\n');
    const glData = budgetData.glLineItems;
    const glDataObject = glData.map((gl) => {
      const allMonth = moment.months();
      const requiredGlData = {
        'GL Code': gl.glAccountCode,
        'GL Description': gl.glAccountDescription,
        Department: gl.departmentName,
        Branch: gl.branchName,
        'Budget Allocated': gl.glBudgetValue,
        'Budget Used': gl.spentAmount,
      };
      allMonth.forEach((month) => {
        if (gl[month.toLowerCase()]) requiredGlData[month] = gl[month.toLowerCase()];
      });
      return requiredGlData;
    });
    const columnDelimiter = ';';
    const lineDelimiter = '\n';
    const keys = Object.keys(glDataObject[0]);
    let glDataCSV = '';
    glDataCSV += keys.join(columnDelimiter);
    glDataCSV += lineDelimiter;
    glDataObject.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) glDataCSV += columnDelimiter;
        glDataCSV += item[key];
        ctr++;
      });
      glDataCSV += lineDelimiter;
    });
    csvContent = csvContent + '\n\n' + glDataCSV;
    const filename = `${budgetData.name}.csv`;
    const link = document.createElement('a');
    if (!csvContent.match(/^data:text\/csv/i)) {
      csvContent = `data:text/csv;charset=utf-8,${csvContent}`;
    }
    link.setAttribute('href', encodeURI(csvContent));
    link.setAttribute('download', filename);
    link.click();
  };

  const columns = useMemo(
    () => [
      {
        name: 'Budget Name',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => {
          const status = row?.budgetStatus;
          const path =
            status === 'ACTIVE' || status === 'ARCHIVED'
              ? `/budgets/${companyId}/details/${row?.id}`
              : status === 'PENDING' || status === 'PENDING_APPROVAL'
              ? `/budgets/${companyId}/pending-approval/${row?.id}`
              : status === 'DRAFT' || status === 'ARCHIVED' || status === 'REJECTED'
              ? `/budgets/${companyId}/edit/${row?.id}`
              : `/budgets/${companyId}/details/${row?.id}`;

          return (
            <span data-content={'Budget Name'} className={generalStyles.tableValue}>
              {accessBudgets?.viewOwn ? (
                <Link to={path} className={styles.tableLink} state={{ row }}>
                  <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>{row.name}</span>
                </Link>
              ) : (
                <span>{row.name}</span>
              )}
            </span>
          );
        },
      },
      {
        name: 'Start Date',
        sortable: true,
        selector: (row) => row.startDate,
        cell: (row) => (
          <span data-content={'Start Date'} className={generalStyles.tableValue}>
            <span>{moment(row?.startDate).format('DD/MM/YYYY')}</span>
          </span>
        ),
        wrap: true,
      },
      {
        name: 'End Date',
        sortable: true,
        selector: (row) => row.endDate,
        cell: (row) => (
          <span data-content={'End Date'} className={generalStyles.tableValue}>
            <span>{moment(row?.endDate).format('DD/MM/YYYY')}</span>
          </span>
        ),
      },
      {
        name: 'Budget Type',
        sortable: true,
        selector: (row) => row.budgetType,
        cell: (row) => {
          const text = row?.budgetType?.split('_')[0]?.toLowerCase();
          return (
            <span data-content={'End Date'} className={generalStyles.tableValue}>
              <span>{text?.charAt(0)?.toUpperCase() + text?.slice(1)}</span>
            </span>
          );
        },
      },
      {
        name: 'Total Allocated',
        sortable: true,
        selector: (row) => row.budgetValue,
        cell: (row) => (
          <span data-content={'Total Allocated'} className={generalStyles.tableValue}>
            <NumericFormat
              value={row?.budgetValue}
              displayType="text"
              thousandSeparator=","
              prefix={`${row?.currency} `}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        name: tab === 'pending' ? 'Pending Approvers' : 'Used',
        cell: (row) =>
          tab === 'pending' ? (
            <span data-content={'Pending Approvers'} className={generalStyles.tableValue}>
              <span>
                {row.approval?.approvers
                  ?.filter((approver) => approver.status === 'PENDING_APPROVAL')
                  .map((approver) => approver.approverName)
                  .join(', ')}
              </span>
            </span>
          ) : (
            <span data-content={'Used'} className={generalStyles.tableValue}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  rowGap: '2px',
                }}
              >
                <span>
                  {`${
                    row?.totalPOApprovedAmount
                      ? ((row?.totalPOApprovedAmount / row.budgetValue) * 100).toFixed(0)
                      : 0
                  } %`}
                </span>
                <ProgressBarWrapper width={100}>
                  <ProgressBarFill
                    progress={(
                      (row?.totalPOApprovedAmount
                        ? ((row?.totalPOApprovedAmount / row.budgetValue) * 100).toFixed(0)
                        : 0 / row.budgetValue) * 100
                    ).toFixed(0)}
                  />
                </ProgressBarWrapper>
              </div>
            </span>
          ),
        sortable: true,
      },
      {
        name: 'Action',
        allowOverflow: true,
        omit: !accessBudgets?.create,
        button: true,
        cell: (row) => {
          const status = row?.budgetStatus;
          let hideButton = false;
          const approval = row?.approval;
          if (status === 'PENDING_APPROVAL' && approval && Array.isArray(approval.approvers)) {
            hideButton = approval.approvers.find((approver) => approver.status === 'APPROVED');
          }
          return hideButton ? null : (
            <div className={generalStyles.actionMenuHolder}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Action $style="white" $width={32} $height={32}>
                    <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                  </Button.Action>
                </Dropdown.Header>
                <Dropdown.Body>
                  <Menu className={generalStyles.actionMenuList}>
                    <MenuItem
                      onClick={() => {
                        createExportData(row.id);
                      }}
                    >
                      Export in CSV
                    </MenuItem>
                    {['DRAFT', 'REJECTED', 'ACTIVE'].includes(status) && (
                      <>
                        {accessBudgets?.edit && (
                          <Link to={`/budgets/${companyId}/edit/${row.id}`}>
                            <MenuItem>Edit</MenuItem>
                          </Link>
                        )}
                      </>
                    )}
                    {status === 'DRAFT' && (
                      <MenuItem
                        onClick={() => {
                          setHandledBudget({
                            type: 'Discard',
                            budget: { id: row.id, name: row.name },
                          });
                        }}
                      >
                        Discard
                      </MenuItem>
                    )}
                    {status === 'ACTIVE' && (
                      <MenuItem onClick={() => toggleArchiveActive(row, 'archive')}>
                        Archive
                      </MenuItem>
                    )}
                    {status === 'ARCHIVED' && (
                      <MenuItem
                        onClick={() => {
                          toggleArchiveActive(row, 'activate');
                        }}
                      >
                        Activate
                      </MenuItem>
                    )}
                    {status === 'PENDING_APPROVAL' && (
                      <MenuItem
                        onClick={() => {
                          setHandledBudget({
                            type: 'Recall',
                            budget: { id: row.id, name: row.name },
                          });
                        }}
                      >
                        Recall
                      </MenuItem>
                    )}
                  </Menu>
                </Dropdown.Body>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [tab, accessBudgets, allBudgets],
  );

  return (
    <>
      <Text type="h2" weight={600}>
        Budget Overview
      </Text>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.tabSection}>
          <TabsSlider>
            <NavLink
              to="/budgets/draft"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Draft
            </NavLink>
            <NavLink
              to="/budgets/pending"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Pending Approval
            </NavLink>
            <NavLink
              to="/budgets/approved"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Approved
            </NavLink>
            <NavLink
              to="/budgets/archived"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Archived
            </NavLink>
            <NavLink
              to="/budgets/rejected"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Rejected
            </NavLink>
          </TabsSlider>

          {accessBudgets?.create && (
            <AddButton $click={() => navigate(`/budgets/${companyId}/create`)} />
          )}
        </div>
        <DataTableBase
          data={allBudgets || []}
          columns={columns}
          expandOnRowClicked
          progressPending={isBusy && accessBudgets}
          paginationResetDefaultPage={false}
        />
        <ActionModal
          setHandledBudget={setHandledBudget}
          companyId={companyId}
          handledBudget={handledBudget}
          setToast={setToast}
          fetchData={fetchData}
        />
      </Box>
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>
            {` The Standard Subscription Plan only allows ${currentSubscription?.plan?.maxAnnualBudgets}
            annual and ${currentSubscription?.plan?.maxProjectBudgets} project budgets.`}
          </Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => navigate('/accounts-billing/subscription')}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default Budgets;
