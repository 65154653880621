import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router';
import styles from '../../../../styles/approvalWorkflow.module.css';
import Dropdown from '../../../UI/General/Dropdown';
import { Menu, MenuItem } from '../../../UI/General/Menu';
import * as Button from '../../../UI/Forms/Button.jsx';
import Icon from '../../../UI/General/Icon';
import generalStyles from '../../../../styles/general.module.css';

const RuleRow = ({
  index,
  moveRule,
  rule,
  companyId,
  isActiveTab,
  currentModuleId,
  type,
  archiveRule,
  activateRule,
}) => {
  const navigate = useNavigate();
  const dropRef = useRef(null);
  const dragRef = useRef(null);
  const [, drop] = useDrop({
    accept: 'item',
    drop(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const difference = Math.sign(hoverClientY - hoverMiddleY);
      moveRule(dragIndex, hoverIndex, difference);
      item.index = hoverIndex;
    },
  });
  const [, drag] = useDrag({
    type: 'item',
    item: () => {
      return { id: rule.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const convertLastUpdated = (dateString) => {
    const handleQuantity = (number) => (number > 1 ? 's' : '');
    const date = new Date(dateString);
    const currentDate = new Date();
    const timePassed = currentDate - date;
    const seconds = Math.floor(timePassed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years) return `${years} year${handleQuantity(years)} ago`;
    else if (months) return `${months} month${handleQuantity(months)} ago`;
    else if (days) return `${days} day${handleQuantity(days)} ago`;
    else if (hours) return `${hours} hour${handleQuantity(hours)} ago`;
    else if (minutes) return `${minutes} minute${handleQuantity(minutes)} ago`;
    else return 'just now';
  };

  const editRule = (ruleId) => {
    navigate(`/approval/${companyId}/edit-rule/${ruleId}`, {
      state: { moduleId: currentModuleId },
    });
  };

  drop(dropRef);
  drag(dragRef);

  return (
    <>
      <div ref={dropRef} className={styles.tableRow}>
        <div>
          {isActiveTab && (
            <button className={styles.dragButton} ref={dragRef}>
              <Icon $width={20} $height={20} $icon="drag" $color="white" />
            </button>
          )}
        </div>
        <div>{rule.name}</div>
        <div>{rule.description}</div>
        <div>{type}</div>
        <div>{convertLastUpdated(rule.lastUpdatedAt)}</div>
        <Dropdown collapsible className={generalStyles.actionMenu}>
          <Dropdown.Header>
            <Button.Action $style="white" $width={32} $height={32}>
              <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
            </Button.Action>
          </Dropdown.Header>
          <Dropdown.Body>
            <Menu className={generalStyles.actionMenuList}>
              {isActiveTab ? (
                <>
                  <MenuItem onClick={() => editRule(rule.id)}>Edit</MenuItem>
                  <MenuItem onClick={() => archiveRule(rule.id)}>Archive</MenuItem>
                </>
              ) : (
                <MenuItem onClick={() => activateRule(rule.id)}>Activate</MenuItem>
              )}
            </Menu>
          </Dropdown.Body>
        </Dropdown>
      </div>
    </>
  );
};

export default RuleRow;
