import React, {memo} from 'react';
import Text from "../../../UI/Typography/Text";
import styles from "../../../../styles/purchaseOrders.module.css";
import Status from "../../../UI/Typography/Status";

const StatusBar = memo(({data}) => {
  return (
    <div className={styles.statusBar}>
      <div className={styles.statusBarRow}>
        <Text type={"body-2"} weight={500} className={styles.statusBarLabel}>Receiving:&nbsp;</Text>
        <Text type={"body-2"} weight={500}><Status value={data?.fulfillmentStatus}/></Text>
      </div>
      <div className={styles.statusBarRow}>
        <Text type={"body-2"} weight={500} className={styles.statusBarLabel}>Invoices:&nbsp;</Text>
        <Text type={"body-2"} weight={500}><Status value={data?.invoiceStatus}/></Text>
      </div>
      <div className={styles.statusBarRow}>
        <Text type={"body-2"} weight={500} className={styles.statusBarLabel}>Payments:&nbsp;</Text>
        <Text type={"body-2"} weight={500}><Status value={data?.paymentStatus}/></Text>
      </div>
    </div>
  );
});

export default StatusBar;
