import React, {useState} from 'react';
import Text from "../../components/UI/Typography/Text";
import ProfileAccount from "../../components/Admins/Profile/ProfileAccount";
import ProfilePassword from "../../components/Admins/Profile/ProfilePassword";
import ProfileOffice from "../../components/Admins/Profile/ProfileOffice";
import TabsSlider from "../../components/UI/General/TabsSlider";
import {NavLink, useParams} from "react-router-dom";

const Profile = () => {
  const { tab } = useParams();
  return (
    <>
      <Text type="h2" weight={600}>My Profile</Text>
      <TabsSlider>
        <NavLink
          to="/users/profile/account"
          className={({ isActive }) => (isActive ? 'active' : '')}
          $islink="true"
        >
          Account
        </NavLink>
        <NavLink
          to="/users/profile/password"
          className={({ isActive }) => (isActive ? 'active' : '')}
          $islink="true"
        >
          Change Password
        </NavLink>
        <NavLink
          to="/users/profile/office"
          className={({ isActive }) => (isActive ? 'active' : '')}
          $islink="true"
        >
          Out of Office
        </NavLink>
      </TabsSlider>
      {tab === "account" && <ProfileAccount/>}
      {tab === "password" && <ProfilePassword/>}
      {tab === "office" && <ProfileOffice/>}
    </>
  );
};

export default Profile;
