import React from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {useStore} from "../../store/store";
import spmsServiceService from "../../services/spmsService.service";
import styles from "../../styles/requisition.module.css";
import * as Button from "../UI/Forms/Button";
import Icon from "../UI/General/Icon";
import Text from "../UI/Typography/Text";
import {useNavigate} from "react-router-dom";

const SelectVendors = (props) => {
  const activeCompany = useStore((state) => state.activeCompany);
  const navigate = useNavigate();

  const fetchVendors = async (search, loadedOptions, { page }) => {
    const result = await spmsServiceService.getVendors(activeCompany?.id,{vendorName: search, vendorStatus: "APPROVED", active: true}, page, 10, ).catch((err) => {
      console.log(err);
      return undefined
    })
    const finalData = result?.data?.data?.content?.map((vendor) => ({
      label: vendor.vendor.legalName,
      value: vendor.vendor.id,
      currency: vendor.currency,
    }))
    const hasMore = finalData !== undefined && result.data.data.totalElements > 0 ? loadedOptions.length < result.data.data.totalElements : false;
    return {
      options: finalData || [],
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const NoOptionsMessage = () => (
    <div className={styles.noOptionRedirect}>
      <Button.ActionLabeled onClick={() => navigate('/vendors/create')}>
        <Button.Action $style="pink" $variant="circle" $width={20} $height={20} type="button">
          <Icon $width={18} $height={18} $icon="plus" $color="white" />
        </Button.Action>
        <Text weight={700} type="subtitle">Add Vendor</Text>
      </Button.ActionLabeled>
    </div>
  );

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchVendors}
      classNamePrefix="react-select"
      isSearchable={true}
      placeholder="Select Vendor"
      components={{NoOptionsMessage: NoOptionsMessage}}
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectVendors;
