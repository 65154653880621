import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import Icon from "../General/Icon";
import Label from "./Label";
import Tooltip from "../General/Tooltip";

const InputIconWrapper = styled.div`
	position: relative;
	& input {
		padding-right: 60px;
	}
	& svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
	}
`;

const StyledInput = styled.input`
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	padding: 4px 10px;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	font-size: ${({$small}) => ($small ? "12px" : "14px")};
	line-height: 1.4em;
	color: #0c0c0c;
	border: 1px solid #e4e2e2;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	outline: none;
	&:disabled {
		background-color: #f4f4f4;
		border: 1px solid #e2e3e4;
	}
	&::placeholder {
		color: #8a8b9d;
	}
	&:focus,
	&:focus-visible {
		border-color: #595858;
	}
	&.success {
		border-color: #69d08f;
	}
	&.error {
		border-color: #cd3e27;
	}
	@media(min-width: 768px)  {
		height: ${({$small}) => ($small ? "40px" : "50px")};
		padding: ${({$small}) => ($small ? "4px 10px" : "4px 20px")};
		font-size: ${({$small}) => ($small ? "12px" : "16px")};
  }
`;

const StyledInputCode = styled(StyledInput)`
	width: 50px;
	height: 50px;
	padding: 8px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid #e2e3e4;
	text-align: center;
`;

const Input = forwardRef((props, ref) => {
	return (
		<>
			{props.$label && props.$label !== "" ? (
				<Label htmlFor={props.id} $title={props.$label} $isRequired={props.$labelRequired} $tooltipText={props.$tooltip} />
			) : null}
			{props.$iconName && props.$iconName !== "" ? (
				<InputIconWrapper>
					<StyledInput {...props} ref={ref} />
					<Icon
						$color={props.$iconColor || "#8A8B9D"}
						$height={20}
						$width={20}
						$icon={props.$iconName}
					/>
				</InputIconWrapper>
			) : (
				<StyledInput {...props} ref={ref} />
			)}
		</>
	);
});

export const InputCode = forwardRef((props, ref) => {
	return <StyledInputCode {...props} ref={ref} />;
});

export const Password = forwardRef((props, ref) => {
	const [show, setShow] = useState(false);
	return (
		<>
			{props.$label && props.$label !== "" ? (
				<Label htmlFor={props.id} $title={props.$label} $isRequired={props.$labelRequired} $tooltipText={props.$tooltip} />
			) : null}
			<InputIconWrapper>
				<StyledInput
					type={show === true ? "text" : "password"}
					{...props}
					ref={ref}
				/>
				<Icon
					$color={props.$iconColor || "#8A8B9D"}
					$height={20}
					$width={20}
					$icon={show === true ? "eye-crossed" : "eye"}
					style={{ cursor: "pointer" }}
					onClick={() => setShow(!show)}
				/>
			</InputIconWrapper>
		</>
	);
});

export default Input;
