import React, {useEffect, useState} from "react";
import styles from "../styles/home.module.css"
import * as Button from "../components/UI/Forms/Button";
import {Link} from "react-router-dom"
import {Controller, useForm} from "react-hook-form";
import Input from "../components/UI/Forms/Input";
import Textarea from "../components/UI/Forms/Textarea";
import Toast from "../components/UI/General/Toast";
import Select from "react-select";
import s2pmsEmail from "../services/s2pmsEmail.service";
import GoogleReCaptcha from "../components/UI/Forms/GoogleReCaptcha";

const countries = [
  {
    "label": "Afghanistan",
    "value": "Afghanistan"
  },
  {
    "label": "Albania",
    "value": "Albania"
  },
  {
    "label": "Algeria",
    "value": "Algeria"
  },
  {
    "label": "Andorra",
    "value": "Andorra"
  },
  {
    "label": "Angola",
    "value": "Angola"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "Antigua and Barbuda"
  },
  {
    "label": "Argentina",
    "value": "Argentina"
  },
  {
    "label": "Armenia",
    "value": "Armenia"
  },
  {
    "label": "Australia",
    "value": "Australia"
  },
  {
    "label": "Austria",
    "value": "Austria"
  },
  {
    "label": "Azerbaijan",
    "value": "Azerbaijan"
  },
  {
    "label": "Bahamas",
    "value": "Bahamas"
  },
  {
    "label": "Bahrain",
    "value": "Bahrain"
  },
  {
    "label": "Bangladesh",
    "value": "Bangladesh"
  },
  {
    "label": "Barbados",
    "value": "Barbados"
  },
  {
    "label": "Belarus",
    "value": "Belarus"
  },
  {
    "label": "Belgium",
    "value": "Belgium"
  },
  {
    "label": "Belize",
    "value": "Belize"
  },
  {
    "label": "Benin",
    "value": "Benin"
  },
  {
    "label": "Bhutan",
    "value": "Bhutan"
  },
  {
    "label": "Bolivia",
    "value": "Bolivia"
  },
  {
    "label": "Bosnia and Herzegovina",
    "value": "Bosnia and Herzegovina"
  },
  {
    "label": "Botswana",
    "value": "Botswana"
  },
  {
    "label": "Brazil",
    "value": "Brazil"
  },
  {
    "label": "Brunei",
    "value": "Brunei"
  },
  {
    "label": "Bulgaria",
    "value": "Bulgaria"
  },
  {
    "label": "Burkina Faso",
    "value": "Burkina Faso"
  },
  {
    "label": "Burundi",
    "value": "Burundi"
  },
  {
    "label": "Cambodia",
    "value": "Cambodia"
  },
  {
    "label": "Cameroon",
    "value": "Cameroon"
  },
  {
    "label": "Canada",
    "value": "Canada"
  },
  {
    "label": "Cape Verde",
    "value": "Cape Verde"
  },
  {
    "label": "Central African Republic",
    "value": "Central African Republic"
  },
  {
    "label": "Chad",
    "value": "Chad"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "China",
    "value": "China"
  },
  {
    "label": "Colombia",
    "value": "Colombia"
  },
  {
    "label": "Comoros",
    "value": "Comoros"
  },
  {
    "label": "Congo Brazzaville",
    "value": "Congo Brazzaville"
  },
  {
    "label": "Congo Democratic Republic of the",
    "value": "Congo Democratic Republic of the"
  },
  {
    "label": "Cook Islands",
    "value": "Cook Islands"
  },
  {
    "label": "Costa Rica",
    "value": "Costa Rica"
  },
  {
    "label": "Croatia",
    "value": "Croatia"
  },
  {
    "label": "Cuba",
    "value": "Cuba"
  },
  {
    "label": "Cyprus",
    "value": "Cyprus"
  },
  {
    "label": "Czech Republic",
    "value": "Czech Republic"
  },
  {
    "label": "Denmark",
    "value": "Denmark"
  },
  {
    "label": "Djibouti",
    "value": "Djibouti"
  },
  {
    "label": "Dominica",
    "value": "Dominica"
  },
  {
    "label": "Dominican Republic",
    "value": "Dominican Republic"
  },
  {
    "label": "East Timor",
    "value": "East Timor"
  },
  {
    "label": "Ecuador",
    "value": "Ecuador"
  },
  {
    "label": "Egypt",
    "value": "Egypt"
  },
  {
    "label": "El Salvador",
    "value": "El Salvador"
  },
  {
    "label": "Equatorial Guinea",
    "value": "Equatorial Guinea"
  },
  {
    "label": "Eritrea",
    "value": "Eritrea"
  },
  {
    "label": "Estonia",
    "value": "Estonia"
  },
  {
    "label": "Eswatini",
    "value": "Eswatini"
  },
  {
    "label": "Ethiopia",
    "value": "Ethiopia"
  },
  {
    "label": "Federated States of Micronesia",
    "value": "Federated States of Micronesia"
  },
  {
    "label": "Fiji",
    "value": "Fiji"
  },
  {
    "label": "Finland",
    "value": "Finland"
  },
  {
    "label": "France",
    "value": "France"
  },
  {
    "label": "Gabon",
    "value": "Gabon"
  },
  {
    "label": "Gambia",
    "value": "Gambia"
  },
  {
    "label": "Georgia",
    "value": "Georgia"
  },
  {
    "label": "Germany",
    "value": "Germany"
  },
  {
    "label": "Ghana",
    "value": "Ghana"
  },
  {
    "label": "Greece",
    "value": "Greece"
  },
  {
    "label": "Grenada",
    "value": "Grenada"
  },
  {
    "label": "Guatemala",
    "value": "Guatemala"
  },
  {
    "label": "Guinea",
    "value": "Guinea"
  },
  {
    "label": "Guinea-Bissau",
    "value": "Guinea-Bissau"
  },
  {
    "label": "Guyana",
    "value": "Guyana"
  },
  {
    "label": "Haiti",
    "value": "Haiti"
  },
  {
    "label": "Honduras",
    "value": "Honduras"
  },
  {
    "label": "Hungary",
    "value": "Hungary"
  },
  {
    "label": "Iceland",
    "value": "Iceland"
  },
  {
    "label": "India",
    "value": "India"
  },
  {
    "label": "Indonesia",
    "value": "Indonesia"
  },
  {
    "label": "Iran",
    "value": "Iran"
  },
  {
    "label": "Iraq",
    "value": "Iraq"
  },
  {
    "label": "Ireland",
    "value": "Ireland"
  },
  {
    "label": "Israel",
    "value": "Israel"
  },
  {
    "label": "Italy",
    "value": "Italy"
  },
  {
    "label": "Jamaica",
    "value": "Jamaica"
  },
  {
    "label": "Japan",
    "value": "Japan"
  },
  {
    "label": "Jordan",
    "value": "Jordan"
  },
  {
    "label": "Kazakhstan",
    "value": "Kazakhstan"
  },
  {
    "label": "Kenya",
    "value": "Kenya"
  },
  {
    "label": "Kiribati",
    "value": "Kiribati"
  },
  {
    "label": "Kosovo",
    "value": "Kosovo"
  },
  {
    "label": "Kuwait",
    "value": "Kuwait"
  },
  {
    "label": "Kyrgyzstan",
    "value": "Kyrgyzstan"
  },
  {
    "label": "Laos",
    "value": "Laos"
  },
  {
    "label": "Latvia",
    "value": "Latvia"
  },
  {
    "label": "Lebanon",
    "value": "Lebanon"
  },
  {
    "label": "Lesotho",
    "value": "Lesotho"
  },
  {
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "label": "Libya",
    "value": "Libya"
  },
  {
    "label": "Liechtenstein",
    "value": "Liechtenstein"
  },
  {
    "label": "Lithuania",
    "value": "Lithuania"
  },
  {
    "label": "Luxembourg",
    "value": "Luxembourg"
  },
  {
    "label": "Macedonia",
    "value": "Macedonia"
  },
  {
    "label": "Madagascar",
    "value": "Madagascar"
  },
  {
    "label": "Malawi",
    "value": "Malawi"
  },
  {
    "label": "Malaysia",
    "value": "Malaysia"
  },
  {
    "label": "Maldives",
    "value": "Maldives"
  },
  {
    "label": "Mali",
    "value": "Mali"
  },
  {
    "label": "Malta",
    "value": "Malta"
  },
  {
    "label": "Marshall Islands",
    "value": "Marshall Islands"
  },
  {
    "label": "Mauritania",
    "value": "Mauritania"
  },
  {
    "label": "Mauritius",
    "value": "Mauritius"
  },
  {
    "label": "Mexico",
    "value": "Mexico"
  },
  {
    "label": "Moldova",
    "value": "Moldova"
  },
  {
    "label": "Monaco",
    "value": "Monaco"
  },
  {
    "label": "Mongolia",
    "value": "Mongolia"
  },
  {
    "label": "Montenegro",
    "value": "Montenegro"
  },
  {
    "label": "Morocco",
    "value": "Morocco"
  },
  {
    "label": "Mozambique",
    "value": "Mozambique"
  },
  {
    "label": "Myanmar Burma",
    "value": "Myanmar Burma"
  },
  {
    "label": "Namibia",
    "value": "Namibia"
  },
  {
    "label": "Nauru",
    "value": "Nauru"
  },
  {
    "label": "Nepal",
    "value": "Nepal"
  },
  {
    "label": "Netherlands",
    "value": "Netherlands"
  },
  {
    "label": "New Zealand",
    "value": "New Zealand"
  },
  {
    "label": "Nicaragua",
    "value": "Nicaragua"
  },
  {
    "label": "Niger",
    "value": "Niger"
  },
  {
    "label": "Nigeria",
    "value": "Nigeria"
  },
  {
    "label": "North Korea",
    "value": "North Korea"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "Northern Mariana Islands"
  },
  {
    "label": "Norway",
    "value": "Norway"
  },
  {
    "label": "Oman",
    "value": "Oman"
  },
  {
    "label": "Pakistan",
    "value": "Pakistan"
  },
  {
    "label": "Palau",
    "value": "Palau"
  },
  {
    "label": "Palestine",
    "value": "Palestine"
  },
  {
    "label": "Panama",
    "value": "Panama"
  },
  {
    "label": "Papua New Guinea",
    "value": "Papua New Guinea"
  },
  {
    "label": "Paraguay",
    "value": "Paraguay"
  },
  {
    "label": "Peru",
    "value": "Peru"
  },
  {
    "label": "Philippines",
    "value": "Philippines"
  },
  {
    "label": "Poland",
    "value": "Poland"
  },
  {
    "label": "Portugal",
    "value": "Portugal"
  },
  {
    "label": "Qatar",
    "value": "Qatar"
  },
  {
    "label": "Romania",
    "value": "Romania"
  },
  {
    "label": "Russia",
    "value": "Russia"
  },
  {
    "label": "Rwanda",
    "value": "Rwanda"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "Saint Kitts and Nevis"
  },
  {
    "label": "Saint Lucia",
    "value": "Saint Lucia"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "Saint Vincent and the Grenadines"
  },
  {
    "label": "Samoa",
    "value": "Samoa"
  },
  {
    "label": "San Marino",
    "value": "San Marino"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "Sao Tome and Principe"
  },
  {
    "label": "Saudi Arabia",
    "value": "Saudi Arabia"
  },
  {
    "label": "Scotland",
    "value": "Scotland"
  },
  {
    "label": "Senegal",
    "value": "Senegal"
  },
  {
    "label": "Serbia",
    "value": "Serbia"
  },
  {
    "label": "Seychelles",
    "value": "Seychelles"
  },
  {
    "label": "Sierra Leone",
    "value": "Sierra Leone"
  },
  {
    "label": "Singapore",
    "value": "Singapore"
  },
  {
    "label": "Slovakia",
    "value": "Slovakia"
  },
  {
    "label": "Slovenia",
    "value": "Slovenia"
  },
  {
    "label": "Solomon Islands",
    "value": "Solomon Islands"
  },
  {
    "label": "Somalia",
    "value": "Somalia"
  },
  {
    "label": "South Africa",
    "value": "South Africa"
  },
  {
    "label": "South Korea",
    "value": "South Korea"
  },
  {
    "label": "South Sudan",
    "value": "South Sudan"
  },
  {
    "label": "Spain",
    "value": "Spain"
  },
  {
    "label": "Sri Lanka",
    "value": "Sri Lanka"
  },
  {
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "label": "Suriname",
    "value": "Suriname"
  },
  {
    "label": "Sweden",
    "value": "Sweden"
  },
  {
    "label": "Switzerland",
    "value": "Switzerland"
  },
  {
    "label": "Syria",
    "value": "Syria"
  },
  {
    "label": "Taiwan",
    "value": "Taiwan"
  },
  {
    "label": "Tajikistan",
    "value": "Tajikistan"
  },
  {
    "label": "Tanzania",
    "value": "Tanzania"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "Togo",
    "value": "Togo"
  },
  {
    "label": "Tonga",
    "value": "Tonga"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "Trinidad and Tobago"
  },
  {
    "label": "Tunisia",
    "value": "Tunisia"
  },
  {
    "label": "Turkiye",
    "value": "Turkiye"
  },
  {
    "label": "Turkmenistan",
    "value": "Turkmenistan"
  },
  {
    "label": "Tuvalu",
    "value": "Tuvalu"
  },
  {
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "label": "Ukraine",
    "value": "Ukraine"
  },
  {
    "label": "United Arab Emirates",
    "value": "United Arab Emirates"
  },
  {
    "label": "United Kingdom",
    "value": "United Kingdom"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  },
  {
    "label": "Uzbekistan",
    "value": "Uzbekistan"
  },
  {
    "label": "Vanuatu",
    "value": "Vanuatu"
  },
  {
    "label": "Vatican City",
    "value": "Vatican City"
  },
  {
    "label": "Venezuela",
    "value": "Venezuela"
  },
  {
    "label": "Vietnam",
    "value": "Vietnam"
  },
  {
    "label": "Wales",
    "value": "Wales"
  },
  {
    "label": "Yemen",
    "value": "Yemen"
  },
  {
    "label": "Zambia",
    "value": "Zambia"
  },
  {
    "label": "Zimbabwe",
    "value": "Zimbabwe"
  },
  {
    "label": "United States of America",
    "value": "United States of America"
  }
]

function Home() {
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const {handleSubmit, control, reset, formState: {errors, isValid, isDirty, isSubmitSuccessful}} = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      country: "",
      message: ""
    }
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined
  });
  const searchByFirstCharacter = (option, inputValue) => option.label.toLowerCase().startsWith(inputValue.toLowerCase());

  const videoBtn = () => {
    console.log("Video")
  }

  const onSubmit = (data) => {
    if (!captchaResponse) return
    setToast((item) => ({...item, opened: false}));
    const newData = {
      user: {
        email: data.email,
        name: `${data.name} ${data.surname}`
      },
      country: data.country?.label,
      message: data.message
    }
    console.log(newData)
    s2pmsEmail.sendEmail(newData).then(r => {
      console.log(r)
      // recaptchaRef.current.reset();
      setToast({
        opened: true,
        message: "Your email has been sent successfully",
        type: "success",
      });
    })
  }

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.headerInner}>
            <label htmlFor="showMobileMenu" className={styles.mobileMenu}><span/><span/><span/></label>
            <div className={styles.headerLogo}>
              <a href="#hero"><img src="assets/img/home/logo.svg" alt="Poms"/></a>
            </div>
            <input type="checkbox" name="showMobileMenu" id="showMobileMenu"/>
            <ul className={styles.headerMenu}>
              <li><a href="#hero">Home</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
            <div className={styles.headerActions}>
              <Link to="/login">Login</Link>
              <Link to="/register"><Button.Main $primary $style="pink">Register</Button.Main></Link>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className={styles.hero} id="hero">
          <div className={styles.container}>
            <div className={styles.heroInner}>
              <div className={styles.heroInfo}>
                <h1 className={styles.heroTitle}>Simplify Your <br/> Purchasing Process with POMS - The All-in-One
                  Solution!</h1>
                <div className={styles.heroDescription}>Streamline your purchasing process with POMS – the all-in-one
                  solution for businesses of all sizes, featuring a user-friendly interface and sophisticated approval
                  matrix.
                </div>
                <Link to="/register"><Button.Main $primary $style="pink">Get started</Button.Main></Link>
              </div>
              <div className={styles.heroAction}>
                <img src="assets/img/home/hero-mobile.jpg" alt="heroBg"/>
                <div className={styles.heroBtn} onClick={videoBtn}>
                  <img src="assets/img/home/play.png" alt="Play"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.features} id="features">
          <div className={styles.container}>
            <h2 className={styles.h2 + " " + styles.title}>Features that help you run your business</h2>
            <div className={styles.featuresItems}>
              <div className={styles.featuresItem}>
                <div className={styles.featuresText}>
                  <h3 className={styles.h3}>Simple Wizard Driven Setup</h3>
                  <p className={styles.p}>From registration, business setup and approval workflows. We have simplified
                    every step to get you and your business operating in no time. Focus on your business, not the tools
                    that run it.</p>
                </div>
                <img src="assets/img/home/feature-1.png" alt="feature-1"/>
              </div>
              <div className={styles.featuresItem}>
                <div className={styles.featuresText}>
                  <h3 className={styles.h3}>Clean User Interface</h3>
                  <p className={styles.p}>We have made sure to keep the screen free from clutter. Showing what is
                    important and only asking what is necessary. In the instance we believe less is definitely more.</p>
                </div>
                <img src="assets/img/home/feature-2.png" alt="feature-2"/>
              </div>
              <div className={styles.featuresItem}>
                <div className={styles.featuresText}>
                  <h3 className={styles.h3}>Approval on the Go</h3>
                  <p className={styles.p}>In today’s business world, we need to operate from all the corners of the
                    globe, whether behind a desk or on the road. We have streamlined the system to ensure a seamless
                    mobile experience for approving those purchases that cannot wait. You can easily see if there is
                    budget available, check supporting documents and even confirm if the correct general ledger accounts
                    have been used. Keeping the guess work out of the way.</p>
                </div>
                <img src="assets/img/home/feature-3.png" alt="feature-3"/>
              </div>
              <div className={styles.featuresItem}>
                <div className={styles.featuresText}>
                  <h3 className={styles.h3}>Tools that matter</h3>
                  <p className={styles.p}>Whether it is budgeting, receipting, orders, approving invoices or tracking
                    payments, we have you covered. Complex approval process? We have you covered there too.</p>
                </div>
                <img src="assets/img/home/feature-4.png" alt="feature-4"/>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.pricing} id="pricing">
          <div className={styles.container}>
            <div className={styles.pricingCta}>
              <h2 className={styles.h2 + " " + styles.pricingH2}>Plans Designed for any kind of Procurement Goal.</h2>
              <Link to="/register">
                <Button.Main $primary $style="pink" type="button" className={styles.pricingCtaBtn}>Start Your Free
                  Trial</Button.Main>
              </Link>
              <p className={styles.pricingP}>No credit cards, No contracts, Cancel anytime.</p>
            </div>

            <div className={styles.pricingTable}>

              <div className={styles.pricingCol}>
                <div className={styles.pricingTableHead}>
                  <div className={styles.pricingTableHeadTop}>
                    <h3 className={styles.pricingTableHeadTitle}>Basic</h3>
                    <p className={styles.pricingTableHeadDescription}>Ideal for Small Businesses</p>
                  </div>
                  <div className={styles.pricingTableHeadContent}>
                    <div className={styles.pricingTableHeadPrice}>
                      <span>$10</span>
                      <span className={styles.pricingTableHeadPricePeriod}>/m</span>
                    </div>
                    <div className={styles.pricingTableHeadPriceDescription}>When Paid Monthly</div>
                  </div>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>1-5</strong> users</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>1</strong> Primary Legal Entities</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Subsidiaries</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Branches</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Departments</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> General Ledgers</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Standard</strong> Role Based Access</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Requisitions</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Purchase Orders</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Vendors</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Budget Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Standard</strong> RQ/PO Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Budget Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Vendor Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Approval on the go (Mobile)</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Standard</strong> Notification Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Reporting</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Integration</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Email Support - Standard working hours</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>No System Audit Log</span>
                </div>
              </div>

              <div className={styles.pricingCol}>
                <div className={styles.pricingTableHead + " " + styles.pricingTableHeadSpecial}>
                  <div className={styles.pricingTableHeadTop}>
                    <h3 className={styles.pricingTableHeadTitle}>Standard</h3>
                    <p className={styles.pricingTableHeadDescription}>Perfect for Growing Businesses</p>
                  </div>
                  <div className={styles.pricingTableHeadContent}>
                    <div className={styles.pricingTableHeadPrice}>
                      <span>$15</span>
                      <span className={styles.pricingTableHeadPricePeriod}>/m</span>
                    </div>
                    <div className={styles.pricingTableHeadPriceDescription}>When Paid Monthly</div>
                  </div>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>1-10</strong> users</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>1</strong> Primary Legal Entities</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Max 2</strong> Subsidiaries</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Branches</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Departments</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> General Ledgers</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Advanced</strong> Role Based Access</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Requisitions</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Purchase Orders</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Vendors</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Max 3 budgets</strong> (1 annual + 2 project)</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Advanced - max 3 rules</strong> RQ/PO Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Budget Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Vendor Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Approval on the go (Mobile)</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Advanced</strong> Notification Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Reporting</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Integration</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Email Support - Standard working hours</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>System Audit Log</span>
                </div>
              </div>

              <div className={styles.pricingCol}>
                <div className={styles.pricingTableHead}>
                  <div className={styles.pricingTableHeadTop}>
                    <h3 className={styles.pricingTableHeadTitle}>Premium</h3>
                    <p className={styles.pricingTableHeadDescription}>Perfect for Growing Businesses</p>
                  </div>
                  <div className={styles.pricingTableHeadContent}>
                    <div className={styles.pricingTableHeadPrice}>
                      <span>$20</span>
                      <span className={styles.pricingTableHeadPricePeriod}>/m</span>
                    </div>
                    <div className={styles.pricingTableHeadPriceDescription}>When Paid Monthly</div>
                  </div>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> users</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>1</strong> Primary Legal Entities</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Subsidiaries</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Branches</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Departments</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> General Ledgers</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Advanced</strong> Role Based Access</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Requisitions</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Purchase Orders</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> Vendors</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Unlimited</strong> budgets</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Advanced - Unlimited rules</strong> RQ/PO Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Budget Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Vendor Approval Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Approval on the go (Mobile)</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span><strong>Advanced</strong> Notification Management</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Reporting</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-no.svg" alt="No"/>
                  <span>Integration</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>Email Support - Standard working hours. Phone-Account Executive</span>
                </div>
                <div className={styles.pricingBox}>
                  <img src="assets/img/home/pricing-yes.svg" alt="Yes"/>
                  <span>System Audit Log</span>
                </div>
              </div>

            </div>

          </div>
        </section>
        {/*<section className={styles.integration}>*/}
        {/*  <div className={styles.container}>*/}
        {/*    <div className={styles.integrationText}>*/}
        {/*      <h2 className={styles.h2 + " " + styles.title}>Integration</h2>*/}
        {/*      <p className={styles.p + " " + styles.integrationSubtitle}>Prebuilt integrations and APIs to sync your*/}
        {/*        data.</p>*/}
        {/*    </div>*/}
        {/*    <div className={styles.integrationItems}>*/}
        {/*      <a href="https://www.amazon.com/" className={styles.integrationItem} target="_blank" rel="noreferrer">*/}
        {/*        <img src="assets/img/home/integration-1.jpg" alt="integration-1"/>*/}
        {/*      </a>*/}
        {/*      <a href="https://www.amazon.com/" className={styles.integrationItem} target="_blank" rel="noreferrer">*/}
        {/*        <img src="assets/img/home/integration-2.jpg" alt="integration-2"/>*/}
        {/*      </a>*/}
        {/*      <a href="https://www.amazon.com/" className={styles.integrationItem} target="_blank" rel="noreferrer">*/}
        {/*        <img src="assets/img/home/integration-3.jpg" alt="integration-3"/>*/}
        {/*      </a>*/}
        {/*      <a href="https://www.amazon.com/" className={styles.integrationItem} target="_blank" rel="noreferrer">*/}
        {/*        <img src="assets/img/home/integration-4.jpg" alt="integration-4"/>*/}
        {/*      </a>*/}
        {/*      <a href="https://www.amazon.com/" className={styles.integrationItem} target="_blank" rel="noreferrer">*/}
        {/*        <img src="assets/img/home/integration-5.jpg" alt="integration-6"/>*/}
        {/*      </a>*/}
        {/*      <a href="https://www.amazon.com/" className={styles.integrationItem} target="_blank" rel="noreferrer">*/}
        {/*        <img src="assets/img/home/integration-6.jpg" alt="integration-6"/>*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}
        <section className={styles.cta}>
          <div className={styles.container}>
            <div className={styles.ctaInner}>
              <div className={styles.ctaText}>
                <h4 className={styles.h4}>Let’s Get Started</h4>
                <p className={styles.p}>Create a free account or request a live demo</p>
              </div>
              <div className={styles.ctaBtns}>
                <Link to="/register"><Button.Main $primary $style="pink">Create Account</Button.Main></Link>
                <Link to="/login"><Button.Main $primary $style="dark">Request a demo</Button.Main></Link>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.contact} id="contact">
          <div className={styles.container}>
            <div className={styles.contactInner}>
              <img src="assets/img/home/contact.jpg" alt="contact"/>
              <div>
                <h2 className={styles.h2Underlined}>Contact us</h2>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.contactForm}>
                  <div className={styles.contactFields}>
                    <div className="inp-container">
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required"
                          }
                        }}
                        render={({field}) => (
                          <Input
                            type="text"
                            placeholder="Name *"
                            className={errors.hasOwnProperty(field.name) && "error"}
                            {...field} />
                        )}
                      />
                      {errors.name && <p className="error-message">{errors.name?.message}</p>}
                    </div>
                    <div className="inp-container">
                      <Controller
                        name="surname"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Surname is required"
                          }
                        }}
                        render={({field}) => (
                          <Input
                            type="text"
                            placeholder="Surname *"
                            className={errors.hasOwnProperty(field.name) && "error"}
                            {...field} />
                        )}
                      />
                      {errors.surname && <p className="error-message">{errors.surname?.message}</p>}
                    </div>
                    <div className="inp-container">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Email is required"
                          },
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Email is not valid"
                          }
                        }}
                        render={({field}) => (
                          <Input
                            type="email"
                            placeholder="Email *"
                            className={errors.hasOwnProperty(field.name) && "error"}
                            {...field} />
                        )}
                      />
                      {errors.email && <p className="error-message">{errors.email?.message}</p>}
                    </div>
                    <Controller
                      name="country"
                      control={control}
                      render={({field}) => (
                        <Select
                          {...field}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isSearchable={true}
                          filterOption={searchByFirstCharacter}
                          placeholder="Select Country"
                          options={countries}
                        />
                      )}
                    />
                    <Controller
                      name="message"
                      control={control}
                      render={({field}) => (
                        <Textarea
                          placeholder="Message or Question"
                          {...field} />
                      )}
                    />
                    <GoogleReCaptcha setCaptchaResponse={setCaptchaResponse}/>
                  </div>
                  <Button.Main $primary $style="pink" type="submit"
                               disabled={!isDirty || !isValid || !captchaResponse}>Submit</Button.Main>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footerInner}>
            <div className={styles.footerGeneral}>
              <img src="assets/img/home/logo-footer.svg" alt="POMS"/>
              <div>123-456-7890 <br/>info@pomsonline</div>
              <div className={styles.footerSocials}>
                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                  <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.89821 20.7001V11.3781H10.0431L10.5105 7.72825H6.89821V5.40344C6.89821 4.35023 7.19165 3.62913 8.70323 3.62913H10.6186V0.375087C9.68706 0.274997 8.74986 0.227227 7.81266 0.230639C5.03291 0.230639 3.12438 1.92761 3.12438 5.04289V7.72142H0V11.3713H3.13121V20.7001H6.89821Z"
                      fill="white"/>
                  </svg>
                </a>
                <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.7765 0.230469H5.54008C2.72532 0.230469 0.421875 2.53289 0.421875 5.34868V15.5851C0.421875 18.3999 2.72532 20.7033 5.54008 20.7033H15.7765C18.5913 20.7033 20.8947 18.3999 20.8947 15.5851V5.34868C20.8947 2.53289 18.5913 0.230469 15.7765 0.230469ZM10.6637 14.7334C8.30779 14.7334 6.39844 12.8233 6.39844 10.4684C6.39844 8.11247 8.30779 6.20312 10.6637 6.20312C13.0186 6.20312 14.929 8.11247 14.929 10.4684C14.929 12.8233 13.0186 14.7334 10.6637 14.7334ZM14.9219 4.92408C14.9219 5.63065 15.4941 6.20364 16.2014 6.20364C16.9088 6.20364 17.481 5.63065 17.481 4.92408C17.481 4.21751 16.9088 3.64453 16.2014 3.64453C15.4941 3.64453 14.9219 4.21751 14.9219 4.92408Z"
                          fill="white"/>
                  </svg>
                </a>
                <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">
                  <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.532 4.91121C20.5468 5.11026 20.5468 5.30816 20.5468 5.50606C20.5468 11.5626 15.937 18.5416 7.51239 18.5416C4.91689 18.5416 2.50565 17.7898 0.476562 16.4841C0.845074 16.5261 1.19994 16.5409 1.58323 16.5409C3.72492 16.5409 5.696 15.8175 7.27127 14.5835C5.25697 14.5414 3.5691 13.222 2.98676 11.4068C3.26997 11.4489 3.55431 11.4773 3.85231 11.4773C4.2629 11.4773 4.67577 11.4204 5.05907 11.3215C2.95832 10.8961 1.38533 9.05241 1.38533 6.82542V6.76856C1.9961 7.10863 2.70469 7.32132 3.45536 7.34976C2.2213 6.52857 1.41263 5.1239 1.41263 3.53499C1.41263 2.68423 1.63896 1.90398 2.03591 1.22383C4.29133 4.00245 7.68186 5.81884 11.483 6.01789C11.4125 5.67667 11.3692 5.32295 11.3692 4.96808C11.3692 2.4431 13.412 0.386719 15.9506 0.386719C17.27 0.386719 18.4608 0.939485 19.2979 1.83347C20.3329 1.63442 21.3247 1.25113 22.2051 0.726795C21.865 1.79025 21.1405 2.68423 20.1908 3.25178C21.1132 3.15169 22.0072 2.89692 22.8284 2.54205C22.2051 3.44968 21.426 4.25836 20.532 4.91121Z"
                      fill="white"/>
                  </svg>
                </a>
                <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                  <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M23.3742 1.19973C24.4681 1.34225 25.5135 2.40605 25.6465 3.51545C26.1174 7.58126 26.1174 11.5239 25.6465 15.5884C25.5135 16.6978 24.4681 17.7629 23.3742 17.9041C16.7041 18.733 9.9587 18.733 3.29055 17.9041C2.19571 17.7626 1.15028 16.6978 1.01726 15.5884C0.546329 11.5236 0.546329 7.58126 1.01726 3.51545C1.15028 2.40605 2.19571 1.34193 3.29055 1.19973C9.9587 0.370923 16.7038 0.370923 23.3742 1.19973ZM11.2188 5.33203V13.7775L17.5528 9.55493L11.2188 5.33203Z"
                          fill="white"/>
                  </svg>
                </a>
              </div>
              <div className={styles.footerStores}>
                <a href="https://play.google.com/" target="_blank" rel="noreferrer"><img
                  src="assets/img/home/google.png" alt="google store"/></a>
                <a href="https://apps.apple.com/" target="_blank" rel="noreferrer"><img src="assets/img/home/apple.png"
                                                                                        alt="apple store"/></a>
              </div>
            </div>
            <div className={styles.footerInfo}>
              <h2 className={styles.h2Underlined + " " + styles.h2UnderlinedPink}>Get in touch</h2>
              <div><strong>POMS</strong> - Your all-in-one solution for effortless purchasing management.</div>
              <div>P: 713-524-9524 <br/>E: info@poms.org</div>
              <div>100 ABC Street, 11th Floor , City Name, lorem ipsum, South Africa</div>
            </div>
          </div>
        </div>
        <div className={styles.copyrights}>
          © {new Date().getFullYear()} POMS |
          All Rights Reserved |&nbsp;
          <Link to="/terms">Terms of Service</Link>&nbsp;|&nbsp;
          <Link to="/policy">Privacy Policy</Link>
        </div>
      </footer>
      {toast.opened === true ? (<Toast message={toast.message} opened={toast.opened} type={toast.type}/>) : null}
    </>
  );
}

export default Home;
