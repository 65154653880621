import { useState, useEffect } from 'react';

import Select from 'react-select';
import Box from '../../components/UI/General/Box';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import formStyles from '../../styles/companySetup.module.css';
import generalrStyles from '../../styles/general.module.css';
import vendorStyles from '../../styles/vendor.module.css';
import styles from '../../styles/vendorProfile.module.css';
import { Controller, useForm } from 'react-hook-form';

import Textarea from '../../components/UI/Forms/Textarea';
import * as Button from '../../components/UI/Forms/Button';
import Documents from '../../components/UI/Forms/Documents';
import spmsServiceService from '../../services/spmsService.service';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion';
import { vendorSchema } from '../../utils/validation/vendorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/store';
import Text from '../../components/UI/Typography/Text';
import Toast from '../../components/UI/General/Toast';
import TabsSlider from '../../components/UI/General/TabsSlider';

const VendorAccount = () => {
  const { externalId: vendorId } = useStore((state) => state.user);
  const countries = useStore((state) => state.countries);
  const [vendorDocs, setVendorDocs] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({ resolver: yupResolver(vendorSchema) });

  const [newFiles, setNewFiles] = useState([]);
  const vendorTypeOptions = [{ value: 'SUPPLIER', label: 'SUPPLIER' }];
  const [tab, setTab] = useState('Notes');
  const [invalidFormats, setInvalidFormats] = useState([]);
  const navigate = useNavigate();

  const fileFormats = [
    'pdf',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'pps',
    'ppsx',
    'odt',
    'xls',
    'xlsx',
    'PSD',
    'dot',
    'csv',
    'txt',
    'rtf',
    'html',
    'htm',
  ];

  const files = (files) =>
    files.map((file) => ({
      id: null,
      attachment: file,
      name: '',
      type: 'VENDOR_DOC',
    }));

  useEffect(() => {
    setInvalidFormats([]);
  }, [newFiles]);

  useEffect(() => {
    if (!vendorId) return;
    spmsServiceService.getVendor(vendorId).then((res) => {
      const vendorData = res.data.data;
      const vendorPreloadData = {
        registrationNo: vendorData.registrationNo,
        vendorType: { label: vendorData.vendorType, value: vendorData.vendorType },
        legalName: vendorData.legalName,
        vendorAddressLine1: vendorData.address.streetAddress,
        vendorAddressLine2: vendorData.address.addressLine2,
        vendorCity: vendorData.address.city,
        vendorZipCode: vendorData.address.zipCode,
        vendorCountry: {
          label: vendorData.address.country,
          value: vendorData.address.country,
        },
        vendorState: { label: vendorData.address.state, value: vendorData.address.state },
        vendorCode: vendorData.vendorCode,
        vendorEmail: vendorData.email,
        vendorPhoneNumber: vendorData.phoneNumber,
      };
      reset(vendorPreloadData);
    });
  }, [vendorId]);

  const submitVendor = async (data) => {
    setInvalidFormats([]);
    setToast((item) => ({ ...item, opened: false }));
    let vendorFiles = await readFiless(newFiles);
    let invalidFileFormats = [];
    vendorFiles.forEach((file) => {
      const fileFormat = file.name.split('.').at(-1);
      if (!fileFormats.includes(fileFormat)) {
        invalidFileFormats.push(fileFormat);
      }
    });
    if (!!invalidFileFormats.length) {
      setInvalidFormats(invalidFileFormats);
      setTab('Documents');
      return;
    }

    const bodyData = {
      legalName: data.legalName,
      registrationNo: data.registrationNo,
      vendorNotes: data.vendorNotes,
      vendorType: data.vendorType.value,
      email: data.vendorEmail,
      phoneNumber: data.vendorPhoneNumber,
      address: {
        addressLine2: data.vendorAddressLine2,
        streetAddress: data.vendorAddressLine1,
        city: data.vendorCity,
        state: data.vendorState.value,
        zipCode: data.vendorZipCode,
        country: data.vendorCountry.value,
      },
      attachment: vendorFiles,
    };
    await spmsServiceService
      .editVendorDetails(vendorId, bodyData)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Your Profile has been updated',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
          type: 'fail',
        });
      });
  };

  const readFiless = async () => {
    let myFiles = [];
    for (var i = 0; i < newFiles.length; i++) {
      let attachment = await readFile(newFiles[i][0]);
      myFiles.push({
        attachment: attachment,
        name: newFiles[i][0].name,
        type: 'VENDOR_DOC',
      });
    }
    return myFiles;
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = function () {
        resolve(filereader.result);
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
  };

  return (
    <>
      <Box $raduis={12} $mobExtend $asHolder>
        <div className={styles.title}>
          <Text weight={600} type="h2">
            Vendor Profile
          </Text>
        </div>
        <form className={formStyles.form}>
          <div className={generalrStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="legalName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Legal Name *"
                    $label="Legal Name"
                    {...field}
                  />
                )}
              />
              {errors.legalName && <p className="error-message">{errors.legalName.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="registrationNo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Registration No *"
                    $label="Registration No"
                    {...field}
                  />
                )}
              />
              {errors.registrationNo && (
                <p className="error-message">{errors.registrationNo.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label $title="Vendor Type" />
              <Controller
                name="vendorType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Category *"
                    options={vendorTypeOptions}
                  />
                )}
              />
              {errors.vendorType && (
                <p className="error-message">{errors.vendorType.value.message}</p>
              )}
            </div>

            <div className="inp-container">
              <Controller
                name="vendorAddressLine1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Suit/Building Name"
                    $label=" Suite Number/Building Name/Office Park"
                    {...field}
                  />
                )}
              />
              {errors.vendorAddressLine1 && (
                <p className="error-message">{errors.vendorAddressLine1.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorAddressLine2"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Street Address *"
                    $label="Street Address"
                    {...field}
                  />
                )}
              />
              {errors.vendorAddressLine2 && (
                <p className="error-message">Vendor Address Line 2 is required</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorCity"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter City *"
                    $label="City"
                    {...field}
                  />
                )}
              />
              {errors.vendorCity && <p className="error-message">{errors.vendorCity.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorZipCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Postal Code *"
                    $label="Postal Code"
                    {...field}
                  />
                )}
              />
              {errors.vendorZipCode && (
                <p className="error-message">{errors.vendorZipCode.message}</p>
              )}
            </div>
            <CountryAndRegion
              countryFieldName="vendorCountry"
              regionFieldName="vendorState"
              control={control}
              countries={countries}
              errors={errors}
            />
            <div className="inp-container">
              <Controller
                name="vendorEmail"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Business Email *"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Business Email"
                    id={field.name}
                    disabled
                    {...field}
                  />
                )}
              />
              {errors.vendorEmail && <p className="error-message">{errors.vendorEmail?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Business Phone Number" />
              <Controller
                name="vendorPhoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <PhoneInput
                      limitMaxLength={true}
                      {...field}
                      placeholder="Enter Phone Number *"
                      international
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    />
                  );
                }}
              />
              {errors.vendorPhoneNumber && (
                <p className="error-message">{errors.vendorPhoneNumber?.message}</p>
              )}
            </div>
          </div>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('Notes')}>Notes</span>
            <span onClick={() => setTab('Documents')}>Documents</span>
          </TabsSlider>
          {tab === 'Notes' && (
            <div className="inp-container">
              <Controller
                name="vendorNotes"
                control={control}
                defaultValue=""
                rules={{ maxLength: 500 }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $label="Notes"
                    $small
                    $low
                    $counter
                    $counterMax={500}
                    placeholder="Enter Notes about the vendor"
                  />
                )}
              />
              {errors.vendorNotes && (
                <p style={{ transform: 'translate(0, -20px)' }} className="error-message">
                  {errors.vendorNotes.message}
                </p>
              )}
            </div>
          )}
          {tab === 'Documents' && (
            <>
              <div className={vendorStyles.docs}>
                <Label>Import Files</Label>
                <Documents
                  control={control}
                  fieldName="vendorAttachments"
                  filesSchema={files}
                  setNewFiles={setNewFiles}
                  newFiles={newFiles}
                />
                {!!invalidFormats.length && (
                  <div className={vendorStyles.formatError}>
                    <p>Unappropriate file format - {invalidFormats.join(', ')}</p>
                    <p>
                      Accepted formats: .pdf, .doc, .docx, .ppt, .pptx, .pps, .ppsx, .odt, .xls,
                      .xlsx, .PSD, .dot, .csv, .txt, .rtf, .html, .htm
                    </p>
                  </div>
                )}
                {/* {vendorSetupData?.vendorAttachments && (
                  <QuotesTab data={vendorSetupData?.vendorAttachments} />
                )} */}
              </div>
            </>
          )}
        </form>
        {toast.opened ? (
          <Toast message={toast.message} opened={toast.opened} type={toast.type} />
        ) : null}
      </Box>
      <div className={generalrStyles.pageButtons}>
        <Button.Main onClick={handleSubmit(submitVendor)} $primary $style="pink" type="submit">
          Save
        </Button.Main>
        <Button.Main
          onClick={() => navigate('/vendors/overview')}
          $primary
          $style="gray"
          type="button"
        >
          Discard
        </Button.Main>
      </div>
    </>
  );
};

export default VendorAccount;
