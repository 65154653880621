import React, {memo, useCallback} from 'react';
import styled from "styled-components";
import * as Button from '../Forms/Button'
import Icon from "./Icon";
import Select from "react-select";
import {usePagination, DOTS} from "../../../hooks/usePagination";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 16px;
  margin-top: 16px;
  color: #000000;
  @media all and (min-width: 768px){
    flex-direction: row;
    justify-content:space-between;
    margin-top: 40px;
  }
`

const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  max-width: 300px;
  -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;
  background-color: #fff;
  @media all and (min-width: 768px){
    grid-template-columns: 1fr 1fr;
  }
`

const Counter = styled.div`
  padding: 8px 20px;
  font-size: 14px;
  text-align:center;
  @media all and (min-width: 768px){
    text-align: left;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
`

const getNumberOfPages = (rowCount, rowsPerPage) => Math.ceil(rowCount / rowsPerPage);

const TablePagination = memo(({rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage, paginationRowsPerPageOptions}) => {
  console.log(rowsPerPage)
  console.log(rowCount)
  console.log(currentPage)
  console.log(paginationRowsPerPageOptions)

  const siblingCount = 1;
  const pageSize = rowsPerPage;
  const totalCount = rowCount;
  const paginationRange = usePagination({currentPage, totalCount, siblingCount, pageSize});

  const lastIndex = currentPage * rowsPerPage;
  const firstIndex = lastIndex - rowsPerPage + 1;
  const numPages = getNumberOfPages(rowCount, rowsPerPage);
  const disabledLesser = currentPage === 1;
  const disabledGreater = currentPage === numPages;
  const range = currentPage === numPages ? `${firstIndex}-${rowCount} of ${rowCount}` : `${firstIndex}-${lastIndex} of ${rowCount}`;

  const selectOptions = paginationRowsPerPageOptions.map(item => ({label: item, value: item}));

  const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [currentPage, onChangePage]);
  const handleNext = useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);
  const handleFirst = useCallback(() => onChangePage(1), [onChangePage]);
  const handleLast = useCallback(() => onChangePage(getNumberOfPages(rowCount, rowsPerPage)), [onChangePage, rowCount, rowsPerPage],
  );
  const handleOnChangeRowsPerPage = useCallback(
    (e) => onChangeRowsPerPage(Number(e.value), currentPage),
    [currentPage, onChangeRowsPerPage],
  );

  const buttons = paginationRange.map(pageNumber => {
    if (pageNumber === DOTS) {
      return <Button.Action key={Math.random()} $width={40} $height={40} $style="pagination" disabled={true}>&#8230;</Button.Action>;
    }
    return (
      <Button.Action
        key={pageNumber}
        $width={40}
        $height={40}
        $style={currentPage === pageNumber ? "pagination_active" : "pagination"}
        type="button"
        onClick={()=>onChangePage(pageNumber)}
      >
        {pageNumber}
      </Button.Action>
    );
  })

  return (
    <Wrapper>
      <Controls>
        <Counter>{range}</Counter>
        <Select
          className="react-select-container table-pagination"
          classNamePrefix="react-select"
          isSearchable={false}
          onChange={handleOnChangeRowsPerPage}
          defaultValue={rowsPerPage ? {label: rowsPerPage, value: rowsPerPage} : selectOptions[0]}
          options={selectOptions}
          menuPortalTarget={document.body}
          menuPosition={'absolute'}
          menuPlacement={'top'}
          menuShouldScrollIntoView={false}
        />
      </Controls>
      <Buttons>
        <Button.Action $width={40} type="button" $height={40} $style={disabledLesser ? "dark" : "blue"} disabled={disabledLesser} onClick={handlePrevious}>
          <Icon $icon="chevron-left" $width={24} $height={24}/>
        </Button.Action>
        {buttons}
        <Button.Action $width={40} type="button" $height={40} $style={disabledGreater ? "dark" : "blue"} disabled={disabledGreater} onClick={handleNext}>
          <Icon $icon="chevron-right" $width={24} $height={24}/>
        </Button.Action>
      </Buttons>
    </Wrapper>
  );
})

export default TablePagination;
