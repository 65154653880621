import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css';
import { NavLink, Outlet } from 'react-router-dom';
import TabsSlider from '../../components/UI/General/TabsSlider';
import Box from '../../components/UI/General/Box';

const AccountsBilling = () => {
  return (
    <>
      <div>
        <Text type="h2">Accounts & Billing</Text>
      </div>
      <Box $radius={12} $mobExtend $asHolder>
        <div className={generalStyles.tabSection}>
          <TabsSlider>
            <NavLink $islink="true" to="/accounts-billing/overview">
              Overview
            </NavLink>
            <NavLink $islink="true" to="/accounts-billing/subscription">
              Subscription
            </NavLink>
            <NavLink $islink="true" to="/accounts-billing/company-information">
              Company Information
            </NavLink>
            <NavLink $islink="true" to="/accounts-billing/payment">
              Payment Methods
            </NavLink>
            <NavLink $islink="true" to="/accounts-billing/invoices">
              Invoices
            </NavLink>
          </TabsSlider>
        </div>
        <Outlet />
      </Box>
    </>
  );
};

export default AccountsBilling;
