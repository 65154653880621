import React from 'react';

const AddCustomer = () => {
  return (
    <div>
      AddCustomer
    </div>
  );
};

export default AddCustomer;
