import axios from 'axios';
import { Auth } from 'aws-amplify';

export const axiosBaseInstance = axios.create({
  baseURL: process.env.REACT_APP_MODE === 'staging' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 200000,
});

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MODE === 'staging' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 200000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    console.log('Interceptors to await TOKEN');
    const session = await Auth.currentSession();
    if (session){
      config.headers.Authorization = 'Bearer ' + session.getIdToken().getJwtToken()
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const baseUrl = '/s2pms/api/v1';
export const s2pmsBaseUrl = '/s2pms/v1';
export const s2pmsEmailUrl = '/s2pms-email-service/api';
