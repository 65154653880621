import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '../../components/UI/General/Box';
import styles from '../../styles/companySetup.module.css';
import generalStyles from '../../styles/general.module.css';
import Input from '../../components/UI/Forms/Input';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Checkbox from '../../components/UI/Forms/Checkbox';
import Text from '../../components/UI/Typography/Text';
import Icon from '../../components/UI/General/Icon';
import Label from '../../components/UI/Forms/Label';
import File from '../../components/UI/Forms/File';
import { confirmAlert } from 'react-confirm-alert';
import spmsServiceService from '../../services/spmsService.service';
import AddNewBranch from '../../components/Admins/Company/AddNewBranch';
import Toast from '../../components/UI/General/Toast';
import requestsServiceService from '../../services/requestsService.service';
import DataTableBase from '../../components/UI/General/DataTableBase';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import { useStore } from '../../store/store';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion';
import PhoneInput from 'react-phone-number-input';
import TabbedNav from '../../components/UI/General/TabbedNav.jsx';
import BranchExpanded from '../../components/UI/General/BranchExpanded.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyExtendedSchema } from '../../utils/validation/companySchema.js';
import useGetUserLocation from '../../hooks/useGetUserLocation.jsx';
import { useLocation, useSearchParams } from 'react-router-dom';
import budgetServiceService from '../../services/budgetService.service.js';
import AddButton from '../../components/shared/AddButton';
import Export from '../../components/shared/Export';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed.jsx';
import UpgradePlan from '../../components/shared/UpgradePlan';

const options = [
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Entertainment And Media', label: 'Entertainment and Media' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Information Technology', label: 'Information Technology (IT)' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Nonprofit And Social Services', label: 'Nonprofit and Social Services' },
  { value: 'Professional Services', label: 'Professional Services' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Transportation And Logistics', label: 'Transportation and Logistics' },
];

const CompanySetup = () => {
  const user = useStore((state) => state.user);
  const updateUser = useStore((state) => state.updateUser);
  const companyFromContext = useStore((state) => state.company);
  const activeCompany = useStore((state) => state.activeCompany);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const setCompany = useStore((state) => state.setCompany);
  const setSteps = useStore((state) => state.setSteps);
  const updateCompany = useStore((state) => state.updateCompany);
  const countries = useStore((state) => state.countries);
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [mode, setMode] = useState(null);
  const [files, setFiles] = useState({ companyLogo: '' });
  const [editBranchData, setEditBranchData] = useState(null);
  const countryCode = useGetUserLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyImages, setCompanyImages] = useState(null);
  const [hasActiveBudget, setHasActiveBudget] = useState(false);
  const currentSubscription = useSubscriptionAllowed();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const location = useLocation();
  const branchesRef = useRef(null);

  useEffect(() => {
    if (searchParams.get('section') === 'branch' && branchesRef.current) {
      branchesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [searchParams, branchesRef.current, companyFromContext]);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(companyExtendedSchema),
  });
  const requisitionsRequired = watch('requisitionsRequired');
  const companyCountry = watch('country');

  useEffect(() => {
    if (companyFromContext !== null) {
      console.log(companyFromContext)
      reset({
        ...companyFromContext,
        country: {
          label: companyFromContext.country,
          value: companyFromContext.country,
        },
        industry: {
          label: companyFromContext.industry,
          value: companyFromContext.industry,
        },
        defaultCurrency: {
          label: companyFromContext.defaultCurrency,
          value: companyFromContext.defaultCurrency,
        },
        autoGeneratedNumberAffix: companyFromContext?.autoGeneratedNumberAffix ?? 'SUFFIX',
        allowedVarianceAmount: companyFromContext?.invoiceVarianceProperties?.allowedVarianceAmount ?? 0,
        allowedVariancePercentage: companyFromContext?.invoiceVarianceProperties?.allowedVariancePercentage ?? 0
      });
    } else if (location.state?.data) {
      reset(location.state.data);
    }
  }, [companyFromContext, location.state]);

  useEffect(() => {
    if (!countries || !companyCountry) return undefined;
    const selectedCountryCurrency = countries.find(
      (country) => country.value === companyCountry.value,
    )?.currency;
    setValue('defaultCurrency', { label: selectedCountryCurrency, value: selectedCountryCurrency });
  }, [countries, companyCountry]);

  const createCurrencyOptions = useCallback(() => {
    if (!countries || !countries.length) return;
    const allCurrencies = countries.map((country) => country.currency).sort();
    const uniqueCurrencies = [...new Set([...allCurrencies])];
    return uniqueCurrencies.map((currency) => ({ label: currency, value: currency }));
  }, [countries]);

  const addNewBranch = () => {
    setEditBranchData({
      address_two: '',
      address_one: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      email: '',
      phoneNumber: '',
    });
    setMode('new');
  };

  useEffect(() => {
    const getCompanyImages = async () => {
      let companyLogo;
      if (companyFromContext.logo) {
        const { logo } = companyFromContext;
        const res = await spmsServiceService.getAttachmentsByKey(logo.key);
        companyLogo = res.data.data;
      }
      setCompanyImages({ companyLogo });
    };
    const getActiveBudget = async () => {
      try {
        const response = await budgetServiceService.getAllBudgetData(companyFromContext.id);
        const budgets = response?.data?.data;
        const activeBudgets = budgets.filter((budget) => budget.budgetStatus === 'ACTIVE');
        setHasActiveBudget(!!activeBudgets.length);
      } catch (err) {
        console.log(err);
      }
    };
    if (companyFromContext) {
      getCompanyImages();
      getActiveBudget();
    }
  }, [companyFromContext]);

  const editBranch = (branchData) => {
    setEditBranchData({
      address_two: branchData.address.addressLine2,
      address_one: branchData.address.streetAddress,
      city: branchData.address.city,
      state: branchData.address.state,
      zip: branchData.address.zipCode,
      country: branchData.address.country,
      name: branchData.name,
      email: branchData.email,
      id: branchData.id,
      phoneNumber: branchData.phoneNumber,
    });
    setMode('edit');
  };

  async function submitCompany(data) {
    console.log(data)
    let { industry, country, defaultCurrency, stamp, logo, allowedVarianceAmount, allowedVariancePercentage, ...prevData } = data;
    let finalData = {
      ...prevData,
      industry: industry.value,
      country: country.value,
      defaultCurrency: defaultCurrency.value,
      subsidiary: false,
      companyLogo: files.companyLogo !== '' ? files.companyLogo : undefined,
      invoiceVarianceProperties: {
        allowedVarianceAmount: parseFloat(allowedVarianceAmount),
        allowedVariancePercentage: parseInt(allowedVariancePercentage)
      },
    };
    console.log(finalData)
    setToast((item) => ({ ...item, opened: false }));
    if (companyFromContext === null) {
      spmsServiceService
        .createCompany(finalData)
        .then((res) => {
          if (res.data.status === 'success') {
            setCompany(res.data.data.id);
            setActiveCompany(res.data.data.id);
            setToast({
              opened: true,
              message: 'Success! You can now create branches',
              type: 'success',
            });
            searchParams.set('section', 'branch');
            setSearchParams(searchParams);
            if (user) {
              requestsServiceService
                .getUserDetailsById(user.id)
                .then((res) => {
                  updateUser(res.data.data);
                  setSteps()
                })
                .catch((err) => console.log(err));
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 400) {
            if (error.response.data.errors != undefined)
              for (var i = 0; i < error.response.data.errors.length; i++)
                setError(error.response.data.errors[i].field, {
                  type: 'required',
                  message: error.response.data.errors[i].message,
                });
            else
              setToast({
                opened: true,
                message: error.response.data.message.toString(),
                type: 'fail',
              });
          }
        });
    } else {
      finalData = { ...finalData, id: companyFromContext.id };
      spmsServiceService
        .updateCompany(finalData)
        .then((res) => {
          if (res.data.status === 'success') {
            setToast({
              opened: true,
              message: 'Success!',
              type: 'success',
            });
            updateCompany(finalData);
            if (activeCompany?.id === finalData.id) setActiveCompany(finalData.id);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.errors !== undefined)
            for (var i = 0; i < error.response.data.errors.length; i++)
              setError(error.response.data.errors[i].field, {
                type: 'required',
                message: error.response.data.errors[i].message,
              });
          else
            setToast({
              opened: true,
              message: error.message,
              type: 'fail',
            });
        });
    }
  }

  const readFile = (event) => {
    let filereader = new FileReader();
    if (!event.target.files.length) return;
    const fileType = event.target.files[0].name.split('.').at(-1);
    filereader.readAsDataURL(event.target.files[0]);

    filereader.onload = function () {
      setFiles({
        ...files,
        [event.target.name]: {
          document: filereader.result,
          name: `${event.target.name}.${fileType}`,
        },
      });
    };
    filereader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const branchColumns = useMemo(
    () => [
      {
        name: 'Branch Name',
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <span data-content={'Branch Name'} className={generalStyles.tableValue}>
            <span>{row.name}</span>
          </span>
        ),
      },
      {
        name: 'Email Address',
        selector: (row) => row.email,

        sortable: true,
        cell: (row) => (
          <span data-content={'Email Address'} className={generalStyles.tableValue}>
            <span>{row.email}</span>
          </span>
        ),
      },
      {
        name: 'City',
        sortable: true,
        selector: (row) => row.address.city,

        cell: (row) => (
          <span data-content={'City'} className={generalStyles.tableValue}>
            <span>{row.address.city}</span>
          </span>
        ),
      },
      {
        name: 'Region',
        sortable: true,
        selector: (row) => row.address.state,
        cell: (row) => (
          <span data-content={'Region'} className={generalStyles.tableValue}>
            <span>{row.address.state}</span>
          </span>
        ),
      },
      {
        name: 'Country',
        sortable: true,
        selector: (row) => row.address.country,
        cell: (row) => (
          <span data-content={'Country'} className={generalStyles.tableValue}>
            <span>{row.address.country}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                {isActiveTab ? (
                  <Menu className={generalStyles.actionMenuList}>
                    {companyFromContext &&
                      Array.isArray(companyFromContext.branches) &&
                      companyFromContext.branches.filter((branch) => branch.active).length !==
                        1 && <MenuItem onClick={() => archiveBranch(row)}>Archive</MenuItem>}
                    <MenuItem onClick={() => editBranch(row)}>Edit</MenuItem>
                  </Menu>
                ) : (
                  <Menu className={generalStyles.actionMenuList}>
                    <MenuItem onClick={() => activateBranch(row)}>Activate</MenuItem>
                  </Menu>
                )}
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [companyFromContext, isActiveTab],
  );

  const toExport = companyFromContext?.branches?.map((item) => ({
    'Branch Name': item.name,
    'Email Address': item.email,
    City: item.address.city,
    Region: item.address.state,
    Country: item.address.country,
    Status: item.active !== false ? 'Active' : 'Archived',
  }));

  const refetchBranches = () => {
    spmsServiceService
      .getCompanyBranches(companyFromContext.id)
      .then((res) => {
        updateCompany({ ...companyFromContext, branches: res.data.data });
      })
      .catch((error) => {
        console.log(error);
        confirmAlert({
          message: error.message,
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
      });
  };

  const archiveBranch = async (branch) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveBranch(companyFromContext.id, branch.id)
      .then((res) => {
        if (res.data.status === 'success') {
          refetchBranches();
          setToast({
            opened: true,
            message: 'Success!',
            type: 'success',
          });
        }
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.message.toString(),
          type: 'fail',
        });
      });
  };
  const activateBranch = async (branch) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateBranch(companyFromContext.id, branch.id)
      .then((res) => {
        if (res.data.status === 'success') {
          refetchBranches();
          setToast({
            opened: true,
            message: 'Success!',
            type: 'success',
          });
        }
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.message.toString(),
          type: 'fail',
        });
      });
  };

  const onRowClick = () => {
    setMode(null);
    setEditBranchData(null);
  };

  return (
    <div>
      <Box $mobExtend $asHolder $noOverflow $raduis={12}>
        <form onSubmit={handleSubmit(submitCompany)} className={styles.form} id="cForm">
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Legal Registered Name"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Company Name"
                    $labelRequired
                    $tooltip="Legal registered name of the company"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="tradingName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Trading Name"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Trading Name"
                    $tooltip="Trading name of the company"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.tradingName && <p className="error-message">{errors.tradingName?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="registrationNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Registration Number"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Registration Number"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.registrationNumber && (
                <p className="error-message">{errors.registrationNumber?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label
                $title="Phone Number"
                $isRequired
                $tooltipText="Primary phone number of the company"
              />
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <PhoneInput
                      defaultCountry={countryCode}
                      limitMaxLength={true}
                      {...field}
                      placeholder="Enter Phone Number"
                      international
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    />
                  );
                }}
              />
              {errors.phoneNumber && <p className="error-message">{errors.phoneNumber?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Email Address"
                    $labelRequired
                    $tooltip="Primary email address of the company"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="error-message">{errors.email?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="vatNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter VAT Number"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="VAT Number"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.vatNumber && <p className="error-message">{errors.vatNumber?.message}</p>}
            </div>
            <CountryAndRegion
              countryFieldName="country"
              control={control}
              isDisable={false}
              countries={countries}
              errors={errors}
              countryTooltip="Country of incorporation"
            />
            <div className="inp-container">
              <Label
                $title="Currency"
                $isRequired
                $tooltipText="Currency the company is trading in"
              />
              <Controller
                name="defaultCurrency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Currency *"
                    isDisabled={hasActiveBudget}
                    options={createCurrencyOptions()}
                  />
                )}
              />
              {hasActiveBudget && (
                <Text
                  weight={500}
                  type="body-3"
                  style={{ position: 'absolute', top: '100%', left: 0, marginTop: 4 }}
                >
                  You already have an active budget
                </Text>
              )}
              {errors.defaultCurrency && (
                <p className="error-message">{errors.defaultCurrency?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label $title="Industry" $isRequired $tooltipText="Industry of the company" />
              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Industry *"
                    options={options}
                  />
                )}
              />
              {errors.industry && <p className="error-message">{errors.industry?.value.message}</p>}
            </div>
          </div>
          <div className={generalStyles.fieldsThree}>
            {(companyFromContext === null || companyFromContext?.subsidiary === false) && (
              <div className={styles.holdingSection}>
                <Controller
                  name="holding"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <>
                      <Checkbox
                        {...field}
                        checked={!!currentSubscription?.plan?.maxSubsidiaries && field.value}
                        disabled={!currentSubscription?.plan?.maxSubsidiaries}
                        $size={22}
                        label="Would you like to link subsidiaries?"
                        $tooltip={
                          !!currentSubscription?.plan?.maxSubsidiaries &&
                          'Check to enable subsidiary module'
                        }
                        className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                      />
                    </>
                  )}
                />
                {!currentSubscription?.plan?.maxSubsidiaries && (
                  <UpgradePlan text="Subsidiaries only available on Standard and Premium." />
                )}
              </div>
            )}
            <Controller
              name="requisitionsRequired"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  $size={22}
                  label="Requisitions Required"
                  $tooltip="Check to enable requisition module"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('requisitionsApprovedToPO', false);
                  }}
                />
              )}
            />
            <Controller
              name="requisitionsApprovedToPO"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  $size={22}
                  disabled={!requisitionsRequired}
                  label={
                    <span style={!requisitionsRequired ? { color: '#C4C4C4' } : {}}>
                      Requisitions Approved to PO
                    </span>
                  }
                  $tooltip="Check to auto approve requisition to purchase order"
                />
              )}
            />
          </div>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="allowedVarianceAmount"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Invoice Variance Amount"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Invoice Variance Amount"
                    $tooltip="Amount that the invoice may exceed the PO amount"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.allowedVarianceAmount && <p className="error-message">{errors.allowedVarianceAmount?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="allowedVariancePercentage"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Invoice Variance Percentage"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Invoice Variance Percentage"
                    $tooltip="Percentage that the invoice may exceed the PO amount"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.allowedVariancePercentage && <p className="error-message">{errors.allowedVariancePercentage?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Company Logo" />
              <Controller
                name="companyLogo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <File
                    {...field}
                    value={field.value.filename}
                    $isInvalid={errors.hasOwnProperty(field.name)}
                    $picture={
                      field.value !== '' && field.value instanceof Blob
                        ? URL.createObjectURL(field.value)
                        : field.value !== '' && !(field.value instanceof Blob)
                        ? 'data:image/jpg;base64,' + field.value
                        : companyImages?.companyLogo
                        ? companyImages?.companyLogo
                        : ''
                    }
                    onChange={(e) => {
                      field.onChange({
                        target: { value: e.target.files[0], name: field.name },
                      });
                      readFile(e);
                    }}
                    accept=".jpg, .jpeg, .png, .gif, .ico, .svg, .bmp, .pdf"
                  />
                )}
              />
              {errors.companyLogo && <p className="error-message">{errors.companyLogo?.message}</p>}
            </div>
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              form="cForm"
              disabled={!isDirty || !isValid}
            >
              {!companyFromContext?.id ? 'Save' : 'Update'}
            </Button.Main>
          </div>
        </form>
      </Box>
      <br />
      <br />
      {companyFromContext && (
        <Box $mobExtend $asHolder $noOverflow $raduis={12}>
          <div ref={branchesRef}>
            <Text className={styles.sectionTitle} type="h3" weight={600}>
              Branches
            </Text>
            <div className={generalStyles.tabSection}>
              <TabbedNav>
                <span
                  onClick={() => {
                    setIsActiveTab(true);
                  }}
                >
                  Active
                </span>
                <span
                  onClick={() => {
                    setIsActiveTab(false);
                  }}
                >
                  Archive
                </span>
              </TabbedNav>
              {mode !== 'new' && (
                <div className={generalStyles.addItemButton}>
                  <AddButton $click={addNewBranch} />
                  <Export data={toExport} name={companyFromContext?.name + '-branches'} />
                </div>
              )}
            </div>
            <div className={styles.branchesSection}>
              {mode === 'new' && (
                <AddNewBranch
                  key={companyFromContext?.branches}
                  branchData={editBranchData}
                  number={companyFromContext?.branches?.length}
                  companyDetails={companyFromContext}
                  onClose={onRowClick}
                  refetchBranches={refetchBranches}
                  countries={countries}
                  regionFieldName
                  setToast={setToast}
                  mode={mode}
                  countryCode={countryCode}
                />
              )}
              {mode === 'new' && !companyFromContext?.branches?.length ? null : (
                <DataTableBase
                  data={companyFromContext?.branches?.filter(
                    (branch) => branch.active === isActiveTab,
                  )}
                  columns={branchColumns}
                  expandableRows
                  expandableRowsComponent={mode ? AddNewBranch : BranchExpanded}
                  expandableRowExpanded={(row) => row.id === editBranchData?.id}
                  onRowClicked={onRowClick}
                  editBranchId={editBranchData?.id}
                  expandOnRowClicked
                  pagination
                  paginationResetDefaultPage={false}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 15, 20, 30]}
                  separatePagination
                  expandableRowsComponentProps={
                    mode
                      ? {
                          key: companyFromContext?.branches,
                          branchData: editBranchData,
                          number: companyFromContext?.branches?.length,
                          companyDetails: companyFromContext,
                          onClose: onRowClick,
                          refetchBranches: refetchBranches,
                          countries: countries,
                          setToast: setToast,
                          mode,
                          countryCode: countryCode,
                        }
                      : { countryCode }
                  }
                />
              )}
            </div>
          </div>
        </Box>
      )}
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </div>
  );
};

export default CompanySetup;
