import * as Button from '../../UI/Forms/Button.jsx';
import styles from '../../../styles/accountBillings.module.css';
import tableStyles from '../../../styles/home.module.css';
import spmsServiceService from '../../../services/spmsService.service.js';
import { useStore } from '../../../store/store.js';

const SubscriptionColumn = ({ sub, isSelected, buttonText, setToast }) => {
  const companyId = useStore((state) => state.company?.id);
  const subscriptionChanged = useStore((state) => state.subscriptionChanged);
  const setSubscriptionChanged = useStore((state) => state.setSubscriptionChanged);
  const tableData = [
    {
      value: sub.maxUsers > 10 ? 'Unlimited' : `1 - ${sub.maxUsers} `,
      text: 'users',
      image: !!sub.maxUsers,
    },
    {
      value: sub.primaryLegalEntities,
      text: 'Primary Legal Entities',
      image: !!sub.primaryLegalEntities,
    },
    {
      value: !!sub.maxSubsidiaries
        ? `${sub.maxSubsidiaries > 10 ? 'Unlimited' : `Max ${sub.maxSubsidiaries}`}`
        : '',
      text: 'Subsidiaries',
      image: !!sub.maxSubsidiaries,
    },
    {
      value:
        sub.maxBranches === 1
          ? 'Single'
          : sub.maxBranches > 10
          ? 'Unlimited'
          : `Up to ${sub.maxBranches}`,
      text: `Branch${sub.maxBranches > 1 ? 'es' : ''}`,
      image: !!sub.maxBranches,
    },
    {
      value: sub.departmentsEnabled && 'Unlimited',
      text: 'Departments',
      image: !!sub.departmentsEnabled,
    },
    {
      value: sub.glManagementEnabled && 'Unlimited',
      text: 'General Ledgers',
      image: !!sub.glManagementEnabled,
    },
    {
      value: sub.allowCustomRBACRoles ? 'Advanced' : 'Standard',
      text: 'Role Based Access',
      image: true,
    },
    {
      value: sub.requisitionsEnabled && 'Unlimited',
      text: `Requisitions`,
      image: !!sub.requisitionsEnabled,
    },
    {
      value: sub.purchaseOrdersEnabled && 'Unlimited',
      text: `Purchase Orders`,
      image: !!sub.purchaseOrdersEnabled,
    },
    {
      value: sub.vendorManagementEnabled && 'Unlimited',
      text: `Vendors`,
      image: !!sub.vendorManagementEnabled,
    },
    {
      value:
        !!sub.maxAnnualBudgets || !!sub.maxProjectBudgets
          ? sub.maxProjectBudgets < 10
            ? `Max ${sub.maxAnnualBudgets + sub.maxProjectBudgets} budgets`
            : 'Unlimited'
          : '',
      text:
        !!sub.maxAnnualBudgets || !!sub.maxProjectBudgets
          ? sub.maxProjectBudgets < 10
            ? `(${sub.maxAnnualBudgets} annual + ${sub.maxProjectBudgets} project)`
            : 'budgets'
          : 'Budget Management',
      image: !!sub.maxAnnualBudgets && !!sub.maxProjectBudgets,
    },
    {
      value: !!sub.maxRQPOApprovalRules
        ? `Advanced - ${
            sub.maxRQPOApprovalRules > 10 ? 'Unlimited' : `max ${sub.maxRQPOApprovalRules}`
          } rules`
        : 'Standard',
      text: 'RQ/PO Approval Management',
      image: true,
    },
    {
      text: `Budget Approval Management`,
      image: !!sub.budgetApprovalManagement,
    },
    {
      text: `Vendor Approval Management`,
      image: !!sub.vendorApprovalManagement,
    },
    {
      text: `Approval on the go (Mobile)`,
      image: !!sub.approvalOnTheGoEnabled,
    },
    {
      value: sub.allowCustomNotificationManagement ? 'Advanced' : 'Standard',
      text: 'Notification Management',
      image: true,
    },
    {
      text: `Reporting`,
      image: !!sub.reportingEnabled,
    },
    {
      text: `Integration`,
      image: !!sub.integrationEnabled,
    },
    {
      text: sub.supportType,
      image: true,
    },
    {
      text: `${sub.systemAuditLogEnabled ? '' : 'No'} System Audit`,
      image: sub.systemAuditLogEnabled,
    },
  ];
  const images = {
    true: '/assets/img/home/pricing-yes.svg',
    false: '/assets/img/home/pricing-no.svg',
  };

  const changeSubscriptionPlan = () => {
    setToast((item) => ({ ...item, opened: false }));

    const requestBody = {
      planId: sub.id,
      cycleType: 'MONTHLY',
    };
    spmsServiceService
      .changeCompanySubscription(companyId, requestBody)
      .then((res) => {
        console.log('RESULT', res);
        setSubscriptionChanged(!subscriptionChanged);
        setToast({
          opened: true,
          message: 'The subscription has been changed',
          type: 'success',
        });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          opened: true,
          message:
            err.response.data.message !== undefined
              ? err.response.data.message
              : err.message.toString(),
          type: 'fail',
        });
      });
  };

  return (
    <div>
      <div className={tableStyles.pricingCol}>
        <div
          className={`${tableStyles.pricingTableHead} ${
            isSelected && tableStyles.pricingTableHeadSpecial
          }`}
        >
          <div className={tableStyles.pricingTableHeadTop}>
            <h3 className={tableStyles.pricingTableHeadTitle}>{sub.name}</h3>
            <p className={tableStyles.pricingTableHeadDescription}>Ideal for Small Businesses</p>
          </div>
          <div className={tableStyles.pricingTableHeadContent}>
            <div className={tableStyles.pricingTableHeadPrice}>
              <span>
                $ {sub.billingCycles.find((cycle) => cycle.cycleType === 'MONTHLY').price}
              </span>
              <span className={tableStyles.pricingTableHeadPricePeriod}>/m</span>
            </div>
            <div className={tableStyles.pricingTableHeadPriceDescription}>When Paid Monthly</div>
            <div className={styles.upgradeButton}>
              {buttonText && (
                <Button.Main onClick={changeSubscriptionPlan} $primary $style="pink">
                  {/* {buttonText} */}
                  Subscribe
                </Button.Main>
              )}
            </div>
          </div>
        </div>
        {tableData.map((box, index) => (
          <div key={index} className={tableStyles.pricingBox}>
            <img src={images[box.image]} alt="Yes" />
            <span>
              <strong>{box.value}</strong> {box.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionColumn;
