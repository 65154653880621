import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '../../UI/General/Box';
import styles from '../../../styles/companySetup.module.css';
import Input from '../../UI/Forms/Input';
import * as Button from '../../UI/Forms/Button.jsx';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Checkbox from '../../UI/Forms/Checkbox';
import Text from '../../UI/Typography/Text';
import Icon from '../../UI/General/Icon';
import generalStyles from '../../../styles/general.module.css';
import DataTableBase from '../../../components/UI/General/DataTableBase';
import Dropdown from '../../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../../components/UI/General/Menu';
import Label from '../../UI/Forms/Label';
import File from '../../UI/Forms/File';
import AddNewBranch from './AddNewBranch';
import spmsServiceService from '../../../services/spmsService.service';
import Toast from '../../UI/General/Toast';
import { confirmAlert } from 'react-confirm-alert';
import { useStore } from '../../../store/store';
import CountryAndRegion from '../../UI/Forms/CountryAndRegion';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyExtendedSchema } from '../../../utils/validation/companySchema.js';
import BranchExpanded from '../../UI/General/BranchExpanded.jsx';
import TabbedNav from '../../UI/General/TabbedNav.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import requestsServiceService from '../../../services/requestsService.service';
import useGetUserLocation from '../../../hooks/useGetUserLocation.jsx';
import AddButton from '../../shared/AddButton';
import Export from '../../shared/Export';

const options = [
  { value: 'Construction', label: 'Construction' },
  { value: 'Consulting Firms', label: 'Consulting Firms' },
  { value: 'Education', label: 'Education' },
  { value: 'Financial', label: 'Financial' },
  { value: 'FMCGs', label: 'FMCGs' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Logistics & Supplies', label: 'Logistics & Supplies' },
  { value: 'Retail & Ecommerce', label: 'Retail & Ecommerce' },
];

const AddNewSubsidiary = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(companyExtendedSchema),
  });
  const parentCompany = useStore((state) => state.company);
  const countries = useStore((state) => state.countries);
  const user = useStore((state) => state.user);
  const updateUser = useStore((state) => state.updateUser);
  const [company, setCompany] = useState(null);
  const setParentCompany = useStore((state) => state.setCompany);
  const [editBranchData, setEditBranchData] = useState(null);
  const [mode, setMode] = useState(null);
  const [branches, setBranches] = useState([]);
  const [files, setFiles] = useState({ companyLogo: ''});
  const [isActiveTab, setIsActiveTab] = useState(true);
  const countryCode = useGetUserLocation();
  const [companyImages, setCompanyImages] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const { id: subsidiaryId } = useParams();
  const navigate = useNavigate();
  const companyCountry = watch('country');
  const requisitionsRequired = watch('requisitionsRequired');

  console.log('branches', branches);

  useEffect(() => {
    if (!subsidiaryId || !countries) return;
    spmsServiceService.getCompany(subsidiaryId).then((r) => {
      if (r.data.status === 'success') {
        console.log(r.data.data)
        const { defaultCurrency, industry, country, region, invoiceVarianceProperties, ...other } = r.data.data;
        const updCurrency = {
          label: defaultCurrency,
          value: defaultCurrency,
        };
        const updIndustry = options.find((item) => item.value === industry);
        const updCountry = countries.find((item) => item.value === country);
        reset({
          ...other,
          defaultCurrency: updCurrency,
          industry: updIndustry,
          country: updCountry,
          allowedVarianceAmount: invoiceVarianceProperties.allowedVarianceAmount ?? 0,
          allowedVariancePercentage: invoiceVarianceProperties.allowedVariancePercentage ?? 0,
        });
        setBranches(r.data.data.branches);
        setCompany(r.data.data);
      }
    });
  }, [countries, subsidiaryId]);

  useEffect(() => {
    if (!countries || !companyCountry) return undefined;
    const selectedCountryCurrency = countries.find(
      (country) => country.value === companyCountry.value,
    )?.currency;
    setValue('defaultCurrency', { label: selectedCountryCurrency, value: selectedCountryCurrency });
  }, [countries, companyCountry]);

  const createCurrencyOptions = useCallback(() => {
    if (!countries || !countries.length) return;
    const allCurrencies = countries.map((country) => country.currency).sort();
    const uniqueCurrencies = [...new Set([...allCurrencies])];
    return uniqueCurrencies.map((currency) => ({ label: currency, value: currency }));
  }, [countries]);

  useEffect(() => {
    const getCompanyImages = async () => {
      if (!company) return;
      let companyLogo;
      if (company.logo) {
        const { logo } = company;
        const res = await spmsServiceService.getAttachmentsByKey(logo.key);
        companyLogo = res.data.data;
      }
      setCompanyImages({ companyLogo });
    };
    getCompanyImages();
  }, [company]);

  async function submitSubsidiary(data) {
    let { industry, country, defaultCurrency, allowedVarianceAmount, allowedVariancePercentage, ...prevData } = data;
    let finalData = {
      ...prevData,
      industry: industry.value,
      country: country.value,
      parentId: parentCompany.id,
      subsidiary: true,
      defaultCurrency: defaultCurrency.value,
      companyLogo: files.companyLogo !== '' ? files.companyLogo : undefined,
      invoiceVarianceProperties: {
        allowedVarianceAmount: parseFloat(allowedVarianceAmount),
        allowedVariancePercentage: parseInt(allowedVariancePercentage)
      },
    };
    console.log(finalData)
    setToast((item) => ({ ...item, opened: false }));
    if (!subsidiaryId) {
      spmsServiceService
        .createCompany(finalData)
        .then((res) => {
          if (res.data.status === 'success') {
            reset(data);
            setParentCompany(parentCompany.id);
            navigate(`/subsidiary-setup/edit/${res.data.data.id}`);
            setToast({
              opened: true,
              message: 'Success! You can now create branches',
              type: 'success',
            });
            if (user !== undefined) {
              requestsServiceService
                .updateUserCompany({
                  companyId: res.data.data.id,
                  companyName: finalData.name,
                  userId: user.id,
                })
                .then((response) => {
                  console.log(response.data);
                  updateUser(response.data.data);
                })
                .catch((error) => {
                  console.log('error ' + error);
                });
            }
          } else {
            confirmAlert({
              message: 'Error  ' + res.data.message,
              buttons: [
                {
                  label: 'Ok',
                },
              ],
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            if (error.response.data.errors != undefined)
              for (var i = 0; i < error.response.data.errors.length; i++)
                setError(error.response.data.errors[i].field, {
                  type: 'required',
                  message: error.response.data.errors[i].message,
                });
            else
              setToast({
                opened: true,
                message: error.response.data.message.toString(),
                type: 'fail',
              });
          } else
            confirmAlert({
              message: error.message,
              buttons: [
                {
                  label: 'Ok',
                },
              ],
            });
        });
    } else {
      finalData = { ...finalData, id: company.id };
      spmsServiceService
        .updateCompany(finalData)
        .then((res) => {
          if (res.data.status === 'success') {
            setCompany({ name: res.data.data.name, id: res.data.data.id });
            setParentCompany(parentCompany.id);
            reset(data);
            setToast({
              opened: true,
              message: 'Success!',
              type: 'success',
            });
          } else {
            confirmAlert({
              message: 'Error  ' + res.data.message,
              buttons: [
                {
                  label: 'Ok',
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 400) {
            if (error.response.data.errors != undefined)
              for (var i = 0; i < error.response.data.errors.length; i++)
                setError(error.response.data.errors[i].field, {
                  type: 'required',
                  message: error.response.data.errors[i].message,
                });
            else
              setToast({
                opened: true,
                message: error.response.data.message.toString(),
                type: 'fail',
              });
          } else
            confirmAlert({
              message: error.message,
              buttons: [
                {
                  label: 'Ok',
                },
              ],
            });
        });
    }
  }

  const readFile = (event) => {
    let filereader = new FileReader();
    if (!event.target.files.length) return;
    const fileType = event.target.files[0].name.split('.').at(-1);
    filereader.readAsDataURL(event.target.files[0]);

    filereader.onload = function () {
      setFiles({
        ...files,
        [event.target.name]: {
          document: filereader.result,
          name: `${event.target.name}.${fileType}`,
        },
      });
    };
    filereader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const addNewBranch = () => {
    setEditBranchData({
      address_two: undefined,
      address_one: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      country: undefined,
      email: undefined,
      phoneNumber: undefined,
      name: undefined,
    });
    setMode('new');
  };

  const editBranch = (branchData) => {
    setEditBranchData({
      address_two: branchData.address.addressLine2,
      address_one: branchData.address.streetAddress,
      city: branchData.address.city,
      state: branchData.address.state,
      zip: branchData.address.zipCode,
      country: branchData.address.country,
      email: branchData.email,
      id: branchData?.id,
      phoneNumber: branchData.phoneNumber,
      name: branchData.name,
    });
    setMode('edit');
  };

  const branchColumns = useMemo(
    () => [
      {
        name: 'Branch Name',
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: 'Email Address',
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: 'City',
        selector: (row) => row.address.city,
        sortable: true,
      },
      {
        name: 'Region',
        selector: (row) => row.address.state,
        sortable: true,
      },
      {
        name: 'Country',
        selector: (row) => row.address.country,
        sortable: true,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <Dropdown collapsible className={generalStyles.actionMenu}>
            <Dropdown.Header>
              <Button.Action $style="white" $width={32} $height={32}>
                <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
              </Button.Action>
            </Dropdown.Header>
            <Dropdown.Body>
              {isActiveTab ? (
                <Menu className={generalStyles.actionMenuList}>
                  {branches?.filter((branch) => branch.active).length !== 1 && (
                    <MenuItem onClick={() => archiveBranch(row)}>Archive</MenuItem>
                  )}
                  <MenuItem onClick={() => editBranch(row)}>Edit</MenuItem>
                </Menu>
              ) : (
                <Menu className={generalStyles.actionMenuList}>
                  <MenuItem onClick={() => activateBranch(row)}>Activate</MenuItem>
                </Menu>
              )}
            </Dropdown.Body>
          </Dropdown>
        ),
      },
    ],
    [branches, isActiveTab],
  );

  const toExport = branches?.map((item) => ({
    'Branch Name': item.name,
    'Email Address': item.email,
    City: item.address.city,
    Region: item.address.state,
    Country: item.address.country,
    Status: item.active !== false ? 'Active' : 'Archived',
  }));

  const refetchBranches = () => {
    if (!subsidiaryId) return;
    spmsServiceService
      .getCompanyBranches(subsidiaryId)
      .then((res) => {
        if (res.data.status == 'success') {
          setBranches(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        confirmAlert({
          message: error.message,
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
      });
  };

  const archiveBranch = async (branch) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveBranch(company.id, branch.id)
      .then((res) => {
        if (res.data.status === 'success') {
          refetchBranches();
          setToast({
            opened: true,
            message: 'Success!',
            type: 'success',
          });
        }
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.message.toString(),
          type: 'fail',
        });
      });
  };

  const activateBranch = async (branch) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateBranch(company.id, branch.id)
      .then((res) => {
        if (res.data.status === 'success') {
          refetchBranches();
          setToast({
            opened: true,
            message: 'Success!',
            type: 'success',
          });
        }
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.message.toString(),
          type: 'fail',
        });
      });
  };

  const onRowClick = () => {
    setMode(null);
    setEditBranchData(null);
  };

  const onDiscard = () => {
    navigate('/subsidiary-setup');
  };

  return (
    <div>
      <Box $mobExtend $asHolder>
        <form onSubmit={handleSubmit(submitSubsidiary)} className={styles.form} id="cForm">
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Legal Registered Name"
                    $labelRequired
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Legal Registered Name"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="tradingName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Trading Name"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Trading Name"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.tradingName && <p className="error-message">{errors.tradingName?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="registrationNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Registration Number"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Registration Number"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.registrationNumber && (
                <p className="error-message">{errors.registrationNumber?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label $title="Phone Number" $isRequired />
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  validate: {
                    isValid: (value) =>
                      isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || ''),
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <PhoneInput
                    defaultCountry={countryCode}
                    limitMaxLength={true}
                    {...field}
                    international
                    placeholder="Enter Phone Number"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                  />
                )}
              />
              {errors.phoneNumber && <p className="error-message">{errors.phoneNumber?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    $labelRequired
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Email Address"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="error-message">{errors.email?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="vatNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter VAT Number"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="VAT Number"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.vatNumber && <p className="error-message">{errors.vatNumber?.message}</p>}
            </div>
            <CountryAndRegion
              countryFieldName="country"
              control={control}
              isDisable={false}
              countries={countries}
              errors={errors}
            />
            <div className="inp-container">
              <Label $title="Currency" $isRequired />
              <Controller
                name="defaultCurrency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Currency *"
                    options={createCurrencyOptions()}
                  />
                )}
              />
              {errors.defaultCurrency && (
                <p className="error-message">{errors.defaultCurrency?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label $title="Industry" $isRequired />
              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Industry *"
                    options={options}
                  />
                )}
              />
              {errors.industry && <p className="error-message">{errors.industry?.value.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="requisitionsRequired"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    $size={22}
                    label="Requisitions Required"
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('requisitionsApprovedToPO', false);
                    }}
                  />
                )}
              />
            </div>
            <div className="inp-container">
              <Controller
                name="requisitionsApprovedToPO"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    $size={22}
                    label={
                      <span style={!requisitionsRequired ? { color: '#C4C4C4' } : {}}>
                        Requisitions Approved to PO
                      </span>
                    }
                    disabled={!requisitionsRequired}
                  />
                )}
              />
            </div>
            <div/>
            <div className="inp-container">
              <Controller
                name="allowedVarianceAmount"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Invoice Variance Amount"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Invoice Variance Amount"
                    $tooltip="Invoice Variance Amount tooltip"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.allowedVarianceAmount && <p className="error-message">{errors.allowedVarianceAmount?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="allowedVariancePercentage"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Invoice Variance Percentage"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Invoice Variance Percentage"
                    $tooltip="Invoice Variance Percentage tooltip"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.allowedVariancePercentage && <p className="error-message">{errors.allowedVariancePercentage?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Company Logo" />
              <Controller
                name="companyLogo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <File
                    $isInvalid={errors.hasOwnProperty(field.name)}
                    {...field}
                    value={field.value.filename}
                    $picture={
                      field.value !== '' && field.value instanceof Blob
                        ? URL.createObjectURL(field.value)
                        : field.value !== '' && !(field.value instanceof Blob)
                        ? 'data:image/jpg;base64,' + field.value
                        : companyImages?.companyLogo
                        ? companyImages?.companyLogo
                        : ''
                    }
                    onChange={(e) => {
                      field.onChange({
                        target: { value: e.target.files[0], name: field.name },
                      });
                      readFile(e);
                    }}
                    accept=".jpg, .jpeg, .png, .gif, .ico, .svg, .bmp, .pdf"
                  />
                )}
              />
              {errors.companyLogo && <p className="error-message">{errors.companyLogo?.message}</p>}
            </div>
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main $primary $style="pink" type="submit" form="cForm" disabled={!isValid || !isDirty}>
              {!subsidiaryId ? 'Save' : 'Update'}
            </Button.Main>
            {!subsidiaryId && (
              <Button.Main $primary onClick={onDiscard} type="button" $style="gray">
                Discard
              </Button.Main>
            )}
          </div>
        </form>
      </Box>
      {subsidiaryId && (
        <>
          <br />
          <br />
          <Box $mobExtend $asHolder>
            <Text className={styles.sectionTitle} type="h3" weight={600}>
              Branches
            </Text>
            <div className={generalStyles.tabSection}>
              <TabbedNav>
                <span
                  onClick={() => {
                    setIsActiveTab(true);
                  }}
                >
                  Active
                </span>
                <span
                  onClick={() => {
                    setIsActiveTab(false);
                  }}
                >
                  Archive
                </span>
              </TabbedNav>
              {mode !== 'new' && (
                <div className={generalStyles.addItemButton}>
                  <AddButton $click={addNewBranch} />
                  <Export data={toExport} name={company?.name + '-branches'} />
                </div>
              )}
            </div>
            <div className={styles.branchesSection}>
              {mode === 'new' && (
                <AddNewBranch
                  key={branches}
                  branchData={editBranchData}
                  number={branches?.length}
                  companyDetails={company}
                  onClose={onRowClick}
                  refetchBranches={refetchBranches}
                  countries={countries}
                  setToast={setToast}
                  mode={'new'}
                />
              )}
              {mode === 'new' && !branches?.length ? null : (
                <DataTableBase
                  data={branches?.filter((branch) => branch.active === isActiveTab)}
                  columns={branchColumns}
                  expandableRows
                  expandableRowsComponent={mode ? AddNewBranch : BranchExpanded}
                  expandableRowExpanded={(row) => row.id === editBranchData?.id}
                  onRowClicked={onRowClick}
                  editBranchId={editBranchData?.id}
                  expandOnRowClicked
                  expandableRowsComponentProps={
                    mode
                      ? {
                          key: branches,
                          branchData: editBranchData,
                          number: branches?.length,
                          companyDetails: company,
                          onClose: onRowClick,
                          refetchBranches: refetchBranches,
                          countries: countries,
                          mode: 'edit',
                          setToast: setToast,
                        }
                      : null
                  }
                />
              )}
            </div>
          </Box>
          {subsidiaryId && (
            <div className={styles.backOverviewButton}>
              <Button.Main
                $primary
                onClick={() => navigate('/subsidiary-setup')}
                type="button"
                $style="pink"
              >
                Back to Overview
              </Button.Main>
            </div>
          )}
        </>
      )}
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </div>
  );
};

export default AddNewSubsidiary;
