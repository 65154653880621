import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "../Typography/Text";
import Icon from "../General/Icon";
import { useWatch } from "react-hook-form";

const passwordSchema = [
	{
		value: /.{8,}/,
		text: "Be a minimum of 8 characters",
	},
	{
		value: /[a-z]+/,
		text: "Have at least one lowercase letter (a-z).",
	},
	{
		value: /[A-Z]+/,
		text: "Have at least one uppercase letter (A-Z).",
	},
	{
		value: /\d+/,
		text: "Have at least one number (0-9).",
	},
	{
		value: /[!@#$%&?]+/,
		text: "Have at least one of these symbols: (!@#$%&?)",
	},
	{
		value: /^[A-Za-z0-9!@#$%&?]*$/,
		text: "Only alphanumeric characters or the symbols listed above are allowed.",
	},
];

const PasswordTestWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const PasswordTestRow = styled.div`
	display: flex;
	align-items: center;
`;

const PasswordTestIndicator = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-right: 12px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	background-color: ${({ $status }) =>
		$status === true ? "#45BABC" : $status === false ? "#CD3E27" : "#595858"};
`;

const Row = ({ reg, text, isDirty, name, control }) => {
	const passwordWatch = useWatch({ name: name, control });
	const [status, setStatus] = useState(null);
	useEffect(() => {
		if (isDirty[name] !== undefined) {
			if (reg.test(passwordWatch)) {
				setStatus(true);
			} else {
				setStatus(false);
			}
		} else {
			setStatus(null);
		}
	}, [passwordWatch, isDirty, name, reg]);
	return (
		<PasswordTestRow>
			<PasswordTestIndicator $status={status}>
				{status === true ? (
					<Icon $icon="marked" $color="white" $width={14} $height={14} />
				) : status === false ? (
					<Icon $icon="close" $color="white" $width={8} $height={8} />
				) : null}
			</PasswordTestIndicator>
			<Text type="body-1" weight={400}>
				{text}
			</Text>
		</PasswordTestRow>
	);
};

const PasswordTest = ({ name, isDirty, control }) => {
	return (
		<PasswordTestWrapper>
			{passwordSchema && passwordSchema.length > 0
				? passwordSchema.map((el, i) => (
						<Row
							key={i}
							text={el.text}
							reg={el.value}
							isDirty={isDirty}
							name={name}
							control={control}
						/>
				  ))
				: null}
		</PasswordTestWrapper>
	);
};

export default PasswordTest;
