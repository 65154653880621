import React from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {useStore} from "../../store/store";
import spmsServiceService from "../../services/spmsService.service";

const SelectDepartments = (props) => {
  const activeCompany = useStore((state) => state.activeCompany);

  const fetchDepartments = async (search, loadedOptions, { page }) => {
    const result = await spmsServiceService.searchDepartments(activeCompany.id, page, 10, {active: true, name: search}).catch(err => {
      console.log(err)
      return undefined
    });
    const finalData = result?.data?.data?.content?.map((item) => ({label: item.name, value: item.id}))
    const hasMore = finalData !== undefined && result.data.data.totalElements > 0 ? loadedOptions.length < result.data.data.totalElements : false;
    return {
      options: finalData || [],
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchDepartments}
      classNamePrefix="react-select"
      isSearchable={true}
      placeholder="Select Department"
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectDepartments;
