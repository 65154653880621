import { Controller } from 'react-hook-form';
import Label from '../../UI/Forms/Label';
import MultiSelectOpened from '../../UI/Forms/MultiSelectOpened';
import styles from '../../../styles/accountBillings.module.css';
import Text from '../../UI/Typography/Text';

const ContactConfig = ({ name, title, description, errors, control, options }) => {
  return (
    <div className={styles.contactConfig}>
      <div className={styles.contactDescription}>
        <Text type="h5">{title}</Text>
        <Text type="h5" weight={500}>
          {description}
        </Text>
      </div>
      <div className={styles.contactUserSelect}>
        <div className="inp-container">
          <Label>Select User</Label>
          <Controller
            name={name}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
            }}
            render={({ field }) => (
              <MultiSelectOpened
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                placeholder="Search Users"
                options={options}
                control={control}
              />
            )}
          />
          {errors.primary && <p className="error-message">{errors[name].message}</p>}
        </div>
      </div>
    </div>
  );
};

export default ContactConfig;
