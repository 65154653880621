import React, {forwardRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import Label from './Label';

const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Counter = styled.div`
  margin-top: 12px;
  margin-left: auto;
  font-size: 12px;
`;

const StyledTextarea = styled.textarea`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ $low }) => ($low ? '120px' : '220px')};
  padding: ${({ $small }) => ($small ? '14px 10px' : '14px 20px')};
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: ${({ $small }) => ($small ? '12px' : '16px')};
  line-height: 1.4em;
  color: #0c0c0c;
  border: 1px solid #e4e2e2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  outline: none;
  resize: none;
  &:disabled {
    background-color: #f4f4f4;
    border: 1px solid #e2e3e4;
  }
  &::placeholder {
    color: #8a8b9d;
  }
  &:focus,
  &:focus-visible {
    border-color: #595858;
  }
  &.success {
    border-color: #69d08f;
  }
  &.error {
    border-color: #cd3e27;
  }
`;

const Textarea = forwardRef((props, ref) => {
  const [chars, setChars] = useState(0);
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
    setChars(e.target.value.length);
  };
  useEffect(() => {
    if (props.$counter && props.value){
      setChars(props.value.length)
    }
  },[props.value, props.$counter])

  return (
    <>
      {props.$label && props.$label !== '' ? (
        <Label htmlFor={props.id} $title={props.$label} $isRequired={props.$labelRequired} $tooltipText={props.$tooltip}/>
      ) : null}
      {props.$counter && props.$counterMax ? (
        <TextareaWrapper>
          <StyledTextarea
            {...props}
            ref={ref}
            maxLength={props.$counterMax}
            onChange={(e) => handleChange(e)}
          />
          <Counter>
            {chars}/{props.$counterMax}
          </Counter>
        </TextareaWrapper>
      ) : (
        <StyledTextarea {...props} ref={ref} />
      )}
    </>
  );
});

export default Textarea;
