import * as yup from "yup";

const validFileExtensions = [
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'odt',
  'xls',
  'xlsx',
  'PSD',
  'dot',
  'csv',
  'txt',
  'rtf',
  'html',
  'htm',
];

const checkIfFilesAreCorrectType = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!validFileExtensions.includes(file.attachment.name.split('.').pop())) {
        return (valid = false);
      } else {
        return (valid = true);
      }
    });
  }
  return valid;
};

const checkFilesAreCorrectSize = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (file.attachment.size < 2097152) {
        return (valid = true);
      } else {
        return (valid = false);
      }
    });
  }
  return valid;
}

export const documentsSchema = yup
  .array()
  .test('file-type', `Only ${validFileExtensions.join(', ')} files are allowed`, checkIfFilesAreCorrectType)
  .test('file-size', 'Maximum file size is 2MB', checkFilesAreCorrectSize)
