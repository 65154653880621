import React, {useEffect, useMemo, useState} from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import DataTableBase from '../../../UI/General/DataTableBase';
import {Link, useParams} from 'react-router-dom';
import Text from '../../../UI/Typography/Text';
import spmsServiceService from '../../../../services/spmsService.service';
import moment from 'moment';
import {downloadExternal} from '../../../../utils/downloadExternal';
import generalStyles from "../../../../styles/general.module.css";
import Status from "../../../UI/Typography/Status";
import {useStore} from "../../../../store/store";
import Icon from "../../../UI/General/Icon";
import * as Button from "../../../UI/Forms/Button";

const ReceivingTab = (props) => {
  const {orderId} = useParams();
  const user = useStore((state) => state.user);
  const {refetch, shouldRefetch} = props;
  const [tableData, setTableData] = useState([]);

  const getDocument = (key, name) => {
    spmsServiceService.getAttachmentsByKey(key).then((res) => {
      const url = res.data.data;
      const fileType = name.split('.').at(-1);
      downloadExternal(url, fileType);
    });
  };

  const deleteItem = (itemId) => {
    props.setToast((item) => ({ ...item, opened: false }));
    spmsServiceService.deleteReceiveItemPurchaseOrder(orderId, itemId).then(() => {
      props.setToast({
        opened: true,
        message: 'Received Item has been deleted successfully',
        type: 'success',
        cb: () => (props.fetchReceiving(), props.$trigger(state => state +1)),
      });
    }).catch(err => {
      props.setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    })
  }

  const columns = useMemo(
    () => [
      {
        name: 'Received Date',
        sortable: true,
        selector: (row) => row.receivedDate,
        format: (row) => moment(row.receivedDate).format('DD/MM/YYYY'),
        cell: row => (
          <span data-tag="allowRowEvents" data-content={"Received Date"} className={generalStyles.tableValue}>
            <Link to={'/purchase-orders/' + orderId + '/received-items/' + row.id} className={styles.linkHighlighted} state={{from: props?.prevLoc}}>
              {moment(row.receivedDate).format('DD/MM/YYYY') ?? "-"}
            </Link>
          </span>
        ),
      },
      {
        name: 'Action By',
        sortable: true,
        selector: (row) => row?.createdBy?.name,
        cell: row => <span data-tag="allowRowEvents" data-content={"Action By"} className={generalStyles.tableValue}><span data-tag="allowRowEvents">{row?.createdBy?.name ?? "-"}</span></span>,
      },
      {
        name: 'Status',
        sortable: true,
        selector: row => row?.fullfillmentStatus,
        cell: (row) => (
          <>
            <span data-tag="allowRowEvents" data-content={"Status"} className={generalStyles.tableValue}>
              <Text
                data-tag="allowRowEvents"
                weight={500}
                type={'body-2'}
              >
                <Status value={row?.fullfillmentStatus} pill/>
              </Text>
            </span>
            {row?.createdBy?.userId === user.id && !props.viewOnly ? (
              <div className={generalStyles.actionMenuHolder}>
                <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={() => deleteItem(row?.id)} type="button">
                  <Icon $width={20} $height={20} $icon="delete" $color="#F24638"/>
                </Button.Action>
              </div>
            ) : null}
          </>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (orderId !== null) {
      spmsServiceService.getReceiveItemsPurchaseOrder(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          setTableData(r.data.data);
          shouldRefetch()
        }
      });
    }
  }, [orderId, refetch]);

  return (
    <DataTableBase
      data={tableData}
      columns={columns}
      pagination={false}
    />
  );
};

export default ReceivingTab;
