import React, { useState } from "react";
import style from "../../styles/log_reg.module.css";
import Box from "../UI/General/Box";
import Text from "../UI/Typography/Text";
import { Controller, useForm } from "react-hook-form";
import { Password } from "../UI/Forms/Input";
import * as Button from "../UI/Forms/Button";
import Icon from "../UI/General/Icon";
import { Auth } from "aws-amplify";
import Modal from "../UI/Modal/Modal";
import PasswordTest from "../UI/Forms/PasswordTest";
import Toast from "../UI/General/Toast";

const ResetPassword = ({ username, setStatus }) => {
	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, dirtyFields },
		getValues,
	} = useForm({ mode: "onChange" });
	const [showModalPassword, setShowModalPassword] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);
	const [showToast, setShowToast] = useState(false);

	async function forgotPasswordSubmit({ code, password }) {
		try {
			if (showToast !== false) {
				setShowToast(false);
			}
			const data = await Auth.forgotPasswordSubmit(username, code, password);
			setTimeout(() => {
				setStatus("sign-in");
			}, 5000);
			console.log(data);
		} catch (err) {
			setToastMessage(err);
			setShowToast(true);
			console.log(err);
		}
	}

	return (
		<>
			<div className={style.auth}>
				<div className={style.authInnerSmall}>
					<Box $radius={40} className={style.authContainer} $noPadding>
						<div className={style.authContent}>
							<div className={style.authHeader + " " + style.cnpHeader}>
								<img
									src="assets/img/login_register/create_new_password.svg"
									alt="create new password"
								/>
								<Text type="h2" weight={600}>
									Reset Password
								</Text>
							</div>
							<form
								onSubmit={handleSubmit(forgotPasswordSubmit)}
								className={style.authForm + " " + style.authFormSmall}
							>
								<div className={style.inputs + " " + style.inputsColomn}>
									<div className="inp-container">
										<Controller
											name="password"
											control={control}
											rules={{
												required: {
													value: true,
													message: "Password is required",
												},
												minLength: {
													value: 8,
													message: "At least 8 characters",
												},
												maxLength: {
													value: 35,
													message: "Maximum 35 characters",
												},
												validate: {
													oneLetter: (v) =>
														/[a-z]+/.test(v) || "At least one lowercase letter",
													oneCapitalLetter: (v) =>
														/[A-Z]+/.test(v) || "At least one capital letter",
													oneNumber: (v) =>
														/\d+/.test(v) || "At least one number",
													oneSpecialChar: (v) =>
														/[!@#$%&?]+/.test(v) ||
														"At least one special character",
													onlyAllowed: (v) =>
														/^[A-Za-z0-9!@#$%&?]*$/.test(v) ||
														"Not allowed symbols",
												},
											}}
											defaultValue=""
											render={({ field }) => (
												<Password
													placeholder="Password"
													$iconColor={
														errors.hasOwnProperty(field.name)
															? "#CD3E27"
															: "#8A8B9D"
													}
													className={
														errors.hasOwnProperty(field.name) && "error"
													}
													{...field}
												/>
											)}
										/>
										{errors.password && (
											<p className="error-message">
												{errors.password?.message}
											</p>
										)}
									</div>
									<div className="inp-container">
										<Controller
											name="repeat_password"
											control={control}
											rules={{
												required: {
													value: true,
													message: "Password is required",
												},
												validate: {
													isValid: (value) =>
														value === getValues("password") ||
														"Passwords don't match",
												},
											}}
											defaultValue=""
											render={({ field }) => (
												<Password
													placeholder="Re-Enter Password"
													$iconColor={
														errors.hasOwnProperty(field.name)
															? "#CD3E27"
															: "#8A8B9D"
													}
													className={
														errors.hasOwnProperty(field.name) && "error"
													}
													{...field}
												/>
											)}
										/>
										{errors.repeat_password && (
											<p className="error-message">
												{errors.repeat_password?.message}
											</p>
										)}
									</div>
								</div>
								<div className={style.passwordRequirements}>
									<Text weight={400} type="body-2">
										<span
											className="link"
											onClick={() => setShowModalPassword(true)}
										>
											Password requirements
										</span>
									</Text>
								</div>
								<div className={style.btn}>
									<Button.Main
										$primary
										$full
										$style="pink"
										$iconRight
										type="submit"
										disabled={!isDirty || !isValid}
									>
										<span>reset password</span>
										<Icon $width={24} $height={24} $icon="arrow-right" />
									</Button.Main>
								</div>
							</form>
						</div>
					</Box>
				</div>
			</div>
			<Modal
				$show={showModalPassword}
				$close={() => setShowModalPassword(false)}
				$title="Requirements"
				$radius={16}
				$closableOutside
			>
				<div className={style.modalSmallPadding}>
					<PasswordTest
						name={"password"}
						isDirty={dirtyFields}
						control={control}
					/>
				</div>
			</Modal>
			{toastMessage != null && showToast !== false ? (
				<Toast type="fail" message={toastMessage} opened={showToast} />
			) : null}
		</>
	);
};

export default ResetPassword;
