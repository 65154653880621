import { Controller } from 'react-hook-form';
import Label from '../../UI/Forms/Label';
import Select, {components} from 'react-select';
import DateFromTo from './DateFromTo';
import generalStyles from '../../../styles/general.module.css';
import Input from '../../UI/Forms/Input';
import React, { useEffect, useState } from 'react';
import spmsServiceService from '../../../services/spmsService.service';
import requestsService from '../../../services/requestsService.service';
import { useStore } from '../../../store/store';
import { vendorCategories } from '../../../constants/vendorCategories';
import Text from "../../UI/Typography/Text";

const GLOption = props => (
  <components.Option {...props}>
    <Text type={"body-2"} weight={600}>{props.data.label}</Text>
    <Text type={"body-3"}>{props.data.text}</Text>
  </components.Option>
);

const Filters = ({ control, errors, setValue, trigger, clearErrors, watch, filters, page }) => {
  const [dynamicOptions, setDynamicOptions] = useState({});
  const activeCompany = useStore((state) => state.activeCompany);

  const isOption = (option) => filters.indexOf(option) !== -1;
  const userType =
    page === 'requisitions' || page === 'po'
      ? 'Requester'
      : page === 'vendors'
      ? 'Approver'
      : 'Users';

  useEffect(() => {
    const options = {
      branches: handleOptions(activeCompany.branches),
    };
    if (options.branches.length === 1) setValue('branchId', options.branches[0]);
    Promise.all([
      isOption('department') &&
        spmsServiceService.getDepartments(activeCompany.id).then((res) => {
          options.departments = handleOptions(res.data.data);
          if (options.departments.length === 1) setValue('departmentId', options.departments[0]);
        }),
      isOption('gl') &&
        spmsServiceService
          .getGlAccounts(activeCompany.id)
          .then((res) => {
            // options.gls = handleOptions(res.data.data.content, 'glCode');
            options.gls = res.data.data.content.map(el => ({label: el.glCode, value: el.id, text: el.description}))
            console.log(options.gls)
          })
          .catch((err) => console.log(err)),
      (isOption('users') || isOption('jobDescription')) &&
        requestsService
          .getData(100, 0, {
            isEnabled: true,
            companyId: activeCompany.id,
          })
          .then((res) => {
            if (isOption('jobDescription')) {
              const positions = Array.from(
                new Set(
                  res.data.data.content
                    .map((user) => user.position)
                    .filter((position) => !!position),
                ),
              );
              options.jobDescription = positions.map((position) => ({
                label: position,
                value: position,
              }));
            } else {
              options.users = res.data.data.content.map((user) => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user.email,
              }));
            }
          }),
      isOption('vendor') &&
        spmsServiceService
          .getVendorsList({
            companyId: activeCompany.id,
          })
          .then((res) => {
            options.vendors = res.data.data.content.map((item) => ({
              label: item.vendor.legalName,
              value: item.vendor.id,
            }));
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 404) options.vendors = [];
          }),
    ]).then((_res) => {
      for (const value of Object.values(options)) {
        value.unshift({ label: 'All', value: '' });
      }
      setDynamicOptions(options);
    });
  }, []);

  const handleOptions = (data, label, value) => {
    if (!data) return [];

    return data.map((item) => ({
      label: label ? item[label] : item.name,
      value: value ? item[value] : item.id,
    }));
  };

  const statusOptions = {
    requisitions: [
      { label: 'All', value: '' },
      { label: 'Approved', value: 'Approved' },
      { label: 'Pending', value: 'PENDING_APPROVAL' },
      { label: 'Rejected', value: 'REJECTED' },
      { label: 'Draft', value: 'DRAFT' },
    ],
    po: [
      { label: 'All', value: '' },
      { label: 'Approved', value: 'Approved' },
      { label: 'Pending', value: 'PENDING_APPROVAL' },
      { label: 'Rejected and Draft', value: 'REJECTED_DRAFT' },
      { label: 'Pending Delivery', value: 'PENDING_Delivery' },
      { label: 'Pending Invoice', value: 'PENDING_Invoice' },
      { label: 'Pending Payment', value: 'PENDING_Payment' },
      { label: 'Closed', value: 'CLOSED' },
    ],
    budgets: [
      { label: 'All', value: '' },
      { label: 'Active', value: 'APPROVED' },
      { label: 'Archived', value: 'ARCHIVED' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Rejected and Draft', value: 'DRAFT' },
    ],
    vendors: [
      { label: 'All', value: '' },
      { label: 'Active', value: 'APPROVED' },
      { label: 'Archived', value: 'ARCHIVED' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Draft', value: 'DRAFT' },
    ],
    users: [
      { label: 'All', value: '' },
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
      { label: 'Invited', value: 'invited' },
      { label: 'All', value: 'all' },
    ],
    departments: [
      { label: 'All', value: '' },
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
    ],
  };

  const vendorType = [
    { label: 'All', value: '' },
    { label: 'SUPPLIER', value: 'SUPPLIER' },
  ];

  return (
    <div className={generalStyles.fieldsFive}>
      <div className="inp-container">
        <Label $title="Branch" $tooltipText="" />
        <Controller
          name={'branchId'}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isSearchable={false}
              placeholder="Select Branch"
              options={dynamicOptions.branches || []}
            />
          )}
        />
      </div>
      {isOption('department') && (
        <div className="inp-container">
          <Label $title="Department" $tooltipText="" />
          <Controller
            name={'departmentId'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Department"
                options={dynamicOptions.departments || []}
              />
            )}
          />
        </div>
      )}
      {isOption('type') && (
        <div className="inp-container">
          <Label $title="Type" $tooltipText="" />
          <Controller
            name={'type'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Type"
                options={vendorCategories}
              />
            )}
          />
        </div>
      )}
      {isOption('vendor') && (
        <div className="inp-container">
          <Label $title="Vendor" $tooltipText="" />
          <Controller
            name={'vendorId'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Vendor"
                options={dynamicOptions.vendors || []}
              />
            )}
          />
        </div>
      )}
      {isOption('gl') && (
        <div className="inp-container">
          <Label $title="General Ledger" $tooltipText="" />
          <Controller
            name={'glAccountId'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                components={{Option: GLOption}}
                getOptionLabel={(option) => (
                  <>
                    <div>{option.label}</div>
                    <div title={option.text} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{option.text}</div>
                  </>
                )}
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select GL"
                options={dynamicOptions.gls || []}
              />
            )}
          />
        </div>
      )}
      {isOption('users') && (
        <div className="inp-container">
          <Label $title={userType} $tooltipText="" />
          <Controller
            name={userType.toLowerCase()}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder={`Select ${userType}`}
                options={dynamicOptions.users || []}
              />
            )}
          />
        </div>
      )}
      {isOption('depManager') && (
        <div className="inp-container">
          <Label $title="Department Manager" $tooltipText="" />
          <Controller
            name={'departmentManager'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Manager"
                options={dynamicOptions.users || []}
              />
            )}
          />
        </div>
      )}
      {isOption('jobDescription') && (
        <div className="inp-container">
          <Label $title="Job Description" $tooltipText="" />
          <Controller
            name={'jobDescription'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Job Description"
                options={dynamicOptions.jobDescription || []}
              />
            )}
          />
        </div>
      )}
      <div className="inp-container">
        <Label $title="Status" $tooltipText="" />
        <Controller
          name={'status'}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isSearchable={false}
              placeholder="Select Status"
              options={statusOptions[page]}
            />
          )}
        />
      </div>
      <DateFromTo
        errors={errors}
        control={control}
        trigger={trigger}
        watch={watch}
        clearErrors={clearErrors}
        setValue={setValue}
      />
      <div className="inp-container">
        <Label $title="Search" $tooltipText="" />
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Search Reports"
              $iconName="search"
              className={errors.hasOwnProperty(field.name) ? 'error' : ''}
              id={field.name}
              {...field}
            />
          )}
        />
        {errors.search && <p className="error-message">{errors.search?.message}</p>}
      </div>
    </div>
  );
};

export default Filters;
