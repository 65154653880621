import React, { useMemo, useState } from 'react';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import { useStore } from '../../store/store';
import DataTableBase from '../../components/UI/General/DataTableBase';
import TabbedNav from '../../components/UI/General/TabbedNav';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import { Link, useNavigate } from 'react-router-dom';
import Box from '../../components/UI/General/Box';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import Export from '../../components/shared/Export';
import AddButton from '../../components/shared/AddButton';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import Modal from '../../components/UI/Modal/Modal';
import Text from '../../components/UI/Typography/Text';

const SubsidiarySetup = () => {
  const company = useStore((state) => state.company);
  const setParentCompany = useStore((state) => state.setCompany);
  const subsidiaries = company?.subsidiaries ?? [];
  const currentSubscription = useSubscriptionAllowed();
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(true);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();
  const toExport = subsidiaries.map((item) => ({
    'Company Name': item.name,
    'Registration Number': item.registrationNumber ?? '-',
    Country: item.country,
    Status: item?.active !== false ? 'Active' : 'Archived',
  }));
  const activateSubsidiary = (id) => {
    spmsServiceService
      .activateCompany(id)
      .then((_res) => {
        setParentCompany(company.id);
        setToast({
          opened: true,
          message: 'Activated',
          type: 'fail',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };
  const archiveSubsidiary = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveCompany(id)
      .then((_res) => {
        setParentCompany(company.id);
        setToast({
          opened: true,
          message: 'Archived',
          type: 'fail',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Company Name',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Company Name'} className={generalStyles.tableValue}>
            <span>{row.name}</span>
          </span>
        ),
      },
      {
        name: 'Registation Number',
        selector: (row) => row?.registrationNumber ?? '-',
        sortable: true,
        cell: (row) => (
          <span data-content={'Registation Number'} className={generalStyles.tableValue}>
            <span>{row.registrationNumber ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Country',
        sortable: true,
        selector: (row) => row.country,
        cell: (row) => (
          <span data-content={'Country'} className={generalStyles.tableValue}>
            <span>{row.country}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {!!tab ? (
                    <>
                      <Link to={`/subsidiary-setup/edit/${row.id}`}>
                        <MenuItem>Edit</MenuItem>
                      </Link>
                      <MenuItem onClick={() => archiveSubsidiary(row.id)}>Archive</MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        if (!currentSubscription) return;
                        else if (
                          subsidiaries?.filter((subsidiary) => subsidiary.active).length >=
                          currentSubscription?.plan?.maxSubsidiaries
                        ) {
                          setShowModal(true);
                          return;
                        } else activateSubsidiary(row.id);
                      }}
                    >
                      Activate
                    </MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, subsidiaries],
  );

  return (
    <Box $mobExtend $asHolder $radius={12}>
      <div className={generalStyles.tabSection}>
        <TabbedNav>
          <span onClick={() => setTab(true)}>Active</span>
          <span onClick={() => setTab(false)}>Archive</span>
        </TabbedNav>
        <div className={generalStyles.addItemButton}>
          {currentSubscription && (
            <AddButton
              $click={() => {
                if (
                  subsidiaries?.filter((subsidiary) => subsidiary.active).length >=
                  currentSubscription?.plan?.maxSubsidiaries
                ) {
                  setShowModal(true);
                  return;
                }
                navigate('/subsidiary-setup/add');
              }}
            />
          )}
          <Export data={toExport} name="Subsidiaries" />
        </div>
      </div>
      <DataTableBase
        data={subsidiaries?.filter((subsidiary) => subsidiary.active === tab)}
        columns={columns}
      />
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>
            The {currentSubscription?.plan?.name} Subscription Plan{' '}
            {currentSubscription?.plan?.maxSubsidiaries === 0
              ? 'does not allow for'
              : `only allows ${currentSubscription?.plan?.maxSubsidiaries}`}{' '}
            subsidiaries.
          </Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => navigate('/accounts-billing/subscription')}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default SubsidiarySetup;
