import Box from '../../components/UI/General/Box.jsx';
import styles from '../../styles/accountBillings.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { useMemo, useState } from 'react';
import Dropdown from '../../components/UI/General/Dropdown.jsx';
import Icon from '../../components/UI/General/Icon.jsx';
import { Menu, MenuItem } from '../../components/UI/General/Menu.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import DataTableBase from '../../components/UI/General/DataTableBase.jsx';
import AddPaymentModal from '../../components/Admins/AccountBillings/AddPaymentModal.jsx';

const AccountPayments = () => {
  const [showModal, setShowModal] = useState(false);

  const data = [
    {
      method: 'Mastercard ending in 1562',
      address: '100 ABC Street, 11th Floor, City Name, lorem ipsum, South Africa',
      expiry: 'Nov 11, 2023',
      products: 'Diamond Package',
    },
  ];
  const columns = useMemo(() => [
    {
      name: 'Payment Method',
      selector: (row) => row.method,
      sortable: true,
    },
    {
      name: 'Billing Address',
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: 'Expiry Date',
      selector: (row) => row.expiry,
      sortable: true,
    },
    {
      name: 'Products',
      selector: (row) => row.products,
      sortable: true,
    },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <Dropdown collapsible className={generalStyles.actionMenu}>
          <Dropdown.Header>
            <Button.Action $style="white" $width={32} $height={32}>
              <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
            </Button.Action>
          </Dropdown.Header>
          <Dropdown.Body>
            <Menu className={generalStyles.actionMenuList}>
              <MenuItem onClick={() => {}}>Edit</MenuItem>
            </Menu>
          </Dropdown.Body>
        </Dropdown>
      ),
    },
  ]);
  const closeModal = () => setShowModal(false);
  return (
    <>
      <div className={styles.addPaymentButton}>
        <Button.Main $primary $style="pink" onClick={() => setShowModal(true)}>
          <Icon $width={20} $height={20} $icon="add" />
          <Text weight={500} type="subtitle">
            Create Payment Method
          </Text>
        </Button.Main>
      </div>
      <DataTableBase data={data} columns={columns} />
      <AddPaymentModal showModal={showModal} closeModal={closeModal} />
    </>
  );
};

export default AccountPayments;
