import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Icon from '../../components/UI/General/Icon';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import spmsServiceService from '../../services/spmsService.service';
import { Link, useNavigate } from 'react-router-dom';
import ExpandedVendor from './ExpandedVendor';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { Suspense } from 'react';

const ActiveVendorOverview = ({ companyId, fetchVendors, setToast, setRefetchTrigger }) => {
  const navigate = useNavigate();
  const accessVendor = useAccessAllowed('Vendors');
  const archiveVendor = (row) => {
    setToast((item) => ({ ...item, opened: false }));
    if (!accessVendor?.delete) {
      setToast({
        opened: true,
        message: 'No permission to archive',
        type: 'fail',
      });
      return;
    }
    spmsServiceService
      .archiveVendor(companyId, row.id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Archived',
          type: 'success',
        });
        setRefetchTrigger((refetchTrigger) => !refetchTrigger);
      })
      .catch((err) => {
        console.log(err);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const columns = [
    {
      name: 'Vendor Name',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) =>
        accessVendor?.viewOwn ? (
          <span data-content={'Vendor Name'} className={generalStyles.tableValue}>
            <Link to={'/vendors/' + companyId + '/viewActive/' + row.vendor.id}>
              <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>
                {row.vendor.legalName}
              </span>
            </Link>
          </span>
        ) : (
          <span data-content={'Vendor Name'} className={generalStyles.tableValue}>
            {row.vendor.legalName}
          </span>
        ),
    },
    {
      name: 'Vendor Code',
      sortable: true,
      selector: (row) => row.vendorCode,
      cell: (row) => (
        <span data-content={'Vendor Code'} className={generalStyles.tableValue}>
          <span>{row.vendorCode}</span>
        </span>
      ),
    },
    // {
    //   name: 'Rating',
    //   cell: (row) => (
    //     <span data-content={'Rating'} className={generalStyles.tableValue}>
    //       <StarRating rating={Number(row.Rating)} />
    //     </span>
    //   ),
    // },
    {
      name: 'Department',
      cell: (row) => (
        <span data-content={'Department'} className={generalStyles.tableValue}>
          <span>{!!row.departments.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'Branch',
      cell: (row) => (
        <span data-content={'Branch'} className={generalStyles.tableValue}>
          <span>{!!row.branches.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'GL',
      cell: (row) => (
        <span data-content={'GL'} className={generalStyles.tableValue}>
          <span>{!!row.glAccounts.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'User',
      cell: (row) => (
        <span data-content={'User'} className={generalStyles.tableValue}>
          <span>{!!row.users.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <div className={generalStyles.actionMenuHolder}>
          <Dropdown collapsible className={styles.actionMenu}>
            <Dropdown.Header>
              <Button.Action $style="white" $width={32} $height={32}>
                <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
              </Button.Action>
            </Dropdown.Header>
            <Dropdown.Body>
              <Menu className={styles.actionMenuList}>
                {accessVendor?.edit && (
                  <MenuItem onClick={() => navigate(`/vendors/${companyId}/edit/${row.vendor.id}`)}>
                    Edit
                  </MenuItem>
                )}
                <MenuItem onClick={() => archiveVendor(row.vendor)}>Archive</MenuItem>
              </Menu>
            </Dropdown.Body>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <Suspense fallback={<h2 className={styles.loader}>Loading...</h2>}>
      <DataTableBaseRemote
        columns={columns}
        fetchData={fetchVendors}
        selectableRows={false}
        paginationResetDefaultPage={false}
        expandableRows
        expandableRowsComponent={ExpandedVendor}
        expandOnRowClicked
      />
    </Suspense>
  );
};

export default ActiveVendorOverview;
