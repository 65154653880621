import {axiosBaseInstance, axiosInstance, baseUrl, s2pmsEmailUrl} from "./API";

const s2pmsEmail = {
  getAllTemplates: (page, size) => axiosInstance.get(s2pmsEmailUrl + "/email-template?direction=DESC&orderBy=createdAt&page=" + page + "&size=" + size + ""),
  getTemplate: (id) => axiosInstance.get(s2pmsEmailUrl + "/email-template/" + id),
  createTemplate: (data) => axiosInstance.post(s2pmsEmailUrl + "/email-template", data),
  updateTemplate: (id, data) => axiosInstance.post(s2pmsEmailUrl + "/email-template/" + id, data),
  sendEmail: (data) => axiosBaseInstance.post(baseUrl + "/support/contact-us", data),
  getAllNotifications: (userId, status) => {
    const read = status !== undefined ? `read=${status}&` : ""
    return axiosInstance.get(s2pmsEmailUrl + "/notifications?"+read+"userId="+userId+"&direction=DESC&orderBy=timestamp&page=0&size=30")
  },
  updateNotification: (id) => axiosInstance.put(s2pmsEmailUrl + "/notifications/"+id+"/read"),
}

export default s2pmsEmail;
