import React from 'react';

const CreateAgent = () => {
  return (
    <div>
      Create Agent
    </div>
  );
};

export default CreateAgent;
