import React, {useState} from 'react';
import generalStyles from "../../styles/general.module.css";
import Text from "../UI/Typography/Text";
import * as Button from "../UI/Forms/Button";
import Modal from "../UI/Modal/Modal";
import {useNavigate} from "react-router-dom";

const UpgradePlan = ({text, asLink, triggerComponent}) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {asLink && triggerComponent === undefined ? (
        <span className={'link'} onClick={() => setShowModal(true)}>Upgrade</span>
      ) : triggerComponent === undefined ? (
        <Button.Main
          $style="pink"
          style={{marginLeft: 'auto'}}
          onClick={() => setShowModal(true)}
          type="button"
        >
          Upgrade
        </Button.Main>
      ) : triggerComponent(() => setShowModal(true))}
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>{text}</Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => {
              setShowModal(false);
              navigate('/accounts-billing/subscription');
            }}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
    </>
  );
};

export default UpgradePlan;
