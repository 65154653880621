import styles from '../../../../styles/approvalWorkflow.module.css';
import generalStyles from '../../../../styles/general.module.css';
import Rules from './Rules';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Box from '../../../../components/UI/General/Box';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Button from '../../../UI/Forms/Button.jsx';
import spmsServiceService from '../../../../services/spmsService.service.js';
import Approvers from './Approvers.jsx';
import getUsersAccess from '../helpers/getUsersAccess.js';
import Toast from '../../../UI/General/Toast.jsx';
import TabsSlider from '../../../UI/General/TabsSlider.jsx';
import BudgetVendorApproval from './BudgetVendorApproval.jsx';
import { useStore } from '../../../../store/store.js';

const CompanyContent = ({ companyId, selCompany }) => {
  const [rules, setRules] = useState(null);
  const [matrix, setMatrix] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);
  const [requisitionRequired, setRequisitionRequired] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const [approversOptions, setApproversOptions] = useState([]);
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [tab, setTab] = useState();
  const company = useStore((state) => state.company);
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      approvalHierarchy: 'DEFAULT_APPROVER',
    },
  });

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const tableRef = useRef(null);
  const preopenedRef = useRef(localStorage.getItem('preOpenedRule'));
  useEffect(() => {
    if (preopenedRef.current && tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [preopenedRef.current, tableRef.current]);

  useEffect(() => {
    if (selCompany !== companyId) return;
    const getApprovers = async () => {
      const approvers = await getUsersAccess(companyId, 'APPROVE', [
        tab === 'Vendor' ? 'Vendors' : tab === 'Budget' ? 'Budgets' : tab,
      ]);
      const activeApprovers = approvers.filter((approver) => approver.enabled);
      setApprovers(activeApprovers);
      setApproversOptions(
        activeApprovers.map((approver) => ({
          label: `${approver.firstName} ${approver.lastName}`,
          value: approver.id,
        })),
      );
    };
    getApprovers();
  }, [selCompany, tab]);

  useEffect(() => {
    const allCompanies = company.subsidiaries ? [company, ...company.subsidiaries] : [company];
    const currentCompany = allCompanies.find((company) => company.id === companyId);
    setTab(currentCompany.requisitionsRequired ? 'Requisition' : 'Purchase_Order');
    setRequisitionRequired(currentCompany.requisitionsRequired);
    spmsServiceService.getApprovalMatrix(companyId).then((res) => {
      setMatrix(res.data.data);
    });
  }, []);

  const getApprovalMatrix = () => {
    if (!matrix) return;
    const reqModule = matrix.find((module) => module.type === tab.toUpperCase());
    spmsServiceService.getApprovalModuleById(companyId, reqModule.id, isActiveTab).then((res) => {
      const module = res.data.data;
      setCurrentModule(module);
      if (['Requisition', 'Purchase_Order'].includes(tab)) {
        setRules(module.rules.slice().sort((a, b) => a.order - b.order));
        if (module.defaultApprover) {
          setValue(`${tab}-defaultApprover`, {
            label: module.defaultApprover.name,
            value: module.defaultApprover.userId,
          });
        }
      } else {
        if (module.rules.length) {
          const approversData = module.rules[0].approvers;
          const prevApproversOptions = approversData.map((approver) => ({
            label: approver.name,
            value: approver.userId,
          }));
          setValue('approvalHierarchy', 'Defined_List');
          setValue(`${tab}-approvers`, prevApproversOptions);
        } else {
          setValue('approvalHierarchy', 'DEFAULT_APPROVER');
          setValue(`${tab}-defaultApprover`, {
            label: module.defaultApprover.name,
            value: module.defaultApprover.userId,
          });
        }
      }
    });
  };
  useEffect(() => {
    getApprovalMatrix();
  }, [selCompany, isActiveTab, tab, matrix]);
  const handleApproverData = (newApprover) => {
    const { email, id, firstName, lastName } = approvers.find(
      (approver) => approver.id === newApprover.value,
    );
    return { userId: id, email, name: `${firstName} ${lastName}` };
  };

  const updateApprovalMatrix = (data) => {
    if (matrix) {
      setToast((item) => ({ ...item, opened: false }));
      const updatedModule = {
        ...currentModule,
      };
      if (['Requisition', 'Purchase_Order'].includes(tab)) {
        updatedModule.defaultApprover = handleApproverData(data[`${tab}-defaultApprover`]);
        updatedModule.rules = rules.map((rule, index) => ({ ...rule, order: index + 1 }));
      } else {
        if (data.approvalHierarchy === 'DEFAULT_APPROVER') {
          updatedModule.defaultApprover = handleApproverData(data[`${tab}-defaultApprover`]);
          updatedModule.rules = [];
        } else {
          const approversRule = {
            name: 'Approvers Rule',
            description: 'Rule Description',
            approvalMatrixRuleConditions: [],
            approvalHierarchyType: 'Defined_List',
            approvers: data[`${tab}-approvers`].map((approver) => handleApproverData(approver)),
            active: true,
          };
          updatedModule.defaultApprover = null;
          updatedModule.rules = currentModule.rules[0]
            ? [{ ...currentModule.rules[0], ...approversRule }]
            : [approversRule];
        }
      }
      spmsServiceService
        .editApprovalModuleById(companyId, currentModule.id, updatedModule)
        .then((res) => {
          setToast({
            opened: true,
            message: 'Approval Hierarchy updated',
            type: 'success',
          });
          getApprovalMatrix();
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.toString(),
            type: 'fail',
          });
        });
    }
  };

  const activateRule = async (ruleId) => {
    try {
      setToast((item) => ({ ...item, opened: false }));
      await spmsServiceService.activateApprovalMatrixRule(companyId, currentModule.id, ruleId);
      const res = await spmsServiceService.getApprovalModuleById(companyId, currentModule.id, true);
      const matrix = res.data.data;
      const matrixRules = matrix.rules;
      const activatedRule = matrixRules.find((rule) => rule.id === ruleId);
      const activatedRuleIndex = matrixRules.indexOf(activatedRule);
      matrixRules[activatedRuleIndex] = { ...activatedRule, order: matrixRules.length };
      const updatedModule = {
        ...matrix,
        rules: matrixRules,
      };
      await spmsServiceService.editApprovalModuleById(companyId, currentModule.id, updatedModule);
      getApprovalMatrix();
      setToast({
        opened: true,
        message: 'The rule has been activated',
        type: 'success',
      });
    } catch (err) {
      setToast({
        opened: true,
        message: err.message.toString(),
        type: 'fail',
      });
    }
  };

  const archiveRule = async (ruleId) => {
    try {
      setToast((item) => ({ ...item, opened: false }));
      await spmsServiceService.archiveApprovalMatrixRule(companyId, currentModule.id, ruleId);
      const filteredRules = rules.filter((rule) => rule.id !== ruleId);
      const updatedModule = {
        ...currentModule,
        rules: filteredRules.map((rule, index) => ({ ...rule, order: index + 1 })),
      };
      await spmsServiceService.editApprovalModuleById(companyId, currentModule.id, updatedModule);
      getApprovalMatrix();
      setToast({
        opened: true,
        message: 'The rule has been archived',
        type: 'success',
      });
    } catch (err) {
      setToast({
        opened: true,
        message: err.message.toString(),
        type: 'fail',
      });
    }
  };

  const createTabs = () => {
    const defaultTabs = [
      { name: 'Purchase Order', value: 'Purchase_Order' },
      { name: 'Vendor', value: 'Vendor' },
      { name: 'Budget', value: 'Budget' },
    ];

    return requisitionRequired
      ? [{ name: 'Requisition', value: 'Requisition' }, ...defaultTabs]
      : defaultTabs;
  };
  const isUpdateDisabled = () => {
    if (selCompany !== companyId || !rules || !rules.length) return isDirty;
    let hasOrderChanged = false;
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].order !== i + 1) {
        hasOrderChanged = true;
        break;
      }
    }
    return hasOrderChanged || isDirty;
  };
  return (
    <Box $radius={12} ref={tableRef} $mobExtend $asHolder className={styles.companyContent}>
      <TabsSlider>
        {createTabs().map((tab) => (
          <span
            key={tab.name}
            onClick={() => {
              setTab(tab.value);
              reset();
            }}
          >
            {tab.name}
          </span>
        ))}
      </TabsSlider>
      {['Requisition', 'Purchase_Order'].includes(tab) && (
        <>
          <Approvers
            control={control}
            errors={errors}
            approversOptions={approversOptions}
            tab={tab}
          />

          <div className={generalStyles.pageButtons}>
            <Button.Main
              disabled={!isUpdateDisabled()}
              $primary
              $style="pink"
              onClick={handleSubmit(updateApprovalMatrix)}
            >
              Update
            </Button.Main>
          </div>
          {selCompany === companyId && currentModule && (
            <DndProvider backend={HTML5Backend}>
              <Rules
                setIsActiveTab={setIsActiveTab}
                companyId={companyId}
                isActiveTab={isActiveTab}
                rules={rules}
                setRules={setRules}
                currentModuleId={currentModule?.id}
                archiveRule={archiveRule}
                activateRule={activateRule}
                type={tab
                  .split('_')
                  .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ')}
              />
            </DndProvider>
          )}
        </>
      )}
      {tab === 'Vendor' && (
        <BudgetVendorApproval
          isDirty={isDirty}
          errors={errors}
          control={control}
          onSubmit={handleSubmit(updateApprovalMatrix)}
          watch={watch}
          approvers={approvers}
          tab={tab}
          approversOptions={approversOptions}
          companyId={companyId}
          setValue={setValue}
        />
      )}
      {tab === 'Budget' && (
        <BudgetVendorApproval
          isDirty={isDirty}
          errors={errors}
          control={control}
          onSubmit={handleSubmit(updateApprovalMatrix)}
          watch={watch}
          approvers={approvers}
          tab={tab}
          approversOptions={approversOptions}
          companyId={companyId}
          setValue={setValue}
        />
      )}

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default CompanyContent;
