import React from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import { useStore } from '../../store/store';

const LoggedOut = () => {
  const location = useLocation();
  const user = useStore((state) => state.user);
  if (location.state?.from !== "" && location.state?.from !== undefined){
    sessionStorage.setItem('redirectPath', location.state?.from?.pathname);
  }
  const redirectTo = sessionStorage.getItem('redirectPath')
  return user === null ? <Outlet /> : <Navigate to={redirectTo ?? "/dashboard"} replace />;
}

export default LoggedOut;
