import {useStore} from "../../store/store";

export const budgetLoader = async  ({ params }) => {
  console.log(params.companyId)
  console.log(params.budgetId)
  const user = useStore.getState().user
  const ccs = user.customCompanies.map(cc => cc.company.id)
  if (!ccs.includes(params.companyId)){
    throw new Response("You are not allowed to see this", {status: 405});
  }else {
    return null
  }
}
