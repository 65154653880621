import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/reports.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store.js';

const Reports = () => {
  const company = useStore((state) => state.company);
  const activeCompany = useStore((state) => state.activeCompany);

  const reportsPages = [
    { name: 'Purchase Order', link: '/reports/purchase-orders' },
    { name: 'Vendor', link: '/reports/vendors' },
    { name: 'Budgets/GL', link: '/reports/budgets' },
    { name: 'Departments', link: '/reports/departments' },
    { name: 'User', link: '/reports/user' },
  ];
  if (activeCompany && activeCompany.requisitionsRequired) {
    reportsPages.unshift({ name: 'Requisitions', link: '/reports/requisitions' });
  }

  return (
    <Box $radius={12} className={styles.wrapper}>
      <div className={styles.title}>
        <Text type="h2" weight={600}>
          Reports Overview
        </Text>
      </div>
      <div className={styles.reportsOverview}>
        <div className={generalStyles.fieldsThree}>
          {reportsPages.map((page) => (
            <div key={page.name} className={styles.reportsItem}>
              <Text weight={600} type="h4">
                {page.name} Reports
              </Text>
              <Link to={page.link}>
                <Button.ActionLabeled className={styles.underlineButton}>
                  <Text weight={500} type="subtitle">
                    View All Reports
                  </Text>
                </Button.ActionLabeled>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default Reports;
