import {Route} from "react-router-dom";
import Terms from "../pages/Terms";
import Policy from "../pages/Policy";
import React from "react";

export const generalRoutes =
  <>
    <Route exact path="/terms" element={<Terms/>}/>
    <Route exact path="/policy" element={<Policy/>}/>
  </>
