import { useState, Suspense } from 'react';
import LegalInformationTab from './LegalInformationTab';
import ConfigurationTab from './ConfigurationTab';
import { useParams } from 'react-router-dom';
import styles from '../../styles/vendor.module.css';
import Text from '../../components/UI/Typography/Text';
import { useEffect } from 'react';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import Representatives from './Representatives';
import TabsSlider from '../../components/UI/General/TabsSlider';

const VendorSetup = () => {
  const [tab, setTab] = useState('Legal Information');
  const { companyId, vendorId, mode } = useParams();
  const [vendorSetupData, setVendorSetupData] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const getVendorCompany = async (vendorId) => {
    spmsServiceService.getCompanyVendorById(companyId, vendorId).then((res) => {
      const vendorData = res.data.data;
      const handledVendorData = {
        ...vendorData.vendor,
        vendorCode: vendorData.vendorCode,
        onceOffPOLimit: vendorData.onceOffPOLimit,
        overallAnnualLimit: vendorData.overallAnnualLimit,
        vendorNotes: vendorData.vendorNotes,
        currency: vendorData.currency,
        departments: vendorData.departments,
        branches: vendorData.branches,
        glAccounts: vendorData.glAccounts,
        users: vendorData.users ?? [],
        vendorStatus: vendorData.vendorStatus,
        vendorAttachments: vendorData.attachments,
        companiesAssigned: vendorData.companiesAssigned,
        previouslyApproved: vendorData.previouslyApproved,
      };
      setVendorSetupData(handledVendorData);
    });
  };

  useEffect(() => {
    if (!vendorId) return;
    if (mode === 'edit') {
      getVendorCompany(vendorId);
    } else if (mode === 'add') {
      spmsServiceService.getVendor(vendorId).then((res) => {
        setVendorSetupData(res.data.data);
      });
    }
  }, [vendorId]);

  return (
    <>
      <Text className={styles.vendorSetupTitle} type="h2" weight={600}>
        {mode === 'create' ? 'Vendor Setup' : 'Vendor Profile'}
      </Text>
      <TabsSlider selected={tab}>
        <span onClick={() => setTab('Legal Information')}>Legal Information</span>
        {vendorId && <span onClick={() => setTab('Representatives')}>Representatives</span>}
        {vendorId && <span onClick={() => setTab('Configuration')}>Configuration</span>}
      </TabsSlider>
      <Suspense fallback={<h2>Loading...</h2>}>
        {tab === 'Legal Information' && (
          <LegalInformationTab
            vendorSetupData={vendorSetupData}
            setToast={setToast}
            setMainTab={setTab}
            getVendorCompany={getVendorCompany}
          />
        )}
        {tab === 'Representatives' && <Representatives setTab={setTab} setToast={setToast} />}
        {tab === 'Configuration' && (
          <ConfigurationTab vendorSetupData={vendorSetupData} setToast={setToast} />
        )}
      </Suspense>

      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default VendorSetup;
