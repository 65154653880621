import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from '../../../UI/General/Dropdown';
import generalStyles from '../../../../styles/general.module.css';
import * as Button from '../../../UI/Forms/Button';
import Icon from '../../../UI/General/Icon';
import { Menu, MenuItem } from '../../../UI/General/Menu';
import spmsServiceService from '../../../../services/spmsService.service';
import styles from '../../../../styles/companySetup.module.css';
import Text from '../../../UI/Typography/Text';
import Box from '../../../UI/General/Box';
import DataTableBase from '../../../UI/General/DataTableBase';
import TabbedNav from '../../../UI/General/TabbedNav';
import requestsServiceService from '../../../../services/requestsService.service';
import Toast from '../../../UI/General/Toast';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../../UI/Forms/Input';
import Export from '../../../shared/Export';
import AddButton from '../../../shared/AddButton';

const ExpandedComponent = ({ data }) => {
  return (
    <div className={styles.extendedComponent}>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>Branch</Text>
        {!data.branches.length ? 'All' : data.branches.map((branch) => <Text>{branch.name}</Text>)}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>User</Text>
        {data.users.map((user) => (
          <Text key={user}>{user}</Text>
        ))}
      </div>
    </div>
  );
};

const DepartmentBody = ({ companyId, isSubsidiary, companyName }) => {
  const [departments, setDepartments] = useState([]);
  const [tab, setTab] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [allUsers, setAllUsers] = useState(null);
  const [allBranches, setAllBranches] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [toExport, setToExport] = useState([]);

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const preopenedRef = useRef(localStorage.getItem('preOpenedDep'));
  useEffect(() => {
    if (preopenedRef.current && tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [preopenedRef.current, tableRef.current]);

  const columns = useMemo(
    () => [
      {
        name: 'Department Name',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <Link className={styles.addLink} to={`/department-setup/${companyId}/edit/${row.id}`}>
            <span data-content={'Department Name'} className={generalStyles.tableValue}>
              <span>{row.name}</span>
            </span>
          </Link>
        ),
      },
      {
        name: 'Department ID',
        sortable: true,
        selector: (row) => row.externalId,
        cell: (row) => (
          <span data-content={'Department ID'} className={generalStyles.tableValue}>
            <span>{row.externalId}</span>
          </span>
        ),
      },
      {
        name: 'Branch',
        cell: (row) => (
          <span data-content={'Branch'} className={generalStyles.tableValue}>
            <span>
              {row.branches !== undefined && allBranches !== null && row.branches.length > 0
                ? 'Limited'
                : 'All'}
            </span>
          </span>
        ),
      },
      {
        name: 'Users',
        cell: (row) => (
          <span data-content={'Users'} className={generalStyles.tableValue}>
            <span>
              {row.users !== undefined && allUsers !== null && row.users.length > 0
                ? 'Limited'
                : 'All'}
            </span>
          </span>
        ),
      },
      {
        name: 'Department Manager',
        selector: (row) => {
          if (allUsers !== null) {
            if (allUsers.filter((item) => item.value === row.departmentManager).length > 0) {
              return allUsers.filter((item) => item.value === row.departmentManager)[0].label;
            } else {
              return '-';
            }
          } else {
            return '-';
          }
        },
        cell: (row) => (
          <span data-content={'Users'} className={generalStyles.tableValue}>
            <span>
              {allUsers !== null && allUsers?.find((item) => item.value === row.departmentManager)
                ? allUsers?.find((item) => item.value === row.departmentManager).label
                : '-'}
            </span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {tab === true ? (
                    <>
                      {departments.length !== 1 && (
                        <MenuItem onClick={() => archiveDepartment([row.id])}>Archive</MenuItem>
                      )}
                      <MenuItem
                        onClick={() => navigate(`/department-setup/${companyId}/edit/${row.id}`)}
                      >
                        Edit
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem onClick={() => activateDepartment([row.id])}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, allUsers, allBranches, departments],
  );

  const fetchDepartments = async () => {
    if (!companyId) return;
    spmsServiceService
      .getDepartments(companyId)
      .then((r) => {
        if (r.data.status === 'success') {
          const data = r.data.data?.filter((item) => item.active === tab);
          setDepartments(data);
          const csvData = r.data.data.map((item) => ({
            'Department Name': item.name,
            'Department ID': item.externalId,
            Branches:
              item.branches !== undefined && allBranches !== null && item.branches.length > 0
                ? 'Limited'
                : 'All',
            Users:
              item.users !== undefined && allUsers !== null && item.users.length > 0
                ? 'Limited'
                : 'All',
            'Department Manager':
              allUsers !== null &&
              !!allUsers.filter((u) => u.value === item.departmentManager).length
                ? allUsers.filter((u) => u.value === item.departmentManager)[0].label
                : '-',
            Status: item.active !== undefined && item.active !== false ? 'Active' : 'Archived',
          }));
          setToExport(csvData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const archiveDepartment = (id) => {
    setToast((item) => ({ ...item, opened: false }));

    spmsServiceService
      .deleteDepartment(companyId, id)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Archived',
          type: 'success',
        });
        fetchDepartments();
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  const activateDepartment = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateDepartment(companyId, id)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Activated',
          type: 'success',
        });
        fetchDepartments();
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  const handleTabClick = (value) => {
    setTab(value);
    setToggleClearRows(!toggledClearRows);
  };

  useEffect(() => {
    fetchDepartments();
  }, [companyId, tab]);

  useEffect(() => {
    const fetchBranchesAndUsers = async () => {
      await Promise.all([
        spmsServiceService.getBranches(companyId).then((r) => {
          if (r.data.status === 'success') {
            setAllBranches(r.data.data.map((item) => ({ label: item.name, value: item.id })));
          }
        }),
        requestsServiceService
          .getData(100, 0, { isEnabled: true, companyId: companyId })
          .then((r) => {
            setAllUsers(
              r.data.data.content.map((item) => ({
                label: item.firstName + ' ' + item.lastName,
                value: item.username,
              })),
            );
          }),
      ]);
    };
    if (companyId) {
      fetchBranchesAndUsers();
    }
  }, [companyId]);

  useEffect(() => {
    if (!companyId) return;
    spmsServiceService
      .getDepartments(companyId)
      .then((r) => {
        if (r.data.status === 'success') {
          const data = r.data.data?.filter(
            (item) =>
              item.active === tab && item.name.toLowerCase().includes(filterText.toLowerCase()),
          );
          setDepartments(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filterText]);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#8A8B9D"
        placeholder="Search by Name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <Box $mobExtend $asHolder $radius={12}>
      <div ref={tableRef} className={styles.departmentBox}>
        <div className={generalStyles.tabSection}>
          <TabbedNav>
            <span onClick={() => handleTabClick(true)}>Active</span>
            <span onClick={() => handleTabClick(false)}>Archive</span>
          </TabbedNav>
          <div className={generalStyles.addItemButton}>
            <AddButton
              $click={() =>
                navigate(`/department-setup/${companyId}/add`, {
                  state: {
                    isSubsidiary,
                    companyName,
                    companyHasDepartments: !!departments?.length,
                  },
                })
              }
            />
            <Export data={toExport} name="Departments" />
          </div>
        </div>
        <div className={generalStyles.search}>{filterCmp}</div>
        <DataTableBase
          data={departments}
          columns={columns}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 15, 20, 30]}
        />
        {toast.opened === true ? (
          <Toast message={toast.message} opened={toast.opened} type={toast.type} />
        ) : null}
      </div>
    </Box>
  );
};

export default DepartmentBody;
