import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './Main';
import { AwsRum } from 'aws-rum-web';

// import i18n (needs to be bundled ;))
import './i18n';

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::869415446886:role/RUM-Monitor-eu-north-1-869415446886-9649083817861-Unauth",
    identityPoolId: "eu-north-1:620176ce-5f9d-4aac-8ed5-14499d442b3c",
    endpoint: "https://dataplane.rum.eu-north-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: true
  };
  const APPLICATION_ID = '112e15c9-9238-4ccc-ab1e-a362c2ed0e08';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'eu-north-1';
  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


