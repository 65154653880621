import requestsService from '../../../../services/requestsService.service';

const getUsersAccess = async (companyId, authorizationType, resourceUris) => {
  const matchingRoles = [];
  const response = await requestsService.getData(100, 0, {
    isEnabled: true,
    companyId,
  });
  const users = response.data.data.content;
  const userRoles = (user) => {
    return user.roles
      .filter((r) => String(r.company?.id) === String(companyId))
      .map((r) => r.role.id);
  };
  await Promise.all(
    users
      .flatMap((user) => userRoles(user))
      .map((role) =>
        requestsService
          .getRoleById(role)
          .then((res) => {
            if (res.data.message === 'Operation Successful') {
              const userRole = res.data.data;
              const matchingPermission = userRole.permissionResponse.find((permission) => {
                const hasResource = resourceUris.includes(permission.resourceUri);
                const hasCreateAuthorization =
                  permission.authorizationTypes.includes(authorizationType);
                return hasResource && hasCreateAuthorization;
              });

              matchingPermission && matchingRoles.push(userRole.roleId);
            }
          })
          .catch(() => null),
      ),
  );
  return users.filter((user) => {
    return userRoles(user).find((userRole) => matchingRoles.includes(userRole));
  });
};

export default getUsersAccess;
