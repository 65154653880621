import React, { useEffect, useRef, useState } from 'react';
import * as Button from '../../../components/UI/Forms/Button';
import Box from '../../../components/UI/General/Box';
import { useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/UI/Forms/Input';
import styles from '../../../styles/companySetup.module.css';
import generalStyles from '../../../styles/general.module.css';
import Label from '../../../components/UI/Forms/Label';
import Select from 'react-select';
import Text from '../../../components/UI/Typography/Text';
import MultiSelectOpened from '../../../components/UI/Forms/MultiSelectOpened';
import spmsServiceService from '../../../services/spmsService.service';
import requestsServiceService from '../../../services/requestsService.service';
import Toast from '../../../components/UI/General/Toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from '../../../store/store';

let financialTypes = [
  {
    label: 'Income Statement',
    options: [
      { label: 'Income', value: 'Income' },
      { label: 'Expenses', value: 'Expenses' },
    ],
  },
  {
    label: 'Balance Sheet',
    options: [
      { label: 'Bank', value: 'Bank' },
      { label: 'Fixed Assets', value: 'Fixed Assets' },
      { label: 'Current Assets', value: 'Current Assets' },
      { label: 'Non-Current Assets', value: 'Non-Current Assets' },
      { label: 'Current Liabilities', value: 'Current Liabilities' },
      { label: 'Non-Current Liabilities', value: 'Non-Current Liabilities' },
      { label: 'Equity', value: 'Equity' },
    ],
  },
  {
    label: 'Projects',
    options: [
      { label: 'Projects 1', value: 'Projects1' },
      { label: 'Projects 2', value: 'Projects2' },
    ],
  },
];

const GlAdd = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      glCode: '',
      type: '',
      subType: '',
      branchIds: [],
      departmentIds: [],
      userIds: [],
      vendorIds: [],
    },
  });
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('preOpenedGL', companyId);
    return () => {
      if (!location.pathname.includes('add') || !location.pathname.includes('edit')) {
        localStorage.removeItem('preOpenedGL');
      }
    };
  }, [location]);

  const setSteps = useStore((state) => state.setSteps);
  const fullCompanies = useStore((state) => state.fullCompanies);
  const setFullCompanies = useStore((state) => state.setFullCompanies);
  const { glId, companyId } = useParams();
  const containerRef = useRef(null);

  const [allOptions, setAllOptions] = useState({
    branches: [],
    departments: [],
    users: [],
    vendors: [],
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();
  const typeWatcher = useWatch({ control, name: 'type' });

  useEffect(() => {
    const fetchLimitTo = async () => {
      const options = {};
      await Promise.all([
        spmsServiceService.getBranches(companyId).then((r) => {
          if (r.data.data) {
            const activeBranches = r.data.data.filter((branch) => !!branch.active);
            options.branches = activeBranches.map((branch) => ({
              label: branch.name,
              value: branch.id,
            }));
          }
        }),
        spmsServiceService.getDepartments(companyId).then((r) => {
          if (r.data.data) {
            const activeDepartments = r.data.data.filter((department) => !!department.active);
            options.departments = activeDepartments.map((department) => ({
              label: department.name,
              value: department.id,
            }));
          }
        }),
        requestsServiceService
          .getData(100, 0, { status: 'ACTIVE', companyId: companyId })
          .then((r) => {
            if (r.data.data.content) {
              options.users = r.data.data.content.map((item) => ({
                label: item.firstName + ' ' + item.lastName,
                value: item.id,
              }));
            }
          }),
        spmsServiceService
          .getVendors(companyId, { active: true, vendorStatus: 'APPROVED' })
          .then((res) => {
            if (res.data.data.content) {
              options.vendors = res.data.data.content.map((item) => ({
                label: item.vendor.legalName,
                value: item.vendor.id,
              }));
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 404) options.vendors = [];
          }),
      ]);
      setAllOptions(options);
    };
    if (companyId !== undefined) {
      fetchLimitTo();
    }
  }, [companyId]);

  useEffect(() => {
    if (glId !== undefined) {
      spmsServiceService.getGlAccount(glId).then((r) => {
        if (r.data.status === 'success') {
          const { glCode, subType, type, branches, users, departments, description, vendors } =
            r.data.data;
          const limitedVendorIds = vendors.map((vendor) => vendor.id);
          const typeUpd = financialTypes
            .flatMap((item) => item.options)
            .find((el) => el.value === type);
          reset({
            glCode,
            subType,
            description,
            type: typeUpd,
            branchIds: branches.map((item) => ({ label: item.name, value: item.id })),
            departmentIds: departments.map((item) => ({
              label: item.name,
              value: item.id,
            })),
            vendorIds: allOptions.vendors.filter((item) => limitedVendorIds.includes(item.value)),
            userIds: allOptions.users.filter((item) =>
              users.map((user) => user.userId).includes(item.value),
            ),
          });
        }
      });
    }
  }, [glId, allOptions]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(true);
    }
  }, [containerRef.current]);

  const onSubmit = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const { type, departmentIds, branchIds, userIds, vendorIds, ...other } = data;
    const newData = {
      ...other,
      type: type.value,
      departmentIds: departmentIds.map((items) => items.value),
      branchIds: branchIds.map((items) => items.value),
      userIds: userIds.map((items) => items.value),
      vendorIds: vendorIds.map((items) => items.value),
      companyId: companyId,
    };
    if (glId !== undefined) {
      spmsServiceService
        .updateGlAccount(glId, newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'GL updated successfully',
            type: 'success',
            cb: () => navigate('/gl-setup'),
          });
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createGlAccount(newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'GL created successfully',
            type: 'success',
          });
          setSteps();
          if (location.state.isSubsidiary && !location.state.companyHasGLs) {
            spmsServiceService.getMetrics(companyId).then((res) => {
              const metrics = res.data.data;
              if (
                metrics.hasBranches &&
                metrics.hasDepartments &&
                metrics.hasGl &&
                !fullCompanies.find((company) => company.companyId === companyId)
              ) {
                setFullCompanies([
                  ...fullCompanies,
                  { companyId: companyId, companyName: location.state.companyName },
                ]);
              }
            });
          }
          navigate('/gl-setup');
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  const formatGroupLabel = (data) => (
    <Text type="body-1" weight={600}>
      {data.label}
    </Text>
  );

  return (
    <Box ref={containerRef} $mobExtend $asHolder $radius={12}>
      <form onSubmit={handleSubmit(onSubmit)} id="glForm">
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Controller
              name="glCode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Code is required',
                },
                maxLength: {
                  value: 65,
                  message: 'Maximum 65 characters',
                },
                validate: {
                  notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                  allowed: (v) =>
                    /^[A-Za-z0-9-\s/]*$/.test(v) || 'Only Alpha and Numerical characters',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Code"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $labelRequired
                  $label="Code"
                  $tooltip="General Ledger number"
                  {...field}
                />
              )}
            />
            {errors.glCode && <p className="error-message">{errors.glCode.message}</p>}
          </div>
          <div className="inp-container">
            <Label
              $title="Financial Type"
              $isRequired
              $tooltipText="Financial type of the General Ledger"
            />
            <Controller
              name="type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Financial Type is required',
                },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Financial Type"
                  options={financialTypes}
                  formatGroupLabel={formatGroupLabel}
                  styles={{
                    group: (base) => ({ ...base, padding: 0 }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: '100 !important',
                    }),
                    groupHeading: () => ({ padding: '4px 12px' }),
                  }}
                  hideSelectedOptions={false}
                />
              )}
            />
            {errors.type && <p className="error-message">{errors.type.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="subType"
              control={control}
              rules={{
                maxLength: {
                  value: 65,
                  message: 'Maximum 65 characters',
                },
                validate: {
                  notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                  allowed: (v) =>
                    /^[A-Za-z0-9\s]*$/.test(v) || 'Only Alpha and Numerical characters and space',
                  onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Subcategory"
                  disabled={typeWatcher === ''}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Subcategory"
                  $tooltip="Subcategory of the General Ledger"
                  {...field}
                />
              )}
            />
            {errors.subType && <p className="error-message">{errors.subType.message}</p>}
            {typeWatcher === '' ? (
              <Text
                weight={500}
                type="body-3"
                style={{ position: 'absolute', top: '100%', right: 0, marginTop: 4 }}
              >
                Select a Financial Type first
              </Text>
            ) : null}
          </div>
          <div className="inp-container">
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Description is required',
                },
                maxLength: {
                  value: 65,
                  message: 'Maximum 65 characters',
                },
                validate: {
                  notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                  allowed: (v) =>
                    (/^[A-Za-z0-9-/\s]*$/.test(v) && v?.trim() !== '') ||
                    'Only Alpha and Numerical characters',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Description"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Description"
                  $labelRequired
                  $tooltip="Description of the General Ledger"
                  {...field}
                />
              )}
            />
            {errors.description && <p className="error-message">{errors.description.message}</p>}
          </div>
        </div>
        <div className={styles.limitTo}>
          <Text weight={600} type="h3">
            Limit To
          </Text>
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Label $title="Branches" />
              <Controller
                name="branchIds"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Branch"
                    options={allOptions.branches}
                    control={control}
                  />
                )}
              />
              <p style={{ marginBottom: 0 }}>Select the Branches this GL is limited to</p>
            </div>
            <div className="inp-container">
              <Label $title="Departments" />
              <Controller
                name="departmentIds"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Department"
                    options={allOptions.departments}
                    control={control}
                  />
                )}
              />
              <p style={{ marginBottom: 0 }}>Select the Departments this GL is limited to</p>
            </div>
            <div className="inp-container">
              <Label $title="Users" />

              <Controller
                name="userIds"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Users"
                    options={allOptions.users}
                    control={control}
                  />
                )}
              />
              <p style={{ marginBottom: 0 }}>Select the Users this GL is limited to</p>
            </div>
            <div className="inp-container">
              <Label $title="Vendors" />
              <Controller
                name="vendorIds"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Vendor"
                    options={allOptions.vendors}
                    control={control}
                  />
                )}
              />
              <p style={{ marginBottom: 0 }}>Select the Vendors this GL is limited to</p>
            </div>
          </div>
        </div>
      </form>
      <div className={styles.backOverviewButton}>
        <div className={generalStyles.pageButtons}>
          <Button.Main
            $primary
            $style="pink"
            type="submit"
            form="glForm"
            disabled={!isDirty || !isValid}
          >
            {glId !== undefined ? 'Update' : 'Save'}
          </Button.Main>
          <Button.Main
            $primary
            $style={!isDirty || !isValid ? 'pink' : 'gray'}
            onClick={() => navigate('/gl-setup')}
          >
            Discard
          </Button.Main>
        </div>
      </div>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </Box>
  );
};

export default GlAdd;
