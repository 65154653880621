import styled, {css} from "styled-components";

const Box = styled.div`
  width: 100%;
  max-width: 100%;
  -webkit-border-radius: ${({$radius}) => $radius || $radius === 0 ? $radius + "px" : "16px"};
  -moz-border-radius: ${({$radius}) => $radius || $radius === 0 ? $radius + "px" : "16px"};
  border-radius: ${({$radius}) => $radius || $radius === 0 ? $radius + "px" : "16px"};
  background-color: #fff;
  box-shadow: 2.564006805419922px 0 12.820034980773926px 0 rgba(0, 0, 0, 0.10);
  overflow: ${({$noOverflow}) => $noOverflow ? "initial" : "hidden"};
  
  ${({ $mobExtend }) => {
    if ($mobExtend) {
      return css`
          @media all and (max-width: 1023px) {
            width: auto;
            max-width: calc(100% + 48px);
            border-radius: 0;
          }
          @media all and (max-width: 767px) {
            max-width: calc(100% + 32px);
          }
        `;
    }
  }}
  ${({ $noPadding }) => {
    if (!$noPadding) {
      return css`
            padding: 16px;
            @media all and (min-width: 768px) {
              padding: 24px;
            }
            @media all and (min-width: 1024px) {
              padding: 40px;
            }
          `;
    }
  }}
  ${({ $asHolder }) => {
    if ($asHolder) {
      return css`
            margin-left: -16px;
            margin-right: -16px;
            @media all and (min-width: 768px) {
              margin-left: -24px;
              margin-right: -24px;
            }
            @media all and (min-width: 1024px) {
              margin-left: 0;
              margin-right: 0;
            }
          `;
    }
  }}
  
`

export default Box;
