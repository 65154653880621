import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Password } from "../UI/Forms/Input";
import Box from "../UI/General/Box";
import Text from "../UI/Typography/Text";
import * as Button from "../UI/Forms/Button";

import Modal from "../UI/Modal/Modal";
import PasswordTest from "../UI/Forms/PasswordTest";
import Toast from "../UI/General/Toast";

import style from "../../styles/log_reg.module.css";

const ForgotResetPassword = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const confirmation_code = new URLSearchParams(location.search).get(
		"confirmation_code"
	);
	const user_name = new URLSearchParams(location.search).get("user_name");

	const {
		handleSubmit,
		control,
		trigger,
		formState: { errors, isDirty, isValid, dirtyFields },
		getValues,
	} = useForm({ mode: "onChange" });
	const [showModalThankyou, setShowModalThankyou] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);
	const [showToast, setShowToast] = useState(false);

	async function forgotPasswordSubmit({ newPassword, repeat_password }) {
		try {
			if (showToast) {
				setShowToast(false);
			}

			await Auth.forgotPasswordSubmit(
				user_name,
				confirmation_code,
				newPassword
			);
			setShowModalThankyou(true);
			setTimeout(() => {
				navigate("/login");
			}, 5000);
		} catch (err) {
			setToastMessage(err.message);
			setShowToast(true);
		}
	}

	useEffect(() => {
		if (!confirmation_code || !user_name) {
			navigate("/login");
		}
	}, [confirmation_code, user_name]);
	return (
		<>
			<div className={style.auth}>
				<div className={style.authInnerSmall}>
					<Box $radius={40} className={style.authContainer} $noPadding>
						<div className={style.authContent}>
							<div className={style.authContentLogo}>
								<Link to="/">
									<img src="assets/img/login_register/logo.svg" alt="Poms" />
								</Link>
							</div>
							<div className={style.authHeader + " " + style.cnpHeader}>
								<img
									src="assets/img/login_register/create_new_password_new.svg"
									alt="create new password"
								/>
								<Text type="h2" weight={600}>
									Create New Password
								</Text>
								<Text type="h4" weight={400}>
									Follow the instruction below to create your new password.
								</Text>
							</div>
							<form
								onSubmit={handleSubmit(forgotPasswordSubmit)}
								className={style.authForm + " " + style.authFormSmall}
							>
								<div
									className={style.forgotPassword + " " + style.inputsColomn}
								>
									<div className="inp-container mb-10">
										<Controller
											name="newPassword"
											control={control}
											rules={{
												required: {
													value: true,
													message: "Password is required",
												},
												minLength: {
													value: 8,
													message: "At least 8 characters",
												},
												maxLength: {
													value: 35,
													message: "Maximum 35 characters",
												},
												validate: {
													isEqualOld: (value) =>
														value !== getValues("currentPassword") ||
														"New password must differs from current password",
													oneLetter: (v) =>
														/[a-z]+/.test(v) || "At least one lowercase letter",
													oneCapitalLetter: (v) =>
														/[A-Z]+/.test(v) || "At least one capital letter",
													oneNumber: (v) =>
														/\d+/.test(v) || "At least one number",
													oneSpecialChar: (v) =>
														/[!@#$%&?]+/.test(v) ||
														"At least one special character",
													onlyAllowed: (v) =>
														/^[A-Za-z0-9!@#$%&?]*$/.test(v) ||
														"Not allowed symbols",
												},
											}}
											render={({ field }) => (
												<Password
													{...field}
													placeholder="Password"
													//$label="New Password"
													$iconColor={
														errors.hasOwnProperty(field.name)
															? "#CD3E27"
															: "#8A8B9D"
													}
													className={
														errors.hasOwnProperty(field.name) && "error"
													}
													onChange={(e) => {
														if (dirtyFields["repeat_password"]) {
															return (
																field.onChange(e), trigger("repeat_password")
															);
														} else {
															return field.onChange(e);
														}
													}}
												/>
											)}
										/>
										{errors.newPassword && (
											<p className="error-message">
												{errors.newPassword?.message}
											</p>
										)}
									</div>

									<div className="inp-container">
										<Controller
											name="repeat_password"
											control={control}
											rules={{
												required: {
													value: true,
													message: "Password is required",
												},
												validate: {
													isValid: (value) =>
														value === getValues("newPassword") ||
														"Password don't match",
												},
											}}
											render={({ field }) => (
												<Password
													placeholder="Re-Enter Password"
													className={
														errors.hasOwnProperty(field.name) && "error"
													}
													{...field}
												/>
											)}
										/>
										{errors.repeat_password && (
											<p className="error-message">
												{errors.repeat_password?.message}
											</p>
										)}
									</div>
								</div>

								<div className={style.passwordRequirements}>
									<Text weight={400} type="body-1" className={style["mb-5"]}>
										Password must meet the following requirements:
									</Text>
									<div>
										<PasswordTest
											name={"newPassword"}
											isDirty={dirtyFields}
											control={control}
										/>
									</div>
								</div>
								<div className={style.btn}>
									<Button.Main
										$primary
										$full
										$style="pink"
										$iconRight
										type="submit"
										disabled={!isDirty || !isValid}
									>
										<span>reset password</span>
									</Button.Main>
								</div>
							</form>
						</div>
					</Box>
				</div>
			</div>
			<Modal $show={showModalThankyou} $radius={16} $maxWidth="600px">
				<div className={style.innerPadding}>
					<div className={style.authHeaderFull}>
						<div className={style.authHeaderImg}>
							<img
								src="assets/img/login_register/thanks-check.svg"
								alt="check_email"
							/>
						</div>
						<Text type="h2" weight={600}>
							Password Changed!
						</Text>
						<Text type="body-1" weight={400}>
							Your password has been changed successfully
						</Text>
					</div>
					<div className={style.btn}>
						<Button.Main
							$primary
							$full
							$style="pink"
							$iconRight
							type="button"
							onClick={() => {
								setShowModalThankyou(false);
								navigate("/login");
							}}
						>
							<span>Login</span>
						</Button.Main>
					</div>
				</div>
			</Modal>
			{toastMessage != null && showToast !== false ? (
				<Toast type="fail" message={toastMessage} opened={showToast} />
			) : null}
		</>
	);
};

export default ForgotResetPassword;
