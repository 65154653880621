import { useMemo } from 'react';
import styles from '../../../styles/vendorProfile.module.css';
import generalStyles from '../../../styles/general.module.css';
import DataTableBase from '../../UI/General/DataTableBase';
import Text from '../../UI/Typography/Text';
import Icon from '../../UI/General/Icon';
import moment from 'moment';
import { downloadExternal } from '../../../utils/downloadExternal';

const OrdersTab = ({ data }) => {
  console.log('data', data);
  const columns = useMemo(
    () => [
      {
        name: 'PO NR',
        sortable: true,
        wrap: true,
        selector: (row) => row.purchaseOrderNo,
      },
      {
        name: 'Date Created',
        selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
        wrap: true,
      },
      {
        name: 'Delivery Date',
        selector: (row) => row.deliveryDate,
        wrap: true,
      },
      {
        name: 'Amount',
        selector: (row) => `$ ${row.total.toFixed(2)}`,
        sortable: true,
      },
      {
        name: 'Files',
        sortable: true,
        cell: (row, index) =>
          row.file ? (
            <span onClick={() => downloadExternal(row.file)} className={styles.download}>
              <Text weight={700} type="h5" className={styles.link}>
                File-Name
              </Text>
              <Icon $width={20} $height={20} $icon="download" />
            </span>
          ) : (
            '-'
          ),
      },
    ],
    [],
  );
  return (
    <DataTableBase
      data={[data] || []}
      columns={columns}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 15, 20, 30]}
    />
  );
};

export default OrdersTab;
