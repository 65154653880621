import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import Label from './Label';
import { useStore } from '../../../store/store';
import { useShallow } from 'zustand/react/shallow';

const CountryAndRegion = ({
  control,
  isDisable,
  countryFieldName,
  regionFieldName,
  errors,
  readOnly,
  countryTooltip,
  regionTooltip,
  isRequired = false
}) => {
  const countriesState = useStore(useShallow((state) => state.countries));
  const regionsState = useStore(useShallow((state) => state.regions));
  const [regions, setRegions] = useState([]);

  const country = useWatch({ control, name: countryFieldName });

  useEffect(() => {
    let regionsTemp = [];
    if (country !== undefined && country !== '' && country.code !== undefined && regionFieldName) {
      regionsTemp = regionsState?.filter((item) => item.code === country.code);
    }
    if (country !== undefined && country !== '' && country.code === undefined && regionFieldName) {
      const code = countriesState.find((el) => el.value === country.value).code;
      regionsTemp = regionsState?.filter((item) => item.code === code);
    }
    setRegions(regionsTemp);
  }, [country, regionsState]);

  const searchByFirstCharacter = (option, inputValue) => option.label.toLowerCase().startsWith(inputValue.toLowerCase());

  return (
    <>
      <div className="inp-container">
        <Label $title="Country" $isRequired $tooltipText={countryTooltip} />
        <Controller
          name={countryFieldName}
          control={control}
          rules={{
            required: {
              value: isRequired,
              message: "Country is required"
            }
          }}
          render={({ field }) => (
            <Select
              {...field}
              openMenuOnClick={!readOnly}
              classNamePrefix="react-select"
              isDisabled={isDisable}
              filterOption={searchByFirstCharacter}
              className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
              isSearchable={!readOnly}
              placeholder="Select Country"
              options={countriesState}
              menuPortalTarget={document.body}
              menuPosition={'absolute'}
              menuPlacement={'bottom'}
              menuShouldScrollIntoView={false}
            />
          )}
        />
        {errors[countryFieldName] && <p className="error-message">{errors[countryFieldName].value.message}</p>}
      </div>

      {regionFieldName && (
        <div className="inp-container">
          <Label $title="Region" $isRequired $tooltipText={regionTooltip} />
          <Controller
            name={regionFieldName}
            control={control}
            rules={{
              required: {
                value: isRequired,
                message: "Region is required"
              }
            }}
            render={({ field }) => (
              <Select
                {...field}
                classNamePrefix="react-select"
                filterOption={searchByFirstCharacter}
                className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                isSearchable
                placeholder="Select Region"
                isDisabled={isDisable}
                options={regions}
                menuPortalTarget={document.body}
                menuPosition={'absolute'}
                menuPlacement={'bottom'}
                menuShouldScrollIntoView={false}
              />
            )}
          />
          {errors[regionFieldName] && <p className="error-message">{errors[regionFieldName].value.message}</p>}
        </div>
      )}
    </>
  );
};

export default CountryAndRegion;
