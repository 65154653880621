import React, {useState} from 'react';
import { Auth } from 'aws-amplify';

import style from '../../styles/log_reg.module.css';
import Box from '../UI/General/Box';
import Text from '../UI/Typography/Text';
import { Controller, useForm } from 'react-hook-form';
import Input from '../UI/Forms/Input';
import * as Button from '../UI/Forms/Button';
import GoogleReCaptcha from "../UI/Forms/GoogleReCaptcha";

const ForgetPassword = ({ setStatus, setUserName }) => {
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onChange",
  });
  async function forgotPassword({ email }) {
    try {
      const data = await Auth.forgotPassword(email, {
        deliveryMethod: 'email',
      });
      console.log(data);
      // recaptchaRef.current.reset();
      setUserName(email);
      setStatus('check-email');
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  console.log(Auth);
  return (
    <div className={style.auth}>
      <div className={style.authInnerSmall}>
        <Box $radius={40} className={style.authContainer} $noPadding>
          <div className={style.authContent}>
            <div className={style.authContentLogo}>
              <img src="assets/img/login_register/logo.svg" alt="Poms" />
            </div>
            <div className={style.authHeader + ' ' + style.cnpHeader}>
              <img src="assets/img/login_register/forgot.svg" alt="forgot password" />
              <Text type="h2" weight={600}>
                Forgot Password
              </Text>
              <Text type="subtitle">
                Enter the email associated with your account to reset your password
              </Text>
            </div>
            <form onSubmit={handleSubmit(forgotPassword)} className={style.authForm + ' ' + style.authFormSmall}>
              <div className={style.inputs + ' ' + style.inputsColomn}>
                <div className='inp-container'>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value:
                          // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: 'Email is not valid',
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        type="email"
                        placeholder="Email *"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        {...field}
                        $iconName="mail"
                      />
                    )}
                  />
                  {errors.email && <p className="error-message">{errors.email?.message}</p>}
                </div>
                <GoogleReCaptcha setCaptchaResponse={setCaptchaResponse}/>
              </div>
              <div className={style.btn}>
                <Button.Main
                  disabled={!isDirty || !isValid || !captchaResponse}
                  $primary
                  $full
                  $style="pink"
                  $iconRight
                  type="submit"
                >
                  <span>Send Email</span>
                </Button.Main>
              </div>
              <div className={style.btnBefore + ' ' + style.btnBeforeLogin}>
                <span onClick={() => setStatus('sign-in')}>Return to Login</span>
              </div>
            </form>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ForgetPassword;
