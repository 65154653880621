import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import {regExps} from "../regExps";

const branchSchema = yup.object().shape({
  name: yup
    .string()
    .required('Branch name is required')
    .matches(/^[a-zA-Z0-9@&()-\s]+$/, 'Invalid characters')
    .test(
      'no-empty-spaces',
      'Branch name cannot be just empty spaces',
      (value) => value.trim() !== '',
    )
    .max(50, 'Branch name must be at most 50 characters'),
  email: yup
    .string()
    .required('Email is required')
    .matches(regExps.email, 'Invalid email address')
    .max(50, 'E-mail must be at most 50 characters'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', (value) => {
      return isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '');
    }),

  address_one: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Suite/Building Name cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/\s]+$/, 'Invalid characters')
    .max(50, 'Suite/Building Name must be at most 50 characters'),
  address_two: yup
    .string()
    .required('Street is required')
    .test('no-empty-spaces', 'Street cannot be just empty spaces', (value) => value.trim() !== '')
    .matches(/^[a-zA-Z0-9'/\s]+$/, 'Invalid characters')
    .max(50, 'Street must be at most 50 characters'),
  city: yup
    .string()
    .required('City is required')
    .test('no-empty-spaces', 'City cannot be just empty spaces', (value) => value.trim() !== '')
    .matches(/^[a-zA-Z\s]+$/, 'Invalid characters')
    .max(25, 'City must be at most 25 characters'),
  country: yup.object().shape({
    value: yup.string().required('Country is required'),
  }),
  state: yup.object().shape({
    value: yup.string().required('Region is required'),
  }),
  zip: yup
    .string()
    .required('Postal Code is required')
    .matches(/^[a-zA-Z0-9-]+$/, 'Invalid characters')
    .max(15, 'Postal Code must be at most 15 characters'),
});

export default branchSchema;
