import { useForm, Controller } from 'react-hook-form';
import Icon from '../../UI/General/Icon';
import Modal from '../../UI/Modal/Modal';
import Text from '../../UI/Typography/Text';
import * as Button from '../../UI/Forms/Button.jsx';
import Input from '../../UI/Forms/Input.jsx';
import Radio from '../../UI/Forms/Radio.jsx';
import styles from '../../../styles/accountBillings.module.css';
import generalStyles from '../../../styles/general.module.css';
import Label from '../../UI/Forms/Label.jsx';
import Checkbox from '../../UI/Forms/Checkbox.jsx';
import Select from 'react-select';
import PaymentModalRadio from './PaymentModalRadio.jsx';
import { useStore } from '../../../store/store.js';

const AddPaymentModal = ({ showModal, closeModal }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(validationSchema),
  });

  const company = useStore((state) => state.company);

  const cardType = watch('cardType');
  const createPayment = () => {};
  return (
    <Modal $maxWidth="910px" $show={showModal} $radius={4}>
      <div className={generalStyles.mainDiv}>
        <div className={generalStyles.textIconDiv}>
          <Text type="h4" className={generalStyles.textField}>
            Add Payment Method
          </Text>
          <Icon
            onClick={closeModal}
            $width={12}
            $height={24}
            $icon="close"
            $size={18}
            className={generalStyles.iconStyle}
          />
        </div>
      </div>
      <div className={styles.paymentModalContainer}>
        <div className={styles.paymentModalTitle}>
          <Text weight={500}>You are Subscribed to </Text>
          <Text weight={600}>Diamond Package</Text>
        </div>
        <Controller
          name="cardType"
          control={control}
          render={({ field }) => (
            <div className={generalStyles.fieldsThree}>
              <Radio
                {...field}
                $size={16}
                label={
                  <PaymentModalRadio
                    name="Credit Card"
                    description="Bronze to Diamond"
                    img="/assets/img/paymentCards/credit.svg"
                  />
                }
                checked={cardType === 'Credit_Card'}
                value="Credit_Card"
              />
              <Radio
                {...field}
                $size={16}
                label={
                  <PaymentModalRadio
                    name="EFT"
                    description="Gold and Diamond"
                    img="/assets/img/paymentCards/eft.svg"
                  />
                }
                checked={cardType === 'EFT'}
                value="EFT"
              />
              <Radio
                {...field}
                $size={16}
                label={
                  <PaymentModalRadio
                    name="Debit Order"
                    description="Diamond"
                    img="/assets/img/paymentCards/debit.svg"
                  />
                }
                checked={cardType === 'Debit'}
                value={'Debit'}
              />
            </div>
          )}
        />

        <div className={generalStyles.fieldsTwo}>
          <div className="inp-container">
            <Label>Cardholder name</Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Cardholder Name"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>
          <div className="inp-container">
            <Label>Card Number</Label>
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Card Number"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.number && <p className="error-message">{errors.number.message}</p>}
          </div>
        </div>
        <div className={generalStyles.fieldsTwo}>
          <div className="inp-container">
            <Label>Expiry Date</Label>
            <Controller
              name="expiryDate"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Expiry Date"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.expiryDate && <p className="error-message">{errors.expiryDate.message}</p>}
          </div>
          <div className="inp-container">
            <Label>CVV Number</Label>
            <Controller
              name="cvv"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter CVV Number"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.cvv && <p className="error-message">{errors.cvv.message}</p>}
          </div>
        </div>
        <div className={styles.paymentModalAddress}>
          <div className="inp-container">
            <Label>Billing Address</Label>
            <Controller
              name="addressLine1"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Billing Address 1"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.notes && <p className="error-message">{errors.notes.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="addressLine2"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Billing Address 2"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.addressLine2 && <p className="error-message">{errors.notes.message}</p>}
          </div>
        </div>
        <div className={generalStyles.fieldsTwo}>
          <div className="inp-container">
            <Label>City</Label>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter City Name"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.city && <p className="error-message">{errors.city.message}</p>}
          </div>
          <div className="inp-container">
            <Label>Region</Label>
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Region Name"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.region && <p className="error-message">{errors.region.message}</p>}
          </div>
        </div>
        <div className={generalStyles.fieldsTwo}>
          <div className="inp-container">
            <Label>Country</Label>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Country Name"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.country && <p className="error-message">{errors.country.message}</p>}
          </div>
          <div className="inp-container">
            <Label>Postal Code</Label>
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Postal Code No"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.postalCode && <p className="error-message">{errors.postalCode.message}</p>}
          </div>
        </div>
        <Controller
          name="sameAddress"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <>
              <Checkbox
                {...field}
                checked={field.value}
                $size={22}
                label="Same as my company address"
                className={errors.hasOwnProperty(field.name) ? 'error' : ''}
              />
            </>
          )}
        />
        <div className={generalStyles.fieldsTwo}>
          <div className="inp-container">
            <Label>Branch</Label>
            <Controller
              name="branch"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Branch"
                    options={company.branches.map((branch) => ({
                      label: branch.name,
                      value: branch.id,
                    }))}
                  />
                </>
              )}
            />
            {errors.branch && <p className="error-message">{errors.branch?.value.message}</p>}
          </div>
        </div>
        <div className={styles.paymentModalButtons}>
          <Button.Main
            onClick={handleSubmit(createPayment)}
            $primary
            $style="pink"
            type="submit"
            form="cForm"
          >
            Save
          </Button.Main>
          <Button.Main onClick={closeModal} $primary $style="gray" type="submit" form="cForm">
            Discard
          </Button.Main>
        </div>
      </div>
    </Modal>
  );
};

export default AddPaymentModal;
