import React from 'react';
import style from "../../styles/log_reg.module.css";
import Box from "../UI/General/Box";
import Text from "../UI/Typography/Text";
import {Controller, useForm} from "react-hook-form";
import {Password} from "../UI/Forms/Input";
import * as Button from "../UI/Forms/Button";
import Icon from "../UI/General/Icon";
import {Auth} from "aws-amplify";
import PasswordTest from "../UI/Forms/PasswordTest";
import rs from '../../services/requestsService.service'

const NewPassword = ({user, setUser}) => {
  const {handleSubmit, trigger, control, formState: {errors, isDirty, isValid, dirtyFields}, getValues} = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      repeat_password: ""
    },
  });

  async function createNewPassword({password}) {
    const updatedUser = await Auth.completeNewPassword(
      user,
      password
    );
    const email = await updatedUser.challengeParam.userAttributes.email
    await rs.activateUser(email)
    setUser(updatedUser)
  }

  return (
    <div className={style.auth}>
      <div className={style.authInnerSmall}>
        <Box $radius={40} className={style.authContainer} $noPadding>
          <div className={style.authContent}>
            <div className={style.authHeader + " " + style.cnpHeader}>
              <img src="assets/img/login_register/create_new_password.svg" alt="create new password"/>
              <Text type="h2" weight={600}>Create New Password</Text>
              <Text type="subtitle">Enter the email associated with your account and follow instructions to reset your password</Text>
            </div>
            <form onSubmit={handleSubmit(createNewPassword)} className={style.authForm + " " + style.authFormSmall}>
              <div className={style.inputs +" "+style.inputsColomn}>
                <div className="inp-container">
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Password is required"
                      },
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 character"
                      },
                      pattern:{
                        value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).*$/g,
                        message: "Password must include 1 letter 1 capital 1 number 1 symbol character"
                      }
                    }}
                    render={({field}) => (
                      <Password
                        {...field}
                        placeholder="Password"
                        $iconColor={errors.hasOwnProperty(field.name) ? "#CD3E27" : "#8A8B9D"}
                        className={errors.hasOwnProperty(field.name) && "error"}
                        onChange={(e) => {
                          if (dirtyFields["repeat_password"]){
                            return (field.onChange(e), trigger("repeat_password"))
                          }else {
                            return field.onChange(e)
                          }
                        }}
                      />
                    )}
                  />
                  {errors.password && <p className="error-message">{errors.password?.message}</p>}
                </div>
                <div className="inp-container">
                  <Controller
                    name="repeat_password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Password is required"
                      },
                      validate: {
                        isValid: (value) => value === getValues("password") || "Password don't match"
                      }
                    }}
                    render={({field}) => (
                      <Password
                        {...field}
                        placeholder="Re-Enter Password"
                        $iconColor={errors.hasOwnProperty(field.name) ? "#CD3E27" : "#8A8B9D"}
                        className={errors.hasOwnProperty(field.name) && "error"}
                      />
                    )}
                  />
                  {errors.repeat_password && <p className="error-message">{errors.repeat_password?.message}</p>}
                </div>
              </div>
              <div className={style.passwordChangeRules}>
                <Text type="subtitle">Password must meet the following requirements:</Text>
                <PasswordTest name={"password"} isDirty={dirtyFields} control={control}/>
              </div>
              <div className={style.btn}>
                <Button.Main $primary $full $style="pink" $iconRight type="submit" disabled={!isDirty || !isValid}>
                  <span>create new password</span>
                  <Icon $width={24} $height={24} $icon="arrow-right"/>
                </Button.Main>
              </div>
            </form>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default NewPassword;
