import React from 'react';
import {AsyncPaginate} from "react-select-async-paginate";

const SelectAsync = (props) => {
  const fetchOptions = async (search, loadedOptions, { page }) => {
    const result = await props.fetchFunction(page, search)
    const hasMore = Math.ceil(result.length * page) >= loadedOptions.length;
    return {
      options: result,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchOptions}
      classNamePrefix="react-select"
      isSearchable={true}
      placeholder={props.placeholder}
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectAsync;
