export const vendorCategories = [
  {
    label: 'Goods Suppliers',
    options: [
      { label: 'Apparel and Footwear', value: 'Apparel and Footwear' },
      { label: 'Automobiles', value: 'Automobiles' },
      { label: 'Building Materials', value: 'Building Materials' },
      { label: 'Components and Parts', value: 'Components and Parts' },
      { label: 'Construction Equipment', value: 'Construction Equipment' },
      { label: 'Consumer Electronics', value: 'Consumer Electronics' },
      { label: 'Furniture & Office', value: 'Furniture & Office' },
      { label: 'Household Appliances', value: 'Household Appliances' },
      { label: 'Industrial Equipment', value: 'Industrial Equipment' },
      { label: 'Medical Equipment & Devices', value: 'Medical Equipment & Devices' },
      { label: 'Packaging', value: 'Packaging' },
      { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
      { label: 'Raw Materials', value: 'Raw Materials' },
      { label: 'Textiles', value: 'Textiles' },
    ],
  },
  {
    label: 'Services Suppliers',
    options: [
      { label: 'Advertising and Marketing', value: 'Advertising and Marketing' },
      { label: 'Consulting', value: 'Consulting' },
      { label: 'Customer', value: 'Customer' },
      { label: 'Financial', value: 'Financial' },
      { label: 'IT and Software', value: 'IT and Software' },
      { label: 'Maintenance and Repair', value: 'Maintenance and Repair' },
      { label: 'Professional', value: 'Professional' },
      { label: 'Research and Development', value: 'Research and Development' },
      { label: 'Security', value: 'Security' },
      { label: 'Training and Education', value: 'Training and Education' },
      { label: 'Travel and Accommodation', value: 'Travel and Accommodation' },
      { label: 'Utilities', value: 'Utilities' },
    ],
  }
]
