import moment from 'moment';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Required')
    .test('no-empty-spaces', 'Required', (value) => value.trim() !== '')
    .max(50, '50 characters max')
    .matches(/^[a-zA-Z0-9-/()'_\s]+$/, 'Invalid characters'),
  currency: yup.string().required('Required'),
  budgetType: yup.object().shape({
    value: yup.string().required('Required'),
  }),
  startDate: yup
    .date()
    .required('Required')
    .test('startDate', 'Start Date should be greater than End Date', function (startDate) {
      const endDate = this.parent.endDate;
      return !endDate || startDate < endDate;
    }),
  endDate: yup
    .date()
    .required('Required')
    .test('endDate', 'Start Date should be greater than End Date', function (endDate) {
      const startDate = this.parent.startDate;
      return !startDate || endDate > startDate;
    }),
  // .test(
  //   'longerThanYear',
  //   'Budget Period is greater than a year. Please add an Annual Budget.',
  //   function (endDate) {
  //     const startDate = this.parent.startDate;
  //     const start = moment(startDate);
  //     const end = moment(endDate);
  //     const diff = end.diff(start, 'years');
  //     return !endDate || diff < 1;
  //   },
  // ),
});
