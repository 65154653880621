import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import styles from '../../styles/users.module.css';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../components/UI/Modal/Modal';
import ModalInner from '../../components/UI/Modal/ModalInner';
import Textarea from '../../components/UI/Forms/Textarea';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import modalStyles from '../../styles/log_reg.module.css';
import style from '../../styles/users.module.css';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import TabsSlider from "../../components/UI/General/TabsSlider";
import {useAccessAllowed} from "../../hooks/useAccessAllowed";
import {reducedText} from "../../utils/reducedText";
import Export from "../../components/shared/Export";
import AddButton from "../../components/shared/AddButton";
import {nf} from "../../utils/nf";

const Requisitions = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange'
  });
  const [filterText, setFilterText] = useState(undefined);
  const activeCompany = useStore((state) => state.activeCompany);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [recallId, setRecallId] = useState(undefined);
  const [itemId, setItemId] = useState(undefined);
  const [showRecallModal, setShowRecallModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [toExport, setToExport] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const accessRequisition = useAccessAllowed('Requisition');

  useEffect(() => {
    if (!activeCompany.requisitionsRequired) {
      navigate('/dashboard');
    }
  }, [activeCompany]);

  const openModal = (id) => {
    setRecallId(id);
    setShowRecallModal(true);
  };
  const closeModal = () => {
    reset();
    setShowRecallModal(false);
  };
  const openDiscardModal = (id) => {
    setItemId(id);
    setShowDiscardModal(true);
  };
  const columns = useMemo(
    () => [
      {
        name: 'Title',
        wrap: true,
        selector: row => row?.title,
        sortable: true,
        cell: (row) => {
          const link = tab === 'pending' || tab === 'approved' ? '/requisitions/' : '/requisitions/edit/'
          return (
            <span data-content={"Title"} className={generalStyles.tableValue}>
              <Link to={link + row.id}>
                <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>{reducedText(row?.title)}</span>
              </Link>
            </span>
          )
        },
      },
      {
        name: 'Ref No',
        sortable: true,
        wrap: true,
        omit: tab !== 'approved',
        cell: (row) => <span data-content={"Ref No"} className={generalStyles.tableValue}><span>{row?.requestNo}</span></span>,
      },
      {
        name: 'Branch',
        cell: (row) => <span data-content={"Branch"} className={generalStyles.tableValue}><span>{row?.branch?.name ?? '-'}</span></span>,
        sortable: true,
        selector: row => row?.branch?.name
      },
      {
        name: 'Department',
        cell: (row) => <span data-content={"Department"} className={generalStyles.tableValue}><span>{row?.department?.name ?? '-'}</span></span>,
        sortable: true,
        selector: row => row?.department?.name
      },
      {
        name: 'Vendor',
        cell: (row) => <span data-content={"Vendor"} className={generalStyles.tableValue}><span>{row?.vendor?.legalName ?? '-'}</span></span>,
        sortable: true,
        selector: row => row?.vendor?.legalName
      },
      {
        name: 'Requester',
        cell: (row) => <span data-content={"Requester"} className={generalStyles.tableValue}><span>{row?.requester?.name}</span></span>,
        sortable: true,
        selector: row => row?.requester?.name
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return <span data-content={"Total Amount"} className={generalStyles.tableValue}><span>{cur + ' ' + nf.format(row?.total)}</span></span>;
        },
        sortable: true,
        selector: row => row?.total
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        omit: !accessRequisition?.create,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {(row.requisitionState === 'Draft'.toUpperCase() ||
                    row.requisitionState === 'rejected'.toUpperCase()) && (
                    <Link to={'/requisitions/edit/' + row.id}>
                      <MenuItem>Edit</MenuItem>
                    </Link>
                  )}
                  {row.requisitionState === 'Draft'.toUpperCase() && (
                    <MenuItem onClick={(e) => openDiscardModal(row.id)}>Discard</MenuItem>
                  )}
                  {row.requisitionState === 'PENDING_APPROVAL'.toUpperCase() && (
                    <MenuItem onClick={() => openModal(row.id)}>Recall</MenuItem>
                  )}
                  {row.requisitionState === 'Approved'.toUpperCase() && (
                    <Link to={'/requisitions/' + row.id}>
                      <MenuItem>View</MenuItem>
                    </Link>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, accessRequisition],
  );

  const discardRequisition = (id) =>
    spmsServiceService
      .discardRequisition(id)
      .then((res) => {
        setShowDiscardModal(false);
        setResetPaginationToggle(!resetPaginationToggle);
        setTrigger((state) => !state);
      })
      .catch((err) => {
        console.log(err);
      });

  const recallRequisition = (data) => {
    spmsServiceService
      .recallRequisition(recallId, data.reason)
      .then((res) => {
        setToast({
          opened: true,
          message: 'Request recalled successfully.',
          type: 'success',
          cb: navigate('/requisitions/overview/drafts'),
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
    closeModal();
  };

  const getData = useCallback(
    (page, size) => {
      let tempTab;
      switch (tab) {
        case 'drafts':
          tempTab = 'DRAFT';
          break;
        case 'pending':
          tempTab = 'PENDING_APPROVAL';
          break;
        case 'approved':
          tempTab = 'Approved';
          break;
        case 'rejected':
          tempTab = 'rejected';
          break;
        default:
          tempTab = 'Draft';
      }
      const search = {
        requisitionState: tempTab.toUpperCase(),
        companyId: activeCompany.id,
        title: filterText,
      };
      return spmsServiceService.getRequisitions(size, page, search)
    },
    [tab, filterText, trigger, activeCompany.id],
  );

  useEffect(() => {
    spmsServiceService.getRequisitions(1, 0, {
      companyId: activeCompany.id
    }).then(r => {
      console.log(r)
      setTotalRows(r.data.data.totalElements)
    });
  },[activeCompany.id])

  useEffect(() => {
    const getExp = async () => {
      const response = await spmsServiceService.getRequisitions(totalRows, 0, {
        companyId: activeCompany.id,
        title: filterText,
      });
      const csvData = response.data.data.content.map(item => ({
        "Req Title": item.title,
        "Req No": item.requestNo,
        "Branch": item.branch.name,
        "Dept": item.department.name,
        "Vendor": item.vendor.legalName,
        "Requester": item.requester.name,
        "PO Budget Amount": item?.currency?.code + ' ' + item?.total?.toFixed(2),
        "Status": item.requisitionState,
        "Required Date": item.deliveryDate,
      }))
      setToExport(csvData)
    }
    if (totalRows === 0){
      console.log("no results for export")
    }else {
      getExp()
    }
  },[filterText, trigger, totalRows, activeCompany.id])

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [tab, getData]);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#8A8B9D"
        placeholder="Search by requestNo"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, resetPaginationToggle],
  );

  return (
    <>
      <Text type="h2" weight={600}>
        Requisitions Overview
      </Text>
      <Box $mobExtend $asHolder $noOverflow>
        <div className={generalStyles.tabSection}>
          <TabsSlider>
            <NavLink
              to="/requisitions/overview/drafts"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Drafts
            </NavLink>
            <NavLink
              to="/requisitions/overview/pending"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Pending Approval
            </NavLink>
            {!activeCompany?.requisitionsRequired && (
              <NavLink
                to="/requisitions/overview/approved"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Approved
              </NavLink>
            )}
            <NavLink
              to="/requisitions/overview/rejected"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Rejected
            </NavLink>
          </TabsSlider>
          <div className={generalStyles.addItemButton}>
            {accessRequisition?.create && (
              <AddButton $click={() => navigate('/requisitions/create')}/>
            )}
            <Export data={toExport} name="Requisitions"/>
          </div>
        </div>
        <div className={generalStyles.search}>{filterCmp}</div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
        <Modal
          $show={showRecallModal}
          $close={closeModal}
          $title="Recall Request"
          $radius={12}
          $closableOutside
        >
          <ModalInner>
            <form onSubmit={handleSubmit(recallRequisition)} className={styles.modalInner}>
              <div className="inp-container">
                <Controller
                  name="reason"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Recall reason is required'
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      placeholder="Enter the reason here"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Reason to Recall the Request"
                      $labelRequired
                      $counter
                      $counterMax={500}
                      {...field}
                    />
                  )}
                />
                {errors.reason && <p className="error-message">{errors.reason?.message}</p>}
              </div>
              <br/>
              <div className={generalStyles.pageButtons}>
                <Button.Main $primary $style="pink" type="submit" disabled={!isValid}>Save</Button.Main>
              </div>
            </form>
          </ModalInner>
        </Modal>
        <Modal
          $show={showDiscardModal}
          $close={() => setShowDiscardModal(false)}
          $title={'Discard'}
          $radius={16}
          $noCloseSign
        >
          <div className={modalStyles.modalTextOnly} style={{ textAlign: 'center' }}>
            <Text type="subtitle">Do you want to continue to permanently delete this entry?</Text>
            <div
              className={generalStyles.pageButtons + ' ' + style.legalEntitySetupBtns}
              style={{ justifyContent: 'center' }}
            >
              <Button.Main
                $primary
                $style="pink"
                type="button"
                onClick={() => setShowDiscardModal(false)}
              >
                No
              </Button.Main>
              <Button.Main
                $primary
                $style="gray"
                type="button"
                onClick={() => discardRequisition(itemId)}
              >
                Yes
              </Button.Main>
            </div>
          </div>
        </Modal>
      </Box>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Requisitions;
