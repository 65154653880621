import s2pmsService from '../services/spmsService.service';

export const setupSlice = (set, get) => ({
  showCompanyDropdown: true,
  switchCompanyDropdown: (cValue) => {
    set({ showCompanyDropdown: cValue });
  },
  steps: [
    {
      number: 1,
      text: 'Add Company',
      link: '/company-setup',
      type: 'company',
      done: false,
    },
    {
      number: 2,
      text: 'Add Branch',
      link: '/company-setup?section=branch',
      type: 'branch',
      done: false,
    },
    {
      number: 3,
      text: 'Add Department',
      link: '/department-setup',
      type: 'department',
      done: false,
    },
    {
      number: 4,
      text: 'Add GL',
      link: '/gl-setup',
      type: 'gl',
      done: false,
    },
  ],
  stepsDone: false,
  checkDone: () => {
    console.log('run check done')
    console.log(get().steps.filter((item) => item.done === false))
    console.log(get().steps.filter((item) => item.done === false).length > 0)
    if (get().steps.filter((item) => item.done === false).length > 0) {
      console.log("check false")
      set({ stepsDone: false });
    } else {
      console.log("check true")
      set({ stepsDone: true });
    }
  },
  setSteps: () => {
    console.log("STEPS RUN")
    const updateState = (type) => {
      const newSteps = get().steps.map((item) =>
        item.type === type ? { ...item, done: true } : item,
      );
      set({ steps: newSteps });
    };
    console.log("COMPANY", get().company)
    if (get().company !== null) {
      console.log("setSteps inside")
      updateState('company');
      s2pmsService.getMetrics(get().company.id).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const metrics = r.data.data;
          console.log("Metrics", metrics)
          if (metrics.hasBranches) updateState('branch');
          if (metrics.hasDepartments) updateState('department');
          if (metrics.hasGl) updateState('gl');
          get().checkDone();
        }
      });
    }
  },
});
