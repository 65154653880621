import styles from '../../../../styles/approvalWorkflow.module.css';
import Select from 'react-select';
import Input from '../../../UI/Forms/Input';
import { Controller } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import spmsServiceService from '../../../../services/spmsService.service';
import getUsersAccess from '../helpers/getUsersAccess';
import * as Button from '../../../UI/Forms/Button.jsx';
import Icon from '../../../UI/General/Icon';
import { AsyncPaginate } from 'react-select-async-paginate';

const SingleCondition = ({
  conditionId,
  setValue,
  control,
  watch,
  errors,
  companyBranches,
  companyId,
  deleteCondition,
  prevCondition,
  selectedSubjects,
  setIsAddConditionActive,
}) => {
  const [conditionOptions, setConditionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const subject = watch(`subject-${conditionId}`);
  const subjectCondition = watch(`subjectCondition-${conditionId}`);
  const conditionValue = watch(`conditionValue-${conditionId}`);
  const typeOptions = [
    { label: 'Amount', value: 'Amount' },
    { label: 'Branch', value: 'Branch' },
    { label: 'Department', value: 'Department' },
    { label: 'GL', value: 'GeneralLedger' },
    { label: 'Requester', value: 'Requester' },
    { label: 'Vendor', value: 'Vendor' },
  ];
  const insOptions = [
    { label: 'IN', value: 'IN' },
    { label: 'NOT IN', value: 'NOT_IN' },
  ];
  const amountOptions = [
    { label: '=', value: 'EQUALS' },
    { label: '<', value: 'LESS_THAN' },
    { label: '>', value: 'GREATER_THAN' },
    { label: '<=', value: 'LESS_THAN_OR_EQUAL' },
    { label: '>=', value: 'GREATER_THAN_OR_EQUAL' },
  ];

  useEffect(() => {
    setIsAddConditionActive(!!subject && !!subjectCondition && !!conditionValue);
  }, [subject, subjectCondition, conditionValue]);

  useEffect(() => {
    if (!prevCondition) return;
    setValue(
      `subject-${conditionId}`,
      typeOptions.find((option) => option.value === prevCondition.subject),
    );
    setValue(
      `subjectCondition-${conditionId}`,
      amountOptions.find((option) => option.value === prevCondition.subjectCondition) ??
        insOptions.find((option) => option.value === prevCondition.subjectCondition),
    );
  }, [prevCondition]);

  useEffect(() => {
    if (!prevCondition) return;
    const prevConditionOptions =
      prevCondition.subject === 'Amount'
        ? prevCondition.conditionValue
        : conditionOptions.filter((option) => prevCondition.conditionValue.includes(option.value));
    setValue(`conditionValue-${conditionId}`, prevConditionOptions);
  }, [conditionOptions, prevCondition]);

  useEffect(() => {
    const geConditionOptions = async () => {
      setIsLoading(true);
      if (!subject) return;
      setConditionOptions([]);
      switch (subject.value) {
        case 'Branch':
          setConditionOptions(handleSelectData(companyBranches, 'name', 'id'));
          setIsLoading(false);
          return;
        case 'Department':
          spmsServiceService.getDepartments(companyId).then((res) => {
            setConditionOptions(handleSelectData(res.data.data, 'name', 'id'));
            setIsLoading(false);
          });
          return;
        case 'Requester':
          getUsersAccess(companyId, 'CREATE', ['Purchase_Order', 'Requisition']).then(
            (requestors) => {
              setConditionOptions(
                requestors
                  .filter((requester) => requester.status === 'ACTIVE')
                  .map((requestor) => ({
                    label: `${requestor.firstName} ${requestor.lastName}`,
                    value: requestor.email,
                  })),
              );
              setIsLoading(false);
            },
          );
          return;
        case 'Vendor':
          spmsServiceService
            .getVendors(companyId, { active: true, vendorStatus: 'APPROVED' })
            .then((res) => {
              setConditionOptions(
                res.data.data.content.map((vendor) => ({
                  label: vendor.vendor.legalName,
                  value: vendor.id,
                })),
              );
              setIsLoading(false);
            });
      }
    };
    geConditionOptions();
  }, [subject]);

  const fetchGlLines = async (search, loadedOptions, { page }) => {
    const result = await spmsServiceService.getFilteredGlAccounts(companyId, page, 10, {
      active: true,
      description: search,
    });
    const finalData = result.data.data.content.map((item) => ({
      label: item.glCode,
      value: item.id,
      text: item.description,
    }));
    const hasMore = Math.ceil(finalData.length * page) >= loadedOptions.length;
    return {
      options: finalData,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSelectData = (data, labelProp, valueProp) => {
    return data
      .filter((item) => !!item.active)
      .map((item) => ({
        label: item[labelProp],
        value: String(item[valueProp ?? labelProp]),
      }));
  };

  const handleSubjectChange = (e) => {
    if (subject?.value !== 'Amount' && e.value === 'Amount') {
      setValue(`subjectCondition-${conditionId}`, null);
      setValue(`conditionValue-${conditionId}`, '');
    } else if (e.value !== 'Amount' && subject?.value === 'Amount') {
      setValue(`subjectCondition-${conditionId}`, null);
      setValue(`conditionValue-${conditionId}`, null);
    } else setValue(`conditionValue-${conditionId}`, null);
  };

  const deleteButtonHandler = () => {
    deleteCondition(conditionId);
  };

  const isFieldRequired = !!subject || !!subjectCondition || !!conditionValue;
  return (
    <div className={styles.singleCondition}>
      <div className={styles.rowContainer}>
        <div className="inp-container">
          <Controller
            name={`subject-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleSubjectChange(e);
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: '100 !important',
                  }),
                }}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select"
                isOptionDisabled={(option) =>
                  selectedSubjects.find((subject) => subject?.value === option.value)
                }
                options={typeOptions}
              />
            )}
          />
          {errors[`subject-${conditionId}`] && (
            <p className="error-message">{errors[`subject-${conditionId}`]?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`subjectCondition-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select"
                options={subject?.value === 'Amount' ? amountOptions : insOptions}
              />
            )}
          />
          {errors[`subjectCondition-${conditionId}`] && (
            <p className="error-message">{errors[`subjectCondition-${conditionId}`]?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`conditionValue-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
              validate: {
                allowed: (v) => {
                  if (subject?.value === 'Amount') {
                    return /^[0-9]+$/.test(v) || 'Numerical characters only';
                  } else return true;
                },
              },
            }}
            render={({ field }) => (
              <>
                {subject?.value === 'Amount' ? (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Enter Amount"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                ) : subject?.value === 'GeneralLedger' ? (
                  <AsyncPaginate
                    value={field.value}
                    onChange={field.onChange}
                    selectRef={field.ref}
                    debounceTimeout={500}
                    loadOptions={fetchGlLines}
                    classNamePrefix="react-select"
                    isSearchable={true}
                    placeholder="Select GL"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    isMulti
                    additional={{
                      page: 0,
                    }}
                  />
                ) : (
                  <Select
                    isMulti
                    isDisabled={!conditionOptions.length}
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select"
                    options={conditionOptions}
                  />
                )}
              </>
            )}
          />

          {errors[`conditionValue-${conditionId}`] && (
            <p className="error-message">{errors[`conditionValue-${conditionId}`]?.message}</p>
          )}
          {!conditionOptions.length &&
            subject?.value &&
            subject?.value !== 'Amount' &&
            !isLoading && <p className="error-message">Please create a {subject.value}</p>}
        </div>
      </div>

      <div className={styles.deleteButton}>
        <Button.Action
          $variant="circle"
          $style="lightGrayishCyan"
          $width={40}
          $height={40}
          onClick={deleteButtonHandler}
          type="button"
        >
          <Icon $width={20} $height={20} $icon="delete" $color="#F24638" />
        </Button.Action>
      </div>
    </div>
  );
};

export default SingleCondition;
