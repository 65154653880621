import React, {Suspense, useCallback, useEffect, useMemo} from 'react';
import Text from "../../components/UI/Typography/Text";
import Box from "../../components/UI/General/Box";
import generalStyles from "../../styles/general.module.css";
import TabsSlider from "../../components/UI/General/TabsSlider";
import {Link, NavLink, useNavigate, useParams} from "react-router-dom";
import DataTableBaseRemote from "../../components/UI/General/DataTableBaseRemote";
import * as Button from "../../components/UI/Forms/Button";
import Icon from "../../components/UI/General/Icon";
import Dropdown from "../../components/UI/General/Dropdown";
import {Menu, MenuItem} from "../../components/UI/General/Menu";
import s2pmsEmail from "../../services/s2pmsEmail.service";
import {readableTitleFromBackend} from "../../utils/readableTitleFromBackend";

const NtList = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        wrap: true,
        cell: (row) => {
          const title = row?.name?.length > 25 ? `${row?.name?.slice(0, 25)}...` : row?.name;
          return (
            <span data-content={"Title"} className={generalStyles.tableValue}>
              <Link to={'/notification-templates/edit/' + row.id}>
                <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>{title}</span>
              </Link>
            </span>
          )
        },
      },
      {
        name: 'Notification Module',
        cell: (row) => <span data-content={"Notification Module"} className={generalStyles.tableValue}><span>{readableTitleFromBackend(row?.module)}</span></span>,
      },
      {
        name: 'Notification Event',
        cell: (row) => <span data-content={"Notification Event"} className={generalStyles.tableValue}><span>{readableTitleFromBackend(row?.event)}</span></span>,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  <MenuItem onClick={() => navigate('/notification-templates/edit/' + row.id)}>Edit</MenuItem>
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab],
  );

  const getData = useCallback((page, size) => {
      return s2pmsEmail.getAllTemplates(page, size)
    }, [tab],
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  return (
    <>
      <Text type="h2" weight={600}>Notification Templates</Text>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.tabSection}>
          <TabsSlider>
            <NavLink to="/notification-templates/overview/active" className={({ isActive }) => (isActive ? 'active' : '')} $islink="true">Active</NavLink>
          </TabsSlider>
          <div className={generalStyles.addItemButton}>
            <Button.ActionLabeled onClick={() => navigate('/notification-templates/create')}>
              <Button.Action $style="pink" $variant="circle" $width={20} $height={20}><Icon $width={20} $height={20} $icon="add" $color="white" /></Button.Action>
              <Text weight={700} type="subtitle">Create new</Text>
            </Button.ActionLabeled>
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            fetchData={getData}
          />
        </Suspense>
      </Box>
    </>
  );
};

export default NtList;
