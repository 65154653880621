import React from "react";
import {Route} from "react-router-dom";
import Orders from "../pages/VendorProfile/Orders";
import VendorAccount from "../pages/VendorProfile/VendorAccount";
import OrderDetailedView from "../pages/VendorProfile/OrderDetailedView";
import VendorClients from "../pages/VendorProfile/VendorClients";
import Profile from "../pages/Users/Profile";

export const vendorRoutes =
  <>
    <Route path="/vendor-orders/:tab" element={<Orders />} />
    <Route path="/vendor-profile" element={<VendorAccount />} />
    <Route path="/vendor-orders/order/:orderId" element={<OrderDetailedView />} />
    <Route path="/vendor-clients" element={<VendorClients />} />
    <Route path="/users/profile" element={<Profile />}/>
  </>
