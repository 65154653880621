import React, {createContext, useContext, useEffect, useRef, useState} from 'react';

const DropdownContext = createContext(false)

const DropdownHeader = ({children, $activeClass, ...props}) => {
  const {isActive,setIsActive} = useContext(DropdownContext);
  return (
    <div className={[(isActive && $activeClass) ? $activeClass : '', props.className].join(' ')} style={{cursor: "pointer"}} onClick={()=>setIsActive(state => !state)}>
      {children}
    </div>
  )
}
const DropdownBody = ({children}) => {
  const {isActive} = useContext(DropdownContext);
  return (
    <>{isActive ? children : null}</>
  )
}

const Dropdown = ({children, collapsible, className}) => {
  const [isActive, setIsActive] = useState(false);
  let ref = useRef(null);
  useEffect(() => {
    if (collapsible){
      const handler = (event) => {
        if (isActive && ref.current && !ref.current.contains(event.target)) {
          setIsActive(false);
        }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
      };
    }
  }, [isActive, collapsible]);
  return (
    <DropdownContext.Provider value={{isActive, setIsActive}}>
      <div ref={ref} className={className}>{children}</div>
    </DropdownContext.Provider>
  );
};

export default Object.assign(Dropdown, {
  Header: DropdownHeader,
  Body: DropdownBody,
});
