import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Icon from '../../components/UI/General/Icon';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import spmsServiceService from '../../services/spmsService.service';
import ExpandedVendor from './ExpandedVendor';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { Suspense } from 'react';

const ArchiveVendorOverview = ({ fetchVendors, setRefetchTrigger, companyId, setToast }) => {
  const accessVendor = useAccessAllowed('Vendors');

  const activateVendor = (row) => {
    setToast((item) => ({ ...item, opened: false }));
    if (!accessVendor?.delete) {
      setToast({
        opened: true,
        message: 'No permission to activate',
        type: 'fail',
      });
      return;
    }
    spmsServiceService
      .activateVendor(companyId, row.id)
      .then((_res) => {
        setRefetchTrigger((refetchTrigger) => !refetchTrigger);
        setToast({
          opened: true,
          message: 'Activated',
          type: 'success',
        });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const columns = [
    {
      name: 'Vendor Name',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Vendor Name'} className={generalStyles.tableValue}>
          <span>{row.vendor.legalName}</span>
        </span>
      ),
    },
    {
      name: 'Vendor Code',
      sortable: true,
      selector: (row) => row.vendorCode,
      cell: (row) => (
        <span data-content={'Vendor Code'} className={generalStyles.tableValue}>
          <span>{row.vendorCode}</span>
        </span>
      ),
    },
    {
      name: 'Department',
      cell: (row) => (
        <span data-content={'Department'} className={generalStyles.tableValue}>
          <span>{!!row.departments.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'Branch',
      cell: (row) => (
        <span data-content={'Branch'} className={generalStyles.tableValue}>
          <span>{!!row.branches.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'GL',
      cell: (row) => (
        <span data-content={'GL'} className={generalStyles.tableValue}>
          <span>{!!row.glAccounts.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'User',
      cell: (row) => (
        <span data-content={'User'} className={generalStyles.tableValue}>
          <span>{!!row.users.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    // {
    //   name: 'Approver',
    //   selector: (row) => row.Approver,
    //   cell: (row) => (
    //     <span data-content={'Approver'} className={generalStyles.tableValue}>
    //       <span>{row.Approver ?? '-'}</span>
    //     </span>
    //   ),
    // },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <div className={generalStyles.actionMenuHolder}>
          <Dropdown collapsible className={styles.actionMenu}>
            <Dropdown.Header>
              <Button.Action $style="white" $width={32} $height={32}>
                <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
              </Button.Action>
            </Dropdown.Header>
            <Dropdown.Body>
              <Menu className={styles.actionMenuList}>
                <MenuItem onClick={() => activateVendor(row.vendor)}>Activate</MenuItem>
              </Menu>
            </Dropdown.Body>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <Suspense fallback={<h2 className={styles.loader}>Loading...</h2>}>
      <DataTableBaseRemote
        columns={columns}
        fetchData={fetchVendors}
        selectableRows={false}
        paginationResetDefaultPage={false}
        expandableRows
        expandableRowsComponent={ExpandedVendor}
        expandOnRowClicked
      />
    </Suspense>
  );
};

export default ArchiveVendorOverview;
