import { Controller, useForm } from 'react-hook-form';
import Box from '../../components/UI/General/Box';
import styles from '../../styles/notifications.module.css';
import approvalStyles from '../../styles/approvalWorkflow.module.css';
import generalStyles from '../../styles/general.module.css';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import spmsServiceService from '../../services/spmsService.service';
import Text from '../../components/UI/Typography/Text';
import Icon from '../../components/UI/General/Icon';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import MultiSelectOpened from '../../components/UI/Forms/MultiSelectOpened.jsx';
import UsersList from '../../components/Admins/ApprovalWorkflow/AddRule/UsersList.jsx';
import RequestsService from '../../services/requestsService.service.js';
import NotificationCondition from '../../components/Admins/Notification/NotificationCondition.jsx';
import Toast from '../../components/UI/General/Toast.jsx';
import ModuleEvent from '../../components/shared/Notifications/ModuleEvent';
import Tooltip from "../../components/UI/General/Tooltip";

const modules = [
  { label: 'Purchase Order', value: 'PURCHASE_ORDERS' },
  { label: 'Requisitions', value: 'REQUISITIONS' },
  { label: 'Budgets', value: 'BUDGETS' },
  { label: 'Vendors', value: 'VENDORS' },
  { label: 'Receiving', value: 'RECEIVING' },
  { label: 'Invoicing', value: 'INVOICING' },
  { label: 'Payments', value: 'PAYMENTS' },
];
const types = {
  PAYMENTS: [{ label: 'Payment Received', value: 'PAYMENT_RECEIVED' }],
  RECEIVING: [{ label: 'Received', value: 'RECEIVED' }],
  OTHER: [
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
  ],
};

const AddNotification = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    unregister,
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
  });
  const module = watch('module');

  const { companyId, id } = useParams();
  const userList = watch('users');
  const [conditionIds, setConditionIds] = useState(id ? [] : [uuidv4()]);
  const [isAddConditionActive, setIsAddConditionActive] = useState(false);
  const [company, setCompany] = useState(null);
  const [users, setUsers] = useState([]);
  const [notification, setNotification] = useState(null);
  const userOptions = users.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!id || !users.length) return;
    spmsServiceService.getNotification(companyId, id).then((res) => {
      const notification = res.data.data;
      const { name, module, triggerEvent, conditions, recipients } = notification;
      setConditionIds(
        !!conditions.length ? conditions.map((condition) => condition.id) : [uuidv4()],
      );
      const triggerType = types[module] ?? types.OTHER;
      const prevRecipientIds = recipients.map((recipient) => recipient.userId);
      const prevRecipients = userOptions.filter((option) =>
        prevRecipientIds.includes(option.value),
      );
      const notificationData = {
        name: name,
        module: modules.find((mod) => mod.value === module),
        triggerEvent: triggerType.find((type) => type.value === triggerEvent),
        users: prevRecipients,
      };
      reset(notificationData);
      setNotification(res.data.data);
    });
  }, [users, id]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService
          .getCompany(companyId)
          .then((res) => setCompany(res.data.data))
          .catch((err) => console.log(err)),
        RequestsService.getData(100, 0, {
          isEnabled: true,
          companyId,
        }).then((res) => {
          const users = res.data.data.content;
          const activeUsers = users.filter((user) => user.enabled);
          setUsers(activeUsers);
        }),
      ]);
    };
    fetchData();
  }, []);

  const deleteCondition = (id) => {
    unregister([`subject-${id}`, `subjectCondition-${id}`, `conditionValue-${id}`]);
    setValue('dirty', true, { shouldDirty: true });
    const filteredConditionIds = conditionIds.filter((conditionId) => conditionId !== id);
    if (!filteredConditionIds.length) {
      setConditionIds([uuidv4()]);
    } else setConditionIds(filteredConditionIds);
  };

  const getAllSubjects = () => {
    const subjects = [];
    for (const [key, value] of Object.entries(getValues())) {
      if (key.includes('subject-')) subjects.push(value);
    }
    return subjects;
  };
  const addNewCondition = () => {
    setConditionIds((conditionIds) => [...conditionIds, uuidv4()]);
  };

  const handleUserData = () => {
    if (!userList) return;
    return userList.map((singleUser) => {
      const reqUser = users.find((user) => singleUser.value === user.id);
      return {
        userName: `${reqUser.firstName} ${reqUser.lastName}`,
        loa: reqUser.customCompanies.find((company) => company.companyId === companyId)
          .limitOfAuthority,
        id: reqUser.id,
      };
    });
  };

  const deleteUser = (id) => {
    const newApprovers = userList.filter((user) => user.value !== id);
    setValue('users', newApprovers);
  };

  const onSubmit = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    let conditions = [];
    if (conditionIds.length && data[`subject-${conditionIds[0]}`]) {
      conditions = conditionIds.map((conditionId) => {
        const subject = data[`subject-${conditionId}`].value;
        const condition = data[`subjectCondition-${conditionId}`].value;
        const values = Array.isArray(data[`conditionValue-${conditionId}`])
          ? data[`conditionValue-${conditionId}`].map((value) => value.value)
          : [data[`conditionValue-${conditionId}`].value];
        const newCondition = { subject, condition, values };
        if (Number(conditionId)) newCondition.id = conditionId;
        return newCondition;
      });
    }

    const recipients = data.users.map((user) => {
      const reqUser = users.find((reqUser) => reqUser.id === user.value);
      return {
        userId: reqUser.id,
        email: reqUser.email,
        name: `${reqUser.firstName} ${reqUser.lastName}`,
        phoneNumner: reqUser.phoneNumber,
        limitOfAuthority: reqUser.customCompanies.find((company) => company.companyId === companyId)
          .limitOfAuthority,
      };
    });
    const newNotification = {
      name: data.name,
      module: data.module.value,
      triggerEvent: data.triggerEvent.value,
      conditions,
      recipients,
    };
    if (id) {
      spmsServiceService
        .editNotification(companyId, id, newNotification)
        .then((_res) => {
          setToast({
            opened: true,
            message: 'Notification created',
            type: 'success',
          });
          navigate('/notification');
        })
        .catch((error) => {
          setToast({
            opened: true,
            message: error.toString(),
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createNotification(companyId, newNotification)
        .then((_res) => {
          setToast({
            opened: true,
            message: 'Notification created',
            type: 'success',
          });
          navigate('/notification');
        })
        .catch((error) => {
          setToast({
            opened: true,
            message: error.toString(),
            type: 'fail',
          });
        });
    }
  };

  return (
    <Box $mobExtend $noPadding $asHolder $radius={12}>
      <form className={approvalStyles.addRuleContainer}>
        <div className={approvalStyles.row}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is Required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    allowed: (v) =>
                      /^(?![\s]+$)[A-Za-z\s]+$/.test(v) || 'Alphabetic characters only',
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Name"
                    $labelRequired
                    $tooltip="Summary of notification"
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <ModuleEvent
              errors={errors}
              setValue={setValue}
              control={control}
              moduleName="module"
              eventName="triggerEvent"
              events={types}
              modules={modules}
            />
          </div>
        </div>
        <div>
          <div className={approvalStyles.conditionsTitle}>
            <Text type="h3" weight={600} style={{display: "flex", alignItems: "center"}}>
              Conditions <Tooltip text="Specify conditions for this notification"/>
            </Text>
          </div>
          <div className={approvalStyles.conditions}>
            {conditionIds.map((condtionId) => (
              <NotificationCondition
                key={condtionId}
                setValue={setValue}
                watch={watch}
                errors={errors}
                control={control}
                conditionId={condtionId}
                companyBranches={company?.branches}
                companyId={companyId}
                deleteCondition={deleteCondition}
                conditionIds={conditionIds}
                prevCondition={
                  notification?.conditions.find((cond) => cond.id === condtionId) ?? null
                }
                selectedSubjects={getAllSubjects()}
                setIsAddConditionActive={setIsAddConditionActive}
                module={module}
              />
            ))}
          </div>
          {isAddConditionActive && (
            <div className={approvalStyles.addConditionButton}>
              <Button.ActionLabeled onClick={addNewCondition}>
                <Button.Action $style="pink" $variant="circle" $width={20} $height={20}>
                  <div className={styles.icon}>
                    <Icon $width={20} $height={20} $icon="add" $color="white" />
                  </div>
                </Button.Action>
                <Text weight={700} type="subtitle">
                  Add Conditions
                </Text>
              </Button.ActionLabeled>
            </div>
          )}
        </div>
        <div className={approvalStyles.container}>
          <div>
            <Text type="h3" weight={600}>
              Recepients
            </Text>
          </div>
          <div className={approvalStyles.userDropDownContainer}>
            <div className={approvalStyles.userDropDown}>
              <div className="inp-container">
                <Controller
                  name="users"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Required',
                    },
                  }}
                  render={({ field }) => (
                    <MultiSelectOpened
                      {...field}
                      noSelectContainer
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      placeholder="Select Users"
                      options={userOptions}
                      control={control}
                    />
                  )}
                />
                {errors['approvers-Defined_List'] && (
                  <p className="error-message">{errors['approvers-Defined_List'].message}</p>
                )}
              </div>
            </div>
            <UsersList users={handleUserData(userList)} deleteUser={deleteUser} noLoa />
          </div>
        </div>
        <div className={styles.submitButtons}>
          <div className={generalStyles.pageButtons}>
            <Button.Main
              disabled={!isDirty || !isValid}
              $primary
              $style="pink"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button.Main>
            <Button.Main
              onClick={() => navigate('/notification')}
              type="button"
              $primary
              $style="gray"
            >
              Discard
            </Button.Main>
          </div>
          {toast.opened === true ? (
            <Toast message={toast.message} opened={toast.opened} type={toast.type} />
          ) : null}
        </div>
      </form>
    </Box>
  );
};

export default AddNotification;
