import styles from '../../../styles/reports.module.css';
import Text from '../../UI/Typography/Text';

const Status = ({ status }) => {
  const statusStyles = {
    approved: {
      color: '#45BABC',
      bgColor: '#45BABC1A',
    },
    active: {
      color: '#45BABC',
      bgColor: '#45BABC1A',
    },
    pending: {
      color: '#F49900',
      bgColor: '#F499001A',
    },
    rejected: {
      color: '#EE2828',
      bgColor: '#EE28281A',
    },
    archived: {
      color: '#EE2828',
      bgColor: '#EE28281A',
    },
    draft: {
      color: '#EE2828',
      bgColor: '#EE28281A',
    },
  };

  const handleStatus = () => {
    let statusState = status.split('_')[0].toLowerCase();
    if (!statusStyles[statusState]) statusState = 'pending';
    const statusStyle = {
      color: statusStyles[statusState].color,
      backgroundColor: statusStyles[statusState].bgColor,
    };
    const statusName = status
      .split('_')
      .map((item) => item.slice(0, 1) + item.slice(1).toLowerCase())
      .join(' ');
    return {
      statusName,
      statusStyle,
    };
  };
  return (
    <div className={styles.status} style={{ ...handleStatus().statusStyle }}>
      <Text weight={500}>{handleStatus().statusName}</Text>
    </div>
  );
};

export default Status;
