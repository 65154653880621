import styles from '../../styles/accountBillings.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { useEffect, useMemo, useState } from 'react';
import Icon from '../../components/UI/General/Icon.jsx';
import DataTableBase from '../../components/UI/General/DataTableBase.jsx';
import { useStore } from '../../store/store.js';
import spmsServiceService from '../../services/spmsService.service.js';
import Dropdown from '../../components/UI/General/Dropdown.jsx';
import { Menu, MenuItem } from '../../components/UI/General/Menu.jsx';
import { Link } from 'react-router-dom';
import moment from 'moment';

const AccountInvoices = () => {
  const companyId = useStore((store) => store.company?.id);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    spmsServiceService.getSubscriptionInvoices(companyId).then((res) => {
      setInvoices(res.data.data);
    });
  }, []);

  const dowloadInvoice = async (id, number) => {
    const response = await spmsServiceService.downloadSubscriptionInvoice(id);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `invoice-#${number}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const columns = useMemo(() => [
    {
      name: 'Invoice',
      selector: (row) => row.number,
      sortable: true,
      cell: (row) => (
        <Link to="/">
          <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>#{row.number}</span>
        </Link>
      ),
    },
    {
      name: 'Date Issued',
      selector: (row) => new Date(row.createdAt),
      sortable: true,
      cell: (row) => <span>{moment(row.createdAt).format('DD MMMM YYYY')}</span>,
    },
    // {
    //   name: 'Products',
    //   selector: (row) => row.products,
    //   sortable: true,
    // },
    {
      name: 'Amount',
      selector: (row) => row.total,
      sortable: true,
      cell: (row) => <div>{`${row.currency} ${row.total}`}</div>,
    },
    {
      name: 'Status',
      selector: (row) => row.state,
      sortable: true,
      cell: (row) => (
        <div className={styles.invoiceStatus}>
          {row.state.slice(0, 1) + row.state.slice(1).toLowerCase()}
        </div>
      ),
    },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <div className={generalStyles.actionMenuHolder}>
          <Dropdown collapsible className={generalStyles.actionMenu}>
            <Dropdown.Header>
              <Button.Action $style="white" $width={32} $height={32}>
                <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
              </Button.Action>
            </Dropdown.Header>
            <Dropdown.Body>
              <Menu className={generalStyles.actionMenuList}>
                <MenuItem onClick={() => dowloadInvoice(row.id, row.number)}>Download</MenuItem>
              </Menu>
            </Dropdown.Body>
          </Dropdown>
        </div>
      ),
    },
  ]);
  return <DataTableBase data={invoices} columns={columns} />;
};

export default AccountInvoices;
