import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from '../styles/loggedInLayout.module.css';
import Icon from './UI/General/Icon';
import Dropdown from './UI/General/Dropdown';
import Text from './UI/Typography/Text';
import { useStore } from '../store/store';
import { useShallow } from 'zustand/react/shallow';
import { useAccessAllowed } from '../hooks/useAccessAllowed';
import spmsServiceService from '../services/spmsService.service.js';
import UpgradePlan from "./shared/UpgradePlan";
import Loading from "./Loading";

const superAdminMenu = [
  {
    list: [
      {
        link: '/dashboard',
        icon: 'dashboard',
        title: 'Dashboard',
        show: true,
      },
      {
        link: '/sales',
        icon: 'sales',
        title: 'Sales',
        show: true,
      },
      {
        icon: 'customers',
        title: 'Customers',
        submenu: [
          {
            link: '/customers/overview',
            title: 'Overview',
            show: true,
          },
          {
            link: '/customers/onboard-new-customer',
            title: 'Onboard new Customer',
            show: true,
          },
        ],
      },
      {
        icon: 'agents',
        title: 'Agents',
        show: true,
        submenu: [
          {
            link: '/agents/overview',
            title: 'Overview',
            show: true,
          },
          {
            link: '/agents/create',
            title: 'Create new Agent',
            show: true,
          },
        ],
      },
      {
        icon: 'vendors',
        title: 'Vendors',
        show: true,
        submenu: [
          {
            link: '/vendors/draft',
            title: 'Overview',
            show: true,
          },
          {
            link: '/vendors/create',
            title: 'Create',
            show: false,
          },
          {
            link: '/vendors/pending-approval',
            title: 'Pending Approval',
            show: true,
          },
          {
            link: '/vendors/approved',
            title: 'Approved',
            show: true,
          },
          {
            link: '/vendors/archived',
            title: 'Archived',
            show: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Management',
    list: [
      {
        icon: 'users',
        title: 'Users Management',
        show: true,
        submenu: [
          {
            link: '/users/overview/active',
            title: 'Overview',
            show: true,
          },
          {
            link: '/users/add',
            title: 'Create new Users',
            show: true,
          },
        ],
      },
      {
        icon: 'reports',
        link: '/reports',
        title: 'Reports',
        show: true,
      },
    ],
  },
  {
    title: 'System Configuration',
    list: [
      {
        icon: 'subscription-packages-and-coupons',
        title: 'Subscription Packages and coupons',
        show: true,
        submenu: [
          {
            link: '/subscription-packages-and-coupons/overview',
            title: 'Overview',
            show: true,
          },
          {
            link: '/subscription-packages-and-coupons/create-a-new-package',
            title: 'Create a new Package',
            show: true,
          },
        ],
      },
      {
        icon: 'integrations',
        link: '/integrations',
        title: 'Integrations',
        show: true,
      },
      {
        icon: 'currencies',
        link: '/currencies',
        title: 'Currencies',
        show: true,
      },
      {
        icon: 'legal-regions',
        link: '/legal-regions',
        title: 'Legal Regions',
        show: true,
      },
      {
        icon: 'roles-permissions',
        link: '/system-roles-and-permissions',
        title: 'System Roles and Permissions',
        show: true,
      },
      {
        icon: 'dashboard',
        title: 'Notification Templates',
        show: true,
        submenu: [
          {
            link: '/notification-templates/create',
            title: 'Create',
            show: true,
          },
          {
            link: '/notification-templates/overview/active',
            title: 'Active',
            show: true,
          },
        ],
      },
    ],
  },
];

const agentMenu = [
  {
    list: [
      {
        link: '/dashboard',
        icon: 'dashboard',
        title: 'Dashboard',
        show: true,
      },
      {
        link: '/sales',
        icon: 'sales',
        title: 'Sales',
        show: true,
      },
      {
        icon: 'customers',
        title: 'Customers',
        show: true,
        submenu: [
          {
            link: '/customers/overview',
            title: 'Overview',
            show: true,
          },
          {
            link: '/customers/onboard-new-customer',
            title: 'Onboard new Customer',
            show: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Management',
    list: [
      {
        link: '/reports',
        icon: 'reports',
        title: 'Reports',
        show: true,
      },
    ],
  },
];

const vendorMenu = [
  {
    list: [
      {
        link: '/vendor-orders',
        icon: 'vendor-orders',
        title: 'Orders',
        show: true,
      },
      {
        link: '/vendor-clients',
        icon: 'clients',
        title: 'Clients',
        show: true,
      },
      {
        link: '/vendor-profile',
        icon: 'userProfile',
        title: 'Profile',
        show: true,
      },
    ],
  },
];

const SideBar = ({ showMenu, accessSubscription }) => {
  const user = useStore((state) => state.user);
  const company = useStore(useShallow((state) => state.company));
  const activeCompany = useStore((state) => state.activeCompany);
  const [menu, setMenu] = useState(null);
  const [canCreateUser, setCanCreateUser] = useState(true);
  const access = useAccessAllowed(['Requisition', 'Purchase_Order', 'Budgets', 'Vendors', 'Payments', 'Receiving', 'Invoicing', 'Reports', 'Configuration'])

  useEffect(() => {
    if (['Basic', 'Standard'].includes(accessSubscription?.plan?.name)) {
      spmsServiceService.getMetrics(company.id).then((res) => {
        const metrics = res.data.data;
        const users = metrics.invitedUsers + metrics.activeUsers;
        setCanCreateUser(accessSubscription?.plan?.maxUsers > users);
      });
    }
  }, [accessSubscription]);

  const clientAdminEndUserMenu = useMemo(
    () => [
      {
        list: [
          {
            icon: 'dashboard',
            link: '/dashboard',
            title: 'Dashboard',
            show: true,
          },
          {
            icon: 'requests',
            title: 'Requests',
            show:
              activeCompany !== null
                ? activeCompany?.requisitionsRequired &&
                  (access?.requisition?.viewAll || access?.requisition?.viewOwn)
                : false,
            submenu: [
              {
                link: '/requisitions/create',
                title: 'Create',
                show: access?.requisition?.create,
              },
              {
                link: '/requisitions/overview/drafts',
                title: 'Draft',
                show: true,
              },
              {
                link: '/requisitions/overview/pending',
                title: 'Pending Approval',
                show: true,
              },
              {
                link: '/requisitions/overview/approved',
                title: 'Approved',
                show: !(activeCompany !== null && activeCompany?.requisitionsRequired),
              },
              {
                link: '/requisitions/overview/rejected',
                title: 'Rejected',
                show: true,
              },
            ],
          },
          {
            icon: 'purchase-orders',
            title: 'Purchase Orders',
            show:
              (access?.purchase_order?.viewAll || access?.purchase_order?.viewOwn) &&
              accessSubscription?.plan?.purchaseOrdersEnabled,
            submenu: [
              {
                link: '/purchase-orders/create',
                title: 'Create',
                show:
                  access?.purchase_order?.create &&
                  !(activeCompany !== null && activeCompany?.requisitionsRequired),
              },
              {
                link: '/purchase-orders/overview/drafts',
                title: 'Draft',
                show: !(activeCompany !== null && activeCompany?.requisitionsRequired),
              },
              {
                link: '/purchase-orders/overview/pending',
                title: 'Pending Approval',
                show: !activeCompany?.requisitionsApprovedToPO,
              },
              {
                link: '/purchase-orders/overview/approved',
                title: 'Approved',
                show: true,
              },
              {
                link: '/purchase-orders/overview/rejected',
                title: 'Rejected',
                show: !(activeCompany !== null && activeCompany?.requisitionsRequired && !activeCompany?.requisitionsApprovedToPO),
              },
              {
                link: '/purchase-orders/overview/closed',
                title: 'Closed',
                show: true,
              },
            ],
          },
          {
            icon: 'receiving',
            title: 'Receiving',
            show: access?.receiving?.viewAll || access?.receiving?.viewOwn,
            submenu: [
              {
                link: '/receiving/overview/not_fulfilled',
                title: 'Not Fulfilled',
                show: true,
              },
              {
                link: '/receiving/overview/partially_fulfilled',
                title: 'Partially Fulfilled',
                show: true,
              },
              {
                link: '/receiving/overview/fulfilled',
                title: 'Fulfilled',
                show: true,
              },
            ],
          },
          {
            icon: 'invoices',
            title: 'Invoices',
            show: access?.invoicing?.viewAll || access?.invoicing?.viewOwn,
            submenu: [
              {
                link: '/invoices/overview/outstanding',
                title: 'Outstanding',
                show: true,
              },
              {
                link: '/invoices/overview/pending_approval',
                title: 'Pending Approval',
                show: true,
              },
              {
                link: '/invoices/overview/approved',
                title: 'Approved',
                show: true,
              },
              {
                link: '/invoices/overview/rejected',
                title: 'Rejected',
                show: true,
              },
            ],
          },
          {
            icon: 'payments',
            title: 'Payments',
            show: access?.payments?.viewAll || access?.payments?.viewOwn,
            submenu: [
              {
                link: '/payments/overview/outstanding',
                title: 'Outstanding',
                show: true,
              },
              {
                link: '/payments/overview/partially_paid',
                title: 'Partially Paid',
                show: true,
              },
              {
                link: '/payments/overview/fully_paid',
                title: 'Fully Paid',
                show: true,
              },
              {
                link: '/payments/overview/closed',
                title: 'Closed',
                show: true,
              },
            ],
          },
        ],
      },

      {
        title: 'Management',
        list: [
          {
            icon: 'vendors',
            title: 'Vendors',
            show: access?.vendors?.viewAll || access?.vendors?.viewOwn,
            submenu: [
              {
                link: '/vendors/create',
                title: 'Create New',
                show: access?.vendors?.create,
              },

              {
                link: '/vendors/draft',
                title: 'Draft',
                show: true,
              },
              {
                link: '/vendors/pending-approval',
                title: 'Pending Approval',
                show: true,
              },
              {
                link: '/vendors/approved',
                title: 'Approved',
                show: true,
              },
              {
                link: '/vendors/rejected',
                title: 'Rejected',
                show: true,
              },
              {
                link: '/vendors/archived',
                title: 'Archived',
                show: true,
              },
            ],
          },
          {
            icon: 'budgets',
            title: 'Budgets',
            show: access?.budgets?.viewAll || access?.budgets?.viewOwn,
            isRestricted: !accessSubscription?.plan?.maxProjectBudgets,
            submenu: [
              {
                link: `budgets/${activeCompany?.id}/create`,
                title: 'Create New',
                show: access?.budgets?.create,
              },
              {
                link: '/budgets/draft',
                title: 'Draft',
                show: true,
              },
              {
                link: '/budgets/pending',
                title: 'Pending Approval',
                show: true,
              },
              {
                link: '/budgets/approved',
                title: 'Approved',
                show: true,
              },
              {
                link: '/budgets/rejected',
                title: 'Rejected',
                show: true,
              },
              {
                link: '/budgets/archived',
                title: 'Archived',
                show: true,
              },
            ],
          },
          {
            icon: 'reports',
            title: 'Reports',
            show: access?.reports?.viewAll || access?.reports?.viewOwn,
            submenu: [
              // { link: '/reports/overview', title: 'Overview', show: true },
              // {
              //   link: '/reports/requisitions',
              //   title: 'Requisition',
              //   show: activeCompany !== null ? activeCompany?.requisitionsRequired && (access?.requisition?.viewAll || access?.requisition?.viewOwn) : false
              // },
              {
                link: '/reports/purchase-orders',
                title: 'Purchase Order',
                show: (access?.purchase_order?.viewAll || access?.purchase_order?.viewOwn) && accessSubscription?.plan?.purchaseOrdersEnabled
              },
              {
                link: '/reports/vendors',
                title: 'Vendor',
                show: access?.vendors?.viewAll || access?.vendors?.viewOwn
              },
              // {
              //   link: '/reports/budgets',
              //   title: 'Budget',
              //   show: (access?.budgets?.viewAll || access?.budgets?.viewOwn) && accessSubscription?.plan?.budgetManagement
              // },
              // { link: '/reports/departments', title: 'Departments', show: true },
              // { link: '/reports/user', title: 'User', show: true },
            ],
          },
        ],
      },

      {
        title: 'Configuration',
        list: [
          {
            link: '/company-setup',
            icon: 'company',
            title: 'Company Profile',
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
          },
          {
            link: '/subsidiary-setup',
            icon: 'subsidiaries',
            title: 'Subsidiaries',
            show:
              (access?.configuration?.viewAll || access?.configuration?.viewOwn) &&
              company !== null &&
              !!accessSubscription?.plan?.maxSubsidiaries
                ? company?.holding
                : null,
          },
          {
            link: '/department-setup',
            icon: 'departments',
            title: 'Departments',
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
          },
          {
            link: '/gl-setup',
            icon: 'gl',
            title: 'General Ledger',
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
          },
          {
            icon: 'roles-permissions',
            title: 'User Roles',
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
            submenu: [
              {
                link: '/user-roles-and-permissions/create',
                title: 'Create New',
                show: accessSubscription?.plan?.allowCustomRBACRoles,
              },
              {
                link: '/user-roles-and-permissions/active',
                title: 'Active',
                show: true,
              },
              {
                link: '/user-roles-and-permissions/archived',
                title: 'Archived',
                show: true,
              },
            ],
          },
          {
            icon: 'users',
            title: 'Users',
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
            submenu: [
              {
                link: '/users/add',
                title: 'Create New',
                show: canCreateUser,
              },
              {
                link: '/users/overview/active',
                title: 'Active',
                show: true,
              },
              {
                link: '/users/overview/invited',
                title: 'Invited',
                show: true,
              },
              {
                link: '/users/overview/archive',
                title: 'Archived',
                show: true,
              },
            ],
          },
          {
            icon: 'approval-workflow',
            link: '/approval',
            title: 'Approvals',
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
          },
          // {
          //   icon: 'accounts-billing',
          //   title: 'Accounts & Billing',
          //   show: accessConfiguration?.viewAll,
          //   submenu: [
          //     {
          //       link: '/accounts-billing/overview',
          //       title: 'Overview',
          //       show: true,
          //     },
          //     {
          //       link: '/accounts-billing/subscription',
          //       title: 'Subscription',
          //       show: true,
          //     },
          //     {
          //       link: '/accounts-billing/billing-information',
          //       title: 'Billing Information',
          //       show: true,
          //     },
          //     {
          //       link: '/accounts-billing/invoices',
          //       title: 'Invoices',
          //       show: true,
          //     },
          //   ],
          // },
          {
            icon: 'taxes',
            title: 'Taxes',
            show: access?.configuration?.viewAll,
            submenu: [
              {
                link: '/taxes/add',
                title: 'Add Tax',
                show: true,
              },
              {
                link: '/taxes/active',
                title: 'Active',
                show: true,
              },
              {
                link: '/taxes/archive',
                title: 'Archive',
                show: true,
              },
            ],
          },
          {
            icon: 'notification',
            link: '/notification',
            title: 'Notifications',
            isRestricted: !accessSubscription?.plan?.allowCustomNotificationManagement,
            show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
          },
        ],
      },

      {
        title: "Super Admin Features",
        list: [
          {
            icon: 'dashboard',
            title: 'Notification Templates',
            show: true,
            submenu: [
              {
                link: '/notification-templates/create',
                title: 'Create',
                show: true,
              },
              {
                link: '/notification-templates/overview/active',
                title: 'Active',
                show: true,
              },
            ],
          },
          {
            icon: 'dashboard',
            title: 'Integrations',
            show: accessSubscription?.plan?.integrationEnabled,
            submenu: [
              {
                link: '/integrations/add',
                title: 'Add',
                show: true,
              },
              {
                link: '/integrations/overview/active',
                title: 'Overview',
                show: true,
              },
            ],
          },
        ],
      },
    ],
    [
      company,
      activeCompany,
      access,
      accessSubscription,
    ],
  );

  useEffect(() => {
    let menu = user.roles.map((el) => el.role?.name) ?? [];
    switch (true) {
      case menu.includes('SUPER_ADMIN'):
        menu = superAdminMenu;
        break;
      case menu.includes('AGENT'):
        menu = agentMenu;
        break;
      case menu.includes('VENDOR'):
        menu = vendorMenu;
        break;
      default:
        menu = clientAdminEndUserMenu;
    }
    setMenu(menu);
  }, [user, clientAdminEndUserMenu]);

  return (
    <aside className={!showMenu ? styles.sidebar : styles.sidebar + ' ' + styles.sidebarOpened}>
      <div className={styles.sidebarLogo}>
        <Link to="/dashboard">
          <img src="/assets/img/general/logo_loggedin.svg" alt="Poms" />
        </Link>
      </div>
      {(company === undefined || access === undefined || accessSubscription === undefined) ? (
        <div className={styles.sidebarMenu}>
          <Loading text={"Loading menu..."}/>
        </div>
        ) : (
        <div className={styles.sidebarMenu}>
          {menu && menu.length > 0
            ? menu.map((item, i) => {
              let menuList;
              let nullCounter = 0;
              if (item.list && item.list.length > 0) {
                menuList = item.list.map((item, index) => {
                  if (item.show !== false && item.show !== null && item.show !== undefined) {
                    if (item.isRestricted){
                      return (
                        <div className={styles.sidebarMenuLink} key={index}>
                          <Icon $icon={item.icon} $width={32} $height={32} />
                          <span>{item.title}</span>
                          <UpgradePlan text={`${item.title} are available on Standard and Premium plans.`}/>
                        </div>
                      )
                    }else {
                      return item.submenu ? (
                        <Dropdown key={index} collapsible={false}>
                          <Dropdown.Header $activeClass={styles.active} className={styles.sidebarMenuLink}>
                            <Icon $icon={item.icon} $width={32} $height={32} />
                            <span>{item.title}</span>
                            <Icon $icon="chevron-down" $width={28} $height={28} />
                          </Dropdown.Header>
                          <Dropdown.Body>
                            <div className={styles.sidebarMenuChildren}>
                              {item.submenu.map(
                                (sub, i) =>
                                  sub.show !== false &&
                                  sub.show !== undefined &&
                                  sub.show !== null && (
                                    <NavLink
                                      key={i}
                                      to={sub.link}
                                      className={({ isActive }) => (isActive ? styles.active : '')}
                                    >
                                      {sub.title}
                                    </NavLink>
                                  ),
                              )}
                            </div>
                          </Dropdown.Body>
                        </Dropdown>
                      ) : (
                        <NavLink
                          key={index}
                          to={item.link}
                          className={({ isActive }) => isActive ? styles.sidebarMenuLink + ' ' + styles.active : styles.sidebarMenuLink}
                        >
                          <Icon $icon={item.icon} $width={32} $height={32} />
                          <span>{item.title}</span>
                        </NavLink>
                      );
                    }
                  } else {
                    nullCounter++;
                  }
                });
              }
              return nullCounter === item.list.length ? null : (
                <div key={i}>
                  {item.title !== undefined ? (
                    <Text weight={500} type="body-1" className={styles.sidebarMenuHeading}>
                      {item.title}
                    </Text>
                  ) : null}
                  {menuList}
                </div>
              );
            })
            : null}
        </div>
      )}
    </aside>
  );

};

export default SideBar;
