import React, {useEffect, useState} from 'react';
import styles from "../../../styles/requisition.module.css";
import Icon from "../../UI/General/Icon";
import * as Button from "../../UI/Forms/Button";
import Input from "../../UI/Forms/Input";
import Select from "react-select";
import Text from "../../UI/Typography/Text";
import Textarea from "../../UI/Forms/Textarea";
import {Controller, useWatch} from "react-hook-form"
import Label from "../../UI/Forms/Label";
import SelectGls from "../../shared/SelectGls";
import {regExps} from "../../../utils/regExps";
import {nf} from "../../../utils/nf";
import Tooltip from "../../UI/General/Tooltip";

const Item = ({remove, index, field, control, getValues, setValue, append, taxes, name, verifyBudget, ...props}) => {
  const formValues = useWatch({
    name: name,
    control
  })

  const [showNote, setShowNote] = useState(false)
  const [isVerified, setIsVerified] = useState(field?.budgetVerification?.isWithinBudget)
  const [budget, setBudget] = useState(undefined)

  const duplicatePresence = formValues.filter(item => item.id === formValues[index].id).length > 1

  const qtyUnitChange = ()=> {
    const quantityValue = getValues([`${name}.${index}.quantity`])
    const unitPriceValue = getValues([`${name}.${index}.unitPrice`])
    const percents = getValues([`${name}.${index}.taxId.value.percentage`])
    const dv = parseFloat(quantityValue) * parseFloat(unitPriceValue)
    if (!isNaN(dv)) {
      setValue(`${name}.${index}.subtotal`, dv.toFixed(2))
    }
    const subtotalValue = parseFloat(getValues([`${name}.${index}.subtotal`]))
    if (!isNaN(subtotalValue) && percents) {
      setValue(`${name}.${index}.taxTotal`,  parseFloat((percents/100)*subtotalValue).toFixed(2))
    }
    const taxTotalValue = parseFloat(getValues([`${name}.${index}.taxTotal`]))
    if (!isNaN(taxTotalValue) && !isNaN(subtotalValue)) {
      setValue(`${name}.${index}.totalCost`, parseFloat(subtotalValue+taxTotalValue).toFixed(2))
    }
  }

  useEffect(() => {
    if (verifyBudget !== undefined && formValues[index].generalLedgerId.value !== undefined){
      if (Array.isArray(verifyBudget.glVerifications) && verifyBudget.glVerifications?.length > 0){
        setIsVerified(verifyBudget.glVerifications.find(el => el.glId === formValues[index]?.generalLedgerId?.value).withinBudget)
        setBudget(verifyBudget.glVerifications.find(el => el.glId === formValues[index]?.generalLedgerId?.value).remainingBudget)
      }
    }
  }, [verifyBudget])

  const tooltipInfo = () => (
    <div className={styles.tooltipRow}>
      <div>
        <Text weight={600} type="body-2">Available</Text>
        <Text weight={600} type="body-2">Requested</Text>
        </div>
      <div>
        <Text type="body-2"><Text weight={500}>{props?.budgetCur}</Text> {nf.format(budget ?? field?.budgetVerification?.remainingBudget)}</Text>
        <Text type="body-2"><Text weight={500}>{props?.budgetCur}</Text> {nf.format(getValues([`${name}.${index}.totalCost`]) * props?.exchangeRate)}</Text>
      </div>
    </div>
  )

  const tooltipIcon = () => (
    <Button.Action $variant="circle" $style={isVerified === true ? "budgetOk" : isVerified === false ? "budgetHigh" : "budgetDefault"} $width={32} $height={32} type="button">
      <Icon $width={32} $height={32} $icon="budget"/>
    </Button.Action>
  )

  return (
    <div className={styles.item}>
      <div className={styles.itemInner}>
        <div>
        {isVerified === undefined ? (
          <Button.Action $variant="circle" $style={"budgetDefault"} $width={32} $height={32} type="button">
            <Icon $width={32} $height={32} $icon="budget"/>
          </Button.Action>
        ) : (
          <Tooltip text={tooltipInfo()} icon={tooltipIcon()} $maxWidth={"max-content"} />
        )}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Description" $isRequired/>
          <Input
            $small
            {...props.register(`${name}.${index}.description`, {
              required: {
                value: true,
                message: "Description is required"
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters"
              },
              validate: {
                onlyAllowed: v => regExps.lineItemDescription.test(v) || 'Alphabetic, numeric, .@&()- characters and space only',
                onlyWhiteSpaces: v => !/^\s+$/.test(v) || 'You have only spaces',
              },
            })}
            className={props.errors?.[name]?.[index]?.description ? "error" : ""}
            type="text"
            placeholder="Enter Description"
          />
          {props.errors?.[name]?.[index]?.description && <p className="error-message">{props.errors?.[name]?.[index]?.description?.message}</p>}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="GL Code" $isRequired/>
          <Controller
            name={`${name}.${index}.generalLedgerId`}
            control={control}
            rules={{
              required: {
                value: true,
                message: "GL is required"
              }
            }}
            render={({ field: { value, onChange, ref } }) => (
              <SelectGls
                value={value}
                onChange={onChange}
                selectRef={ref}
                className={props.errors?.[name]?.[index]?.generalLedgerId ? "react-select-container small error" : "react-select-container small"}
              />
            )}
          />
          {props.errors?.[name]?.[index]?.generalLedgerId && <p className="error-message">{props.errors?.[name]?.[index]?.generalLedgerId?.message}</p>}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Quantity" $isRequired/>
          <Input
            $small
            {...props.register(`${name}.${index}.quantity`, {
              valueAsNumber: true,
              required: {
                value: true,
                message: "Quantity is required"
              },
              maxLength: {
                value: 10,
                message: "Maximum 10 characters"
              },
              min: {
                value: 1,
                message: "Min 1"
              },
              validate: {
                allowed: v => /\d+(\.\d+)?/g.test(v) || 'Numerical values only',
                allowedDepth: v => /^[0-9]+([.][0-9]{1,3})?$/.test(v) || 'Max depth 0.000',
                // allowed: v => /^[0-9]*$/.test(v) || 'Numerical values only',
              },
              onChange: () => qtyUnitChange()
            })}
            className={props.errors?.[name]?.[index]?.quantity ? "error" : ""}
            type="text"
            placeholder="Enter Qty"
          />
          {props.errors?.[name]?.[index]?.quantity && <p className="error-message">{props.errors?.[name]?.[index]?.quantity?.message}</p>}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Unit price" $isRequired/>
          <Input
            $small
            {...props.register(`${name}.${index}.unitPrice`, {
              valueAsNumber: true,
              required: {
                value: true,
                message: "Unit price is required"
              },
              maxLength: {
                value: 10,
                message: "Maximum 10 characters"
              },
              min: {
                value: 0.005,
                message: "Min 0.005"
              },
              validate: {
                allowed: v => /^[0-9]+([.][0-9]{1,3})?$/.test(v) || 'Max depth 0.000',
              },
              onChange: () => qtyUnitChange()
            })}
            defaultValue=""
            className={props.errors?.[name]?.[index]?.unitPrice ? "error" : ""}
            type="text"
            placeholder="Enter Unit Price"
          />
          {props.errors?.[name]?.[index]?.unitPrice && <p className="error-message">{props.errors?.[name]?.[index]?.unitPrice?.message}</p>}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Tax" $isRequired/>
          <Controller
            name={`${name}.${index}.taxId`}
            control={control}
            rules={{
              required: {
                value: true,
                message: "Tax is Required"
              }
            }}
            render={({ field}) => (
              <Select
                {...field}
                className={props.errors?.[name]?.[index]?.taxId ? "react-select-container small error" : "react-select-container small"}
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Sales Tax"
                options={taxes !== null ? taxes : null}
                getOptionLabel={(option) => option.type}
                getOptionValue={(option) => option.value.id}
                onChange={(e) => {return (field.onChange(e), qtyUnitChange())}}
                menuPortalTarget={document.body}
                menuPosition={'absolute'}
                menuPlacement={'bottom'}
                menuShouldScrollIntoView={false}
              />
            )}
          />
          {props.errors?.[name]?.[index]?.taxId && <p className="error-message">{props.errors?.[name]?.[index]?.taxId?.message}</p>}
        </div>
        <div>
          <Label className={styles.label} $title="Subtotal"/>
          <input type="hidden" {...props.register(`${name}.${index}.subtotal`, {valueAsNumber: true})} />
          <input type="hidden" {...props.register(`${name}.${index}.taxTotal`, {valueAsNumber: true})} />
          <input type="hidden" {...props.register(`${name}.${index}.totalCost`, {valueAsNumber: true})} />
          <input type="hidden" {...props.register(`${name}.${index}.id`, {value: duplicatePresence ? null : formValues[index].id} )} defaultValue={null}/>
          <Text type="body-2" weight={500}>{nf.format(formValues[index].subtotal)}</Text>
        </div>
        <div className={styles.itemActions}>
          <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={()=>setShowNote(!showNote)} type="button">
            <Icon $width={20} $height={20} $icon="add-note" $color="#45BABC"/>
          </Button.Action>
          <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={()=>remove(index)} type="button">
            <Icon $width={20} $height={20} $icon="delete" $color="#F24638"/>
          </Button.Action>
          <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={()=>append(formValues[index])} type="button">
            <Icon $width={20} $height={20} $icon="duplicate" $color="#45BABC"/>
          </Button.Action>
        </div>
      </div>
      {showNote ? (
        <div className={styles.itemNote}>
          <div className="inp-container">
            <Controller
              name={`${name}.${index}.itemNotes`}
              control={control}
              rules={{
                pattern: {
                  value: regExps.notes,
                  message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                },
              }}
              render={({ field}) => (
                <Textarea
                  {...field}
                  $label="Notes"
                  $low
                  $small
                  placeholder="Notes text goes here"
                  className={props.errors?.[name]?.[index]?.itemNotes && 'error'}
                />
              )}
            />
            {props.errors?.[name]?.[index]?.itemNotes && <p className="error-message">{props.errors?.[name]?.[index]?.itemNotes?.message}</p>}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Item;
