import React, {useCallback, useEffect, useState} from 'react';
import style from "../../styles/log_reg.module.css";
import Box from "../UI/General/Box";
import Text from "../UI/Typography/Text";
import {Controller, useForm} from "react-hook-form";
import {InputCode} from "../UI/Forms/Input";
import * as Button from "../UI/Forms/Button";
import Icon from "../UI/General/Icon";
import QRCodeCanvas from 'qrcode.react';
import {Auth} from "aws-amplify";
import Toast from "../UI/General/Toast";

const Topt = ({user}) => {
  const {handleSubmit, control, setFocus, formState: {errors, isValid, isDirty}} = useForm({mode: "onChange"});
  const [str, setStr] = useState(null)
  const [localError, setLocalError] = useState(null);
  const [showError, setShowError] = useState(false);

  const generateQRCode = useCallback(
    async (user) => {
      try {
        if (showError !== false){
          setShowError(false)
        }
        const secretCode = await Auth.setupTOTP(user);
        const totpCode = 'otpauth://totp/AWSCognito:'+ user.username + '?secret=' + secretCode + '&issuer=AWSCognito';
        setStr(totpCode);
        console.log(secretCode)
      } catch (error) {
        console.error(error);
        setLocalError(error);
        setShowError(true);
      }
    },
    [],
  );

  useEffect(()=>{
    if (user.challengeName === 'MFA_SETUP'){
      generateQRCode(user)
    }
  },[user, generateQRCode])

  useEffect(()=>{
    setFocus('code.0.number');
  },[setFocus])

  async function setupTOTPAuth({code}) {
    const newCode = code.map(el=>el.number).join('')
    if (user.challengeName === 'MFA_SETUP'){
      try {
        if (showError !== false){
          setShowError(false)
        }
        await Auth.verifyTotpToken(user, newCode);
        await Auth.setPreferredMFA(user, 'TOTP');
      } catch (error) {
        console.log(error)
        setLocalError(error);
        setShowError(true);
      }
    }else {
      await Auth.confirmSignIn(user, newCode, "SOFTWARE_TOKEN_MFA");
    }
  }

  return (
    <div className={style.auth}>
      <div className={style.authInnerSmall}>
        <Box $radius={40} className={style.authContainer}>
          <div className={style.authContent}>
            <div className={style.authContentLogo}>
              <img src="assets/img/login_register/logo.svg" alt="Poms"/>
            </div>
            <div className={style.authHeader}>
              {str !== null ? <QRCodeCanvas value={str} size={192}/> : null}
              <Text type="h2" weight={600}>{str !== null ? "Set up Authentication":"Enter Authentication Code"}</Text>
            </div>
            {str !== null ? (
              <div className={style.totpList}>
                <ul>
                  <li><Text type="subtitle">Download and intstal your preferred (Google or Microsoft) authentication app from your app store.</Text></li>
                  <li><Text type="subtitle">In the app select "Setup new Account"</Text></li>
                  <li><Text type="subtitle">Choose "Scan QR code" to generate an auth code</Text></li>
                </ul>
              </div>
            ) : null}
            <form onSubmit={handleSubmit(setupTOTPAuth)} className={style.authForm + " " + style.authFormSmall}>
              <div className={style.inputsCode}>
                {[1,2,3,4,5,6].map((el,i) => (
                  <Controller
                    key={i}
                    name={`code.${i}.number`}
                    control={control}
                    rules={{
                      required:true,
                      minLength:1,
                      maxLength:1,
                      pattern:/^[0-9]*$/,
                    }}
                    defaultValue=""
                    render={({field}) => (
                      <InputCode
                        {...field}
                        type="text"
                        maxLength={1}
                        onChange={(e) => {
                          const res = e.target.value.replace(/[^0-9]/g, '')
                          field.onChange(res)
                          if (res !== ''){
                            setFocus(`code.${i+1}.number`)
                          }else{
                            setFocus(`code.${i-1}.number`)
                          }
                        }}
                      />
                    )}
                  />
                ))}
              </div>
              <div className={style.btn}>
                <Button.Main $primary $full $style="pink" $iconRight type="submit" disabled={!isDirty || !isValid}>
                  <span>Verify Code</span>
                  <Icon $width={24} $height={24} $icon="arrow-right"/>
                </Button.Main>
              </div>
            </form>
          </div>
        </Box>
      </div>
      {localError != null && showError !== false ? <Toast type="fail" message={localError} opened={showError}/> : null}
    </div>
  );
};

export default Topt;
