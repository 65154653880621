import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import spmsServiceService from '../../../../services/spmsService.service';
import moment from 'moment';
import { useStore } from '../../../../store/store';
import { downloadCSV } from '../../../../utils/csv';

const useGetReportsData = (data, trigger) => {
  const { pathname } = useLocation();
  const activeCompany = useStore((state) => state.activeCompany);

  const checkDateExist = (date) => (date ? moment(date).format('YYYY-MM-DD') : undefined);

  const getReports = useCallback(
    async (page, size) => {
      const handledData = {
        ...data,
        companyId: String(activeCompany.id),
        startDate: checkDateExist(data?.startDate),
        endDate: checkDateExist(data?.endDate),
      };
      const module = pathname.split('/')[2];
      const reportsData = Object.fromEntries(
        Object.entries(handledData)
          .map(([key, value]) =>
            typeof value === 'string'
              ? [key, value]
              : !!value?.value
              ? [key, value.value]
              : [key, undefined],
          )
          .filter(([_, value]) => !!value),
      );
      return await spmsServiceService.fetchReports(module, reportsData, page, size);
    },
    [trigger],
  );

  const getExport = async (name) => {
    const exportData = await getReports(0, 200);
    downloadCSV(exportData.data.data.content, name);
  };

  return { getReports, getExport };
};

export default useGetReportsData;
