import React, { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '../../store/store';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Box from '../../components/UI/General/Box';
import Toast from '../../components/UI/General/Toast';

import budgetServiceService from '../../services/budgetService.service';
import AddNewGl from './AddNewGl';
import GlListView from './GlListView';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';
import { NumericFormat } from 'react-number-format';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import { validationSchema } from '../../utils/validation/budgetSchema';
import PieChartComponent from '../../components/UI/General/PieChartComponent';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import Textarea from '../../components/UI/Forms/Textarea';
import { regExps } from '../../utils/regExps';

const typeOptions = [
  { label: 'Annual', value: 'ANNUAL_BUDGET' },
  { label: 'Project', value: 'PROJECT_BUDGET' },
];

const BudgetsCreate = () => {
  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);
  const accessBudgets = useAccessAllowed('Budgets');
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const params = useParams();

  const companyId = params?.companyId;
  const budgetId = params?.budgetId;
  const [glMode, setGlMode] = useState({ active: false, type: 'add' });
  const [budget, setBudget] = useState(null);
  const [glItems, setGlItems] = useState([]);
  const [monthsToRender, setMonthsToRender] = useState([]);
  const [wasBudgetEdited, setWasBudgetEdited] = useState(false);
  const [editableGl, setEditableGl] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const isRejected = budget?.budgetStatus === 'REJECTED';
  const [tab, setTab] = useState('notes');
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const activeCompany = useStore((state) => state.activeCompany);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      currency: undefined,
      startDate: undefined,
      endDate: undefined,
      branchId: '',
      departmentId: '',
      budgetValue: '',
      notes: '',
    },
  });
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const columns = useMemo(
    () => [
      {
        name: 'GL Code',
        selector: (row) => row.glAccountName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'GL Code'} className={generalStyles.tableValue}>
            <span>{row.glAccountName}</span>
          </span>
        ),
      },
      {
        name: 'GL Description',
        selector: (row) => row.glAccountDescription,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'GL Description'} className={generalStyles.tableValue}>
            <span>{row.glAccountDescription}</span>
          </span>
        ),
      },
      {
        name: 'Department',
        selector: (row) => row?.departmentName,
        wrap: true,
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row.departmentName}</span>
          </span>
        ),
      },
      {
        name: 'Branch',
        selector: (row) => row?.branchName,
        sortable: true,
        cell: (row) => (
          <span data-content={'Branch'} className={generalStyles.tableValue}>
            <span>{row.branchName}</span>
          </span>
        ),
      },
      {
        name: 'GL Budget Value',
        cell: (row) => (
          <span data-content={'GL Budget Value'} className={generalStyles.tableValue}>
            <NumericFormat
              value={row?.glBudgetValue}
              displayType="text"
              thousandSeparator=","
              prefix={budget?.currency + ' '}
              decimalScale={2}
            />
          </span>
        ),
        sortable: true,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        omit: !accessBudgets?.edit,
        cell: (row) => {
          return (
            <div className={generalStyles.actionMenuHolder}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Action $style="white" $width={32} $height={32}>
                    <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                  </Button.Action>
                </Dropdown.Header>
                <Dropdown.Body>
                  <Menu className={generalStyles.actionMenuList}>
                    <MenuItem
                      onClick={() => {
                        setGlMode({ active: true, type: 'edit' });
                        setEditableGl(row);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        deleteGlLines(row.id);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Dropdown.Body>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [budget, glItems, accessBudgets],
  );

  useEffect(() => {
    if (budgetId) {
      budgetServiceService.getBudgetById(budgetId, companyId).then((response) => {
        const formattedData = mapData(response?.data?.data);
        reset(formattedData);
        setBudget(response?.data?.data);
        const glLines = response?.data?.data?.glLineItems;
        setGlItems(glLines);
      });
    }
  }, [companyId, budgetId]);

  useEffect(() => {
    const start = moment(startDate);
    const end = moment(endDate);
    const diff = end.diff(start, 'years');
    if (startDate < endDate && diff > 1) clearErrors(['startDate', 'endDate']);
  }, [startDate, endDate]);

  const onSubmitApproval = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const postData = {
      name: data.name,
      currency: data.currency,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      glLineItems: glItems,
      budgetType: data.budgetType.value,
    };
    await budgetServiceService.editBudgetItem(postData, companyId, budgetId);
    const response = await budgetServiceService.getBudgetById(budgetId, companyId);
    const postDataforApproval = {
      submittedBy: user?.email,
    };
    budgetServiceService
      .sendBudgetForApproval(postDataforApproval, companyId, response.data.data.id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Budget send for approval successfully',
          type: 'success',
          cb: () => navigate(`/budgets/pending`),
        });
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };

  const onSubmitBudget = async (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const postData = {
      name: data.name,
      currency: data.currency,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      glLineItems: glItems,
      budgetType: data.budgetType.value,
      notes: data.budgetNotes,
    };
    if (budgetId) {
      try {
        await budgetServiceService.editBudgetItem(postData, companyId, budgetId);
        const response = await budgetServiceService.getBudgetById(budgetId, companyId);
        const formattedData = mapData(response?.data?.data);
        reset(formattedData);
        setBudget(response?.data?.data);
        setToast({
          opened: true,
          message: isRejected
            ? 'This budget will now be available in draft'
            : 'Budget Updated successfully',
          type: 'success',
          cb: () => budget?.budgetStatus !== "ACTIVE" ? navigate('/budgets/draft') : navigate('/budgets/pending'),
        });
      } catch (error) {
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
      } finally {
        setIsButtonsBlocked(false);
      }
    } else {
      try {
        const response = await budgetServiceService.createBudget(postData, companyId);
        const budgetId = response?.data?.data?.id;
        setToast({
          opened: true,
          message: 'Budget created successfully',
          type: 'success',
        });
        setGlMode({ active: true, type: 'add' });
        navigate(`/budgets/${companyId}/edit/${budgetId}`);
        setWasBudgetEdited(true);
      } catch (error) {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      } finally {
        setIsButtonsBlocked(false);
      }
    }
  };

  function mapData(originalData) {
    return {
      ...originalData,
      glBudgetValue:
        originalData?.glLineItems.reduce((acc, curr) => acc + curr.glBudgetValue, 0) || 0,
      endDate: new Date(originalData.endDate),
      startDate: new Date(originalData.startDate),
      name: originalData?.name || '',
      currency: originalData?.currency || 'USD',
      departmentId: {
        value: originalData?.glLineItems[0]?.departmentId || 0,
        label: originalData?.glLineItems[0]?.departmentName,
      },
      branchId: {
        value: originalData?.glLineItems[0]?.branchId || 0,
        label: originalData?.glLineItems[0]?.branchName,
      },
      budgetType:
        {
          value: originalData?.budgetType || '',
          label: originalData?.budgetType
            .split('_')
            .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
            .join(' '),
        } || '',
    };
  }
  const handleGlLine = (glData) => {
    setToast((item) => ({ ...item, opened: false }));
    let updatedGLItems;
    if (glMode.type === 'add') {
      updatedGLItems = [...glItems, glData];
    } else {
      const filteredGlItems = glItems.filter((gl) => gl.id !== glData.id);
      updatedGLItems = [...filteredGlItems, glData];
    }
    setGlItems(updatedGLItems);
    setGlMode({ type: null, active: false });
    setWasBudgetEdited(true);
    setEditableGl(null);
  };

  const deleteGlLines = async (id) => {
    setGlItems((glItems) => glItems.filter((gl) => gl.id !== id));
    setWasBudgetEdited(true);
  };

  const updateMonthFields = () => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');
    if (startDate && endDate) {
      const result = [];
      let date = new Date(startDate);

      while (date <= new Date(endDate)) {
        const monthIndex = date.getMonth() + 1;
        const year = date.getFullYear();
        result.push(`${monthIndex}-${year}`);
        date.setMonth(date.getMonth() + 1);
        date.setDate(1);
      }
      setMonthsToRender(result);
    } else {
      setMonthsToRender([]);
    }
  };
  const handleDateChange = (name, date) => {
    setValue(name, date);
    updateMonthFields();
    if (startDate < endDate) clearErrors(['startDate', 'endDate']);
  };

  const openStartDatePicker = () => {
    if (startPickerRef.current) {
      startPickerRef.current.setOpen(true);
    }
  };
  const openEndDatePicker = () => {
    if (endPickerRef.current) {
      endPickerRef.current.setOpen(true);
    }
  };

  const isSubmitDisabled = () => {
    if (isRejected) {
      return (!wasBudgetEdited && !isDirty) || !isValid || glMode.active;
    }
    return !isValid || glMode.active;
  };

  const chartData = [
    { name: 'Allocated Unpaid', value: budget?.budgetValue || 1 },
    { name: 'Allocated Paid', value: budget?.totalPOApprovedAmount || 0 },
  ];
  const addNewGlProps = {
    companyId,
    setWasBudgetEdited,
    setGlMode,
    budgetId,
    setToast,
    monthsToRender,
    updateMonthFields,
    glMode,
    handleGlLine,
    glItems,
    currency: budget?.currency,
  };

  return (
    <>
      <div className={generalStyles.pageTop}>
        <Text weight={600} type="h2" className="main-heading">
          {budgetId ? 'Update' : 'Add'} Budget
        </Text>
      </div>
      <Box $mobExtend $noPadding $asHolder $noOverflow $radius={12}>
        <div className={`${generalStyles.fieldsTwo} ${styles.budgetAdd}`}>
          <form className={styles.budgetForm} id={`cForm-budget`}>
            <div className={generalStyles.fieldsTwo}>
              <div className="inp-container">
                <Label $title="Budget Name" $isRequired $tooltipText="Descriptive budget name" />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Budget Name"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      {...field}
                    />
                  )}
                />
                <p className="error-message">{errors?.name?.message}</p>
              </div>
              <div className="inp-container">
                <Label
                  $title="Default Currency"
                  $isRequired
                  $tooltipText="Company currency configured during setup"
                />
                <Controller
                  name="currency"
                  control={control}
                  defaultValue={activeCompany.defaultCurrency}
                  render={({ field }) => (
                    <Input type="text" placeholder={'0'} readOnly {...field} />
                  )}
                />
              </div>
              <div className="inp-container">
                <Label
                  $title="Start Date"
                  $isRequired
                  $tooltipText="Start date of the budget period"
                />
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Enter Start Date"
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        autoComplete="off"
                        customInput={
                          <Input
                            {...field}
                            $iconName="calendar"
                            className={errors.hasOwnProperty(field.name) && 'error'}
                            $iconclick={openStartDatePicker}
                          />
                        }
                        onChange={(date) => {
                          setValue('dirty', true, { shouldDirty: true });
                          handleDateChange('startDate', date);
                          trigger('startDate');
                        }}
                        ref={startPickerRef}
                      />
                    );
                  }}
                />
                <p className="error-message">{errors?.startDate?.message}</p>
              </div>
              <div className="inp-container">
                <Label $title="End Date" $isRequired $tooltipText="End date of the budget period" />
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Enter End Date"
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        autoComplete="off"
                        customInput={
                          <Input
                            {...field}
                            $iconName="calendar"
                            className={errors.hasOwnProperty(field.name) && 'error'}
                            $iconclick={openEndDatePicker}
                          />
                        }
                        onChange={(date) => {
                          setValue('dirty', true, { shouldDirty: true });
                          handleDateChange('endDate', date);
                          trigger('endDate');
                        }}
                        ref={endPickerRef}
                      />
                    );
                  }}
                />
                <p className="error-message">{errors?.endDate?.message}</p>
              </div>
              <div className="inp-container">
                <Label $title="Budget Type" $isRequired $tooltipText="Annual or project budget" />
                <Controller
                  name="budgetType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Type"
                      options={typeOptions}
                    />
                  )}
                />
                <p className="error-message">{errors.currency?.label?.message}</p>
              </div>
            </div>
          </form>
          {budgetId && budget && (
            <PieChartComponent
              piechartData={chartData}
              totalAmount={budget?.totalPOApprovedAmount}
              currency={budget?.currency}
            />
          )}
        </div>

        {budgetId && (
          <div className={styles.glTable}>
            <div className={styles.glTitle}>
              <Text type="h3" weight={600}>
                GL Lines
              </Text>
              {!glMode.active && accessBudgets?.edit && (
                <Button.ActionLabeled
                  onClick={() => {
                    setGlMode({ type: 'add', active: true });
                    updateMonthFields();
                  }}
                >
                  <Button.Action $style="pink" $variant="circle" $width={20} $height={20}>
                    <Icon $width={20} $height={20} $icon="add" $color="white" />
                  </Button.Action>
                  <Text weight={700} type="subtitle">
                    Add New
                  </Text>
                </Button.ActionLabeled>
              )}
            </div>
            {glMode.type === 'add' && glMode.active && <AddNewGl {...addNewGlProps} />}
            {(!!glItems.length || !glMode.active) && (
              <GlListView
                {...addNewGlProps}
                glItems={glItems}
                setEditableGl={setEditableGl}
                editableGl={editableGl}
                columns={columns}
              />
            )}

            <div className={styles.notes}>
              <TabsSlider selected={tab} className={generalStyles.tabs}>
                <span onClick={() => setTab('notes')}>Notes</span>
                <span onClick={() => setTab('history')}>History</span>
              </TabsSlider>
            </div>

            {tab === 'notes' && (
              <div className={styles.notesContainer}>
                <div className="inp-container">
                  <Controller
                    name="budgetNotes"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 500,
                        message: 'Maximum 500 characters',
                      },
                      validate: {
                        allowed: (v) =>
                          regExps.notes.test(v) || 'Only Alpha and Numerical characters',
                      },
                    }}
                    render={({ field }) => (
                      <Textarea
                        {...field}
                        $low
                        $counter
                        $counterMax={500}
                        placeholder="Enter notes"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                      />
                    )}
                  />
                  {errors.reason && (
                    <p className="error-message position-top-95">{errors.reason.message}</p>
                  )}
                </div>
                <NotesTab data={budget?.notes} />
              </div>
            )}
            {tab === 'history' && <HistoryTab data={budget.history} />}
          </div>
        )}
      </Box>
      <div className={generalStyles.pageButtons}>
        <Button.Main
          $primary
          disabled={
            !isValid ||
            (!isDirty && !wasBudgetEdited) ||
            !accessBudgets?.edit ||
            isButtonsBlocked ||
            glMode.active
          }
          $style="pink"
          type="submit"
          form="cForm-budget"
          onClick={handleSubmit(onSubmitBudget)}
        >
          {budget?.budgetStatus !== "ACTIVE" ? "Save" : "Submit for approval"}
        </Button.Main>

        {budget?.budgetStatus !== "ACTIVE" ? (
          <>
            {glItems?.length !== 0 && (
              <Button.Main
                $primary
                $style="pink"
                disabled={isSubmitDisabled()}
                type="submit"
                onClick={handleSubmit(onSubmitApproval)}
              >
                Submit for approval
              </Button.Main>
            )}
          </>
        ) : null}

        <Button.Main
          $primary
          $style="gray"
          onClick={() => navigate(`/budgets/${isRejected ? 'rejected' : 'draft'}`)}
        >
          Discard
        </Button.Main>
      </div>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default BudgetsCreate;
