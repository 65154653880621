import styled from "styled-components";
import React from "react";
import Tooltip from "../General/Tooltip";

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  
  @media(min-width: 768px)  {
    font-size: 16px;
  }
`
const Required = styled.span`
color: #cd3e27;
`

const Label = (props) => {
  return (
    <StyledLabel {...props}>
      {props.$title}
      {props.$isRequired && <Required>*</Required>}
      {props.$tooltipText && <Tooltip text={props.$tooltipText}/>}
    </StyledLabel>
  );
};

export default Label
