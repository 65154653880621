import React from 'react';
import Text from "../../../UI/Typography/Text";
import styles from "../../../../styles/purchaseOrders.module.css";
import Status from "../../../UI/Typography/Status";

const Title = ({title, poNumber, poState}) => {
  return (
    <div className={styles.title}>
      <Text weight={600} type="h2">{title}</Text>
      {poNumber && <Text weight={600} type="h4" className={styles.titleNumber}>({poNumber})</Text>}
      {poState && (
        <div className={styles.titleStatus}>
          <Text type={"h4"} weight={500} className={styles.statusBarLabel}>Status:&nbsp;</Text>
          <Text type={"h4"} weight={700}><Status value={poState}/></Text>
        </div>
      )}
    </div>
  );
};

export default Title;
