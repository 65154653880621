import React, {useEffect, useState} from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import {Amplify, Hub} from "aws-amplify";
import awsExports from "./aws-exports";
import {useStore} from "./store/store";
import {useShallow} from "zustand/react/shallow";
import Smartlook from "smartlook-client";
import requestsService from "./services/requestsService.service";
import {userRoutes} from "./routing/userRoutes";
import {generalRoutes} from "./routing/generalRoutes";
import {adminRoutes} from "./routing/adminRoutes";
import {vendorRoutes} from "./routing/vendorRoutes";
import Toast from "./components/UI/General/Toast";
import {RootBoundary} from "./routing/errorElements/root";
import LoggedIn from "./components/Layouts/LoggedIn";
import LoggedOut from "./components/Layouts/LoggedOut";
import {axiosInstance} from "./services/API";

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

const Main = () => {
  const user = useStore(useShallow((state) => state.user));
  const resetUser = useStore((state) => state.reset);
  const updateUser = useStore((state) => state.updateUser);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  console.log(process.env)
  useEffect(() => {
    const inited = Smartlook.initialized();
    if (inited === false) {
      Smartlook.init('2935ed8c3059f1276afeb01fd2a2d19c97597fb2', {region: 'eu'});
    }
  }, []);

  useEffect(() => {
    setToast((item) => ({...item, opened: false}));
    Hub.listen('auth', ({payload: {event, data}}) => {
      if (event === 'signIn') {
        console.log(data);
        try {
          if (user === null) {
            requestsService.getUserDetailsByEmail(data.signInUserSession.idToken.payload.email)
              .then((r) => {
                updateUser(r.data.data);
                axiosInstance.interceptors.request.use((config) => {
                    console.log('Interceptors to await COMPANY');
                    config.headers["Company-id"] = r.data.data?.customCompanies?.find(item => item.company.mainCompany === true)?.companyId
                    return config;
                  },
                  (error) => {
                    return Promise.reject(error);
                  },
                );
              })
              .catch((error) => {
                setToast({
                  opened: true,
                  message: error.response.data.errors[0].errorMessage,
                  type: 'fail',
                });
              });
          }
        } catch (error) {
          console.log('Error fetching user:', error);
        }
      }
      if (event === 'signOut') {
        useStore.persist.clearStorage();
        resetUser();
        Smartlook.anonymize();
      }
    });
  }, [user]);

  useEffect(() => {
    const jiraWidget = (callback) => {
      let script = document.createElement('script');
      script.setAttribute('class', 'jsdClass');
      script.setAttribute('data-jsd-embedded', '');
      script.setAttribute('data-key', '9043f2ac-dbaa-4b80-8713-8faf429f034d');
      script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      if (script.readyState) {
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        script.onload = () => callback();
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    };
    if (window.location.pathname !== '/' && document.querySelectorAll('.jsdClass').length < 1) {
      jiraWidget(() => {
        let DOMContentLoaded_event = document.createEvent('Event');
        DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
        window.document.dispatchEvent(DOMContentLoaded_event);
      });
    }
    if (window.location.pathname === '/') {
      const widget = document.getElementById('jsd-widget');
      const scripts = document.querySelectorAll('.jsdClass');
      if (scripts && widget) {
        scripts.forEach((el) => {
          el.parentNode.removeChild(el);
        });
        widget.parentNode.removeChild(widget);
      }
    }
  }, [window.location.pathname]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<RootBoundary/>}>
        <Route element={<LoggedOut/>}>
          {userRoutes}
        </Route>
        <Route element={<LoggedIn/>}>
          {vendorRoutes}
          {adminRoutes}
        </Route>
        {generalRoutes}
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>}/>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type}/>
      ) : null}
    </>
  );
};

export default Main;
