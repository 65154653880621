import { useState, useEffect } from 'react';
import Text from '../../components/UI/Typography/Text';
import spmsServiceService from '../../services/spmsService.service';
import { useParams } from 'react-router-dom';
import Box from '../../components/UI/General/Box';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/vendor.module.css';
import ActiveLegalInfo from './ActiveLegalInfo';
import ActivePO from './ActivePO';
import Representatives from './Representatives';
import ConfigurationTab from './ConfigurationTab';
import Toast from '../../components/UI/General/Toast';
import TabsSlider from '../../components/UI/General/TabsSlider';

const ActiveDashboard = () => {
  const { vendorId, companyId } = useParams();
  const [isRating, setIsRating] = useState();
  const [activeVendorData, setActiveVendorData] = useState(null);
  const [tab, setTab] = useState('profile');
  const [refetch, setRefetch] = useState(false);

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    spmsServiceService.getCompanyVendorById(companyId, vendorId).then((res) => {
      const vendorData = res.data.data;
      const handledVendorData = {
        ...vendorData.vendor,
        vendorCode: vendorData.vendorCode,
        onceOffPOLimit: vendorData.onceOffPOLimit,
        overallAnnualLimit: vendorData.overallAnnualLimit,
        vendorNotes: vendorData.vendorNotes,
        currency: vendorData.currency,
        departments: vendorData.departments,
        branches: vendorData.branches,
        glAccounts: vendorData.glAccounts,
        users: vendorData.users ?? [],
        vendorStatus: vendorData.vendorStatus,
        approval: vendorData.approval,
        history: vendorData.history,
      };
      setActiveVendorData(handledVendorData);
    });
    spmsServiceService.getVendorRating(vendorId).then((res) => {
      setIsRating(res.data.data);
    });
  }, [refetch]);

  return (
    <>
      <div>
        <Text type="h2" weight={600}>
          {activeVendorData?.legalName}
        </Text>
        <br />
      </div>
      <TabsSlider>
        <span onClick={() => setTab('profile')}>Vendor Profile</span>
        <span onClick={() => setTab('po')}>Purchase Order</span>
        <span onClick={() => setTab('contacts')}>Representative</span>
        <span onClick={() => setTab('config')}>Configuration</span>
      </TabsSlider>
      {tab === 'profile' && (
        <ActiveLegalInfo
          activeVendorData={activeVendorData}
          setToast={setToast}
          companyId={companyId}
          triggerRefetch={setRefetch}
        />
      )}
      {tab === 'po' && (
        <ActivePO
          vendorId={vendorId}
          companyId={companyId}
          isRating={isRating}
          currency={activeVendorData?.currency}
        />
      )}
      {tab === 'contacts' && <Representatives isActive setToast={setToast} />}
      {tab === 'config' && (
        <ConfigurationTab vendorSetupData={activeVendorData} setToast={setToast} />
      )}
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default ActiveDashboard;
