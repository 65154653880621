import React, { useRef } from 'react';
import styled from 'styled-components';
import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import Icon from '../General/Icon';
import Text from '../Typography/Text';
import { bytesToSizes } from '../../../utils/bytesToSizes';

const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FileInputButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  font-weight: 500;
  color: #c85d9f;
  border: 1px solid #c85d9f;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`;

const FileInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 220px;
  margin-bottom: 0;
  padding: 35px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: ${({ $isInvalid }) => ($isInvalid ? '1px dashed #cd3e27' : '1px dashed #acaec9')};
  font-size: 14px;
  color: #8a8b9d;
  &:hover ${FileInputButton} {
    background-color: #c85d9f;
    color: #ffffff;
  }
  & input[type='file'] {
    display: none;
  }
`;

const FileOutput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 220px;
  padding: 18px 16px;
  border: 1px solid #acaec9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow-x: hidden;
`;
const FileItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #dddddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
`;

const FileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: rgba(69, 186, 188, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
`;

const FileInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FileRemove = styled.div`
  margin-top: -2px;
  margin-right: -2px;
  cursor: pointer;
`;

const validFileExtensions = [
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'odt',
  'xls',
  'xlsx',
  'PSD',
  'dot',
  'csv',
  'txt',
  'rtf',
  'html',
  'htm',
];

const checkIfFilesAreCorrectType = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!validFileExtensions.includes(file.attachment.name.split('.').pop())) {
        return (valid = false);
      } else {
        return (valid = true);
      }
    });
  }
  return valid;
};

const checkFilesAreCorrectSize = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (file.attachment.size < 2097152) {
        return (valid = true);
      } else {
        return (valid = false);
      }
    });
  }
  return valid;
}

const Documents = ({control, fieldName, filesSchema, isDisable, error, required, clearErrors}) => {
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
    rules: {
      required: {
        value: required,
        message: "Files are required"
      },
      validate: {
        notAllowed: v => checkIfFilesAreCorrectType(v) || `Only ${validFileExtensions.join(', ')} files are allowed`,
        lessThan2MB: v => checkFilesAreCorrectSize(v) || 'Maximum file size is 2MB',
      },
    },
  });

  const filesValues = useWatch({
    name: fieldName,
    control,
  });

  const hiddenFileInput = useRef(null);

  const handleRemove = (index) => {
    remove(index);
    hiddenFileInput.current.value = '';
  };

  const handleAddDocuments = (event) => {
    if (clearErrors){
      clearErrors(fieldName)
    }
    const uploadedFiles = Array.from(event.target.files);
    const files = filesSchema(uploadedFiles);
    append(files);
    hiddenFileInput.current.value = '';
  };

  return (
    <div style={{ position: 'relative' }}>
      <FileWrapper>
        <FileInput $isInvalid={error}>
          <FileInputButton>Choose File</FileInputButton>
          <Text weight={400} type="body-2">
            {filesValues && filesValues.length > 0 ? filesValues.length + ' Files Uploaded' : 'No file chosen'}
          </Text>
          <input
            ref={hiddenFileInput}
            type="file"
            multiple
            onChange={handleAddDocuments}
            disabled={isDisable}
          />
        </FileInput>
        {filesValues && filesValues.length > 0 ? (
          <FileOutput>
            {fields.map(({ id, attachment }, index) => (
              <div key={id}>
                <Controller
                  control={control}
                  name={`${fieldName}.${index}`}
                  render={() => (
                    <FileItem>
                      <FileIcon>
                        <Icon $icon="sb-2" $width={32} $height={32} />
                      </FileIcon>
                      <FileInfo>
                        <Text weight={600} type="body-2">{attachment?.name}</Text>
                        <Text type="body-3">{bytesToSizes(attachment?.size)}</Text>
                      </FileInfo>
                      <FileRemove onClick={() => handleRemove(index)}>
                        <Icon $icon="close" $width={16} $height={16} />
                      </FileRemove>
                    </FileItem>
                  )}
                />
              </div>
            ))}
          </FileOutput>
        ) : null}
      </FileWrapper>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Documents;
