import spmsServiceService from "../../services/spmsService.service";
import DataTableBaseRemote from "../../components/UI/General/DataTableBaseRemote";
import { Suspense } from "react";
import Text from "../../components/UI/Typography/Text";
import generalStyles from '../../styles/general.module.css'

function CompanyList() {

  let columns = [
    {
      name: 'Name',
      selector: row => row.name,
    }, {
      name: 'Email',
      selector: row => row.email,
    }, {
      name: 'PhoneNumber',
      selector: row => row.phoneNumber,
    }, {
      name: 'Industry',
      selector: row => row.industry,
    }, {
      name: 'Subsidiary',
      cell: (row) => row.subsidiary ? (
        "true"
      ) : (
        "false"
      )
    },
  ]

  const getData = (page, size) => {
    return spmsServiceService.getCompanies(size, page);
  }

  return (
    <>
      <div className={generalStyles.pageTop}>
        <Text type="h2" weight={600}>Company List</Text>
      </div>
      <br></br>
      <Suspense fallback={<h2>Loading...</h2>}>

        <DataTableBaseRemote
          columns={columns}
          fetchData={getData}
          selectableRows
          selectableRowsHighlight
          selectableRowsNoSelectAll
        />
      </Suspense>
    </>
  );
}

export default CompanyList;
