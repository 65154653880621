import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from '../../styles/purchaseOrders.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import Title from '../../components/Admins/PurchaseOrders/View/Title';
import Top from '../../components/Admins/PurchaseOrders/View/Top';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import GridItem from '../../components/Admins/PurchaseOrders/View/GridItem';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import { Controller, useForm } from 'react-hook-form';
import Toast from '../../components/UI/General/Toast';
import spmsServiceService from '../../services/spmsService.service';
import { useNavigate, useParams } from 'react-router-dom';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import Textarea from '../../components/UI/Forms/Textarea';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useStore } from '../../store/store';
import TabsSlider from '../../components/UI/General/TabsSlider';
import {useAccessAllowed} from "../../hooks/useAccessAllowed";
import {regExps} from "../../utils/regExps";

const RequisitionView = () => {
  const { defaultCurrency } = useStore((state) => state.activeCompany);
  const activeCompany = useStore((state) => state.activeCompany);
  const user = useStore((state) => state.user);
  const { requisitionId } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState('notes');
  const [values, setValues] = useState(null);
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      requisitionState: '',
      title: '',
      requester: '',
      notes: [],
      requestNotes: '',
      companyId: '',
      branchId: '',
      departmentId: '',
      requestOnBehalfOf: '',
      vendorId: '',
      currency: '',
      approver: '',
      requisitionItems: [],
      attachments: [],
      deliveryDate: '',
    },
    values,
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false)

  const accessRequisition = useAccessAllowed('Requisition');

  const subtotal = useMemo(() => {
    return values?.requisitionItems
      ? values?.requisitionItems.reduce(
          (acc, current) => acc + (parseFloat(current.subtotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const taxTotal = useMemo(() => {
    return values?.requisitionItems
      ? values?.requisitionItems.reduce(
          (acc, current) => acc + (parseFloat(current.taxTotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const totalCost = useMemo(() => {
    return values?.requisitionItems
      ? values?.requisitionItems.reduce(
          (acc, current) => acc + (parseFloat(current.totalCost) || 0),
          0,
        )
      : 0;
  }, [values]);

  const onSubmit = (data, type) => {
    setToast((item) => ({ ...item, opened: false }));
    const newData = {
      reason: data.requestNotes,
      approved: type === 'approved' ? true : type === 'rejected' ? false : undefined,
      itemsApproved: data.requisitionItems.map((el) => el.id),
    };
    if (type === 'rejected' && data.requestNotes === '') {
      setTab('notes');
      setError('requestNotes', {
        type: 'focus',
        message: 'Please give a reason for rejecting the requisition',
      });
    } else {
      const pac = data.approval.approvers.filter(item => item.status === "PENDING_APPROVAL" && item.userId !== user.id).length
      console.log(pac)
      const to = () => {
        if (type === 'approved'){
          if (pac > 0){
            return '/requisitions/overview/pending'
          }else {
            return activeCompany?.requisitionsApprovedToPO ? '/purchase-orders/overview/approved' : '/purchase-orders/overview/pending';
          }
        }else {
          return '/requisitions/overview/' + type
        }
      }
      spmsServiceService
        .approveRequisition(requisitionId, newData)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Requisition ' + type,
            type: 'success',
            cb: () => navigate(to()),
          });
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      return await spmsServiceService.getRequisition(requisitionId);
    };
    if (requisitionId) {
      fetchData().then((r) => {
        if (r.data.message === 'Operation Successful') {
          isApprover.current = r.data.data.approval?.approvers?.filter(item => item.userId === user.id).length ?? 0;
          isCurrentUserApproved.current = r.data.data.approval?.approvers?.find(item => item.userId === user.id)?.status === "APPROVED" ?? false;
          const {vendorNotes, ...other} = r.data.data
          const newData = {
            ...other,
            vendorNote: vendorNotes?.note
          }
          setValues(newData);
        }
      });
    }
  }, [requisitionId, user]);

  const files = (files) =>
    files.map((file) => ({
      id: 0,
      attachment: file,
      name: file.name,
      type: 'REQUISITION_DOC',
      companyId: values?.companyId?.value,
      requisitionId: 1,
      companyVendorId: 1,
    }));

  return (
    <>
      <Title title={values?.title} poNumber={values?.requisitionState === "PENDING_APPROVAL" ? null : values?.requestNo} />
      <Box $mobExtend $asHolder>
        <Top data={values} />

        <form id="requisitionForm">
          <div className={styles.table}>
            <div className={styles.tableHead}>
              <div/>
              <Text type="body-1" weight={500}>
                Description
              </Text>
              <Text type="body-1" weight={500}>
                GL Code
              </Text>
              <Text type="body-1" weight={500}>
                Quantity
              </Text>
              <Text type="body-1" weight={500}>
                Unit Price
              </Text>
              <Text type="body-1" weight={500}>
                Tax
              </Text>
              <Text type="body-1" weight={500}>
                Subtotal
              </Text>
            </div>
            <div className={styles.tableBody}>
              {values?.requisitionItems?.map((item) => (
                <GridItem
                  key={item.id}
                  data={item}
                  id={item.id}
                  budgetCur={values?.budget?.currency}
                  exchangeRate={values?.currency?.rate !== undefined ? values?.currency?.rate : 1}
                />
              ))}
            </div>
          </div>
          <div className={styles.summaryWrapper}>
            <div className={styles.vendorNotes}>
              <Text type={"body-1"} weight={600}>Vendor Notes</Text>
              <Text type={"body-1"}>{values?.vendorNote ?? "There're no Vendor Notes"}</Text>
            </div>
            <Summary
              subtotal={subtotal}
              taxTotal={taxTotal}
              totalCost={totalCost}
              currency={values?.currency?.code}
              currencyBudget={defaultCurrency}
              totalBudgetCost={values?.currency?.rate * totalCost}
            />
          </div>
        </form>

        <TabsSlider className={generalStyles.tabs} selected={tab}>
          <span onClick={() => setTab('notes')}>Notes</span>
          <span onClick={() => setTab('documents')}>Documents</span>
          <span onClick={() => setTab('history')}>History</span>
          <span onClick={() => setTab('approval')}>Approval</span>
        </TabsSlider>

        <div className={styles.attachmentsBlock}>
          {tab === 'notes' && (
            <div className={styles.attachmentsBlockItem}>
              {values?.requisitionState !== 'APPROVED' ? (
                <div className="inp-container">
                  <Controller
                    name="requestNotes"
                    control={control}
                    rules={{
                      pattern: {
                        value: regExps.notes,
                        message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                      },
                    }}
                    render={({ field }) => (
                      <Textarea
                        {...field}
                        $low
                        $counter
                        $counterMax={500}
                        placeholder="Notes text goes here"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                      />
                    )}
                  />
                  {errors.requestNotes && <p className="error-message">{errors.requestNotes?.message}</p>}
                </div>
              ) : null}
              <NotesTab data={values?.notes} />
            </div>
          )}
          {tab === 'documents' && <QuotesTab data={values?.attachments} />}
          {tab === 'history' && <HistoryTab data={values?.history} />}
          {tab === 'approval' && <ApprovalTab data={values?.approval?.approvers} />}
        </div>
      </Box>
      <div className={generalStyles.pageButtons}>
        {(values?.requisitionState === 'PENDING_APPROVAL' && accessRequisition?.approve && isApprover.current > 0 && !isCurrentUserApproved.current) && (
          <>
            <Button.Main
              $primary
              $style="pink"
              disabled={!values?.requisitionItems?.length || isSubmitSuccessful}
              type="submit"
              form="requisitionForm"
              onClick={handleSubmit((data) => onSubmit(data, 'approved'))}
            >
              Approve
            </Button.Main>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              form="requisitionForm"
              disabled={isSubmitSuccessful && !errors.length}
              onClick={handleSubmit((data) => onSubmit(data, 'rejected'))}
            >
              Reject
            </Button.Main>
          </>
        )}
        <Button.Main
          $primary
          $style="gray"
          onClick={() => {
            const to =
              values?.requisitionState === 'PENDING_APPROVAL'
                ? 'pending'
                : values?.requisitionState === 'APPROVED'
                ? 'approved'
                : 'drafts';
            navigate('/requisitions/overview/' + to);
          }}
          type="button"
        >
          Discard
        </Button.Main>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default RequisitionView;
