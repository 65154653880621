import styles from '../../../../styles/approvalWorkflow.module.css';
import generalStyles from '../../../../styles/general.module.css';
import TabbedNav from '../../../UI/General/TabbedNav';
import Text from '../../../UI/Typography/Text';
import { useCallback, useEffect, useState } from 'react';
import RuleRow from './RuleRow';
import Pagination from './Pagination';
import * as Button from '../../../UI/Forms/Button.jsx';
import { useNavigate } from 'react-router-dom';
import TableNoData from '../../../UI/General/TableNoData.jsx';
import { useSubscriptionAllowed } from '../../../../hooks/useSubscriptionAllowed.jsx';
import AddButton from '../../../shared/AddButton.jsx';
import Modal from '../../../UI/Modal/Modal.jsx';
import spmsServiceService from '../../../../services/spmsService.service.js';

const Rules = ({
  setIsActiveTab,
  companyId,
  isActiveTab,
  rules,
  setRules,
  currentModuleId,
  type,
  archiveRule,
  activateRule,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState({ label: 10, value: 10 });
  const currentSubscription = useSubscriptionAllowed();
  const [activePORQRules, setActivePORQRules] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const startIndex = currentPage * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  const currentRules = rules?.slice(startIndex, endIndex);

  useEffect(() => {
    if (currentRules.length === 0 && currentPage !== 0) prevPage();
  }, [currentPage, currentRules]);

  useEffect(() => {
    if (currentRules.length === 0 && currentPage !== 0) prevPage();
  }, [currentPage, currentRules]);

  useEffect(() => {
    spmsServiceService.getMetrics(companyId).then((res) => {
      const metrics = res.data.data;
      console.log('metrics', metrics);
      setActivePORQRules(metrics.activeApprovalRules-2);
    });
  }, [rules]);

  const headerColumns = [
    {
      name: 'Rule Name',
      value: 'name',
    },
    {
      name: 'Rule Description',
      value: 'description',
    },
    {
      name: 'Type',
      value: 'type',
    },
    {
      name: 'Last Updated',
      value: 'lastUpdatedAt',
    },
  ];

  const moveRule = useCallback(
    (dragIndex, hoverIndex, difference) => {
      const paginationIndex = currentPage * 10;
      const dragItem = rules[dragIndex + paginationIndex];
      setRules((rules) => {
        const updatedRules = [...rules];
        if (dragIndex < hoverIndex) {
          updatedRules.splice(hoverIndex + paginationIndex + (difference > 0 ? 1 : 0), 0, dragItem);
          updatedRules.splice(dragIndex + paginationIndex, 1);
        }
        if (dragIndex > hoverIndex) {
          updatedRules.splice(dragIndex + paginationIndex, 1);
          updatedRules.splice(hoverIndex + paginationIndex + (difference > 0 ? 1 : 0), 0, dragItem);
        }
        return updatedRules;
      });
    },
    [rules, currentPage],
  );

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToActive = () => {
    setIsActiveTab(true);
    setCurrentPage(0);
  };

  const goToArchive = () => {
    setIsActiveTab(false);
    setCurrentPage(0);
  };
  return (
    <div>
      <div className={generalStyles.tabSection}>
        <TabbedNav>
          <span onClick={goToActive}>Active</span>
          <span onClick={goToArchive}>Archived</span>
        </TabbedNav>
        <div className={generalStyles.addItemButton}>
          {activePORQRules !== undefined && currentSubscription && (
            <AddButton
              $click={() => {
                if (activePORQRules >= currentSubscription?.plan?.maxRQPOApprovalRules) {
                  setShowModal(true);
                  return;
                }
                navigate(`/approval/${companyId}/add-rule`, {
                  state: { moduleId: currentModuleId },
                });
              }}
            />
          )}
        </div>
      </div>
      <div>
        {!rules.length ? (
          <TableNoData />
        ) : (
          <div>
            <div className="mb-10">
              <Text>Rules will execute from top to bottom</Text>
            </div>
            <div className={styles.rules}>
              <div className={styles.ruleTable}>
                <div className={styles.tableHeader}>
                  <span></span>
                  {headerColumns.map((headerColumn) => (
                    <div key={headerColumn.value}>{headerColumn.name}</div>
                  ))}
                  <div>Action</div>
                </div>
                <div className={styles.tableRows}>
                  {currentRules?.map((rule, i) => (
                    <RuleRow
                      id={rule.id}
                      isActiveTab={isActiveTab}
                      companyId={companyId}
                      key={i}
                      rule={rule}
                      index={i}
                      moveRule={moveRule}
                      currentModuleId={currentModuleId}
                      type={type}
                      archiveRule={archiveRule}
                      activateRule={activateRule}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Pagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              changePage={changePage}
              nextPage={nextPage}
              prevPage={prevPage}
              currentPage={currentPage}
              itemsLength={rules.length}
              startIndex={startIndex}
              endIndex={endIndex}
            />
          </div>
        )}
      </div>
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>
            {currentSubscription?.plan?.maxRQPOApprovalRules === 0
              ? 'Approval Rules are available on Standard and Premium plans'
              : `The ${currentSubscription?.plan?.name} Subscription Plan 
              only allows ${currentSubscription?.plan?.maxRQPOApprovalRules}
            approval rules.`}
          </Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => navigate('/accounts-billing/subscription')}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
    </div>
  );
};

export default Rules;
