import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import React from "react";
import NotFound from "../../components/NotFound";

export const RootBoundary = () => {
  const error = useRouteError();
  console.log(error)
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound/>;
    }
    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }
    if (error.status === 405) {
      return error.data ? <div>{error.data}</div> : <div>You are not allowed to see this</div>;
    }
    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }
  }
  return <div>Something went wrong</div>;
}
