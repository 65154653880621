import { Controller, useForm } from 'react-hook-form';
import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import DatePicker from 'react-datepicker';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles/systemAudit.module.css';
import generalStyles from '../../styles/general.module.css';
import Select from 'react-select';
import RequestsService from '../../services/requestsService.service';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import moment from 'moment';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote.jsx';
import SelectAsync from '../../components/shared/SelectAsync.jsx';

const modules = [
  { label: 'Company', value: 'Company' },
  { label: 'Subsidiary', value: 'Subsidiary' },
  { label: 'Branch', value: 'Branch' },
  { label: 'Department', value: 'Department' },
  { label: 'GL', value: 'GL' },
  { label: 'User Roles + Permissions', value: 'User Roles + Permissions' },
  { label: 'Users', value: 'Users' },
  { label: 'Requisitions', value: 'Requisitions' },
  { label: 'Purchase Orders', value: 'Purchase Orders' },
  { label: 'Vendors', value: 'Vendors' },
  { label: 'Budgets', value: 'Budgets' },
  { label: 'Subscriptions', value: 'Subscriptions' },
  { label: 'Notifications', value: 'Notifications' },
];

const SystemAudit = () => {
  const companyId = useStore((state) => state.company?.id);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const {
    control,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const module = watch('module');
  const actor = watch('actor');

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Date'} className={generalStyles.tableValue}>
            <span>{moment(row.createdAt).format('DD/MM/YYYY')}</span>
          </span>
        ),
      },
      {
        name: 'Actor',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Actor'} className={generalStyles.tableValue}>
            <span>{row.actor.name}</span>
          </span>
        ),
      },
      {
        name: 'Module',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Module'} className={generalStyles.tableValue}>
            <span>{row.module}</span>
          </span>
        ),
      },
      {
        name: 'Event',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Module'} className={generalStyles.tableValue}>
            <span>{row.event}</span>
          </span>
        ),
      },
    ],
    [],
  );
  useEffect(() => {
    setValue('endDate', new Date());
    setValue('startDate', new Date(new Date().setDate(new Date().getDate() - 7)));
  }, []);

  const getData = useCallback(
    async (page, size) => {
      const search = {
        module: module?.value,
        userId: actor?.value,
        startDate: startDate && new Date(startDate).toISOString(),
        endDate: endDate && new Date(endDate).toISOString(),
      };
      return await spmsServiceService.getSystemAuditLog(size, page, companyId, search);
    },
    [module, actor, startDate, endDate],
  );

  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);
  const openStartDatePicker = () => {
    if (startPickerRef.current) {
      startPickerRef.current.setOpen(true);
    }
  };
  const openEndDatePicker = () => {
    if (endPickerRef.current) {
      endPickerRef.current.setOpen(true);
    }
  };

  const handleDateChange = (name, date) => {
    setValue(name, date);
    if (startDate < endDate) clearErrors(['startDate', 'endDate']);
  };

  const getActorOptions = (page) =>
    RequestsService.getData(10, page, { companyId, statuses: ['ACTIVE', 'ARCHIVED'] }).then((res) =>
      res.data.data.content.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })),
    );

  return (
    <>
      <Text type="h2" weight={600}>
        System Audit
      </Text>
      <Box $radius={12} $mobExtend $asHolder $noOverflow>
        <div className={styles.container}>
          <div className={generalStyles.fieldsFour}>
            <div className="inp-container">
              <Label $title="Module" $isRequired $tooltipText="" />
              <Controller
                name="module"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setResetPaginationToggle(!resetPaginationToggle);
                    }}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Module"
                    options={modules}
                  />
                )}
              />
              <p className="error-message">{errors.currency?.label?.message}</p>
            </div>
            <div className="inp-container">
              <Label $title="Actor" $isRequired $tooltipText="" />
              <Controller
                name="actor"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <SelectAsync
                    {...rest}
                    onChange={(e) => {
                      rest.onChange(e);
                      setResetPaginationToggle(!resetPaginationToggle);
                    }}
                    selectRef={ref}
                    className={
                      errors.hasOwnProperty(rest.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Actor"
                    isClearable={true}
                    fetchFunction={getActorOptions}
                  />
                )}
              />
              <p className="error-message">{errors.currency?.label?.message}</p>
            </div>
            <div className="inp-container">
              <Label $title="Start Date" $isRequired $tooltipText="" />
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Enter Start Date"
                      selected={field.value}
                      wrapperClassName="custom-datepicker"
                      autoComplete="off"
                      customInput={
                        <Input
                          {...field}
                          $iconName="calendar"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $iconclick={openStartDatePicker}
                        />
                      }
                      onChange={(date) => {
                        handleDateChange('startDate', date);
                        setResetPaginationToggle(!resetPaginationToggle);
                        trigger('startDate');
                      }}
                      ref={startPickerRef}
                    />
                  );
                }}
              />
              <p className="error-message">{errors?.startDate?.message}</p>
            </div>
            <div className="inp-container">
              <Label $title="End Date" $isRequired $tooltipText="" />
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      placeholderText="Enter End Date"
                      selected={field.value}
                      wrapperClassName="custom-datepicker"
                      autoComplete="off"
                      customInput={
                        <Input
                          {...field}
                          $iconName="calendar"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $iconclick={openEndDatePicker}
                        />
                      }
                      onChange={(date) => {
                        handleDateChange('endDate', date);
                        setResetPaginationToggle(!resetPaginationToggle);
                        trigger('endDate');
                      }}
                      ref={endPickerRef}
                    />
                  );
                }}
              />
              <p className="error-message">{errors?.endDate?.message}</p>
            </div>
          </div>

          <div className={styles.table}>
            <DataTableBaseRemote
              columns={columns}
              selectableRows={false}
              paginationResetDefaultPage={resetPaginationToggle}
              fetchData={getData}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default SystemAudit;
