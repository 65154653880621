import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import SpmsService from '../../services/spmsService.service';
import Modal from '../../components/UI/Modal/Modal';
import Textarea from '../../components/UI/Forms/Textarea';
import { Controller, useForm } from 'react-hook-form';
import ModalInner from '../../components/UI/Modal/ModalInner';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import modalStyles from '../../styles/log_reg.module.css';
import style from '../../styles/users.module.css';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import Box from '../../components/UI/General/Box';
import TabsSlider from "../../components/UI/General/TabsSlider";
import {reducedText} from "../../utils/reducedText";
import Export from "../../components/shared/Export";
import AddButton from "../../components/shared/AddButton";
import spmsServiceService from "../../services/spmsService.service";
import {readableTitleFromBackend} from "../../utils/readableTitleFromBackend";
import {nf} from "../../utils/nf";

const PurchaseOrders = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange'
  });
  const activeCompany = useStore((state) => state.activeCompany);
  const [filterText, setFilterText] = useState(undefined);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [itemId, setItemId] = useState(undefined);
  const [showRecallModal, setShowRecallModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const user = useStore((state) => state.user);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [toExport, setToExport] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const accessPO = useAccessAllowed('Purchase_Order');

  const openModal = (id) => {
    setItemId(id);
    setShowRecallModal(true);
  };
  const openDiscardModal = (id) => {
    setItemId(id);
    setShowDiscardModal(true);
  };
  const closeModal = () => {
    reset();
    setShowRecallModal(false);
  };

  const from = useMemo(() => {
    let x;
    switch (tab) {
      case 'pending':
        x = 'posp';
        break;
      case 'approved':
        x = 'posa';
        break;
      case 'closed':
        x = 'poc';
        break;
      default:
        x = null;
    }
    return x
  }, [tab])

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: row => row?.title,
        cell: (row) => {
          const link = tab === 'pending' || tab === 'approved' || tab === 'closed' ? '/purchase-orders/' : '/purchase-orders/edit/'
          return (
            <span data-content={"Title"} className={generalStyles.tableValue}>
              <Link to={link + row.id} state={{from: from}}>
                <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>{reducedText(row?.title)}</span>
              </Link>
            </span>
          )
        },
      },
      {
        name: 'PO No',
        sortable: true,
        wrap: true,
        selector: row => row?.purchaseOrderNo,
        omit: tab !== 'approved',
        cell: (row) => <span data-content={"PO No"} className={generalStyles.tableValue}><span>{row?.purchaseOrderNo}</span></span>,
      },
      {
        name: 'Branch',
        cell: (row) => <span data-content={"Branch"} className={generalStyles.tableValue}><span>{row?.branchName}</span></span>,
        sortable: true,
        selector: row => row?.branchName
      },
      {
        name: 'Department',
        cell: (row) => <span data-content={"Department"} className={generalStyles.tableValue}><span>{row?.department?.name}</span></span>,
        sortable: true,
        selector: row => row?.department?.name
      },
      {
        name: 'Vendor',
        cell: (row) => <span data-content={"Vendor"} className={generalStyles.tableValue}><span>{row?.vendor?.legalName}</span></span>,
        sortable: true,
        selector: row => row?.vendor?.legalName
      },
      {
        name: 'Requester',
        cell: (row) => <span data-content={"Requester"} className={generalStyles.tableValue}><span>{row?.requester?.name}</span></span>,
        sortable: true,
        selector: row => row?.requester?.name
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return <span data-content={"Total Amount"} className={generalStyles.tableValue}><span>{cur + ' ' + nf.format(row?.total)}</span></span>;
        },
        sortable: true,
        selector: row => row?.total
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        omit: !accessPO?.create || tab === 'closed',
        cell: (row) => {
          return (
            <div className={generalStyles.actionMenuHolder}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Action $style="white" $width={32} $height={32}>
                    <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                  </Button.Action>
                </Dropdown.Header>
                <Dropdown.Body>
                  <Menu className={generalStyles.actionMenuList}>
                    {(row.purchaseOrderState === 'Draft'.toUpperCase() || row.purchaseOrderState === 'rejected'.toUpperCase()) && accessPO?.edit ? (
                      <Link to={'/purchase-orders/edit/' + row.id} state={{from: tab === 'pending' ? "posp" : tab === 'approved' ? "posa" : null}}>
                        <MenuItem>Edit</MenuItem>
                      </Link>
                    ) : null}
                    {row.purchaseOrderState === 'Draft'.toUpperCase() && accessPO?.delete && (
                      <MenuItem onClick={() => openDiscardModal(row.id)}>Discard</MenuItem>
                    )}
                    {row.purchaseOrderState === 'PENDING_APPROVAL'.toUpperCase() && (
                      <MenuItem onClick={() => openModal(row.id)}>Recall</MenuItem>
                    )}
                    {row.purchaseOrderState === 'Approved'.toUpperCase() && (accessPO?.viewAll || (accessPO?.viewOwn && row.createdBy.userId === user.id)) ? (
                      <MenuItem onClick={() => navigate('/purchase-orders/' + row.id)}>View</MenuItem>
                    ) : null}
                    {row.purchaseOrderState === 'Approved'.toUpperCase() && (row.fulfillmentStatus === "FULFILLED" && row.invoiceStatus === "APPROVED" && row.paymentStatus === "PAID") ? (
                      <MenuItem onClick={() => closePO(row.id)}>Close PO</MenuItem>
                    ) : null}
                  </Menu>
                </Dropdown.Body>
              </Dropdown>
            </div>
          )
        }
      },
    ],
    [tab, accessPO],
  );

  const discardPurchaseOrder = (id) =>
    SpmsService.discardPurchaseOrder(id)
      .then((r) => {
        console.log(r);
        setShowDiscardModal(false);
        setResetPaginationToggle(!resetPaginationToggle);
        setTrigger((state) => !state);
      })
      .catch((err) => {
        console.log(err);
      });

  const recallPurchaseOrder = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    SpmsService.recallPurchaseOrder(itemId, data.reason)
      .then((res) => {
        setToast({
          opened: true,
          message: 'Purchase order recalled successfully.',
          type: 'success',
          cb: navigate('/purchase-orders/overview/drafts'),
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
    closeModal();
  };

  const closePO = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService.closePurchaseOrder(id).then(r => {
      console.log(r)
      setToast({
        opened: true,
        message: 'Purchase order closed successfully.',
        type: 'success',
      });
    }).catch(err => {
      console.log(err)
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    })
  }

  const getData = useCallback(
    (page, size) => {
      let tempTab;
      switch (tab) {
        case 'drafts':
          tempTab = 'DRAFT';
          break;
        case 'pending':
          tempTab = 'PENDING_APPROVAL';
          break;
        case 'approved':
          tempTab = 'Approved';
          break;
        case 'rejected':
          tempTab = 'REJECTED';
          break;
        case 'closed':
          tempTab = 'CLOSED';
          break;
        default:
          tempTab = 'Draft';
      }
      const search = {
        purchaseOrderState: tempTab.toUpperCase(),
        companyId: activeCompany.id,
        title: filterText,
      };
      return SpmsService.getPurchaseOrders(size, page, search, activeCompany.id)
    },
    [tab, filterText, trigger, activeCompany.id],
  );

  useEffect(() => {
    SpmsService.getPurchaseOrders(1, 0, {
      companyId: activeCompany.id
    }).then(r => {
      setTotalRows(r.data.data.totalElements)
    });
  },[])

  useEffect(() => {
    const getExp = async () => {
      const response = await spmsServiceService.getPurchaseOrders(totalRows, 0, {
        companyId: activeCompany.id,
        title: filterText,
      });
      const csvData = response.data.data.content.map(item => ({
        "PO Title": item.title,
        "PO No": item.purchaseOrderNo,
        "Branch": item.branch.name,
        "Dept": item.department.name,
        "Vendor": item.vendor.legalName,
        "Requester": item.requester.name,
        "PO Budget Amount": item?.currency?.code + ' ' + item?.total?.toFixed(2),
        "Status": item.purchaseOrderState,
        "Required Date": item.deliveryDate,
        "Received": readableTitleFromBackend(item.fulfillmentStatus),
        "Invoiced": readableTitleFromBackend(item.invoiceStatus),
        "Paid": readableTitleFromBackend(item.paymentStatus),
      }))
      setToExport(csvData)
    }
    if (totalRows === 0){
      console.log("no results for export")
    }else {
      getExp()
    }
  },[filterText, trigger, totalRows, activeCompany.id])

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#8A8B9D"
        placeholder="Search by title"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Text type="h2" weight={600}>Purchase Order Overview</Text>
      <Box $mobExtend $asHolder $noOverflow>
        <div className={generalStyles.tabSection}>
          <TabsSlider>
            {!activeCompany?.requisitionsRequired && (
              <NavLink
                to="/purchase-orders/overview/drafts"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Drafts
              </NavLink>
            )}
            {!activeCompany?.requisitionsApprovedToPO && (
              <NavLink
                to="/purchase-orders/overview/pending"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Pending Approval
              </NavLink>
            )}
            <NavLink
              to="/purchase-orders/overview/approved"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Approved
            </NavLink>
            {!(activeCompany?.requisitionsRequired && !activeCompany?.requisitionsApprovedToPO) && (
              <NavLink
                to="/purchase-orders/overview/rejected"
                className={({ isActive }) => (isActive ? 'active' : '')}
                $islink="true"
              >
                Rejected
              </NavLink>
            )}
            <NavLink
              to="/purchase-orders/overview/closed"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Closed
            </NavLink>
          </TabsSlider>
          <div className={generalStyles.addItemButton}>
            {(accessPO?.create && !activeCompany?.requisitionsRequired) && (
              <AddButton $click={() => navigate('/purchase-orders/create')} />
            )}
            <Export data={toExport} name='Purchase-orders'/>
          </div>
        </div>
        <div className={generalStyles.search}>{filterCmp}</div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>

      <Modal
        $show={showRecallModal}
        $close={closeModal}
        $title="Recall Purchase Order"
        $radius={12}
        $maxWidth="540px"
      >
        <ModalInner>
          <form onSubmit={handleSubmit(recallPurchaseOrder)}>
            <div className="inp-container">
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Recall reason is required'
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    placeholder="Enter the reason here"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Reason to recall the Purchase Order"
                    $labelRequired
                    $counter
                    $counterMax={500}
                    {...field}
                  />
                )}
              />
              {errors.reason && <p className="error-message">{errors.reason?.message}</p>}
            </div>
            <br/>
            <div className={generalStyles.pageButtons}>
              <Button.Main $primary $style="pink" type="submit" disabled={!isValid}>Save</Button.Main>
            </div>
          </form>
        </ModalInner>
      </Modal>
      <Modal
        $show={showDiscardModal}
        $close={() => setShowDiscardModal(false)}
        $title={'Discard'}
        $radius={16}
        $noCloseSign
      >
        <div className={modalStyles.modalTextOnly} style={{ textAlign: 'center' }}>
          <Text type="subtitle">Do you want to continue to permanently delete this entry?</Text>
          <div
            className={generalStyles.pageButtons + ' ' + style.legalEntitySetupBtns}
            style={{ justifyContent: 'center' }}
          >
            <Button.Main
              $primary
              $style="pink"
              type="button"
              onClick={() => setShowDiscardModal(false)}
            >
              No
            </Button.Main>
            <Button.Main
              $primary
              $style="gray"
              type="button"
              onClick={() => discardPurchaseOrder(itemId)}
            >
              Yes
            </Button.Main>
          </div>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default PurchaseOrders;
