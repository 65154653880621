import Accordion from '../../../components/UI/General/Accordion';
import styles from '../../../styles/companySetup.module.css';
import Text from '../../../components/UI/Typography/Text';
import DepartmentBody from '../../../components/Admins/Company/Department/DepartmentBody';
import { useStore } from '../../../store/store';

const DepartmentSetup = () => {
  const company = useStore((state) => state.company);
  const allCompanies = company?.subsidiaries
    ? [company, ...company.subsidiaries.filter((c) => c.active === true)]
    : [company];
  const preOpenedItem = localStorage.getItem('preOpenedDep');

  return (
    <div className={styles.accordionItems}>
      {allCompanies.length === 1 ? (
        <DepartmentBody companyId={allCompanies[0].id} />
      ) : (
        <>
          {allCompanies && allCompanies.length > 0 ? (
            allCompanies.map((company) => (
              <Accordion key={company.id} preOpened={Number(preOpenedItem) === company.id}>
                <div className={styles.branch}>
                  <Accordion.Header>
                    <div className={styles.branchHead}>
                      <Text weight={500} type="subtitle">
                        Company Name
                      </Text>
                      <div className={styles.branchName}>{company.name}</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <DepartmentBody
                      companyId={company.id}
                      isSubsidiary={company.subsidiary}
                      companyName={company.name}
                    />
                  </Accordion.Body>
                </div>
              </Accordion>
            ))
          ) : (
            <Text type="h3">User have no companies</Text>
          )}
        </>
      )}
    </div>
  );
};

export default DepartmentSetup;
