export const downloadExternal = (filePath, type) => {
  fetch(filePath, { method: 'GET' })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: `application/${type}` }));
      const link = document.createElement('a');
      link.href = url;
      link.download = filePath.toString().split('/').pop().split('?').shift().replaceAll("%20", "-")
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(new Blob([blob], { type: `application/${type}` }));
      link.parentNode.removeChild(link);
    });
};
