import axios from 'axios';

export default async function getExchangeRate(from, to, amount) {
  const APIKEY = 'f6e3a2685d6f089484607b29';
  try {
    const response = await axios.get(
      `https://v6.exchangerate-api.com/v6/${APIKEY}/pair/${from}/${to}/${amount}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          crossorigin: true,
        },
      },
    );
    if (response.status === 200) {
      console.log('response.data.conversation_result', response.data.conversion_result);
      return response.data.conversion_result;
    }
  } catch (error) {
    console.error('Error fetching data: ', error);
  }
}
