import React, {useEffect, useMemo, useState} from 'react';
import Text from "../../components/UI/Typography/Text";
import requestsService from "../../services/requestsService.service";
import DataTableBase from "../../components/UI/General/DataTableBase";
import generalStyles from '../../styles/general.module.css'
import {useStore} from "../../store/store";
import Dropdown from "../../components/UI/General/Dropdown";
import * as Button from "../../components/UI/Forms/Button";
import Icon from "../../components/UI/General/Icon";
import {Menu, MenuItem} from "../../components/UI/General/Menu";
import {Link} from "react-router-dom";

const SystemRolesPermissions = () => {
  const company = useStore((state) => state.company);
  const [tableData, setTableData] = useState()

  const columns = useMemo(() => [
    {
      name: 'Role Name',
      selector: row => row.roleName || "-",
      sortable: true,
    }, {
      name: 'Role Description',
      selector: row => row.roleDescription || "-"
    },
    {
      name: 'No of Users Assigned',
      selector: row => row.numberOfUsers || "-"
    },
    {
      name: "Action",
      allowOverflow: true,
      button: true,
      cell: row => (
        <Dropdown collapsible className={generalStyles.actionMenu}>
          <Dropdown.Header>
            <Button.Action $style="white" $width={32} $height={32}>
              <Icon $icon="menu-dots" $width={32} $height={32} $color="black"/>
            </Button.Action>
          </Dropdown.Header>
          <Dropdown.Body>
            <Menu className={generalStyles.actionMenuList}>
              <Link to={'/system-roles-and-permissions/edit/' + row.roleId}><MenuItem>Edit</MenuItem></Link>
            </Menu>
          </Dropdown.Body>
        </Dropdown>
      )
    }
  ], [])


  useEffect(() => {
    const initData = async () => {
      const roles = await requestsService.getRolesByCompany(company.parentId !== undefined ? company.parentId : company.id).then(r => {
        if (r.data.status === 200) {
          return r.data.data.filter(item => item.isEnabled === true && item.type === "SYSTEM");
        } else {
          alert("error" + r.data.message)
        }
      });
      setTableData(roles)
    }
    initData()
  }, [company])

  const ExpandedComponent = ({data}) => (<><h3>Title</h3><pre>{JSON.stringify(data, null, 2)}</pre></>);

  return (
    <>
      <div className={generalStyles.pageTop} style={{marginBottom: "32px"}}>
        <Text type="h2" weight={600}>System Role User Management</Text>
      </div>
      <DataTableBase
        data={tableData}
        columns={columns}
        pagination
        separatePagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 30]}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked
        expandableRowsHideExpander
      />
    </>
  );
};

export default SystemRolesPermissions;
