import styles from '../../styles/vendor.module.css';
import Text from '../../components/UI/Typography/Text';
import StarRating from '../../components/UI/General/StarRating';
import spmsServiceService from '../../services/spmsService.service';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import PieChartComponent from '../../components/UI/General/PieChartComponent';
import generalStyles from '../../styles/general.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TabsSlider from '../../components/UI/General/TabsSlider';
import Box from '../../components/UI/General/Box';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';

const ActivePO = ({ vendorId, companyId, isRating, currency }) => {
  const [tab, setTab] = useState('pending');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [chartData, setChartData] = useState({ pieChart: [], totalAmount: null });

  useEffect(() => {
    const getPieChartData = async () => {
      let result;
      result = await spmsServiceService.getPurchaseOrders(10, 0, {
        vendorId: vendorId,
        companyId: companyId,
        purchaseOrderState: 'APPROVED',
      });
      const totalElements = result.data.data.totalElements;
      const contentLength = result.data.data.content.length;
      if (totalElements > contentLength) {
        result = await spmsServiceService.getPurchaseOrders(totalElements, 0, {
          vendorId: vendorId,
          companyId: companyId,
        });
      }
      const pos = result.data.data.content;
      const paidPos = pos.filter((po) => ['PARTIALLY_PAID', 'PAID'].includes(po.paymentStatus));
      const unpaidPos = pos.filter((po) => !['PARTIALLY_PAID', 'PAID'].includes(po.paymentStatus));
      const paidPosSum = paidPos.reduce((acc, current) => acc + current.total, 0);
      const unpaidPosSum = unpaidPos.reduce((acc, current) => acc + current.total, 0);
      setChartData({
        pieChart: [
          { name: 'Allocated Unpaid', value: parseFloat(unpaidPosSum.toFixed(2)) },
          { name: 'Allocated Paid', value: parseFloat(paidPosSum.toFixed(2)) },
        ],
        totalAmount: parseFloat(paidPosSum.toFixed(2)) + parseFloat(unpaidPosSum.toFixed(2)),
      });
    };
    getPieChartData();
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        selector: (row) => row.title,
        wrap: true,
        format: (row) => `${row.title.slice(0, 20)}...`,
      },
      {
        name: 'PO No',
        selector: (row) => row.purchaseOrderNo,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Company',
        cell: (row) => row.companyName,
      },
      {
        name: 'Branch',
        cell: (row) => row.branchName,
        sortable: true,
      },
      {
        name: 'Department',
        cell: (row) => row.department.name,
        sortable: true,
      },
      {
        name: 'Receiving',
        omit: tab === 'pending',
        cell: (row) => readableTitleFromBackend(row.fulfillmentStatus),
        sortable: true,
      },
      {
        name: 'Invoices',
        omit: tab === 'pending',
        cell: (row) => readableTitleFromBackend(row.invoiceStatus),
        sortable: true,
      },
      {
        name: 'Payment',
        omit: tab === 'pending',
        cell: (row) => readableTitleFromBackend(row.paymentStatus),
        sortable: true,
      },

      {
        name: 'Total Amount',
        selector: (row) => `${row.total} ${row.currency.code}`,
        sortable: true,
      },
    ],
    [tab],
  );

  const getData = useCallback(
    async (page, size) => {
      const search = {
        purchaseOrderState: tab,
        vendorId: vendorId,
        companyId: companyId,
      };
      return spmsServiceService.getPurchaseOrders(size, page, search);
    },
    [tab, vendorId, companyId],
  );

  return (
    <Box $mobExtend $asHolder $noPadding>
      <div className={`${generalStyles.fieldsTwo} ${styles.dataContainer}`}>
        <PieChartComponent
          totalAmount={chartData.totalAmount}
          piechartData={chartData.pieChart}
          currency={currency}
          borderRight
        />
        <div className={styles.vendorRating}>
          <div className={styles.ratingOverall}>
            <Text className={styles.ratingText} type="h5" weight={600}>
              Overall Rating: <StarRating rating={isRating?.overallRating} />
            </Text>
            <Text className={styles.votes} type="h5" weight={600}>
              (Total votes: 20)
            </Text>
          </div>
          <div className={styles.ratingDetails}>
            <Text className={styles.ratingText} type="h5" weight={600}>
              Price: <StarRating rating={isRating?.ratingPerCategory?.PRICE} />
            </Text>
            <Text className={styles.ratingText} type="h5" weight={600}>
              Service: <StarRating rating={isRating?.ratingPerCategory?.SERVICE} />
            </Text>
            <Text className={styles.ratingText} type="h5" weight={600}>
              Support: <StarRating rating={isRating?.ratingPerCategory?.SUPPORT} />
            </Text>
            <Text className={styles.ratingText} type="h5" weight={600}>
              Quality: <StarRating rating={isRating?.ratingPerCategory?.QUALITY} />
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.poHistory}>
        <Text weight={600} type="h3">
          Purchase Order history
        </Text>
        <TabsSlider className={generalStyles.tabs}>
          <span onClick={() => setTab('pending')}>Pending Approval</span>
          <span onClick={() => setTab('approved')}>Approved</span>
        </TabsSlider>
        <div>
          <DataTableBaseRemote
            columns={columns}
            selectableRows={false}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            paginationPerPage={6}
            paginationRowsPerPageOptions={[6, 12, 20, 50]}
            fetchData={getData}
          />
        </div>
      </div>
    </Box>
  );
};

export default ActivePO;
