import axios from 'axios';
import { useEffect, useState } from 'react';

const useGetUserLocation = () => {
  const [countryNav, setCountryNav] = useState(null);
  console.log("useGetUserLocation")
  useEffect(() => {
    if ('geolocation' in navigator) {
      console.log('geolocation in navigator')
      navigator.geolocation.getCurrentPosition((position) => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
          )
          .then((r) => {
            if (r.status === 200) {
              setCountryNav(r.data.address.country_code.toUpperCase());
            }
          }).catch(err => {
          console.log("geolocation error")
          console.log(err)
        });
      });
    }
  }, []);
  return countryNav;
};

export default useGetUserLocation;
