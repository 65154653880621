import React from 'react';
import Label from '../../components/UI/Forms/Label';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import Input from '../../components/UI/Forms/Input';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/vendor.module.css';
import PhoneInput from 'react-phone-number-input';
import * as Button from '../../components/UI/Forms/Button';

const AddNewContact = ({
  errors,
  control,
  submitVendorDetails,
  unregister,
  contactType,
  handleSubmit,
  handelContactCancel,
  isDirty,
}) => {
  const vendorType = [
    { label: 'Sales', value: 'Sales' },
    { label: 'Billing', value: 'Billing' },
    { label: 'Other', value: 'Other' },
  ];
  return (
    <form id="cForm" className={styles.addContactForm}>
      <div className={styles.contactFields}>
        <div className={generalStyles.fieldsThree}>
          <div className={styles.typeField}>
            <div className="inp-container">
              <Label $title="Type" $isRequired $tooltipText="Representative primary function" />
              <Controller
                name="contactType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    isSearchable={false}
                    onChange={(e) => {
                      field.onChange(e);
                      if (e !== 'Other') unregister('contactTypeOther');
                    }}
                    placeholder="Select Type"
                    options={vendorType}
                  />
                )}
              />
              {errors.contactType && (
                <p className="error-message">{errors.contactType.value.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className={generalStyles.fieldsThree}>
          {contactType?.value === 'Other' && (
            <div className="inp-container">
              <Controller
                name="contactTypeOther"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    $labelRequired
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Name of Type"
                    $label="Name of Type"
                    {...field}
                  />
                )}
              />
              {errors.contactTypeOther && (
                <p className="error-message">{errors.contactTypeOther.message}</p>
              )}
            </div>
          )}
          <div className="inp-container">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  $labelRequired
                  type="text"
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  placeholder="Enter First Name"
                  $label="First Name"
                  $tooltip="First Name of the representative"
                  {...field}
                />
              )}
            />
            {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <Input
                  $labelRequired
                  type="text"
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  placeholder="Enter Last Name"
                  $label="Last Name"
                  $tooltip="Last Name of the representative"
                  {...field}
                />
              )}
            />
            {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
          </div>
          <div className="inp-container">
            <Label $title="Phone Number" $isRequired $tooltipText="Primary phone number of the representative" />
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => {
                return (
                  <PhoneInput
                    limitMaxLength={true}
                    {...field}
                    placeholder="Enter Phone Number"
                    international
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                  />
                );
              }}
            />
            {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              defaultValue=""
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  $labelRequired
                  type="text"
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  placeholder="Enter Email Address"
                  $label="Email Address"
                  $tooltip="Primary email address of the representative"
                  {...field}
                />
              )}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="jobDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  $labelRequired
                  type="text"
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  placeholder="Enter Role/Position"
                  $label="Role/Position"
                  $tooltip="Role of the representative"
                  {...field}
                />
              )}
            />
            {errors.jobDescription && (
              <p className="error-message">{errors.jobDescription.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className={generalStyles.pageButtons}>
        <Button.Main
          $primary
          $style="pink"
          type="submit"
          id="cForm"
          disabled={!isDirty}
          onClick={handleSubmit(submitVendorDetails)}
        >
          Save
        </Button.Main>
        <Button.Main
          className={styles.discardBtn}
          $primary
          $style="gray"
          type="button"
          onClick={() => handelContactCancel()}
        >
          Discard
        </Button.Main>
      </div>
    </form>
  );
};

export default AddNewContact;
