import { Controller } from 'react-hook-form';
import MultiSelectOpened from '../../../UI/Forms/MultiSelectOpened.jsx';
import UsersList from '../AddRule/UsersList.jsx';
import * as Button from '../../../UI/Forms/Button.jsx';
import styles from '../../../../styles/approvalWorkflow.module.css';
import generalStyles from '../../../../styles/general.module.css';
import Radio from '../../../UI/Forms/Radio.jsx';
import Approvers from './Approvers.jsx';
import React from "react";

const BudgetVendorApproval = ({
  control,
  errors,
  approversOptions,
  tab,
  onSubmit,
  watch,
  approvers,
  companyId,
  setValue,
  isDirty,
}) => {
  const approvalHierarchy = watch('approvalHierarchy');
  const userList = watch(`${tab}-approvers`);

  const handleUserData = () => {
    if (!userList || !approvers.length) return;
    return userList.map((singleUser) => {
      const reqUser = approvers.find((user) => singleUser.value === user.id);
      if (reqUser)
        return {
          userName: `${reqUser.firstName} ${reqUser.lastName}`,
          loa: reqUser.customCompanies.find((company) => company.companyId === companyId)
            .limitOfAuthority,
          id: reqUser.id,
        };
    });
  };

  const deleteUser = (id) => {
    const newApprovers = userList.filter((user) => user.value !== id);
    setValue('dirty', true, { shouldDirty: true });
    setValue(`${tab}-approvers`, newApprovers);
  };

  return (
    <>
      <Controller
        name="approvalHierarchy"
        control={control}
        render={({ field }) => (
          <div className={styles.hierarchyRadio}>
            <Radio
              {...field}
              $size={16}
              label={'Default Approver'}
              checked={approvalHierarchy === 'DEFAULT_APPROVER'}
              value="DEFAULT_APPROVER"
              $tooltip="Select the user that should be the final approver"
            />
            <Radio
              checked={approvalHierarchy === 'Defined_List'}
              {...field}
              $size={16}
              label={'Defined List'}
              value={'Defined_List'}
              $tooltip="Approval required from all users in defined list"
            />
          </div>
        )}
      />
      {approvalHierarchy === 'DEFAULT_APPROVER' ? (
        <Approvers
          control={control}
          errors={errors}
          approversOptions={approversOptions}
          tab={tab}
        />
      ) : (
        <div className={styles.userDropDownContainer}>
          <div className={styles.userDropDown}>
            <div className="inp-container">
              <Controller
                name={`${tab}-approvers`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Required',
                  },
                }}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    noSelectContainer
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Users"
                    options={approversOptions}
                    control={control}
                  />
                )}
              />
              {errors[`${tab}-approvers`] && (
                <p className="error-message">{errors[`${tab}-approvers`].message}</p>
              )}
            </div>
          </div>
          {}
          <UsersList users={handleUserData()} deleteUser={deleteUser} />
        </div>
      )}
      <div className={generalStyles.pageButtons}>
        <Button.Main disabled={!isDirty} $primary $style="pink" onClick={onSubmit}>
          Save
        </Button.Main>
      </div>
    </>
  );
};

export default BudgetVendorApproval;
