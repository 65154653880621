import { useStore } from '../../store/store';
import companyStyles from '../../styles/companySetup.module.css';
import Accordion from '../../components/UI/General/Accordion';
import Text from '../../components/UI/Typography/Text';
import CompanyNotification from '../../components/Admins/Notification/CompanyNotification';

const NotificationList = () => {
  const company = useStore((state) => state.company);
  const companiesData = company?.subsidiaries
    ? [company, ...company.subsidiaries].filter((company) => company.active)
    : [company];
  return (
    <div className={companyStyles.accordionItems}>
      {companiesData.length === 1 ? (
        <CompanyNotification companyId={companiesData[0].id} />
      ) : (
        <>
          {companiesData?.map((selectedCompany) => (
            <Accordion key={selectedCompany.id}>
              <div className={companyStyles.branch}>
                <Accordion.Header>
                  <div className={companyStyles.branchHead}>
                    <Text weight={500} type="subtitle">
                      Company Name
                    </Text>
                    <div className={companyStyles.branchName}>{selectedCompany.name}</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <CompanyNotification companyId={selectedCompany.id} />
                </Accordion.Body>
              </div>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
};

export default NotificationList;
