import React from 'react';
import styles from '../../../../styles/purchaseOrders.module.css'
import Text from "../../../UI/Typography/Text";
import moment from "moment";

const NotesTab = ({data}) => {
  return (
    <>
      {data !== undefined ? (
        <div className={styles.notes}>
          {data.sort((a,b) => Date.parse(b.noteDate) - Date.parse(a.noteDate)).map((item, index) => {
            if (item.note === '' || item.note === null){
              return null
            }else {
              return (
                <div key={index}>
                  <div className={styles.notesHead}>
                    <Text weight={600} type="body-1">{item?.noteByName}</Text>
                    <Text weight={600} type="body-2" className={styles.notesTime}>{moment(item?.noteDate).format('DD-MM-YYYY hh:mm A')}</Text>
                  </div>
                  <Text type="body-1">{item?.note}</Text>
                </div>
              )
            }
          })}
        </div>
      ) : null}
    </>
  );
};

export default NotesTab;
