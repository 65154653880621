import { Link, useNavigate } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import styles from '../../styles/reports.module.css';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import Box from '../../components/UI/General/Box.jsx';
import DataTableBase from '../../components/UI/General/DataTableBase.jsx';
import Icon from '../../components/UI/General/Icon.jsx';
import Status from '../../components/Admins/Reports/Status.jsx';
import Filters from '../../components/Admins/Reports/Filters.jsx';

const UserReports = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        name: 'User Name',
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <Link className={styles.nameLink} to={'/'}>
            {row.name}
          </Link>
        ),
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        wrap: true,
      },
      {
        name: 'Role',
        selector: (row) => row.role,
      },
      {
        name: 'Company',
        selector: (row) => row.company,
      },
      {
        name: 'Status',
        selector: (row) => row.department,
        cell: (row) => <Status status={row.status} />,
      },
    ],
    [],
  );
  const data = [
    {
      name: 'Name',
      email: 'example@gmail.com',
      role: 'Administrator',
      company: 'my company',
      status: 'pending',
    },
    {
      name: 'Name',
      email: 'example@gmail.com',
      role: 'Administrator',
      company: 'my company',
      status: 'pending',
    },
    {
      name: 'Name',
      email: 'example@gmail.com',
      role: 'Administrator',
      company: 'my company',
      status: 'pending',
    },
  ];

  const onSubmit = async (data) => {
    console.log(data);
  };

  return (
    <div>
      <div className={styles.backButton}>
        <Button.Main $style="gray" $iconLeft onClick={() => navigate(-1)}>
          <span>Back</span>
        </Button.Main>
      </div>
      <div className={styles.sectionTitle}>
        <Text type="h2" weight={600}>
          User Reports
        </Text>
      </div>
      <Box $radius={12} className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.filterForm}>
          <Filters
            control={control}
            errors={errors}
            trigger={trigger}
            watch={watch}
            clearErrors={clearErrors}
            setValue={setValue}
            filters={['department', 'jobDescription']}
            page={'users'}
          />
          <div>
            <Button.Main $primary $style="pink" type="submit">
              Submit
            </Button.Main>
          </div>
        </form>
      </Box>
      <br />
      <br />
      <Box $radius={12} className={styles.container}>
        <div>
          <div className={styles.exportBtnContainer}>
            <Button.Main $primary $style="pink" onClick={() => {}} $iconLeft>
              <Icon $width={20} $height={20} $icon="export" />
              <Text weight={500} type="subtitle">
                Export
              </Text>
            </Button.Main>
          </div>
          <DataTableBase
            pagination
            data={data || []}
            columns={columns}
            separatePagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 15, 20, 25]}
          />
        </div>
      </Box>
    </div>
  );
};

export default UserReports;
