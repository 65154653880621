import React from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {useStore} from "../../store/store";
import requestsServiceService from "../../services/requestsService.service";

const SelectUsers = (props) => {
  const activeCompany = useStore((state) => state.activeCompany);

  const fetchUsers = async (search, loadedOptions, { page }) => {
    let searchBody = {
      firstName: search,
      status: 'ACTIVE',
      companyId: props?.companyId ?? activeCompany.id
    }
    if (props?.roleName){
      searchBody.roleNames = props?.roleName
    }
    const result = await requestsServiceService.getData(10, page, searchBody).catch(err => {
      console.log(err)
      return undefined
    })
    console.log(result)
    const finalData = result?.data?.data?.content?.map((item) => ({
      label: item.firstName + ' ' + item.lastName,
      value: item.username,
      userId: item.id,
    }))
    const hasMore = finalData !== undefined && result.data.data.totalElements > 0 ? loadedOptions.length < result.data.data.totalElements : false;
    return {
      options: finalData || [],
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchUsers}
      classNamePrefix="react-select"
      isSearchable={true}
      placeholder="Select User"
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectUsers;
