import React from 'react';
import {useNavigate} from 'react-router-dom'
import Text from "./UI/Typography/Text";
import * as Button from "../components/UI/Forms/Button"
import styled from 'styled-components';

const Error = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  min-height: 100vh;
`
const ErrorInner = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  flex-direction: column;
  gap: 16px;
`

const NotFound = () => {
  const nav = useNavigate()
  return (
    <Error>
      <ErrorInner>
        <Text type="h2">This page doesn't exists</Text>
        <Button.Main $primary $style="pink" onClick={() => nav(-1)}>Return to the previous page</Button.Main>
      </ErrorInner>
    </Error>
  );
};

export default NotFound;
