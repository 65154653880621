import React, {useEffect, useState} from 'react';
import Box from "../../components/UI/General/Box";
import generalStyles from "../../styles/general.module.css";
import {Controller, useForm, useWatch} from "react-hook-form";
import Input from "../../components/UI/Forms/Input";
import Label from "../../components/UI/Forms/Label";
import Text from "../../components/UI/Typography/Text";
import * as Button from "../../components/UI/Forms/Button";
import Toast from "../../components/UI/General/Toast";
import {useNavigate, useParams} from "react-router-dom";
import {useStore} from "../../store/store";
import Select from "react-select";
import spmsServiceService from "../../services/spmsService.service";
import Checkbox from "../../components/UI/Forms/Checkbox";

const integrationsTypes = [
  {label: 'Poms', value: 'POMS'},
  {label: 'Business Central', value: 'MICROSOFT_BUSINESS_CENTRAL'},
]

const IntegrationsAdd = () => {
  const {integrationId} = useParams();
  const navigate = useNavigate();
  const activeCompany = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null);
  const [companies, setCompanies] = useState([])
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [tempIntegrationId, setTempIntegrationId] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const defaultValues = {
    integrationSolution: null,
    tenantId: '', // bd478d97-8fbc-4391-9e19-900a044cee22
    clientId: '', // cbf3b6ab-a1f3-442c-9fa6-f569cf70fe6b
    clientSecret: '', // b3s8Q~UGEi_JAzV3qLhxA19Yp3t4HhVPIgWUNddS
    companyId: null,
    environment: '', // development
    enabledModules: null
  }

  const {
    handleSubmit,
    control,
    register,
    formState: {errors, isDirty, isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues,
    values,
  });

  const companyIdWatcher = useWatch({name: 'companyId', control: control})

  const onTestConnection = async (data) => {
    console.log(data);
    setIsButtonsBlocked(true)
    setToast((item) => ({...item, opened: false}));

    const newData = {
      tenantId: data.tenantId,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      environmentName: data.environment,
      integrationType: data.integrationSolution.value,
    }
    console.log(newData)
    await spmsServiceService.createIntegration(activeCompany.id, newData).then(r => {
      setTempIntegrationId(r?.data?.data?.id)
      setIsConnected(true)
      setToast({
        opened: true,
        message: 'Connection established successfully',
        type: 'success',
      });
    }).catch(err => {
      console.log(err)
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
        type: 'fail',
      });
    })
  };

  const onSaveConfiguration = async (data) => {
    console.log(data);
    setToast((item) => ({...item, opened: false}));
    const newData = {
      tenantId: data.tenantId,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      environmentName: data.environment,
      integrationType: data.integrationSolution.value,
      companyId: data.companyId.id,
      enabledModules: [...data.enabledModules, "PURCHASE_ORDERS"]
    }
    console.log(newData)

    const intId = integrationId ? integrationId : tempIntegrationId
    await spmsServiceService.editIntegration(activeCompany.id, intId, newData).then(r => {
      console.log(r)
      setToast({
        opened: true,
        message: 'Integration configuration saved successfully',
        type: 'success',
        cb: () => navigate('/integrations/overview/active'),
      });
    }).catch(err => {
      console.log(err)
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
        type: 'fail',
      });
    })

  };

  useEffect(() => {
    if (activeCompany.id && integrationId){
      spmsServiceService.getIntegration(activeCompany.id, integrationId).then(r => {
        console.log(r)
        if (r.data.message === "Operation Successful"){
          setIsConnected(true)
          console.log(r.data.data)
          const {environmentName, companyId, enabledModules,...rest} = r.data.data
          const data = {
            ...rest,
            integrationSolution: integrationsTypes.find(el => el.value === "MICROSOFT_BUSINESS_CENTRAL"),
            environment: environmentName ?? '',
            companyId: companies.find(el => el.id === companyId),
            enabledModules: enabledModules
          }
          setValues(data)
        }
      }).catch(err => {
        console.log(err)
        setIsButtonsBlocked(false)
        setToast({
          opened: true,
          message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
          type: 'fail',
        });
      })
    }
  },[activeCompany.id, integrationId, companies])

  useEffect(() => {
    if (activeCompany.id && (isConnected || integrationId)){
      spmsServiceService.getIntegrationBCCompanies(activeCompany.id).then(r => {
        console.log(r)
        if (r.data.message === "Operation Successful"){
          setCompanies(r.data.data)
        }
      }).catch(err => {
        console.log(err)
        setToast({
          opened: true,
          message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
          type: 'fail',
        });
      })
    }
  },[activeCompany.id, isConnected, integrationId])

  return (
    <>
      <Text weight={600} type="h2">Integrations</Text>
      <Box $mobExtend $asHolder $noOverflow className={generalStyles.gapFormButtons}>
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Label $title="Integration Solution" $isRequired $tooltipText="Select the solution you want to integrate to" />
            <Controller
              name="integrationSolution"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Integration Solution is required',
                },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Integration Solution"
                  options={integrationsTypes}
                />
              )}
            />
            {errors.integrationSolution && <p className="error-message">{errors.integrationSolution.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="tenantId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'TenantID is required',
                },
              }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter TenantID"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="TenantID (from the BC instance)"
                  $labelRequired
                  $tooltip="This is your tenant ID in BC"
                  disabled={integrationId}
                  {...field}
                />
              )}
            />
            {errors.tenantId && <p className="error-message">{errors.tenantId?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="clientId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Client ID is required',
                },
              }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter Client ID"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Client ID (entra)"
                  $labelRequired
                  $tooltip="Client ID from entraa ID"
                  disabled={integrationId}
                  {...field}
                />
              )}
            />
            {errors.clientId && <p className="error-message">{errors.clientId?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="clientSecret"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Client ID is required',
                },
              }}
              render={({field}) => (
                <Input
                  type="text"
                  placeholder="Enter Client Secret"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Client Secret (entra)"
                  $labelRequired
                  $tooltip="Client secret from entra"
                  {...field}
                />
              )}
            />
            {errors.clientSecret && <p className="error-message">{errors.clientSecret?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="environment"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Environment is required',
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter Environment"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Environment"
                  $labelRequired
                  $tooltip="Enter the environment you want to connect to"
                />
              )}
            />
            {errors.environment && <p className="error-message">{errors.environment.message}</p>}
          </div>
        </div>
        {!integrationId && !isConnected ? (
          <div className={generalStyles.pageButtons}>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              form="poForm"
              onClick={handleSubmit((data) => onTestConnection(data))}
              disabled={!isDirty || !isValid || isButtonsBlocked}
            >
              Test Connection
            </Button.Main>
            <Button.Main
              $primary
              $style="gray"
              type="button"
              onClick={() => navigate('/integrations/overview/active')}
            >
              Discard
            </Button.Main>
          </div>
        ) : null}
      </Box>
      {isConnected ? (
        <Box $mobExtend $asHolder $noOverflow className={generalStyles.gapFormButtons}>
          <div className={generalStyles.fieldsFour}>
            <div className="inp-container">
              <Label $title="Company ID" $isRequired $tooltipText="Select company you want to connect to" />
              <Controller
                name="companyId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Company ID is required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Company ID"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={companies}
                  />
                )}
              />
              {errors.companyId && <p className="error-message">{errors.companyId.message}</p>}
            </div>
          </div>
          {companyIdWatcher ? (
            <>
              <Text type={'h3'} weight={600}>Choose the master of the data</Text>
              <div className={generalStyles.fieldsFive}>
                <Checkbox
                  {...register('enabledModules[]')}
                  defaultValue={'PURCHASE_ORDERS'}
                  defaultChecked={true}
                  $size={20}
                  label={"Purchase Orders"}
                  disabled={true}
                />
                <Checkbox
                  {...register('enabledModules[]')}
                  defaultValue={'VENDORS'}
                  defaultChecked={Array.isArray(values?.enabledModules) && values?.enabledModules?.includes('VENDORS')}
                  $size={20}
                  label={"Vendors"}
                />
                <Checkbox
                  {...register('enabledModules[]')}
                  defaultValue={'RECEIPTS'}
                  defaultChecked={Array.isArray(values?.enabledModules) && values?.enabledModules?.includes('RECEIPTS')}
                  $size={20}
                  label={"Receipts"}
                />
                <Checkbox
                  {...register('enabledModules[]')}
                  defaultValue={'INVOICES'}
                  defaultChecked={Array.isArray(values?.enabledModules) && values?.enabledModules?.includes('INVOICES')}
                  $size={20}
                  label={"Invoices"}
                />
                <Checkbox
                  {...register('enabledModules[]')}
                  defaultValue={'PAYMENTS'}
                  defaultChecked={Array.isArray(values?.enabledModules) && values?.enabledModules?.includes('PAYMENTS')}
                  $size={20}
                  label={"Payments"}
                />
              </div>
              <div className={generalStyles.pageButtons}>
                <Button.Main
                  $primary
                  $style="pink"
                  type="submit"
                  form="poForm"
                  onClick={handleSubmit((data) => onSaveConfiguration(data))}
                  disabled={!isDirty || !isValid}
                >
                  Save configuration
                </Button.Main>
                <Button.Main
                  $primary
                  $style="gray"
                  type="button"
                  onClick={() => navigate('/integrations/overview/active')}
                >
                  Discard
                </Button.Main>
              </div>
            </>
          ) : null}
        </Box>
      ) : null}
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb}/>: null}
    </>
  );
};

export default IntegrationsAdd;
