import React, {useEffect, useMemo, useRef, useState} from 'react';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import DatePicker from 'react-datepicker';
import styles from '../../styles/requisition.module.css';
import Textarea from '../../components/UI/Forms/Textarea';
import Documents from '../../components/UI/Forms/Documents';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import InvoiceItem from '../../components/Admins/PurchaseOrders/View/InvoiceItem';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import {useAccessAllowed} from "../../hooks/useAccessAllowed";
import TabsSlider from "../../components/UI/General/TabsSlider";
import {regExps} from "../../utils/regExps";
import ApprovalTab from "../../components/Admins/PurchaseOrders/Tabs/ApprovalTab";
import Title from "../../components/Admins/PurchaseOrders/View/Title";
import Icon from "../../components/UI/General/Icon";
import moment from "moment";

const PurchaseOrdersInvoice = () => {
  const { orderId, invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = useStore((state) => state.activeCompany?.id);
  const userId = useStore((state) => state.user?.id);
  const [values, setValues] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [tab, setTab] = useState('notes');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false)
  const [full, setFull] = useState(0)
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false);
  let isCreator = useRef(false);
  let isRoboUser = useRef(false)
  let hasRoboUser = useRef(false)

  const invoiceDefault = {
    invoiceNumber: '',
    invoiceIssueDate: '',
    invoiceReceivedDate: '',
    invoiceDueDate: '',
    invoiceNotes: '',
    totalTaxAmount: 0,
    subTotal: 0,
    totalInvoiceAmount: 0,
    invoiceItems: [],
    files: [],
  };

  const invoiceItemDefault =  {
    id: null,
    description: '',
    generalLedgerId: '',
    receivedQuantity: 0,
    quantityInvoiced: 0,
    unitPrice: 0,
    taxId: '',
    subtotal: 0,
    taxTotal: 0,
    totalCost: 0,
    itemNotes: '',
  }

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: invoiceDefault,
    values,
  });

  const accessInvoicing = useAccessAllowed('Invoicing');

  const { fields, append, remove } = useFieldArray({
    name: 'invoiceItems',
    control,
  });

  const formValues = useWatch({
    name: 'invoiceItems',
    control,
  });

  const notesWatcher = useWatch({name:"invoiceNotes", control})

  const quantityInvoicedSum = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.quantityInvoiced) || 0), 0) : 0;
  },[formValues]);

  const subtotal = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.subtotal) || 0), 0) : 0;
  }, [formValues]);
  const taxTotal = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.taxTotal) || 0), 0) : 0;
  }, [formValues]);
  const totalCost = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.totalCost) || 0), 0) : 0;
  }, [formValues]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService.getTaxList(companyId).then((r) => {
          if (r.data.message === 'Operation Successful') {
            console.log(r.data.data);
            const temp = r.data.data
              .filter((item) => item.active === true)
              .map((item) => ({
                value: { percentage: item.percentage, id: item.id },
                type: item.type,
              }));
            temp.splice(0, 0, {
              value: { id: '0', percentage: 0 },
              type: 'No tax',
            });
            setTaxes(temp);
          }
        }),
      ]);
    };
    if (companyId !== null || companyId !== undefined) {
      fetchData();
    }
  }, [companyId]);

  useEffect(() => {
    const fetchOrder = async () => {
      await spmsServiceService.getPurchaseOrder(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data)
          const { purchaseOrderItems, currency, totalInvoicedAmount, total, purchaseOrderNo, invoiceStatus} = r.data.data;
          if (invoiceId === undefined && purchaseOrderItems !== undefined) {
            if (purchaseOrderItems.length > 0) {
              setValues({
                currency,
                totalInvoicedAmount,
                invoiceStatus,
                purchaseOrderNo: purchaseOrderNo,
                invoiceItems: purchaseOrderItems.map((item) => ({
                  ...item,
                  subtotal: 0,
                  taxTotal: 0,
                  totalCost: 0,
                  generalLedgerId: {label: item.glAccount.glCode, value: item.glAccount.id, text: item.glAccount.description},
                  taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || '0'))[0],
                })),
              });
            }
          }else {
            setValues(s => ({...s,totalInvoicedAmount, purchaseOrderNo: purchaseOrderNo, poTotal: total}))
          }
          hasRoboUser.current = r.data.data.requestOnBehalfOf.userId !== ''
          isRoboUser.current = r.data.data.requestOnBehalfOf.userId === userId
        }
      });
    };
    if (orderId !== undefined) {
      fetchOrder();
    }
  }, [orderId, invoiceId, taxes, userId]);

  useEffect(() => {
    const fetchInvoice = async () => {
      await spmsServiceService.getPurchaseOrderInvoice(orderId, invoiceId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data)
          const { invoiceItems, invoiceDueDate, invoiceIssueDate, invoiceReceivedDate, ...other } = r.data.data;
          const newInvoiceItems = invoiceItems.map((item) => ({
            ...item,
            id: item?.purchaseOrderItem?.id,
            subtotal: item.unitPrice * item.quantityInvoiced,
            taxTotal: item.taxAmount,
            totalCost: item.totalCost,
            description: item?.purchaseOrderItem?.description ?? item?.description,
            receivedQuantity: item?.purchaseOrderItem?.receivedQuantity ?? 0,
            generalLedgerId: { label: item.glAccount.glCode, value: item.glAccount.id, text: item.glAccount.description },
            taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || '0'))[0],
          }));
          isApprover.current = r.data.data.approval?.approvers?.filter(item => item.userId === userId).length ?? 0;
          isCurrentUserApproved.current = r.data.data.approval?.approvers?.find(item => item.userId === userId)?.status === "APPROVED" ?? false;
          isCreator.current = r.data.data.createdBy.userId === userId
          setValues(s => ({
            ...s,
            ...other,
            invoiceItems: newInvoiceItems,
            invoiceIssueDate: new Date(invoiceIssueDate),
            invoiceDueDate: new Date(invoiceDueDate),
            invoiceReceivedDate: new Date(invoiceReceivedDate),
          }));
        }
      });
    };
    if (orderId !== undefined && invoiceId !== undefined) {
      fetchInvoice();
    }
  }, [invoiceId, orderId, taxes, userId]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data, type) => {
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const { files, invoiceItems } = data;
    const newItemInvoices = invoiceItems !== undefined && invoiceItems.length > 0 ? invoiceItems.map((item) => ({
      purchaseOrderItem: { id: item.id },
      glAccount: { id: item.generalLedgerId.value },
      quantityInvoiced: item.quantityInvoiced,
      tax: { id: item.taxId.value.id },
      unitPrice: item.unitPrice,
      subTotal: item.subtotal,
      taxAmount: item.taxTotal,
      totalCost: item.totalCost,
      description: item.description
    })) : [];

    let newPurchaseOrderAttachments = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newPurchaseOrderAttachments = await Promise.all(filesArray).then((result) => result);
    }
    if (!newPurchaseOrderAttachments?.length) {
      setIsButtonsBlocked(false)
      setTab('invoices');
      requiredRef.current = true
      setError('files', { type: 'focus', message: 'Files required' });
      return;
    }
    const newData = {
      currency: values?.currency,
      status: values?.invoiceStatus,
      invoiceNumber: data.invoiceNumber,
      invoiceIssueDate: data.invoiceIssueDate,
      invoiceReceivedDate: data.invoiceReceivedDate,
      invoiceDueDate: data.invoiceDueDate,
      notes: data.invoiceNotes === '' ? null : data.invoiceNotes,
      totalTaxAmount: taxTotal,
      subTotal: subtotal,
      totalInvoiceAmount: totalCost,
      attachments: newPurchaseOrderAttachments,
      invoiceItems: newItemInvoices,
    };
    console.log('InvoiceMode', newData);
    if (orderId && invoiceId === undefined) {
      await spmsServiceService.createPurchaseOrderInvoice(orderId, newData).then((r) => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Invoice submitted for approval successfully',
          type: 'success',
          cb: () => navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: "invoices" } }),
        });
      })
      .catch((reason) => {
        console.log(reason);
        setIsButtonsBlocked(false)
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      });
    }
    if (orderId && invoiceId && type === 'edit') {
      await spmsServiceService.updatePurchaseOrderInvoice(orderId, invoiceId, newData).then((r) => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Invoice updated successfully',
          type: 'success',
          cb: () => navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: "invoices" } }),
        });
      })
      .catch((reason) => {
        console.log(reason);
        setIsButtonsBlocked(false)
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      });
    }
  };

  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  const varianceCalc = () => totalCost > values?.poTotal ? totalCost - values?.poTotal : 0

  const allowApprove = () => isRoboUser.current ? true : isCreator.current && !hasRoboUser.current;

  const approveReject = async (action) => {
    setToast((item) => ({ ...item, opened: false }));
    const body = {
      approved: action === 'approved',
      reason: notesWatcher,
    };
    if (action === 'rejected' && (notesWatcher === '' || notesWatcher === undefined)) {
      setTab('notes');
      setError('invoiceNotes', {
        type: 'focus',
        message: 'Please add the reason for the invoice rejecting',
      });
    }else if(action === 'approved' && allowApprove() && (notesWatcher === '' || notesWatcher === undefined) && varianceCalc() !== 0){
      setTab('notes');
      setError('invoiceNotes', {
        type: 'focus',
        message: 'Please specify the reason for the invoice variance.',
      });
    }else {
      setIsButtonsBlocked(true)
      await spmsServiceService
        .approvePurchaseOrderInvoice(orderId, invoiceId, body)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Invoice ' + action + ' successfully',
            type: 'success',
            cb: () => navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: "invoices" } }),
          });
        })
        .catch((reason) => {
          setIsButtonsBlocked(false)
          setToast({
            opened: true,
            message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
            type: 'fail',
          });
        });
    }
  };

  const showToCreator = useMemo(()=>{
    return values?.approvalStatus === "PENDING_APPROVAL" && values?.createdBy?.userId === userId
  },[userId, values])

  return (
    <>
      <Title
        title="Invoice"
        poNumber={values?.purchaseOrderNo}
        poState={values?.approvalStatus}
      />
      <Box $mobExtend $asHolder>
        <form id="invForm">
          <div className={generalStyles.fieldsFour}>
            <div className="inp-container">
              {invoiceId !== undefined && !showToCreator ? (
                <>
                  <Label $title="Invoice Number"/>
                  <Text type={"body-1"}>{values?.invoiceNumber}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="invoiceNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Invoice Number is required',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Maximum 50 characters',
                      },
                      validate: {
                        onlyAllowed: (v) => /^[A-Za-z0-9@&()-]*$/.test(v) || 'Not allowed symbols',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Enter Invoice Number"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        $label="Invoice Number"
                        $labelRequired
                        disabled={invoiceId !== undefined && !showToCreator}
                        {...field}
                      />
                    )}
                  />
                  {errors.invoiceNumber && <p className="error-message">{errors.invoiceNumber?.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {invoiceId !== undefined && !showToCreator ? (
                <>
                  <Label $title="Invoice Issue Date"/>
                  <Text type={"body-1"}>{moment(values?.invoiceIssueDate).format('DD/MM/YYYY')}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="invoiceIssueDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Invoice Issue Date is required',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Maximum 10 characters',
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Enter Invoice Issue Date"
                        maxDate={new Date()}
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        disabled={invoiceId !== undefined && !showToCreator}
                        customInput={
                          <Input
                            {...field}
                            $label="Invoice Issue Date"
                            $labelRequired
                            className={errors.hasOwnProperty(field.name) && 'error'}
                          />
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          trigger(field.name);
                        }}
                      />
                    )}
                  />
                  {errors.invoiceIssueDate && <p className="error-message">{errors.invoiceIssueDate?.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {invoiceId !== undefined && !showToCreator ? (
                <>
                  <Label $title="Invoice Received Date"/>
                  <Text type={"body-1"}>{moment(values?.invoiceReceivedDate).format('DD/MM/YYYY')}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="invoiceReceivedDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Invoice Received Date is required',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Maximum 10 characters',
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Enter Invoice Received Date"
                        maxDate={new Date()}
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        disabled={invoiceId !== undefined && !showToCreator}
                        customInput={
                          <Input
                            {...field}
                            $label="Invoice Received Date"
                            $labelRequired
                            className={errors.hasOwnProperty(field.name) && 'error'}
                          />
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          trigger(field.name);
                        }}
                      />
                    )}
                  />
                  {errors.invoiceReceivedDate && <p className="error-message">{errors.invoiceReceivedDate?.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {invoiceId !== undefined && !showToCreator ? (
                <>
                  <Label $title="Invoice Due Date"/>
                  <Text type={"body-1"}>{moment(values?.invoiceDueDate).format('DD/MM/YYYY')}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="invoiceDueDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Invoice Due Date is required',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Maximum 10 characters',
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Enter Invoice Due Date"
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        disabled={invoiceId !== undefined && !showToCreator}
                        customInput={
                          <Input
                            {...field}
                            $label="Invoice Due Date"
                            $labelRequired
                            className={errors.hasOwnProperty(field.name) && 'error'}
                          />
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          trigger(field.name);
                        }}
                      />
                    )}
                  />
                  {errors.invoiceDueDate && <p className="error-message">{errors.invoiceDueDate?.message}</p>}
                </>
              )}
            </div>
          </div>

          <div className={styles.items}>
            <div className={styles.itemsTable}>
              <div className={styles.itemsInvoiceTableHead}>
                <Text type="body-1" weight={500}>
                  Description
                </Text>
                <Text type="body-1" weight={500}>
                  GL Code
                </Text>
                <Text type="body-1" weight={500}>
                  Qty Received
                </Text>
                <Text type="body-1" weight={500}>
                  Qty Invoiced
                </Text>
                <Text type="body-1" weight={500}>
                  Unit Price
                </Text>
                <Text type="body-1" weight={500}>
                  Tax
                </Text>
                <Text type="body-1" weight={500}>
                  Subtotal
                </Text>
              </div>
              <div className={styles.itemsTableBody}>
                {fields.map((field, index) => {
                  return (
                    <InvoiceItem
                      key={field.id}
                      field={field}
                      index={index}
                      register={register}
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      taxes={taxes}
                      viewOnly={invoiceId !== undefined && !showToCreator}
                      name="invoiceItems"
                      full={full}
                      remove={remove}
                      canRemove={showToCreator || invoiceId === undefined}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.itemsAdd}>
            {invoiceId === undefined || showToCreator ? (
              <Button.ActionLabeled onClick={() => append(invoiceItemDefault)}>
                <Button.Action
                  $style="pink"
                  $variant="circle"
                  $width={20}
                  $height={20}
                  type="button"
                >
                  <Icon $width={18} $height={18} $icon="plus" $color="white"/>
                </Button.Action>
                <Text weight={700} type="subtitle">
                  Add Line
                </Text>
              </Button.ActionLabeled>
            ) : null}
            </div>
            <Summary
              subtotal={subtotal}
              taxTotal={taxTotal}
              totalCost={totalCost}
              currency={values?.currency?.code}
              // totalInvoicedAmount={values?.totalInvoicedAmount}
              variance={varianceCalc()}
            />
          </div>

          <TabsSlider selected={tab} className={generalStyles.tabs}>
            <span onClick={() => setTab('notes')}>Notes</span>
            <span onClick={() => setTab('invoices')}>Invoices</span>
            {values?.approvalStatus ? (
              <span onClick={() => setTab('approvers')}>Approvers</span>
            ) : null}
          </TabsSlider>

          <div className={styles.attachmentsBlock}>
            {tab === 'notes' && (
              <div className={styles.attachmentsBlockItem}>
                {invoiceId === undefined || (values?.approvalStatus === "PENDING_APPROVAL" && accessInvoicing?.approve && isApprover.current > 0 && !isCurrentUserApproved.current) || showToCreator ? (
                  <div className="inp-container">
                    <Controller
                      name="invoiceNotes"
                      control={control}
                      rules={{
                        pattern: {
                          value: regExps.notes,
                          message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                        },
                      }}
                      render={({ field }) => (
                        <Textarea
                          {...field}
                          $low
                          $counter
                          $counterMax={500}
                          placeholder="Notes text goes here"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                        />
                      )}
                    />
                    {errors.invoiceNotes && <p className="error-message">{errors.invoiceNotes?.message}</p>}
                  </div>
                ) : null}
                {<NotesTab data={values?.notes} />}
              </div>
            )}
            {tab === 'invoices' && (
              <div className={styles.attachmentsBlockItem}>
                {!!values?.attachments?.length && <QuotesTab data={values?.attachments} />}
                {invoiceId === undefined || showToCreator ? (
                  <div>
                    <Label $title="Upload Supporting Documents"/>
                    <Documents
                      control={control}
                      fieldName="files"
                      filesSchema={files}
                      error={errors?.files?.message || errors?.files?.root?.message}
                      required={requiredRef.current}
                      clearErrors={clearErrors}
                      isDisable={invoiceId !== undefined && values?.createdBy?.userId !== userId && values?.approvalStatus === "PENDING_APPROVAL"}
                    />
                  </div>
                ) : null}
              </div>
            )}
            {tab === 'approvers' && (
              <ApprovalTab data={values?.approval?.approvers}/>
            )}
          </div>
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        {invoiceId !== undefined && showToCreator ? (
          <Button.Main
            $primary
            $style="pink"
            type="submit"
            form="invForm"
            onClick={handleSubmit((data) => onSubmit(data, 'edit'))}
            disabled={!isDirty || !isValid || isButtonsBlocked || !quantityInvoicedSum}
          >
            Save
          </Button.Main>
        ) : null}
        {invoiceId !== undefined && (values?.approvalStatus === "PENDING_APPROVAL" && accessInvoicing?.approve && isApprover.current > 0 && !isCurrentUserApproved.current) && (
          <>
            <Button.Main
              $primary
              $style="pink"
              type="button"
              onClick={() => approveReject('approved')}
              disabled={isButtonsBlocked || !isValid}
            >
              Approve
            </Button.Main>
            <Button.Main
              $primary
              $style="gray"
              type="button"
              disabled={isButtonsBlocked || !isValid}
              onClick={() => approveReject('rejected')}
            >
              Reject
            </Button.Main>
          </>
        )}
        {orderId !== undefined && invoiceId === undefined && (
          <>
            <Button.Main
              $primary
              $style="pink"
              type="button"
              onClick={() => setFull(s => s+1)}
              disabled={isButtonsBlocked}
            >
              Invoice in Full
            </Button.Main>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              form="invForm"
              onClick={handleSubmit((data) => onSubmit(data))}
              disabled={!isDirty || !isValid || isButtonsBlocked || !quantityInvoicedSum}
            >
              Submit Invoice
            </Button.Main>
            <Button.Main
              $primary
              $style="gray"
              type="button"
              onClick={() => navigate('/purchase-orders/' + orderId, {state: {from: location?.state?.from}})}
            >
              Discard
            </Button.Main>
          </>
        )}
        {invoiceId !== undefined && (
          <Button.Main
            $primary
            $style="gray"
            type="button"
            onClick={() => navigate('/purchase-orders/' + orderId, {state: {tabToSwitch: "invoices", from: location?.state?.from}})}
          >
            Back
          </Button.Main>
        )}
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default PurchaseOrdersInvoice;
