import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { regExps } from '../regExps';
import {documentsSchema} from "./validateDocuments";

const vendorSchema = yup.object().shape({
  registrationNo: yup
    .string()
    .required('Registration No is required')
    .max(30, '30 characters max')
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters'),
  vendorType: yup.object().shape({
    value: yup.string().required('Vendor Type is required'),
  }),
  legalName: yup
    .string()
    .required('Legal name is required')
    .matches(/^[a-zA-Z0-9@&()-\s]+$/, 'Invalid characters')
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .max(50, '50 characters max'),
  vendorAddressLine1: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Suite/Building Name cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/\s]+$/, 'Invalid characters')
    .max(50, 'Suite/Building Name must be at most 50 characters'),
  vendorAddressLine2: yup
    .string()
    .required('Street is required')
    .test('no-empty-spaces', 'Should not be empty', (value) => value.trim() !== '')
    .matches(/^[a-zA-Z0-9'/\s]+$/, 'Invalid characters')
    .max(50, '50 characters max'),
  vendorCity: yup
    .string()
    .required('City is required')
    .test('no-empty-spaces', 'Should not be empty', (value) => value.trim() !== '')
    .matches(/^[a-zA-Z\s]+$/, 'Invalid characters')
    .max(25, '25 characters max'),
  vendorZipCode: yup
    .string()
    .required('Postal Code is required')
    .matches(/^[a-zA-Z0-9-]+$/, 'Invalid characters')
    .max(15, 'Postal Code must be at most 15 characters'),
  vendorCountry: yup.object().shape({
    value: yup.string().required('Country is required'),
  }),

  vendorState: yup.object().shape({
    value: yup.string().required('Region is required'),
  }),
  vendorPhoneNumber: yup
    .string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', (value) => {
      return isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '');
    }),
  vendorEmail: yup
    .string()
    .required('Email is required')
    .matches(regExps.email, 'Invalid email address')
    .max(50, 'E-mail must be at most 50 characters'),
  vendorNotes: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .matches(regExps.notes, 'Invalid characters')
    .max(500, '500 characters max'),
});

const vendorExtendedSchema = vendorSchema.shape({
  vendorCode: yup
    .string()
    .required('Vendor code is required')
    .max(15, '15 characters max')
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters'),
  onceOffPOLimit: yup
    .string()
    .required('Once Off PO Limit is required')
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .matches(/^[0-9]+$/, 'Invalid characters')
    .max(10, '10 characters max'),
  overallAnnualLimit: yup
    .string()
    .required('Overall Annual Limit is required')
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .matches(/^[0-9]+$/, 'Invalid characters')
    .max(10, '10 characters max'),
  defaultCurrency: yup.object().shape({
    value: yup.string().required('Default Currency is required'),
  }),
  vendorNotes: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .matches(/^[a-zA-Z0-9@&()-\s]+$/, 'Invalid characters')
    .max(500, '500 characters max'),
  vendorAttachments: documentsSchema
});

export { vendorSchema, vendorExtendedSchema };
