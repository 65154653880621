import requestsServiceService from '../services/requestsService.service';

export const countriesSlice = (set) => ({
  countries: null,
  regions: null,
  fetchCountries: async () => {
    await requestsServiceService.listCountries().then((res) => {
      const data = res.data.data.map((item) => ({
        label: item.countryName,
        value: item.countryName,
        code: item.countryCode,
        currency: item.currency,
      }));
      set({ countries: data });
    });
  },
  fetchRegions: async () => {
    await requestsServiceService.regionsList().then((res) => {
      const data = res.data.data.map((item) => ({
        label: item.regionName,
        value: item.regionName,
        code: item.countryCode,
      }));
      set({ regions: data });
    });
  },
});
