import React, { useCallback, useEffect, useState } from 'react';
import styles from '../../styles/vendor.module.css';
import * as Button from '../../components/UI/Forms/Button';
import generalStyles from '../../styles/general.module.css';
import Label from '../../components/UI/Forms/Label';
import MultiSelectOpened from '../../components/UI/Forms/MultiSelectOpened';
import { Controller, useForm } from 'react-hook-form';
import Box from '../../components/UI/General/Box';
import spmsServiceService from '../../services/spmsService.service';
import requestsServiceService from '../../services/requestsService.service';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';

const ConfigurationTab = ({ vendorSetupData, setToast }) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm();
  const { companyId, vendorId } = useParams();
  const accessVendor = useAccessAllowed('Vendors', companyId);
  const [page, setPage] = useState(0);
  const [allGlAmount, setAllGlAmount] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [options, setOptions] = useState({
    branches: [],
    departments: [],
    users: [],
  });
  const [glOptions, setGlOptions] = useState([]);

  const navigate = useNavigate();

  const onSubmit = (forApproval) => async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    try {
      const newData = {
        ...vendorSetupData,
        branchIds: data.branchIds ? data.branchIds.map((branch) => branch.value) : [],
        departmentIds: data.departmentIds
          ? data.departmentIds.map((department) => department.value)
          : [],
        glAccountIds: data.glIds ? data.glIds.map((gl) => gl.value) : [],
        userIds: data.userIds ? data.userIds.map((user) => user.value) : [],
      };
      console.log('newData', newData);
      await spmsServiceService.editVendor(companyId, vendorId, newData);
      if (forApproval || vendorSetupData.vendorStatus === 'APPROVED') {
        await spmsServiceService.submitVendorApproval(companyId, vendorId);
        navigate('/vendors/pending-approval');
        return;
      }
      setToast({
        opened: true,
        message: 'Configuration updated',
        type: 'success',
      });
      if (vendorSetupData?.vendorStatus !== 'APPROVED') navigate('/vendors/draft');
    } catch (err) {
      setToast({
        opened: true,
        message: err.message,
        type: 'fail',
      });
    }
  };
  console.log('options', options);
  const handlePreload = (data, name, options) => {
    if (!data?.length) return;
    const itemIds = data.map((item) => item.id);
    setValue(
      name,
      options.filter((option) => itemIds.includes(option.value)),
    );
  };
  useEffect(() => {
    if (!vendorSetupData) return;
    handlePreload(vendorSetupData.branches, 'branchIds', options.branches);
    handlePreload(vendorSetupData.departments, 'departmentIds', options.departments);
    handlePreload(vendorSetupData.glAccounts, 'glIds', glOptions);
    handlePreload(
      vendorSetupData?.users?.map((user) => ({ id: user.userId })),
      'userIds',
      options.users,
    );
  }, [vendorId, options]);

  useEffect(() => {
    const optionsData = {};
    Promise.all([
      spmsServiceService
        .getBranches(companyId)
        .then((r) => {
          const activeBranches = r.data.data.filter((branch) => !!branch.active);
          optionsData.branches = activeBranches.map((branch) => ({
            label: branch.name,
            value: branch.id,
          }));
        })
        .catch((err) => {
          console.log(err.message);
          optionsData.branches = [];
        }),
      spmsServiceService
        .getDepartments(companyId)
        .then((r) => {
          const activeDepartments = r.data.data.filter((department) => !!department.active);
          optionsData.departments = activeDepartments.map((department) => ({
            label: department.name,
            value: department.id,
          }));
        })
        .catch((err) => {
          console.log(err.message);
          optionsData.departments = [];
        }),
      requestsServiceService
        .getData(100, 0, { companyId: companyId, isEnabled: true })
        .then((r) => {
          optionsData.users = r.data.data.content.map((user) => ({
            label: user.firstName + ' ' + user.lastName,
            value: user.id,
          }));
        })
        .catch((err) => {
          console.log(err.message);
          optionsData.users = [];
        }),
    ]).then((_res) => setOptions(optionsData));
  }, [companyId]);

  const fetchGlLines = useCallback(async () => {
    if (Math.round(allGlAmount / 10) >= page) {
      const result = await spmsServiceService.getFilteredGlAccounts(companyId, page, 10, {
        active: true,
        glCode: debouncedValue,
      });
      const glData = result.data.data.content;
      const allGls = result.data.data.totalElements;
      if (!allGlAmount) setAllGlAmount(allGls);
      const newglOptions = glData.map((gl) => ({
        label: 'GL-' + gl.glCode,
        value: gl.id,
      }));
      setGlOptions((glOptions) => (page === 0 ? newglOptions : [...glOptions, ...newglOptions]));
    }
  }, [page, debouncedValue]);

  useEffect(() => {
    if (!companyId) return;
    fetchGlLines();
  }, [fetchGlLines, companyId, debouncedValue]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
      setPage(0);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const searchByFirstCharacter = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <>
      <Box $mobExtend $asHolder $radius={12}>
        <form>
          <div className={generalStyles.fieldsTwo}>
            <div className={styles.limitContainer}>
              <div>
                <Label $title="GL" />
                <Controller
                  name="glIds"
                  control={control}
                  render={({ field }) => (
                    <MultiSelectOpened
                      {...field}
                      isDisabled={!accessVendor?.edit}
                      className="react-select-container"
                      placeholder="GLs"
                      filterOption={searchByFirstCharacter}
                      onInputChange={(value) => {
                        setFilterText(value);
                      }}
                      onMenuScrollToBottom={() => {
                        if (Math.floor(allGlAmount / 10) > page) {
                          setPage((page) => page + 1);
                        }
                      }}
                      options={glOptions}
                      control={control}
                    />
                  )}
                />
              </div>
              <p>Select the GL you would like to limit the vendor to.</p>
            </div>
            <div className={styles.limitContainer}>
              <div>
                <Label $title="Department" />
                <Controller
                  name="departmentIds"
                  control={control}
                  render={({ field }) => (
                    <MultiSelectOpened
                      isDisabled={!accessVendor?.edit}
                      {...field}
                      className="react-select-container"
                      placeholder="Department"
                      options={options.departments}
                      control={control}
                    />
                  )}
                />
              </div>
              <p>Select the Department you would like to limit the vendor to.</p>
            </div>
            <div className={styles.limitContainer}>
              <div>
                <Label $title="Branch" />
                <Controller
                  name="branchIds"
                  control={control}
                  render={({ field }) => (
                    <MultiSelectOpened
                      {...field}
                      isDisabled={!accessVendor?.edit}
                      className="react-select-container"
                      placeholder="Branch"
                      options={options.branches}
                      control={control}
                    />
                  )}
                />
              </div>

              <p>Select the Branch you would like to limit the vendor to.</p>
            </div>
            <div className={styles.limitContainer}>
              <div>
                <Label $title="Users" />
                <Controller
                  name="userIds"
                  control={control}
                  render={({ field }) => (
                    <MultiSelectOpened
                      {...field}
                      isDisabled={!accessVendor?.edit}
                      className="react-select-container"
                      placeholder="Users"
                      options={options.users}
                      control={control}
                    />
                  )}
                />
              </div>
              <p>Select the User you would like to limit the vendor to.</p>
            </div>
          </div>
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        {vendorSetupData?.vendorStatus === 'APPROVED' ? (
          <Button.Main
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit(false))}
            $primary
            $style="pink"
            type="submit"
          >
            Update
          </Button.Main>
        ) : (
          <>
            <Button.Main
              onClick={handleSubmit(onSubmit(false))}
              $primary
              $style="pink"
              type="submit"
            >
              Save as Draft
            </Button.Main>
            <Button.Main
              onClick={handleSubmit(onSubmit(true))}
              $primary
              $style="gray"
              type="submit"
            >
              Submit for Approval
            </Button.Main>
          </>
        )}

        <Button.Main
          $primary
          type="button"
          $style="gray"
          onClick={() => {
            reset();
            navigate('/vendors/draft');
          }}
        >
          Discard
        </Button.Main>
      </div>
    </>
  );
};

export default ConfigurationTab;
