import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Icon from '../../components/UI/General/Icon';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import spmsServiceService from '../../services/spmsService.service';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../components/UI/Modal/Modal';
import React, { Suspense, useState } from 'react';
import ExpandedVendor from './ExpandedVendor';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';

const DraftVendorOverview = ({ companyId, fetchVendors, setRefetchTrigger }) => {
  const [vendorToDelete, setVendorToDelete] = useState({ id: null, name: null });
  const navigate = useNavigate();
  const accessVendor = useAccessAllowed('Vendors');

  const deleteVendor = async (vendorId) => {
    await spmsServiceService.deleteVendor(companyId, vendorId).then((_res) => {
      setRefetchTrigger((refetchTrigger) => !refetchTrigger);
      setVendorToDelete({ id: null, name: null });
    });
  };
  const closeModal = () => {
    setVendorToDelete({ id: null, name: null });
  };
  const columns = [
    {
      name: 'Vendor Name',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Vendor Name'} className={generalStyles.tableValue}>
          <Link
            to={`/vendors/${companyId}/edit/${row.vendor.id}`}
            className={styles.tableLink}
            weight={500}
          >
            <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>
              {row.vendor.legalName}
            </span>
          </Link>
        </span>
      ),
    },
    {
      name: 'Vendor Code',
      sortable: true,
      selector: (row) => row.vendorCode,
      cell: (row) => (
        <span data-content={'Vendor Code'} className={generalStyles.tableValue}>
          <span>{row.vendorCode}</span>
        </span>
      ),
    },
    {
      name: 'Department',
      cell: (row) => (
        <span data-content={'Department'} className={generalStyles.tableValue}>
          <span>{!!row.departments.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'Branch',
      cell: (row) => (
        <span data-content={'Branch'} className={generalStyles.tableValue}>
          <span>{!!row.branches.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'GL',
      cell: (row) => (
        <span data-content={'GL'} className={generalStyles.tableValue}>
          <span>{!!row.glAccounts.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'User',
      cell: (row) => (
        <span data-content={'User'} className={generalStyles.tableValue}>
          <span>{!!row.users.length ? 'Limited' : 'All'}</span>
        </span>
      ),
    },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <div className={generalStyles.actionMenuHolder}>
          <Dropdown collapsible className={styles.actionMenu}>
            <Dropdown.Header>
              <Button.Action $style="white" $width={32} $height={32}>
                <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
              </Button.Action>
            </Dropdown.Header>
            <Dropdown.Body>
              <Menu className={styles.actionMenuList}>
                {accessVendor?.edit && (
                  <MenuItem onClick={() => navigate(`/vendors/${companyId}/edit/${row.vendor.id}`)}>
                    Edit
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    setVendorToDelete({ name: row.vendor.legalName, id: row.vendor.id })
                  }
                >
                  Discard
                </MenuItem>
              </Menu>
            </Dropdown.Body>
          </Dropdown>
        </div>
      ),
    },
  ];
  console.log(vendorToDelete);
  return (
    <>
      <Suspense fallback={<h2 className={styles.loader}>Loading...</h2>}>
        <DataTableBaseRemote
          columns={columns}
          fetchData={fetchVendors}
          selectableRows={false}
          paginationResetDefaultPage={false}
          expandableRows
          expandableRowsComponent={ExpandedVendor}
          expandOnRowClicked
        />
      </Suspense>
      <Modal
        $show={!!vendorToDelete.id}
        $close={closeModal}
        $title={`Discard Vendor ${vendorToDelete.name}`}
        $radius={12}
      >
        <div className={generalStyles.modalContainer}>
          <div>Do you want to continue deleting this item?</div>
          <div className={generalStyles.modalButtons}>
            <Button.Main
              onClick={() => deleteVendor(vendorToDelete.id)}
              $primary
              $style="pink"
              type="submit"
              form="cForm"
            >
              Continue
            </Button.Main>
            <Button.Main onClick={closeModal} $primary $style="gray" type="submit" form="cForm">
              Cancel
            </Button.Main>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DraftVendorOverview;
