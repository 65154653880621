import React from 'react';
import styled from "styled-components";
import Text from "./UI/Typography/Text";

const Loader = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  height: 100%;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 32px;
`

const Spinner = styled.span`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid rgba(69, 186, 188, 1);
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid rgba(200, 93, 159, 1);
    border-left: 4px solid transparent;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loading = ({text}) => (
  <Loader>
    <Body>
      <Spinner/>
      <Text type={"h4"} weight={500}>{text}</Text>
    </Body>
  </Loader>
)

export default Loading
