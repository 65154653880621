import React from 'react';
import {Route} from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Registration";
import ForgotResetPassword from "../components/Auth/ForgotResetPassword";

export const userRoutes =
  <>
    <Route index element={<Home />}/>
    <Route path="/" element={<Home />}/>
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/register" element={<Register />} />
    <Route exact path="/reset-password" element={<ForgotResetPassword />} />
  </>
