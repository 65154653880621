import styles from '../../styles/approvalWorkflow.module.css';
import generalStyles from '../../styles/general.module.css';
import { useNavigate, useParams, useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { v4 as uuidv4 } from 'uuid';
import Text from '../../components/UI/Typography/Text';
import Input from '../../components/UI/Forms/Input';
import SingleCondition from '../../components/Admins/ApprovalWorkflow/AddRule/SingleCondition';
import spmsServiceService from '../../services/spmsService.service';
import ApprovalHierarchy from '../../components/Admins/ApprovalWorkflow/AddRule/ApprovalHierarchy';
import Box from '../../components/UI/General/Box.jsx';
import Icon from '../../components/UI/General/Icon';
import requestsService from '../../services/requestsService.service.js';
import Tooltip from '../../components/UI/General/Tooltip';

const ApprovalAddRule = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    unregister,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      hierarchy: 'Auto_Approve',
    },
  });

  const { companyId, ruleId } = useParams();
  const [company, setCompany] = useState(null);
  const [module, setModule] = useState(null);
  const [rule, setRule] = useState();
  const [conditionIds, setConditionIds] = useState(ruleId ? [] : [uuidv4()]);
  const [isAddConditionActive, setIsAddConditionActive] = useState(false);
  const [users, setUsers] = useState([]);
  const hierarchy = watch('hierarchy');

  useEffect(() => {
    unregister(['approvers']);
    if (hierarchy !== 'Auto_Approve' && !users.length) {
      requestsService
        .getData(100, 0, {
          isEnabled: true,
          companyId,
        })
        .then((res) => {
          const users = res.data.data.content;
          const activeUsers = users.filter((user) => user.enabled);
          setUsers(activeUsers);
        });
    }
  }, [hierarchy]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('preOpenedRule', companyId);
    return () => {
      if (!location.pathname.includes('add') || !location.pathname.includes('edit')) {
        localStorage.removeItem('preOpenedRule');
      }
    };
  }, [location]);

  useEffect(() => {
    spmsServiceService
      .getCompany(companyId)
      .then((res) => setCompany(res.data.data))
      .catch((err) => console.log(err));
    if (location.state) {
      spmsServiceService
        .getApprovalModuleById(companyId, location.state.moduleId, true)
        .then((res) => {
          const reqModule = res.data.data;
          setModule(reqModule);
          if (!ruleId || !res.data.data.rules) return;
          const currentRule = res.data.data.rules.find((rule) => rule.id === ruleId);
          setRule(currentRule);

          const conditions = currentRule.approvalMatrixRuleConditions;
          setConditionIds(
            !!conditions.length ? conditions.map((condition) => condition.id) : [uuidv4()],
          );
          setValue('name', currentRule.name);
          setValue('description', currentRule.description);
          setValue('hierarchy', currentRule.approvalHierarchyType);
        });
    }
  }, []);

  const addNewCondition = () => {
    setConditionIds((conditionIds) => [...conditionIds, uuidv4()]);
  };

  const deleteCondition = (id) => {
    unregister([`subject-${id}`, `subjectCondition-${id}`, `conditionValue-${id}`]);
    setValue('dirty', true, { shouldDirty: true });
    const filteredConditionIds = conditionIds.filter((conditionId) => conditionId !== id);
    if (!filteredConditionIds.length) {
      setConditionIds([uuidv4()]);
    } else setConditionIds(filteredConditionIds);
  };

  const getAllSubjects = () => {
    const subjects = [];
    for (const [key, value] of Object.entries(getValues())) {
      if (key.includes('subject-')) subjects.push(value);
    }
    return subjects;
  };

  const onSubmit = async (data) => {
    let conditions = [];
    if (conditionIds.length && data[`subject-${conditionIds[0]}`]) {
      conditions = conditionIds.map((conditionId) => {
        const subject = data[`subject-${conditionId}`].value;
        const subjectCondition = data[`subjectCondition-${conditionId}`].value;
        const conditionValue =
          subject === 'Amount'
            ? data[`conditionValue-${conditionId}`]
            : data[`conditionValue-${conditionId}`].map((value) => value.value).join(',');
        const condition = { subject, subjectCondition, conditionValue };
        if (Number(conditionId)) condition.id = conditionId;
        return condition;
      });
    }
    const approvers = data[`approvers-${data.hierarchy}`] ?? [];
    const approversData = approvers.map((singleApprover) => {
      const approver = users.find((user) => user.id === singleApprover.value);
      return {
        userId: approver.id,
        email: approver.email,
        phoneNumber: approver.phoneNumber,
        limitOfAuthority: approver.customCompanies.find(
          (company) => company.companyId === companyId,
        ).limitOfAuthority,
        name: `${approver.firstName} ${approver.lastName}`,
      };
    });
    const newRule = {
      ...rule,
      name: data.name,
      description: data.description,
      approvalMatrixRuleConditions: conditions,
      approvalHierarchyType: data.hierarchy,
      approvers: approversData,
    };
    let updatedModule;
    if (ruleId) {
      const filteredRules = module.rules.filter((rule) => rule.id !== ruleId);
      updatedModule = { ...module, rules: [...filteredRules, newRule] };
    } else {
      newRule.order = module.rules.length + 1;
      updatedModule = { ...module, rules: [newRule, ...module.rules] };
    }
    spmsServiceService.editApprovalModuleById(companyId, module.id, updatedModule).then((res) => {
      return navigate(-1);
    });
  };
  return (
    <Box $mobExtend $noPadding $asHolder $radius={12} $noOverflow>
      <form className={styles.addRuleContainer}>
        <div className={styles.row}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Rule Name is Required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    allowed: (v) =>
                      /^(?![\s]+$)[A-Za-z0-9\s]+$/.test(v) || 'Alphanumeric characters only',
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Rule Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Rule Name"
                    $labelRequired
                    $tooltip="Summary of rule"
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="description"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Rule Description is Required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    allowed: (v) =>
                      /^(?![\s]+$)[A-Za-z0-9\s]+$/.test(v) || 'Alphanumeric characters only',
                  },
                }}
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Rule Description"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Rule Description"
                    $labelRequired
                    $tooltip="Add short description of the rule"
                    {...field}
                  />
                )}
              />
              {errors.description && <p className="error-message">{errors.description?.message}</p>}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.conditionsTitle}>
            <Text type="h3" weight={600} style={{ display: 'flex', alignItems: 'center' }}>
              Conditions <Tooltip text="Add criteria for the rule to execute" />
            </Text>
          </div>
          <div className={styles.conditions}>
            {conditionIds.map((condtionId) => (
              <SingleCondition
                key={condtionId}
                setValue={setValue}
                watch={watch}
                errors={errors}
                control={control}
                conditionId={condtionId}
                companyBranches={company?.branches}
                companyId={companyId}
                deleteCondition={deleteCondition}
                conditionIds={conditionIds}
                prevCondition={
                  rule?.approvalMatrixRuleConditions.find((cond) => cond.id === condtionId) ?? null
                }
                selectedSubjects={getAllSubjects()}
                setIsAddConditionActive={setIsAddConditionActive}
              />
            ))}
          </div>
          {isAddConditionActive && (
            <div className={styles.addConditionButton}>
              <Button.ActionLabeled onClick={addNewCondition}>
                <Button.Action $style="pink" $variant="circle" $width={20} $height={20}>
                  <div className={styles.icon}>
                    <Icon $width={20} $height={20} $icon="add" $color="white" />
                  </div>
                </Button.Action>
                <Text weight={700} type="subtitle">
                  Add Conditions
                </Text>
              </Button.ActionLabeled>
            </div>
          )}
        </div>
        <div className={styles.container}>
          <ApprovalHierarchy
            errors={errors}
            control={control}
            watch={watch}
            unregister={unregister}
            companyId={companyId}
            currency={company?.defaultCurrency}
            setValue={setValue}
            prevUsers={rule?.approvers}
            users={
              hierarchy === 'LOA'
                ? users.filter((user) => user.id !== module?.defaultApprover?.userId)
                : users
            }
          />
        </div>
        <div className={styles.submitButtons}>
          <div className={generalStyles.pageButtons}>
            <Button.Main
              disabled={!isDirty || !isValid}
              $primary
              $style="pink"
              onClick={handleSubmit(onSubmit)}
            >
              {ruleId ? 'Update' : 'Save'}
            </Button.Main>
            <Button.Main onClick={() => navigate('/approval')} type="button" $primary $style="gray">
              Discard
            </Button.Main>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default ApprovalAddRule;
