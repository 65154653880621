import DataTableBase from '../../components/UI/General/DataTableBase';
import styles from '../../styles/budgets.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text';
import AddNewGl from './AddNewGl';
import moment from "moment";
import React from "react";

const ExpandedComponent = ({ data }) =>  (
  <div className={styles.expandedGlContainer}>
    <Text weight={600}>Budget Allocated </Text>
    <div className={generalStyles.fieldsSix}>
      {data.allocations.sort((a,b) => moment([a.month.split('-')[1], a.month.split('-')[0]-1]).diff(moment([b.month.split('-')[1], b.month.split('-')[0]-1])))?.map((allocation) => (
        <div className={styles.expandedGlLine} key={allocation.month}>
          <Text weight={600}>{moment([allocation.month.split('-')[1], allocation.month.split('-')[0]-1]).format("MMMM YYYY")}:</Text>
          <Text weight={500}>{allocation.amount}</Text>
        </div>
      ))}
    </div>
  </div>
)

const GlListView = ({
  companyId,
  budgetId,
  monthsToRender,
  updateMonthFields,
  setGlMode,
  glMode,
  setToast,
  glItems,
  setEditableGl,
  editableGl,
  columns,
  handleGlLine,
  currency,
}) => {
  const handleRowClick = (_row) => {
    updateMonthFields();
    setEditableGl(null);
    setGlMode({ type: null, active: false });
  };
  return (
    <DataTableBase
      data={glItems || []}
      columns={columns}
      expandOnRowClicked
      expandableRows
      onRowClicked={handleRowClick}
      expandableRowExpanded={() => !!editableGl}
      expandableRowsComponentProps={
        glMode?.type === 'edit' && {
              companyId,
              setGlMode,
              budgetId,
              setToast,
              monthsToRender,
              updateMonthFields,
              glMode,
              editableGl,
              handleRowClick,
              handleGlLine,
              glItems,
              currency,
            }
      }
      expandableRowsComponent={glMode?.type === 'edit' ? AddNewGl : ExpandedComponent}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 15, 20, 30]}
    />
  );
};

export default GlListView;
