import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const siteKey = '6Le7gOspAAAAAGxC6reliKOaKoI8FhnIQcYSWI9y';

const GoogleReCaptcha = ({setCaptchaResponse}) => {
  const onChange = (value) => setCaptchaResponse(value)
  const onExpired = () => setCaptchaResponse(null)

  return (
    <ReCAPTCHA
      sitekey={siteKey}
      onChange={onChange}
      onExpired={onExpired}
    />
  );
};

export default GoogleReCaptcha;
